<template>
  <cgs-modal :visible="visible" title="添加用户" @close="close" @ok="$emit('save')">
    <template #content>
      <cgs-table
        bordered
        size="small"
        emptyText="暂无用户"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: selectRowHandle,
        }"
        rowKey="userId"
        :columns="columns"
        :data-source="userList"
        :pagination="false"
        :scroll="{ y: 400 }"
      />
    </template>
  </cgs-modal>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { getUserList } from "@/api/user";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectUsers: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const selectedRowKeys = computed({
      get() {
        return props.selectUsers;
      },
      set(vals) {
        ctx.emit("update:selectUsers", vals);
      },
    });
    const selectRowHandle = (keys) => {
      selectedRowKeys.value = keys;
    };
    const columns = [
      {
        title: "姓名",
        dataIndex: "name",
      },
    ];
    const userList = ref([]);
    getUserList().then((res) => {
      if (res.success && res.data.length) {
        userList.value = res.data;
      }
    });
    const close = () => {
      ctx.emit("update:visible");
    };
    return {
      selectedRowKeys,
      columns,
      userList,
      selectRowHandle,
      close,
    };
  },
});
</script>
