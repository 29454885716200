<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 19:21:10
!-->

<template>
    <cgs-modal class="portal-menu-authority-role-user-modal" v-model:visible="vis" :title="title" @close="closeModal"
        style="width: 1000px">
        <template #content>
            <div class="portal-menu-authority-role-user-modal-content">
                <div class="portal-menu-authority-role-user-modal-content-wrapper">
                    <cgs-table :columns="columns" :data-source="userList" :pagination="false" mode="portal" size="small"
                        :backgroundTransparent="true" :scroll="{ y: 500 }">
                        <template #bodyCell="{ column, text, index }">
                            <template v-if="column.key === 'index'">
                                <div class="portal-menu-authority-role-role-checkbox">
                                    <span class="portal-menu-authority-role-role-index">{{ index + 1 }}</span>
                                </div>
                            </template>
                            <template v-if="column.key === 'type'">
                                <span v-if="text === 'user'">{{ $t('auth.typeCollection.user') }}</span>
                                <span v-else-if="text === 'department'">{{ $t('auth.typeCollection.department')
                                    }}</span>
                            </template>
                        </template>
                    </cgs-table>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="portal-menu-authority-role-user-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
let columns = [{
    title: '序号/全选',
    dataIndex: 'index',
    key: 'index'
},
{
    title: '名称',
    dataIndex: 'name',
    key: 'name',
},
{
    title: '类型',
    dataIndex: 'type',
    key: 'type',
},
{
    title: '部门',
    dataIndex: 'department',
    key: 'department',
},
{
    title: '加入时间',
    dataIndex: 'addTime',
    key: 'addTime',
}
]

export default defineComponent({
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        userList: {
            type: Array,
            default: () => []
        }
    },

    setup (props, ctx) {
        const { t } = useI18n()
        const state = reactive({})
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })


        let title = computed(() => {

            return props.mode === 'add' ? t('auth.addRole') : t('auth.updateRole')

        })
        onMounted(() => {
            _init()
        })
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                _init()
            }
        })
        const _init = async () => {
            try {
                console.log(1)
            } catch (err) {
                console.error(err)
            }
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => { }
        return {
            vis,
            state,
            title,
            columns,
            close,
            closeModal,
            ok

        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-menu-authority-role-user-modal-content {
    .text-1();
}

.portal-menu-authority-role-user-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>