<template>
  <div>
    <h3 class="portal-home-work-flow-title little-title">{{ $t('homeBench.my') }}</h3>
    <cgs-tabs v-model:activeKey="categoryActiveKey" mode="button" @change="onChangeCategory">
      <cgs-tab-pane v-for="item in categoryList" :key="item.categoryValue">
        <template #tab>
          <span>{{ $t(item.categoryKey) }}</span>
        </template>
      </cgs-tab-pane>
    </cgs-tabs>
    <div class="portal-home-work-collect-app">
      <div class="portal-home-work-collect-app-roll">
        <AppIcon :sceneList="showAppList" @enter="enterScene" @clikMenuItem="clikSceneMenuItem"
          @clikCollect="clikSceneCollect">
        </AppIcon>
      </div>
    </div>
    <div class="portal-home-work-collect-app-more" v-if="state.sceneList.length > 8" @click="showAll = !showAll">{{
      showAll
        ? '折叠'
        : '展示全部'
    }}</div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watch, computed, ref, defineEmits, onMounted } from "vue"
import AppIcon from "../../workSpace/component/appIcon.vue"
import { getWorkSpaceList } from "@/api/workSpace"
const props = defineProps({
  sceneList: {
    type: Array,
    default: undefined
  },
  setting: {
    type: Array,
    default: undefined
  }
})
const state = reactive({
  sceneList: []
})
const categoryList = ref([{
  categoryKey: "homeBench.workSpace",
  categoryValue: "-1",
  id: 1,
  visualType: "CAD"
}, {
  categoryKey: "homeBench.app",
  categoryValue: "0",
  id: 2,
  visualType: "CAD"
}])
const categoryActiveKey = ref("-1")

const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect", "onChangeCategory", "updateApp"])
const showAll = ref(false)
watch(() => props.sceneList, (newVal) => {
  if (newVal) {
    let _list = newVal ? newVal : []
    state.sceneList = JSON.parse(JSON.stringify(_list))
  }
})

watch(() => props.setting, (newVal) => {
  if (newVal) {
    console.log(newVal, "newVal");
    if (newVal.myApp && newVal.myWorkspace) {
      categoryList.value = [{
        categoryKey: "homeBench.workSpace",
        categoryValue: "-1",
        id: 1,
        visualType: "CAD"
      }, {
        categoryKey: "homeBench.app",
        categoryValue: "0",
        id: 2,
        visualType: "CAD"
      }]
    } else if (newVal.myApp) {
      categoryList.value = [{
        categoryKey: "homeBench.app",
        categoryValue: "0",
        id: 2,
        visualType: "CAD"
      }]
      emit("updateApp", "App")
      categoryActiveKey.value = "0"
    } else if (newVal.myWorkspace) {
      categoryList.value = [{
        categoryKey: "homeBench.workSpace",
        categoryValue: "-1",
        id: 1,
        visualType: "CAD"
      }]
      emit("updateApp", "workSpace")
      categoryActiveKey.value = "-1"
    }
  }

}, { deep: true, immediate: true })


onMounted(() => {
  init()
})
const init = async () => {
  if (!props.sceneList) {
    let mySpace = await getWorkSpaceList({ isMyApp: true })
    if (mySpace.code === 200) {
      mySpace.data.forEach(item => {
        item.title = item.name
      })
      state.sceneList = mySpace.data
    }
  } else {
    state.sceneList = JSON.parse(JSON.stringify(props.sceneList))
  }
  if (state.sceneList.length > 0) {
    if (state.sceneList[0].appType) {
      categoryActiveKey.value = "0"
    } else {
      categoryActiveKey.value = "-1"
    }
  }

}
const showAppList = computed(() => {
  let _list = showAll.value ? state.sceneList : state.sceneList.filter((_item, index) => index < 8)
  return _list
})



const enterScene = (scene) => {
  emit("enterScene", scene)
}
const clikSceneMenuItem = (scene, menuItemParams) => {
  emit("clikSceneMenuItem", scene, menuItemParams)
}
const clikSceneCollect = (scene) => {
  emit("clikSceneCollect", scene)
}
const onChangeCategory = (val) => {
  emit("onChangeCategory", val)
}

</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-work-collect-app-more {
  color: @primary-color;
  cursor: pointer;
  margin-left: 5px;

}

.portal-home-work-flow-title {
  font-size: 16px;
  color: @font-color-1;
  margin-bottom: 10px;
}
</style>