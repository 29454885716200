<script setup>
import searchMenuCard from './search-menu-card.vue'
import { ref, defineEmits, defineProps, onMounted, onUnmounted, watch, nextTick } from "vue";
const props = defineProps({
    list: {
        type: Array,
        default: () => {
            return []
        }
    }
})
const menuContainerRef = ref()
const emit = defineEmits(['searchChange', 'cardClick'])
const searchInput = ref('')
const extentShow = ref(false)
const moreShow = ref(false)
const moreRefShow = ref(false)

const handleSearchChange = () => {
    emit('searchChange', searchInput.value)
}

watch(() => props.list, () => {
    nextTick(() => {
        let flag = false
        if (menuContainerRef.value) {
            const element = menuContainerRef.value
            if (element.scrollHeight > element.clientHeight) {
                flag = true
            }
        }
        moreRefShow.value = flag
    })
}, { deep: true })

const handleProjectSelect = (value) => {
    emit('cardClick', value)
}

const handleSearchFocus = () => {
    extentShow.value = true
}
const handleBgClick = () => {
    extentShow.value = false
    moreShow.value = false
}
const handleMoreClick = () => {
    moreShow.value = !moreShow.value
}
onMounted(() => { });
onUnmounted(() => { })
</script>

<template>
    <div class="portal-public-global-search"
        :class="{ 'portal-public-global-search-extent': extentShow, 'portal-public-global-search-radius': props.list.length == 0 }">
        <div class="portal-public-global-search-bg" :class="{ 'portal-public-global-search-bg-active': extentShow }"
            @click.stop="handleBgClick"></div>
        <div class="portal-public-global-search-wrapper">
            <cgs-input @change="handleSearchChange"
                :class="{ 'portal-public-global-search-input': !extentShow, 'portal-public-global-search-input-extent': extentShow }"
                v-model:value="searchInput" :placeholder="$t('globalSearch.placeholder')" @focus="handleSearchFocus">
                <template #prefix>
                    <span
                        class="portal-public-global-search-icon cgs-portal-iconfont cgs-portal-icon-sousuo font-color-2"></span>
                </template>
            </cgs-input>
            <div class="portal-public-global-search-extent-content card-box-shadow"
                v-if="extentShow && props.list.length > 0">
                <div class="portal-public-global-search-extent-wrapper">
                    <div class="portal-public-global-search-menu" ref="menuContainerRef"
                        :class="{ 'portal-public-global-search-menu-more': moreShow }">
                        <search-menu-card v-for="item in props.list" :key="item.Name" :name="item.Name"
                            @cardClick="handleProjectSelect" :list="item.list" :icon="item.Icon"></search-menu-card>
                        <slot name="menu"></slot>
                    </div>
                    <div class="portal-public-global-search-more-button" @click="handleMoreClick" v-if="moreRefShow">
                        <template v-if="!moreShow">
                            <span
                                class="portal-public-global-search-icon cgs-portal-iconfont cgs-portal-icon-shousuojiantou"></span>
                            <span>{{ $t('globalSearch.unfold') }}</span>
                        </template>
                        <template v-if="moreShow">
                            <span
                                class="portal-public-global-search-icon cgs-portal-iconfont cgs-portal-icon-shousuoshangjiantou"></span>
                            <span>{{ $t('globalSearch.fold') }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
@import "~@style/less/theme.less";

.portal-public-global-search {
    width: 400px;

    .portal-public-global-search-wrapper {
        position: relative;
        padding-top: 11px;
        z-index: 11;

        .portal-public-global-search-input {
            background-color: @card-color;
            padding: 0 15px;
            height: 32px;
            border-radius: 32px;

            .border(1px, 1px, 1px, 1px);

            .portal-public-global-search-icon {
                font-size: 14px;
            }
        }

        .portal-public-global-search-input-extent {
            height: 60px;

            .portal-public-global-search-icon {
                font-size: 20px;
                margin-right: 15px;
            }
        }


        .portal-public-global-search-extent-content {
            background-color: @card-color;
            border-radius: 0 0 10px 10px;
            .border(0px, 1px, 1px, 1px);
        }

        .portal-public-global-search-extent-wrapper {
            .portal-public-global-search-menu {
                padding: 20px;
                max-height: 300px;
                overflow-y: auto;

                .portal-public-serach-menu-card-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1
                }
            }

            .portal-public-global-search-menu-more {
                max-height: 600px;
            }

            .portal-public-global-search-more-button {
                height: 40px;
                cursor: pointer;
                .border(1px, 0px, 0px, 0px);
                display: flex;
                align-items: center;
                justify-content: center;
                color: @primary-color;
            }
        }
    }

    .portal-public-global-search-bg {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .portal-public-global-search-bg-active {
        pointer-events: all;
    }

    .portal-public-global-search-icon {
        margin-right: 8px
    }

    .cgs-design-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: @border-color;
    }

    .cgs-design-input.ant-input-affix-wrapper {
        background-color: @card-color;
    }
}

.portal-public-global-search-extent {
    width: 600px;

    .cgs-design-input.ant-input-affix-wrapper {
        border-radius: 10px 10px 0 0;
    }
}

.portal-public-global-search-radius .cgs-design-input.ant-input-affix-wrapper {
    border-radius: 10px 10px 10px 10px;
}
</style>