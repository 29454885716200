<template>
    <div class="portal-system-dept-table">
        <div class="portal-system-dept-table-wrapper">
            <cgs-table :columns="showColumns" :data-source="sceneList" mode="portal" size="small" :pagination="false" position="bottomCenter"
                :backgroundTransparent="true">
                <template #expandedRowRender="{record}">
                    <div class="portal-system-dept-table-id">
                        <div class="portal-system-dept-table-id-text">{{$t('systemInfo.serverId')+':'}}</div>
                        <div>{{ record.machineId }}</div>
                    </div>
                    <div class="portal-system-dept-table-license">
                        <div class="portal-system-dept-table-id-text">{{$t('common.license')+':'}}</div>
                        <!-- <div>{{ record.license }}</div> -->
                        <cgs-textarea v-model:value="record.license" style="width: 300px;height: 300px;"></cgs-textarea>
                    </div>
                </template>
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'status'">
                        <cgs-radio-group v-model:value="radioId" @change="handleRadioButton(record)">
                            <cgs-radio
                                  :value="record.id">
                            {{record.status === 1? $t('systemInfo.activated'): $t('systemInfo.notActivated')}}
                            </cgs-radio>
                        </cgs-radio-group>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>


<script>
    import {
      computed,
        defineComponent,
        ref,
        watch,
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    const columns = [{
        title: '',
        dataIndex: 'index',
        key: 'index',
        width: '25%',
        customRender: ({
            index
        }) => `${index + 1}`,
    }, {
        title: '',
        dataIndex: 'status',
        key: 'status',
        width: '25%',
    },{
        title: '',
        dataIndex: 'startTime',
        key: 'startTime',
        width: '25%',
    },  {
        title: '',
        dataIndex: 'endTime',
        key: 'endTime',
        width: '25%',
    }];
    export default defineComponent({
        components: {
        },
        props: {
            sceneList: {
                type: Array,
                default: () => []
            },
            radioStatus: {
                type: String,
                default: ''
            }
        },

        setup(props, ctx) {
            const {
                t
            } = useI18n()
            const radioId = ref('')
            const showColumns = computed(() => {
                return columns.map(_item => {
                    _item.title = t('systemInfo.licenseColumns.'+_item.key)
                    return _item
                })
            })
            const handleChange = (roles,column) => {
                ctx.emit('handleChangeUserRole', roles, column)
            }
            const handleRadioButton = (record) => {
                ctx.emit('handleEnableLicense', record.id)
            }
            watch(() => props.radioStatus, () => {
                radioId.value = props.radioStatus
            })
            return {
                showColumns,
                radioId,
                handleChange,
                handleRadioButton
            }
        },
    })
</script>

<style scoped lang="less">
@import "~@style/less/theme.less";
.portal-system-dept-table{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.portal-system-dept-table-wrapper{
    width: 100%;
    height: 100%;
}

.portal-system-dept-table-id{
    display: flex;
    align-items: center;
    .text-2;
    color: @font-color-1;
}
.portal-system-dept-table-license{
    display: flex;
    .text-2;
    color: @font-color-1;
}
.portal-system-dept-table-id-text{
    width: 70px;
}
</style>