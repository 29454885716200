import {
    dispatchEventStroage
} from '@/utils/localMessage'
import {
    setSystemInfo,
    setSystemVersion,
    setLanguage
} from '@/utils/author'
import {
    getSysInfo,
    getVersion
} from "@api/system"
import { hasLanguage, getCurrentLanguage } from '@/utils/tool'
import { changeFavicon } from '@/utils/tool'
import ThemeManager from './assets/js/themeManager/index.js'
import AuthManager from './assets/js/authManager/index.js'

(function () {
    let _themeManager = new ThemeManager()
    window.themeManager = _themeManager
    _themeManager.replaceTheme()

    let _authManager = new AuthManager()
    window.authManager = _authManager
    _authManager.init()

    let _currentLang = getCurrentLanguage()
    if (!hasLanguage()) {
        setLanguage(_currentLang)
    }
    dispatchEventStroage()

    getSysInfo().then(result => {
        if (result.code === 200) {
            console.log(result);
            let _sysInfo = result.data
            if (_sysInfo.name === '') {
                _sysInfo.name = 'CGSCloud'
            }
            changeFavicon(_sysInfo.logo)
            setSystemInfo(_sysInfo)
            if (window.themeManager) {
                window.themeManager.replaceTheme(_sysInfo.theme)
            }
        }
    })
    getVersion().then(res => {
        if (res.code === 200) {
            setSystemVersion(res.data)
        }
    })
})()