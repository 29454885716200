<template>
    <div class="portal-system-role">
        <div class="portal-system-role-wrapper">
            <div class="portal-system-role-header">
                <div class="portal-system-role-header-title">
                    {{ $t('systemInfo.role') }}
                </div>
                <div>
                    <cgs-button ghost class="portal-system-role-add-role" @click="appRoleList">
                        <template #icon>
                            <span class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-role-icon"></span>
                        </template>
                        {{ $t('systemInfo.roleInfo.addRole') }}</cgs-button>
                </div>
            </div>
            <div class="portal-system-role-content">
                <div class="portal-system-role-content-table">
                    <roleTable :moreMenuList="showMoreMenuList" :tableData="state.tableData" @clikMenuItem="clikMenuItem">
                    </roleTable>
                </div>
            </div>
        </div>
        <addUpdataRole :dialogVisible="state.dialogVisible" :authorities="state.authorities"
            :submitState="state.submitState" @cancelAddRoleModal="state.dialogVisible = false"
            @formRePassSubmit="formRePassSubmit"></addUpdataRole>
    </div>
</template>

<script>
    import {
        defineComponent,
        reactive,
        getCurrentInstance,
        computed
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    import {
        getRoleList,
        getAuthorityList,
        deleteRole,
        addRole,
        editRole,
        getRoleByID,
    } from '@/api/tenant'
    import roleTable from './components/role-table.vue'
    import addUpdataRole from './components/addUpdataRole.vue'

    export default defineComponent({
        components: {
            roleTable,
            addUpdataRole
        },
        props: {},
        setup() {
            let {
                proxy
            } = getCurrentInstance();
            const {
                t
            } = useI18n()
            const state = reactive({
                tableData: [],
                authorities: [],
                submitState: {
                    state: 'add',
                    scene: {}
                },
                dialogVisible: false,
                roleAuthorities: [],
                checkAll: false,
            })
            let moreMenuList = [{
                    title: '',
                    key: 'edit',
                    icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
                },
                {
                    title: '',
                    key: 'delete',
                    icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
                }
            ]
            const showMoreMenuList = computed(() => {
                return moreMenuList.map(_item => {
                    _item.title = t('systemInfo.roleInfo.' + _item.key)
                    return _item
                })
            })
            const getList = () => {
                getRoleList({
                    type: "BACK_ROLE"
                }).then((res) => {
                    if (res && res.code === 200) {
                        state.tableData = res.data;
                    }
                });

                getAuthorityList().then((res) => {
                    if (res && res.code === 200) {
                        state.authorities = res.data;
                    }
                })
            }
            const clikMenuItem = (scene, params) => {
                if (params.key === 'edit') {
                    let data = scene
                    state.submitState = {
                        scene: data,
                        state: 'updata'
                    }
                    getRoleByID({
                        id: scene.id
                    }).then((res) => {
                        if (res && res.success) {
                            state.submitState.scene.roleAuthorities = res.data.authorities || [];
                            state.submitState.scene.checkAll = state.roleAuthorities.length ===
                                state.authorities.length;
                            state.dialogVisible = true
                        }
                    })
                } else if (params.key === 'delete') {
                    proxy.$confirm({
                        title: t('common.remind'),
                        content: t('systemInfo.roleInfo.deleteRemind'),
                        onOk() {
                            deleteRole({
                                "id": scene.id
                            }).then((res) => {
                                if (res && res.code === 200) {
                                    proxy.$message.success(t('common.deleteSuccess'))
                                    getList();
                                } else {
                                    proxy.$message.error(t('common.deleteFail'))
                                }
                            }).catch((res) => {
                                proxy.$message.error(res.msg)
                            })
                        },
                        onCancel() {
                            proxy.$message.info(t('common.cancelledDeletion'))
                        },
                    });
                }
            }
            const formRePassSubmit = (values) => {
                state.dialogVisible = false;
                if (state.submitState.state === "add") {
                    addRole({
                        "roleName": values.roleName,
                        "authorities": values.checkedList,
                        "type": "BACK_ROLE"
                    }).then((res) => {
                        if (res && res.success) {
                            proxy.$message.success(t('common.addSuccess'))
                            getList();
                        } else {
                            proxy.$message.error(t('common.addFail'))
                        }
                    })
                } else if (state.submitState.state === "updata") {
                    let row = values;
                    let data = {
                        "id": row.id,
                        "roleName": row.roleName,
                        "authorities": row.checkedList,
                        "type": "BACK_ROLE",
                    }
                    editRole(data).then((res) => {
                        if (res && res.success) {
                            proxy.$message.success(t('common.modifySuccess'))
                            getList();
                        } else {
                            proxy.$message.error(t('common.modifyFail'))
                        }
                    })
                }
            }
            const appRoleList = () => {
                state.submitState = {
                    scene: {},
                    state: 'add'
                }
                state.dialogVisible = true;
            }
            getList()
            return {
                showMoreMenuList,
                state,
                appRoleList,
                clikMenuItem,
                formRePassSubmit
            }
        },
    })
</script>

<style lang="less" scoped>
    @import "~@style/less/theme.less";

    .portal-system-role {
        width: 100%;
        height: 100%;
        padding: 24px 48px 8px 48px;
        overflow: hidden;
    }

    .portal-system-role-wrapper {
        width: 100%;
        height: 100%;
    }

    .portal-system-role-header {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .portal-system-role-header-title {
        .little-title;
        color: @font-color-1;
    }

    .portal-system-role-add-role {
        margin-left: 10px;
    }

    .portal-system-role-content {
        width: 100%;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
    }

    .portal-system-role-content-table {
        flex: 1;
        height: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .portal-system-role-content-footer {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .portal-system-role-icon {
        font-size: 10px;
        color: @font-color-1;
        margin-right: 5px;
    }
</style>