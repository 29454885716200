<template>
    <div class="portal-system-user-addUser">
        <cgs-modal :visible="dialogVisible" :title="submitState.state ==='add'? '添加' : '修改'" @close="$emit('cancelAddCategoryModal')" :footer="null">
            <template #content>
                <cgs-form
                    :labelCol="{span:4, offset: 0}"
                    :model="formState"
                    @finish="() => {$emit('formRePassSubmit',formState)}"
                >
                    <cgs-form-item
                    label="模块名:"
                    name="categoryKey"
                    :rules="[{ required: true, message: '请输入模块名' }]"
                    >
                        <cgs-input v-model:value="formState.categoryKey" />
                    </cgs-form-item>
                    <cgs-form-item
                    label="模块值"
                    name="categoryValue"
                    :rules="[{ required: true, message: '请输入模块值' }]"
                    >
                        <cgs-input-number id="inputNumber" v-model:value="formState.categoryValue"/>
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddCategoryModal')" class="portal-menu-category-addCategory-button">取 消</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-menu-category-addCategory-button">确 定</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    ref,
    watch
} from 'vue'

export default defineComponent({
    props: {
        dialogVisible:{
            type: Boolean,
            default: false,
        },
        submitState: {
            type: Object,
            default: () => {},
        }
    },
    setup(props) {
        const formState = ref({
            categoryKey:'',
            categoryValue:'',
        })
        watch(() => props.dialogVisible, () => {
            if (props.submitState.state === 'updata' && props.dialogVisible === true) {
                let data = props.submitState.scene
                formState.value = {
                    categoryKey:data.categoryKey,
                    categoryValue:data.categoryValue,
                    id:data.id,
                }
            }else if(props.submitState.state === 'add' && props.dialogVisible === true){
                formState.value = {
                    categoryKey:'',
                    categoryValue:'',
                }
            }
        })
        return {
            formState,
        }
    },
})
</script>

<style lang='less' scoped>
.portal-menu-category-addCategory-button{
    margin: 0px 15px;
}
</style>