<template>
    <div>
        <cgs-modal :visible="dialogVisible" :title="$t('systemInfo.addLicense')" @close="$emit('cancelAddlicenseModal')" :footer="null">
            <template #content>
                <cgs-form
                    :labelCol="{span:0, offset: 0}"
                    :model="formState"
                    @finish="(values) => {$emit('addlicenseModal',values)}"
                    >
                    <cgs-form-item
                    :label="$t('systemInfo.addLicenseRemind')"
                    name="licenseInput"
                    :rules="[{ required: true, message: $t('systemInfo.addLicenseRemind') }]">
                        <cgs-textarea v-model:value="formState.licenseInput" style="height: 100px;"></cgs-textarea>
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddlicenseModal')" class="portal-system-license-button">{{$t('common.cancel')}}</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-system-license-button">{{$t('common.ok')}}</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
} from 'vue'

export default defineComponent({
    props: {
        dialogVisible:{
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const formState = reactive({
            licenseInput:'',
        })
        return {
            formState,
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-license-button{
    margin: 0px 15px;
}
</style>