<template>
 <cgs-modal class="portal-menu-table-export-modal" v-model:visible="vis" title="导出工作空间" @close="closeModal">
  <template #content>
   <div class="portal-menu-table-export-modal-content">
    <div class="portal-menu-table-export-modal-content-item ">
     <cgs-checkbox :checked="isAll" @change="changeAll"> 全选 </cgs-checkbox>
     <cgs-checkbox-group class="portal-menu-table-export-modal-checkbox" v-model:value="checkedList">
      <cgs-checkbox class="portal-menu-table-export-modal-checkbox-item" v-for="item in sceneList" :key="item.id"
       :value="item.id">
       <span> {{ item.title }}</span>
       <span class="portal-menu-table-export-modal-checkbox-type" v-if="item.typeName">{{ item.typeName }}</span>
      </cgs-checkbox>
     </cgs-checkbox-group>
    </div>
    <div class="portal-menu-table-export-modal-content-item ">
     <cgs-checkbox :checked="isExport" @change="changeExport"> 全选 </cgs-checkbox>
     <cgs-checkbox-group class="portal-menu-table-export-modal-checkbox" v-model:value="exportList">
      <cgs-checkbox class="portal-menu-table-export-modal-checkbox-item" v-for="item in sceneList" :key="item.id"
       :value="item.id">
       <span> 包含数据</span>
      </cgs-checkbox>
     </cgs-checkbox-group>
    </div>
   </div>
   <!-- {{ sceneList }} -->
   <!-- <cgs-checkbox :checked="isReserve" @change="isReserve = !isReserve"> 是否包含数据</cgs-checkbox> -->
  </template>
  <template #footer>
   <div class="portal-menu-table-export-modal-footer">
    <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
    <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
   </div>
  </template>
 </cgs-modal>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue'


const props = defineProps({
 vis: {
  type: Boolean,
  default: false
 },
 sceneList: {
  type: Array,
  default: () => []
 }

})
const emit = defineEmits(['close', 'ok'])
// const { proxy } = getCurrentInstance()
const passType = ["App", "TwoD", "ThreeD", "Earth", "NewEarth", "Workflow", "Workflow", "SCADA", "CAD", 'Report']

const vis = computed(() => props.vis)
const sceneList = computed(() => {
 let list = []
 props.sceneList.forEach(item => {
  if (passType.includes(item.appType)) {
   list.push({
    value: item.id,
    ...item
   })
  }

 })
 return list
})

// const isReserve = ref(true)
// const isAll = ref(false)
const checkedList = ref([])
const exportList = ref([])
const isAll = computed(() => {
 return checkedList.value.length === sceneList.value.length
})
const isExport = computed(() => {
 return exportList.value.length === sceneList.value.length
})


const changeAll = (e) => {
 let check = e.target.checked
 if (check) {
  checkedList.value = sceneList.value.map(item => item.value)
 } else {
  checkedList.value = []
 }
}
const changeExport = (e) => {
 let check = e.target.checked
 if (check) {
  exportList.value = sceneList.value.map(item => item.value)
 } else {
  exportList.value = []
 }
}

const close = () => {
 exportList.value = []
 checkedList.value = []
 emit('close')
 // props.value = false
}
const closeModal = () => {
 close()
}

const ok = async () => {
 let obj = {}
 checkedList.value.forEach(item => {
  if (exportList.value.includes(item)) {
   obj[item] = true
  } else {
   obj[item] = false
  }
 })
 emit("ok", obj)
 close()
}

</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-menu-table-export-modal-footer {
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
}

.portal-menu-table-export-modal-theme {
 width: 32px;
 height: 32px;
 border-radius: 16px;
 text-align: center;
 line-height: 32px;
 color: #FFFFFF;
 cursor: pointer;
}

.portal-menu-table-export-modal-content {
 display: flex;
}

.portal-menu-table-export-modal-content-item {
 width: 50%;
}

.portal-menu-table-export-modal-checkbox {
 display: flex;
 flex-direction: column;
 max-height: 500px;
 overflow: auto;

}

.portal-menu-table-export-modal-checkbox .cgs-design-checkbox.ant-checkbox-wrapper {
 margin-left: 0;

}

.portal-menu-table-export-modal-checkbox-item {
 margin: 5px 0;
}

.portal-menu-table-export-modal-checkbox-type {
 margin-left: 10px;
 font-size: 12px;
 color: @font-color-3;
 background: @selected-color;
 padding: 0 2px;
 border-radius: 3px;
 .border(1px, 1px, 1px, 1px);
}
</style>