<template>
 <div class="portal-home-setting">
  <div class="portal-home-setting-item">
   <div>我的流程</div>
   <div> <cgs-switch v-model:checked="homeSetting.myProcess" @change="onChange($event, 'myProcess')" /></div>
  </div>
  <div class="portal-home-setting-item">
   <div>我的收藏</div>
   <div> <cgs-switch v-model:checked="homeSetting.myCollect" @change="onChange($event, 'myCollect')" /></div>
  </div>
  <div class="portal-home-setting-item">
   <div>最近使用</div>
   <div> <cgs-switch v-model:checked="homeSetting.myRecent" @change="onChange($event, 'myRecent')" /></div>
  </div>
  <div class="portal-home-setting-item">
   <div>我的应用</div>
   <div> <cgs-switch v-model:checked="homeSetting.myApp" @change="onChange($event, 'myApp')" /></div>
  </div>
  <div class="portal-home-setting-item">
   <div>我的工作空间</div>
   <div> <cgs-switch v-model:checked="homeSetting.myWorkspace" @change="onChange($event, 'myWorkspace')" /></div>
  </div>
  <div class="portal-home-setting-item">
   <div>全部工作空间</div>
   <div> <cgs-switch v-model:checked="homeSetting.allWorkspace" @change="onChange($event, 'allWorkspace')" /></div>
  </div>
 </div>
</template>

<script setup>
import { defineProps, reactive, watch, defineEmits, onMounted } from "vue"
import { updateWorkbenchConfig } from "@api/workSpace"


const props = defineProps({
 setting: {
  type: Object,
  default: undefined
 }
})
const emit = defineEmits(['changeModule'])

const homeSetting = reactive({
 allWorkspace: false, // 我的流程
 myCollect: false,
 myRecent: false,
 myApp: false,
 myWorkspace: false,
 myProcess: false
})


watch(() => props.setting, (newVal) => {
 if (newVal) {
  homeSetting.allWorkspace = newVal.allWorkspace
  homeSetting.myCollect = newVal.myCollect
  homeSetting.myRecent = newVal.myRecent
  homeSetting.myApp = newVal.myApp
  homeSetting.myWorkspace = newVal.myWorkspace
  homeSetting.myProcess = newVal.myProcess
 }
}, { deep: true })
onMounted(() => {
 if (props.setting) {
  homeSetting.allWorkspace = props.setting.allWorkspace
  homeSetting.myCollect = props.setting.myCollect
  homeSetting.myRecent = props.setting.myRecent
  homeSetting.myApp = props.setting.myApp
  homeSetting.myWorkspace = props.setting.myWorkspace
  homeSetting.myProcess = props.setting.myProcess
 }
})


const onChange = async (checked, key) => {
 let setting = await updateWorkbenchConfig(homeSetting)
 if (setting.code === 200) {
  homeSetting[key] = checked
  emit("changeModule", key, checked)
 } else {
  homeSetting[key] = !checked
 }

}
</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-setting {
 width: 200px;
 height: 100%;
}

.portal-home-setting-item {
 display: flex;
 justify-content: space-between;
 align-items: center;
 height: 40px;
 color: @font-color-1;
}
</style>