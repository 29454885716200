<template>
    <div class="portal-system-finance">
        <div class="portal-system-finance-wrapper">
            <div class="portal-system-finance-header">
                <div class="portal-system-finance-header-title">
                    财务管理
                </div>
            </div>
            <div class="portal-system-finance-card">
                <financeCard :num="state.userBalance" :title="'账户余额（￥）：'"></financeCard>
            </div>
            <div class="portal-system-finance-tabs">
                <cgs-tabs v-model:activeKey="state.switchFinanceMenu" mode="button" @change="onChangeFinance">
                    <cgs-tab-pane v-for="item in state.treeData" :key="item.value">
                        <template #tab>
                            <span :class="`cgs-portal-iconfont cgs-portal-icon-${item.icon} portal-system-finance-tabs-icon`"></span>
                                <span>{{item.label}}</span>
                        </template>
                    </cgs-tab-pane>
                </cgs-tabs>
            </div>
            <div class="portal-system-finance-content">
                <div class="portal-system-finance-content-table">
                    <financeTable :sceneList="state.sceneList"></financeTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive
} from 'vue'

import {
    getTenantInfo,
    BalanceHistory
} from '@/api/tenant'
import {
    getUserInfo
} from '@/api/user'
import financeTable from './components/finance-table.vue'
import financeCard from './components/finance-card.vue'

export default defineComponent({
    components:{
        financeTable,
        financeCard
    },
    props: {},
    setup() {
        const state = reactive({
            tenantId:'',
            sceneList:[],
            treeData:[
                {key:'1',value:0,label:'充值记录',icon:'chongzhi'},
                {key:'2',value:1,label:'扣费记录',icon:'koufei'}
            ],
            switchFinanceMenu:0,
            userBalance:'',
        })
        const getUser = () => {
            getUserInfo().then((res)=>{
                state.tenantId = res.data.tenantId
                getTenantInfo({tenantId:state.tenantId}).then((res)=>{
                    state.userBalance = res.data.account.balance
                })
                onChangeFinance(0)
            })
        }
        const onChangeFinance = (value) => {
            BalanceHistory(state.tenantId, value).then((res)=>{
                let in_res = res.data
                in_res.forEach(e => {
                    e.in_type = '充值'
                });
                state.sceneList = in_res
            })
        }
        getUser()
        return {
            state,
            getUser,
            onChangeFinance
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";
.portal-system-finance{
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
    overflow: hidden;
}
.portal-system-finance-wrapper{
    width: 100%;
    height: 100%;
}
.portal-system-finance-header{
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.portal-system-finance-header-title{
    .little-title;
    color: @font-color-1;
}
.portal-system-finance-content{
    width: 100%;
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
}
.portal-system-finance-content-table{
    flex: 1;
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.portal-system-finance-card{
    margin-bottom: 10px;
}

.portal-system-finance-tabs-icon{
    font-size: 10px;
    margin-right: 5px;
}
</style>