<template>
 <cgs-collapse v-model:activeKey="activeKey" :ghost="true">
  <cgs-collapse-panel v-for="(item, index) in foldList " :key="index" :header="item.name">
   <div>
    <AppCard :sceneList="item.children" @enter="enterScene" @clikMenuItem="clikSceneMenuItem"
     @clikCollect="clikSceneCollect">
    </AppCard>
   </div>
  </cgs-collapse-panel>

 </cgs-collapse>
</template>

<script setup>
import { defineProps, computed, defineEmits, ref, onMounted } from 'vue';
import AppCard from './appCard.vue';

const props = defineProps({
 sceneList: {
  type: Array,
  default: () => []
 }
})
const activeKey = ref([])
const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect"])
const foldList = computed(() => {
 let list = props.sceneList.reduce((prev, cur) => {
  if (!prev.some(item => item.name === cur.typeName)) {
   prev.push({ name: cur.typeName, unfold: false, children: [] });
  }
  return prev;
 }, []);

 list.forEach((item) => {
  props.sceneList.forEach(scene => {
   if (scene.typeName === item.name) {
    item.children.push(scene);
   }
  })
 })
 return list
})
onMounted(() => {
 foldList.value.forEach((item, index) => {
  activeKey.value.push(index)
 })
})

const enterScene = (scene) => {
 emit("enter", scene)
}
const clikSceneMenuItem = (scene, menuItemParams) => {
 emit("clikMenuItem", scene, menuItemParams)
}
const clikSceneCollect = (scene) => {
 emit("clikCollect", scene)
}

</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-workspace-foldAppIcon {
 width: 100%;
 height: 100%;
}

.portal-home-workspace-foldAppIcon-layer {
 width: 100%;
 height: 100%;
}

.portal-home-workspace-foldAppIcon-layer-box {
 width: 100%;
 transition: all 0.3s;
}

.cgs-design-collapse.ant-collapse {
 background: transparent !important;
 .border(1px, 0px, 0px, 0px)
}

.cgs-design-collapse .ant-collapse-content {
 background-color: transparent !important;
}

.cgs-design-collapse.ant-collapse>.ant-collapse-item {

 border: none !important;
}
</style>