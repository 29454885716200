import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@style/css/index.css'
import i18n from './lang/i18n'
import "./axios"
import './init'
import './router/permission.js'
import '@style/css/font.css'
import Plugins from "@/plugins"

import pinia from "./store/index.js"

import cgsDesign from '@cgs/design'
import '@cgs/design/dist/lib/cgsDesign.css'
// window.CESIUM_BASE_URL = '/cgs-cdn/dependences/Cesium-1.95/'


// import '@public/cdn/vform/VFormRender.css'

import CgsDataServer from '@cgs/data-server'
import '@cgs/data-server/dist/style.css'

import '@/assets/icon/common/iconfont.js'
import '@/assets/icon/common/iconfont.css'
import "@/assets/icon/cgs-logo/iconfont.css"

const app = createApp(App)
app.use(router)
app.use(cgsDesign)
app.use(Plugins)
app.use(i18n)
app.use(pinia)
app.use(CgsDataServer)
app.mount('#app')
