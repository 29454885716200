<template>
    <main-layout class="portal-account">
        <template #header>
            <main-header>
                <template #prefix-icon>
                    <span
                        class="portal-account-change-icon cgs-portal-iconfont cgs-portal-icon-pailiefangshi font-color-3"
                        @click="switchLeftCollapse"></span>
                </template>
                <template #middle>
                </template>
            </main-header>
        </template>
        <template #left>
            <left-nav @click-menu="onClickMenu" :menuCollapse="state.menuCollapse"></left-nav>
        </template>
        <template #right>
            <router-view v-slot="{ Component }">
                <keep-alive exclude="authority">
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </template>
    </main-layout>
</template>

<script>
import {
    defineComponent,
    reactive,
    onMounted,
    getCurrentInstance
} from 'vue'
import mainLayout from '@components/main-layout.vue'
import mainHeader from '@components/main-header.vue'
import leftNav from './left-nav.vue'
import {
    useRouter
} from 'vue-router'


export default defineComponent({
    components: {
        mainLayout,
        mainHeader,
        leftNav
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance()
        let router = useRouter()
        const state = reactive({
            menuCollapse: false,
        })
        const onClickMenu = (key) => {
            router.push({
                name: key
            })
        }

        const switchLeftCollapse = () => {
            state.menuCollapse = !state.menuCollapse
        }
        onMounted(() => {
            proxy.$ws.on('current_license', (data) => {
                if (data.Timeout) {
                    proxy.$message.warning("License已过期，请重新申请！")
                }
            })
        })
        return {
            state,
            onClickMenu,
            switchLeftCollapse
        }
    }
})
</script>

<style lang="less" scoped>
.portal-account-container {
    width: 100%;
    height: 100%;
}

.portal-account-change-icon {
    cursor: pointer;
    font-size: 14px;
}
</style>