<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:21:13
!-->

<template>
    <div class="portal-menu-authority-role-card">
        <div class="portal-menu-authority-role-card-wrapper">
            <div class="portal-menu-authority-role-card-cover">
                <div class="portal-menu-authority-role-card-icon">
                    <span class="portal-menu-authority-role-card-icon-span" :class="icon"></span>
                </div>
            </div>
            <div class="portal-menu-authority-role-card-info">
                <div class="portal-menu-authority-role-card-title"><span>{{ title }}</span></div>
                <div class="portal-menu-authority-role-card-desc">
                    <span>{{desc}}</span>
                </div>
            </div>
            <div class="portal-menu-authority-role-card-num" v-if="num !== undefined">
                <div class="portal-menu-authority-role-card-num-wrapper">
                    <span>{{`${num}`}} {{ $t( 'auth.unit' ) }}</span>
                </div>
            </div>
            <div class="portal-menu-authority-role-card-more" v-if="more">
                <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler">
                </cgs-more>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent
    } from 'vue'

    export default defineComponent({
        components: {},
        props: {
            desc: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            },
            themeName: {
                type: String,
                default: ''
            },
            num: {
                type: Number,
                default: undefined
            },
            more: {
                type: Boolean,
                default: false
            }
        },

        setup(props, ctx) {
            const moreMenuList = [{
                    title: '编辑',
                    key: 'edit',
                    icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
                },
                {
                    title: '删除',
                    key: 'delete',
                    icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
                }
            ]
            const clikMenuItemHandler = (params) => {
                ctx.emit(params.key)
            }
            return {
                moreMenuList,
                clikMenuItemHandler
            }
        },
    })
</script>

<style lang='less' scoped>
    @import "~@style/less/theme.less";

    .portal-menu-authority-role-card {
        //margin: 5px;
        // width: 24.2%;
        // margin: 0.4%;
        // height: 100px;
        border-radius: 6px;
        .border();
        background: @card-color;
        overflow: hidden;
    }

    .portal-menu-authority-role-card-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 16px;
    }

    .portal-menu-authority-role-card-cover {
        width: 80px;
        height: 80px;
        position: relative;
        overflow: hidden;
    }

    .portal-menu-authority-role-card-info {
        flex: 1;
        .text-1();
        color: @font-color-1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 8px;
    }

    .portal-menu-authority-role-card-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 8px;
    }

    .portal-menu-authority-role-card-desc {
        margin-top: 24px;
        .secondary-text();
        color: @font-color-2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .portal-menu-authority-role-card-published {
        color: @primary-color;
    }

    .portal-menu-authority-role-card-unpublish {
        color: @font-color-2;
    }

    .portal-menu-authority-role-card-icon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: @primary-color;
    }

    .portal-menu-authority-role-card-icon-span {
        font-size: 28px;
    }

    .portal-menu-authority-role-card-num {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .portal-menu-authority-role-card-num-wrapper {
        padding: 4px 20px;
        margin-left: 16px;
        background: @selected-color;
        border-radius: 5px;
    }

    .portal-menu-authority-role-card-more {
        position: absolute;
        right: 16px;
    }
</style>