<!--
*@description 
*@author 杜超峰
*@date 2023/04/27 15:04:04
!-->

<template>
 <cgs-modal class="portal-menu-table-edit-modal" v-model:visible="vis" :title="$t('modifyModal.modifyInfo')"
  @close="closeModal">
  <template #content>
   <cgs-checkbox :checked="isReserve" @change="isReserve = !isReserve"> 包含数据</cgs-checkbox>
   <div></div>
  </template>
  <template #footer>
   <div class="portal-menu-table-edit-modal-footer">
    <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
    <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
   </div>
  </template>
 </cgs-modal>
</template>

<script>
// import {
//  defineComponent,
//  computed,
//  onMounted,
//  reactive,
//  watch,
//  getCurrentInstance,
//  ref
// } from 'vue'
// import {
//  useI18n
// } from 'vue-i18n'
import { defineComponent, computed, ref } from "vue"

export default defineComponent({
 props: {
  visible: {
   type: Boolean,
   default: false
  },
  scene: {
   type: Object,
   default: () => {
    return {}
   }
  }
 },

 setup (props, ctx) {
  // const {
  //  proxy
  // } = getCurrentInstance()
  // const {
  //  t
  // } = useI18n()
  const isReserve = ref(true)
  let vis = computed({
   get: () => {
    return props.visible
   },
   set: (val) => {
    ctx.emit('update:visible', val)
   },
  })
  let scene = computed(() => {
   return props.scene
  })
  const close = () => {
   ctx.emit('close')
   vis.value = false
  }
  const closeModal = () => {
   close()
  }
  const ok = () => {
   let url = "/api/app/export?id=";
   if (isReserve.value) {
    // 包含数据
    window.open(url + scene.value.id + "&exportData=true");
   } else {
    // 不包含数据
    window.open(url + scene.value.id + "&exportData=false");
   }
   close()
  }

  return {
   isReserve,
   vis,
   closeModal,
   ok
  }
 }
})
</script>

<style lang='less' scoped>
.portal-menu-table-edit-modal-footer {
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
}

.portal-menu-table-edit-modal-theme {
 width: 32px;
 height: 32px;
 border-radius: 16px;
 text-align: center;
 line-height: 32px;
 color: #FFFFFF;
 cursor: pointer;
}
</style>

<style>
.portal-menu-table-edit-modal-switch .ant-switch-inner {
 width: 38px;
}
</style>