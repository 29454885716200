<template>
  <div class="portal-system-user">
    <div class="portal-system-user-wrapper">
      <div class="portal-system-user-header">
        <div class="portal-system-user-header-title">
          {{ $t("systemInfo.user") }}
        </div>
        <div>
          <cgs-button ghost @click="state.batchAddDialogVisible = true">
            <template #icon>
              <span class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-user-add-user-icon"></span>
            </template>
            {{ $t("systemInfo.userInfo.batchAddUsers") }}
          </cgs-button>
          <cgs-button ghost class="portal-system-user-add-user" @click="appUserList">
            <template #icon>
              <span class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-user-add-user-icon"></span>
            </template>
            {{ $t("systemInfo.userInfo.addUser") }}
          </cgs-button>
        </div>
      </div>
      <div class="portal-system-user-content">
        <div class="portal-system-user-content-table">
          <userTable :moreMenuList="showMoreMenuList" :sceneList="showDatasource" :role_options="role_options"
            @handleChangeUserRole="handleChangeUserRole" @clikMenuItem="clikMenuItem"
            @handleChangeState="handleChangeState" @searchInput="searchInput">
          </userTable>
        </div>
        <div class="portal-system-user-content-footer">
          <cgs-pagination v-model:current="state.currentPagination.current"
            v-model:pageSize="state.currentPagination.pageSize" show-quick-jumper show-size-changer :total="total"
            @change="onChangePagination" :show-total="(total) => $t('common.paginationTotal', { num: total })
              " />
        </div>
      </div>
    </div>
    <batchAddUser :batchAddDialogVisible="state.batchAddDialogVisible" @cancelBatchAddModal="cancelBatchAddModal"
      @handleUpload="handleUpload"></batchAddUser>
    <addUpdataUser :dialogVisible="state.dialogVisible" :submitState="state.submitState" :dept_options="dept_options"
      :job_options="job_options" :role_options="role_options" @cancelAddUserModal="state.dialogVisible = false"
      @formRePassSubmit="formRePassSubmit">
    </addUpdataUser>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  getCurrentInstance,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import {
  getDeptList,
  createDeptTree,
  getUserList,
  getRoleList,
  editUser,
  addUser,
  deleteUser,
  resetUserPassword,
  importUser,
  createCascadDepValue,
  getJobList,
} from "@/api/tenant";
import userTable from "./components/user-table.vue";
import addUpdataUser from "./components/addUpdataUser.vue";
import batchAddUser from "./components/batchAddUser.vue";

export default defineComponent({
  components: {
    userTable,
    addUpdataUser,
    batchAddUser,
  },
  props: {},
  setup () {
    let { proxy } = getCurrentInstance();
    let { t } = useI18n();
    const deptList = ref([]);
    const deptMaps = ref({});
    const dept_options = ref([]);
    const job_options = ref([]);
    const tableData = ref([]);
    const role_options = ref([]);
    const state = reactive({
      searchInput: "",
      categoryActiveKey: "",
      currentPagination: {
        current: 1,
        pageSize: 20,
      },
      // 修改
      editModalConfig: {},
      // 添加
      dialogVisible: false,
      submitState: {},
      // 批量添加
      batchAddDialogVisible: false,
    });
    let moreMenuList = [
      {
        title: "",
        key: "edit",
        icon: "cgs-portal-iconfont cgs-portal-icon-xiugai",
      },
      {
        title: "",
        key: "reset",
        icon: "cgs-portal-iconfont cgs-portal-icon-daochu",
      },
      {
        title: "",
        key: "delete",
        icon: "cgs-portal-iconfont cgs-portal-icon-shanchu",
      },
    ];
    const showMoreMenuList = computed(() => {
      return moreMenuList.map((_item) => {
        _item.title = t("systemInfo.userInfo." + _item.key);
        return _item;
      });
    });
    const handleUpload = (file) => {
      if (file.file && file.file.response) {
        importUser({
          link: file.file.response.data.link,
        }).then((res) => {
          if (res.code === 200) {
            state.batchAddDialogVisible = false;
            proxy.$message.success(t("common.addSuccess"));
            getList();
          } else {
            proxy.$message.info(res.msg);
          }
        });
      }
    };
    const cancelBatchAddModal = () => {
      state.batchAddDialogVisible = false;
    };
    const onChangePagination = () => {
      _getUserList();
    };
    const jobMap = {};
    const getList = () => {
      Promise.all([getDeptList(), getJobList()]).then(([res, jobRes]) => {
        let depArr = res.data;
        if (!depArr) {
          proxy.$message.warning(t("systemInfo.userInfo.addDeptRemind"));
          return;
        }
        deptList.value = depArr;
        let m = {};
        deptList.value.forEach((p) => {
          m[p.id] = p;
        });
        deptMaps.value = m;
        dept_options.value = createDeptTree(depArr);
        if (Array.isArray(jobRes.data)) {
          jobRes.data.forEach((item) => {
            jobMap[item.id] = item.name;
          });
          job_options.value = jobRes.data
        }
        _getUserList();
      });

      getRoleList({
        type: "BACK_ROLE",
      }).then((res) => {
        if (res && res.code === 200) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].roleAlias === "Administrator") {
              res.data[i]["disabled"] = true;
              role_options.value = res.data;
              break;
            }
          }
        }
      });
    };

    const _getUserList = () => {
      // let _listParams = {
      //     Current: state.currentPagination.current,
      //     Size: state.pageSize,
      //     Category: state.categoryActiveKey,
      // }
      getUserList().then((res) => {
        if (res && res.success) {
          tableData.value = [];

          for (let i = 0; i < res.data.length; i++) {
            let user = res.data[i];
            let jobs = [];
            if (user.jobIds) {
              jobs = user.jobIds.map((id) => jobMap[id]).filter(Boolean);
            }
            tableData.value.push({
              userId: user.userId,
              userRole: user.roleId,
              userName: user.username,
              userAlia: user.name,
              userDepts: user.depts,
              userState: user.state,
              userDeptNames: getDeptNames(user.depts),
              jobs: jobs.join(', '),
            });
          }
        }
      });
    };

    const getDeptNames = (csv) => {
      if (!csv) {
        return "";
      }
      let deptArr = csv.split(",");
      let deptNames = deptArr
        .map((d) => {
          return deptMaps.value[d] ? deptMaps.value[d].name : "";
        })
        .join(",");
      return deptNames;
    };
    const handleChangeUserRole = (roles, column) => {
      let data = {
        userId: column.userId,
        username: column.userName,
        name: column.userAlia,
        depts: column.userDepts,
        state: column.userState,
        roleId: roles,
      };
      editUser(data).then((res) => {
        if (res && res.success) {
          proxy.$message.success(t("common.modifySuccess"));
        } else {
          proxy.$message.error(t("common.modifyFail"));
        }
        _getUserList();
      });
    };
    const handleChangeState = (state, column) => {
      let data = {
        userId: column.userId,
        username: column.userName,
        name: column.userAlia,
        depts: column.userDepts,
        roleId: column.userRole,
        state: state,
      };
      editUser(data).then((res) => {
        if (res && res.success) {
          proxy.$message.success(t("common.modifySuccess"));
        } else {
          proxy.$message.error(t("common.modifyFail"));
        }
        _getUserList();
      });
    };
    const createSelectedDepts = (row) => {
      return createCascadDepValue(row.userDepts, deptList.value);
    };
    const clikMenuItem = (scene, params) => {
      if (params.key === "edit") {
        let data = scene;
        data.userDepts = createSelectedDepts(scene);
        state.submitState = {
          scene: data,
          state: "updata",
        };
        state.dialogVisible = true;
      } else if (params.key === "delete") {
        proxy.$confirm({
          title: t("common.remind"),
          content: t("remind.rermanentDeletionRemind"),
          onOk () {
            deleteUser({
              userId: scene.userId,
            })
              .then((res) => {
                if (res && res.code === 200) {
                  proxy.$message.success(t("common.deleteSuccess"));
                  getList();
                } else {
                  proxy.$message.error(t("common.deleteFail"));
                }
              })
              .catch((res) => {
                proxy.$message.error(res.msg);
              });
          },
          onCancel () {
            proxy.$message.info(t("common.cancelledDeletion"));
          },
        });
      } else if (params.key === "reset") {
        proxy.$confirm({
          title: t("common.remind"),
          content: t("systemInfo.userInfo.resetPasswordRemind"),
          onOk () {
            resetUserPassword({
              userId: scene.userId,
              newPassword: `${scene.userName}123456&`,
              confirmPassword: `${scene.userName}123456&`,
            })
              .then((res) => {
                if (res && res.code === 200) {
                  proxy.$message.success(
                    t("systemInfo.userInfo.resetPasswordSuccessRemind") +
                    `${scene.userName}123456&`
                  );
                } else {
                  proxy.$message.error(
                    t("systemInfo.userInfo.resetPasswordFailRemind")
                  );
                }
              })
              .catch((res) => {
                proxy.$message.error(res.msg);
              });
          },
          onCancel () {
            proxy.$message.info(
              t("systemInfo.userInfo.cancelledResetPasswordRemind")
            );
          },
        });
      }
    };
    const createCSVDepts = (arr) => {
      return arr
        .map((a) => {
          let ind = a.length;
          return a[ind - 1];
        })
        .join(",");
    };
    const appUserList = () => {
      state.submitState = {
        scene: [],
        state: "add",
      };
      state.dialogVisible = true;
    };
    const formRePassSubmit = (values) => {
      state.dialogVisible = false;
      if (state.submitState.state === "add") {
        let userDeptsCSV = createCSVDepts([values.userDepts]);
        addUser({
          username: values.userName,
          password: values.passWord,
          name: values.userAlia,
          depts: userDeptsCSV,
          roleId: values.userRole,
          jobIds: values.jobIds
        }).then((res) => {
          if (res && res.success) {
            proxy.$message.success(t("common.addSuccess"));
            getList();
          } else {
            proxy.$message.error(t("common.addFail"));
          }
        });
      } else if (state.submitState.state === "updata") {
        let row = values;
        let userDeptsCSV = row.userDepts;
        if (Array.isArray(row.userDepts)) {
          userDeptsCSV = createCSVDepts([row.userDepts]);
        }
        let data = {
          userId: row.userId,
          username: row.userName,
          name: row.userAlia,
          depts: userDeptsCSV,
          roleId: row.userRole,
          jobIds: row.jobIds
        };
        editUser(data).then((res) => {
          if (res && res.success) {
            proxy.$message.success(t("common.modifySuccess"));
            getList();
          } else {
            proxy.$message.error(t("common.modifyFail"));
          }
        });
      }
    };
    const searchInput = (value) => {
      state.currentPagination.current = 1;
      state.searchInput = value;
    };
    const showDatasource = computed(() => {
      let _pagination = state.currentPagination;
      return sceneData.value.filter((item, index) => {
        return (
          index >= _pagination.pageSize * (_pagination.current - 1) &&
          index < _pagination.pageSize * _pagination.current
        );
      });
    });
    const sceneData = computed(() => {
      let searchInput = state.searchInput ? state.searchInput : "";
      let data = tableData.value;
      let filterData = data.filter(
        (data) =>
          !searchInput ||
          data.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
          data.userAlia.toLowerCase().includes(searchInput.toLowerCase())
      );
      return filterData;
    });
    const total = computed(() => {
      return sceneData.value.length;
    });
    getList();
    return {
      state,
      role_options,
      dept_options,
      job_options,
      showMoreMenuList,
      tableData,
      showDatasource,
      sceneData,
      total,
      getList,
      getDeptNames,
      onChangePagination,
      _getUserList,
      handleChangeUserRole,
      clikMenuItem,
      formRePassSubmit,
      appUserList,
      createCSVDepts,
      cancelBatchAddModal,
      handleUpload,
      createSelectedDepts,
      searchInput,
      handleChangeState,
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-system-user {
  width: 100%;
  height: 100%;
  padding: 24px 48px 8px 48px;
  overflow: hidden;
}

.portal-system-user-wrapper {
  width: 100%;
  height: 100%;
}

.portal-system-user-header {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.portal-system-user-header-title {
  .little-title;
  color: @font-color-1;
}

.portal-system-user-add-user {
  margin-left: 10px;
}

.portal-system-user-content {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.portal-system-user-content-table {
  flex: 1;
  height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.portal-system-user-content-footer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portal-system-user-add-user-icon {
  font-size: 10px;
  color: @font-color-2;
  margin-right: 5px;
}
</style>
