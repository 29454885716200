<!--
*@description 
*@author 杜超峰
*@date 2023/04/27 15:04:04
!-->

<template>
    <cgs-modal class="portal-menu-table-add-modal" v-model:visible="vis" :title="$t('createScene')" @close="closeModal">
        <template #content>
            <div class="portal-menu-table-add-modal-content">
                <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }" labelAlign="left">
                    <cgs-form-item style="display: none;" :label="$t('modifyModal.orgCategory')" name="orgCategory">
                        <cgs-select v-model:value="formState.orgCategory">
                            <cgs-select-option :value="parseInt(option.categoryValue)"
                                v-for="option in state.orgOptions" :key="option.categoryValue">{{ option.categoryKey
                                }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item label="工作空间" name="spaceId">
                        <cgs-select v-model:value="formState.workspaceId" :allowClear="true">
                            <cgs-select-option :value="option.id" v-for="option in state.spaceOptions"
                                :key="option.id">{{ option.name
                                }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.category')" name="Category"
                        :rules="[{ required: true, message: $t('modifyModal.categoryNullError') }]">
                        <cgs-select v-model:value="formState.Category">
                            <cgs-select-option :value="parseInt(option.categoryValue)" v-for="option in categoryOptions"
                                :key="option.categoryValue">{{ option.categoryKey }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.title')" name="Title"
                        :rules="[{ required: true, message: $t('modifyModal.titleNullError') }]">
                        <cgs-input v-model:value="formState.Title" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.password')" name="Password">
                        <cgs-input v-model:value="formState.Password" />
                    </cgs-form-item>
                    <!-- <cgs-form-item :label="$t('modifyModal.theme')" name="themeId">
                        <cgs-select v-model:value="formState.themeId">
                            <cgs-select-option :value="option.id" v-for="option in state.themeOptions" :key="option.id">
                                {{option.name}}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item> -->
                    <cgs-form-item :label="$t('modifyModal.status')" name="Status">
                        <cgs-switch class="portal-menu-table-add-modal-switch" v-model:checked="formState.Status"
                            :checkedValue="1" :unCheckedValue="0" :checkedChildren="$t('modifyModal.published')"
                            :unCheckedChildren="$t('modifyModal.unpublished')" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.ThemeIcon')" name="ThemeIcon">
                        <div class="portal-menu-table-add-modal-theme-icon">
                            <div class="portal-menu-table-add-modal-theme" :style="{ background: themeColor }"
                                :class="[iconFontFamily, showIcon]" @click="openSelectThemeModal"></div>
                            <cgs-select-theme-icon v-model:visible='state.themeModelVisible'
                                v-model:iconName="formState.icon" :left="state.themeIconLeft"
                                :top="state.themeIconTop + 20" v-model:themeClassName="formState.theme"
                                @themeChanged="changeTheme" @iconChanged="changeIcon">
                            </cgs-select-theme-icon>
                        </div>
                    </cgs-form-item>
                </cgs-form>
            </div>
        </template>
        <template #footer>
            <div class="portal-menu-table-add-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    getCurrentInstance,
    ref,
    watch
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    getUserInfo
} from '@/api/user'
import {
    setUserAuthority,
} from "@/utils/author.js";
import {
    getThemeList,
    addScene
} from '@api/scene'
import {
    profix,
    default_class_name,
    font_family,
    defaultIcon_app,
    getThemeObjByClassName
} from '@js/tableIconAndTheme'
import { getWorkSpaceList } from "@/api/workSpace"

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        categoryOptions: {
            type: Array,
            default: () => []
        },
        sceneType: {
            type: String,
            default: ''
        },
        selectCategory: {
            type: String,
            default: ''
        },
        workSpaceId: {
            type: String,
            default: ''
        },
        spaceOptions: {
            type: [Array, undefined],
            default: undefined
        },
        themeOptions: {
            type: [Array, undefined],
            default: undefined
        }
    },

    setup (props, ctx) {
        const {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        const sceneForm = ref(null)
        const formState = reactive({
            Category: '',
            // ID: '',
            Password: '',
            Status: 0,
            Title: '',
            orgCategory: null,
            themeId: '',
            icon: '',
            theme: '',
            workspaceId: ""
        });
        const state = reactive({
            orgOptions: [],
            themeOptions: [],
            spaceOptions: [],
            themeModelVisible: false,
            themeIconLeft: 0,
            themeIconTop: 0,
        })

        let iconFontFamily = font_family
        let showIcon = computed({
            get: () => {
                let _icon = formState.icon
                if (formState.icon === '') _icon = defaultIcon_app
                return profix + _icon
            },
            set: () => { }
        })
        let themeColor = computed({
            get: () => {
                let _color = ''
                let _themeName = formState.theme
                if (formState.theme === '') _themeName = default_class_name
                let _themeObj = getThemeObjByClassName(_themeName)
                _color = _themeObj.color
                return _color
            },
            set: () => { }
        })

        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        watch(() => props.selectCategory, () => {
            if (props.selectCategory) {
                formState.Category = parseInt(props.selectCategory)
            }
        })
        watch(() => props.workSpaceId, () => {
            if (props.workSpaceId) {
                formState.workspaceId = props.workSpaceId
            }
        })
        watch(() => props.themeOptions, (newValue) => {
            let _list = newValue ? newValue : []
            state.themeOptions = JSON.parse(JSON.stringify(_list))
        })
        watch(() => props.spaceOptions, (newValue) => {
            let _list = newValue ? newValue : []
            state.spaceOptions = JSON.parse(JSON.stringify(_list))
        })
        onMounted(() => {
            _init()
        })
        const _init = async () => {
            try {
                if (!props.themeOptions) {
                    let _themeResult = await getThemeList()
                    if (_themeResult.code === 200) {
                        state.themeOptions = _themeResult.data
                    }
                } else {
                    state.themeOptions = JSON.parse(JSON.stringify(props.themeOptions))
                }
                if (!props.spaceOptions) {
                    let _workspaseResult = await getWorkSpaceList()
                    if (_workspaseResult.code === 200) {
                        state.spaceOptions = _workspaseResult.data
                    }
                } else {
                    state.spaceOptions = JSON.parse(JSON.stringify(props.spaceOptions))
                }
                formState.workspaceId = props.workSpaceId
            } catch (err) {
                console.error(err)
            }
        }
        const updateUserAuthority = () => {
            return new Promise((resolve, reject) => {
                getUserInfo().then(infoRes => {
                    if (infoRes.data && infoRes.success) {
                        setUserAuthority(infoRes.data);
                        window.authManager.init();
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const openSelectThemeModal = (e) => {
            state.themeIconLeft = e.x
            state.themeIconTop = e.y
            state.themeModelVisible = true
        }
        const changeTheme = (theme) => {
            formState.theme = theme
        }
        const changeIcon = (icon) => {
            formState.icon = icon
        }
        const ok = async () => {
            try {
                await sceneForm.value.validateFields()
                proxy.$loading.open()
                let _detail = {
                    name: formState.Title,
                    nodeList: [],
                    lineList: []
                }
                let params = {
                    Config: {
                        Detail: JSON.stringify(_detail)
                    },
                    Visual: formState,
                    VisualType: props.sceneType,
                    isPortal: true
                }
                let _result = await addScene(params)
                if (_result.code === 200) {
                    proxy.$message.success(t('common.createSuccess'))
                    await updateUserAuthority()
                    ctx.emit('success')
                    close()
                } else {
                    proxy.$message.error('common.createFail')
                }
                proxy.$loading.close()
            } catch (err) {
                console.error(err)
                proxy.$loading.close()
                // proxy.$message.error(t('common.modifyFail'))
            }
        }
        return {
            state,
            formState,
            vis,
            sceneForm,
            themeColor,
            iconFontFamily,
            showIcon,
            openSelectThemeModal,
            close,
            closeModal,
            changeTheme,
            changeIcon,
            ok

        }
    },
})
</script>

<style lang='less' scoped>
.portal-menu-table-add-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portal-menu-table-add-modal-theme {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    color: #FFFFFF;
    cursor: pointer;
}
</style>

<style>
.portal-menu-table-add-modal-switch .ant-switch-inner {
    width: 38px;
}
</style>