<template>
    <div class="portal-menu-category">
        <div class="portal-menu-category-tabs">
            <cgs-tabs v-model:activeKey="state.switchCategoryMenu" mode="button" @change="onChangeCategory">
                <cgs-tab-pane v-for="item in state.treeData" :key="item.index">
                    <template #tab>
                        <span class="portal-menu-category-tabs-title">{{ item.title }}</span>
                    </template>
                </cgs-tab-pane>
            </cgs-tabs>
            <cgs-button type="primary" html-type="submit" @click="addCategory">添 加</cgs-button>
        </div>
        <div class="portal-menu-category-content">
            <div class="portal-menu-category-table">
                <categoryTable :moreMenuList="moreMenuList" :tableList="state.tableList" @clikMenuItem="clikMenuItem">
                </categoryTable>
            </div>
            <div class="portal-menu-category-pagination">
                <cgs-pagination v-model:current="state.currentPagination.current"
                    v-model:pageSize="state.currentPagination.size" show-quick-jumper show-size-changer
                    :total="state.currentPagination.total" @change="onChangePagination"
                    :show-total="total => `共${total}条`" />
            </div>
        </div>
        <addUpdataCategory :dialogVisible="state.dialogVisible" :submitState="state.submitState"
            @cancelAddCategoryModal="state.dialogVisible = false" @formRePassSubmit="formRePassSubmit">
        </addUpdataCategory>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    getCurrentInstance,
    onMounted,
} from 'vue'
import {
    getList,
    delObj,
    addObj,
    updateObj
} from '@api/category'
import { getSceneTypeSetting } from '@api/scene'
import categoryTable from './table.vue'
import addUpdataCategory from './addUpdataCategory.vue'

export default defineComponent({
    components: {
        categoryTable,
        addUpdataCategory
    },
    props: {},
    setup (props, ctx) {
        let {
            proxy
        } = getCurrentInstance();
        const state = reactive({
            switchCategoryMenu: 'App',
            treeData: [
                // {title: '大屏', index: 'Visual'},
                {
                    title: '2D',
                    index: 'TwoD'
                },
                {
                    title: '3D',
                    index: 'ThreeD'
                },
                {
                    title: 'Earth',
                    index: 'Earth'
                },
                {
                    title: 'Page',
                    index: 'Static'
                },
                // {title: 'BIM', index: 'Bim'},
                { title: 'Workflow', index: 'Workflow' },
                {
                    title: 'App',
                    index: 'App'
                },
                {
                    title: 'GeoModel',
                    index: 'GeoModel'
                },
                {
                    title: 'Volume',
                    index: 'Vtk'
                },
                {
                    title: 'Cad',
                    index: 'CAD'
                },
                {
                    title: 'Das',
                    index: 'DAS'
                },
                {
                    title: '组织',
                    index: 'Org'
                },
                {
                    title: 'File',
                    index: 'FileStorage'
                }, {
                    title: 'CodeEngine',
                    index: 'CodeEngine'
                },
                {
                    title: 'NewEarth',
                    index: 'NewEarth'
                },
            ],
            currentPagination: {
                current: 1,
                total: -1,
                size: 20,
            },
            tableList: [],

            dialogVisible: false,
            submitState: {},
        })
        let moreMenuList = [{
            title: '编辑',
            key: 'edit',
            icon: 'cgs-portal-iconfont cgs-portal-icon-daochu'
        },
        {
            title: '删除',
            key: 'delete',
            icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
        }
        ]

        onMounted(async () => {
            let res = await getSceneTypeSetting()
            state.treeData = res.data.map((item) => {
                return { title: item.typeName, "index": item.typeId }
            })

        })
        const addCategory = () => {
            state.submitState = {
                scene: [],
                state: 'add'
            }
            state.dialogVisible = true
        }
        const onChangeCategory = (key) => {
            try {
                // proxy.$loading.open()
                let {
                    current,
                    size
                } = state.currentPagination
                let VisualType = key;
                getList({
                    current,
                    size,
                    VisualType
                }).then(res => {
                    // proxy.$loading.close();
                    if (res.success) {
                        let data = res.data;
                        state.currentPagination.total = data.total;
                        state.currentPagination.size = data.size;
                        state.tableList = data.records;
                        state.tableList.sort(function (a, b) {
                            return Number(a.categoryValue) - Number(b.categoryValue)
                        });
                    }
                })
            } catch {
                // proxy.$loading.close()
            }
        }

        const onChangePagination = (value) => {
            console.log(value);
            // this.page.size = value;
            onChangeCategory(state.switchCategoryMenu);
        }
        const clikMenuItem = (scene, params) => {
            if (params.key === 'edit') {
                let data = scene
                state.submitState = {
                    scene: data,
                    state: 'updata'
                }
                state.dialogVisible = true
            } else if (params.key === 'delete') {
                proxy.$confirm({
                    title: '提示',
                    content: '此操作将永久删除, 是否继续?',
                    onOk () {
                        let VisualType = state.switchCategoryMenu;
                        delObj({
                            ID: scene.id,
                            VisualType
                        }).then((res) => {
                            if (res && res.code === 200) {
                                proxy.$message.success("删除成功!")
                                if (state.switchCategoryMenu === 'Org') {
                                    ctx.emit('changeOrganization')
                                }
                                onChangeCategory(state.switchCategoryMenu);
                            } else {
                                proxy.$message.error("删除失败！")
                            }
                        }).catch((res) => {
                            proxy.$message.error(res.msg)
                        })
                    },
                    onCancel () {
                        proxy.$message.info('已取消删除')
                    },
                });
            }
        }
        const formRePassSubmit = (values) => {
            state.dialogVisible = false;
            let VisualType = state.switchCategoryMenu
            if (state.submitState.state === "add") {
                addObj({
                    categoryKey: values.categoryKey,
                    categoryValue: values.categoryValue.toString(),
                    VisualType
                }).then((res) => {
                    if (res && res.success) {
                        proxy.$message.success("添加成功！")
                        if (state.switchCategoryMenu === 'Org') {
                            ctx.emit('changeOrganization')
                        }
                        onChangeCategory(state.switchCategoryMenu);
                    } else {
                        proxy.$message.error("添加失败！")
                    }
                })
            } else if (state.submitState.state === "updata") {
                let data = {
                    categoryKey: values.categoryKey,
                    categoryValue: values.categoryValue.toString(),
                    VisualType,
                    ID: values.id
                }
                updateObj(data).then((res) => {
                    if (res && res.success) {
                        proxy.$message.success("修改成功！")
                        if (state.switchCategoryMenu === 'Org') {
                            ctx.emit('changeOrganization')
                        }
                        onChangeCategory(state.switchCategoryMenu);
                    } else {
                        proxy.$message.error("修改失败！")
                    }
                })
            }
        }
        onChangeCategory(state.switchCategoryMenu);
        return {
            state,
            moreMenuList,
            onChangeCategory,
            clikMenuItem,
            onChangePagination,
            formRePassSubmit,
            addCategory
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-menu-category {
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
    display: flex;
    flex-direction: column;
}

.portal-menu-category-tabs {
    display: flex;
    justify-content: space-between;
}

.portal-menu-category-content {
    width: 100%;
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
}

.portal-menu-category-table {
    flex: 1;
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.portal-menu-category-pagination {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-menu-category-tabs-title {
    display: inline-block;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>