import logoIconJson from "@/assets/icon/cgs-logo/iconfont.json"

export const logoIconList= logoIconJson.glyphs

export const profix = logoIconJson.css_prefix_text

export const font_family = logoIconJson.font_family

export const defaultIcon_app = 'yingyongzhongxin'

export const defaultIcon_worksheet = 'tubiaozhizuomoban-90'

export const THEMELIST = [
    {name: '颜色1', color: "rgba(255, 185, 0, 1)", className: 'theme1'},
    {name: '颜色2', color: "rgba(255, 140, 0, 1)", className: 'theme2'},
    {name: '颜色3', color: "rgba(247, 99, 12, 1)", className: 'theme3'},
    {name: '颜色4', color: "rgba(202, 80, 16, 1)", className: 'theme4'},
    {name: '颜色5', color: "rgba(218, 59, 1, 1)", className: 'theme5'},
    {name: '颜色6', color: "rgba(239, 105, 80, 1)", className: 'theme6'},
    {name: '颜色7', color: "rgba(209, 52, 56, 1)", className: 'theme7'},
    {name: '颜色8', color: "rgba(255, 67, 67, 1)", className: 'theme8'},

    {name: '颜色9', color: "rgba(231, 72, 86, 1)", className: 'theme9'}, 
    {name: '颜色10', color: "rgba(232, 17, 35, 1)", className: 'theme10'},
    {name: '颜色11', color: "rgba(234, 0, 94, 1)", className: 'theme11'}, 
    {name: '颜色12', color: "rgba(195, 0, 82, 1)", className: 'theme12'}, 
    {name: '颜色13', color: "rgba(227, 0, 140, 1)", className: 'theme13'}, 
    {name: '颜色14', color: "rgba(191, 0, 119, 1)", className: 'theme14'}, 
    {name: '颜色15', color: "rgba(194, 57, 179, 1)", className: 'theme15'},
    {name: '颜色16', color: "rgba(154, 0, 137, 1)", className: 'theme16'}, 

    {name: '颜色17', color: "rgba(0, 120, 215, 1)", className: 'theme17'},  
    {name: '颜色18', color: "rgba(0, 99, 177, 1)", className: 'theme18'}, //
    {name: '颜色19', color: "rgba(142, 140, 216, 1)", className: 'theme19'}, 
    {name: '颜色20', color: "rgba(107, 105, 214, 1)", className: 'theme20'},   
    {name: '颜色21', color: "rgba(135, 100, 184, 1)", className: 'theme21'},  
    {name: '颜色22', color: "rgba(116, 77, 169, 1)", className: 'theme22'}, 
    {name: '颜色23', color: "rgba(177, 70, 194, 1)", className: 'theme23'},  
    {name: '颜色24', color: "rgba(136, 23, 152, 1)", className: 'theme24'}, 

    {name: '颜色25', color: "rgba(0, 153, 188, 1)", className: 'defaultTheme'},  
    {name: '颜色26', color: "rgba(45, 125, 154, 1)", className: 'theme26'}, //
    {name: '颜色27', color: "rgba(0, 183, 195, 1)", className: 'theme27'}, 
    {name: '颜色28', color: "rgba(3, 131, 135, 1)", className: 'theme28'},   
    {name: '颜色29', color: "rgba(0, 178, 148, 1)", className: 'theme29'},  
    {name: '颜色30', color: "rgba(1, 133, 116, 1)", className: 'theme30'}, 
    {name: '颜色31', color: "rgba(0, 204, 106, 1)", className: 'theme31'},  
    {name: '颜色32', color: "rgba(16, 137, 62, 1)", className: 'theme32'},  

    {name: '颜色33', color: "rgba(122, 117, 116, 1)", className: 'theme33'},  
    {name: '颜色34', color: "rgba(93, 90, 88, 1)", className: 'theme34'}, 
    {name: '颜色35', color: "rgba(104, 118, 138, 1)", className: 'theme35'}, 
    {name: '颜色36', color: "rgba(81, 92, 107, 1)", className: 'theme36'},   
    {name: '颜色37', color: "rgba(86, 124, 115, 1)", className: 'theme37'},  
    {name: '颜色38', color: "rgba(72, 104, 96, 1)", className: 'theme38'}, 
    {name: '颜色39', color: "rgba(73, 130, 5, 1)", className: 'theme39'},  
    {name: '颜色40', color: "rgba(16, 124, 16, 1)", className: 'theme40'},  

    {name: '颜色41', color: "rgba(118, 118, 118, 1)", className: 'theme41'},  
    {name: '颜色42', color: "rgba(76, 74, 72, 1)", className: 'theme42'}, 
    {name: '颜色43', color: "rgba(105, 121, 126, 1)", className: 'theme43'}, 
    {name: '颜色44', color: "rgba(74, 84, 89, 1)", className: 'theme44'},   
    {name: '颜色45', color: "rgba(100, 124, 100, 1)", className: 'theme45'},  
    {name: '颜色46', color: "rgba(82, 94, 84, 1)", className: 'theme46'}, 
    {name: '颜色47', color: "rgba(132, 117, 69, 1)", className: 'theme47'},  
    {name: '颜色48', color: "rgba(126, 115, 95, 1)", className: 'theme48'} 
]

export const default_class_name = 'defaultTheme'

export const getThemeObjByClassName = (className) => {
    return THEMELIST.find(_item => _item.className === className)

}