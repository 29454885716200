import { get, post, DELETE } from "@/axios/http.js"
import qs from 'qs'

export const getCategory = (type) => get(`/api/Cgs/Portal/Category/List?VisualType=${type}`)

export const getList = (params) => get(`/api/Cgs/Portal/Visual/List?${qs.stringify(params)}`)

export const getSceneTypeList = () => get(`/api/visual/type/list`)

export const getOrganization = () => get(`/api/Cgs/Portal/Visual/ListAll`)

export const getSceneTypeSetting = () => get(`/api/visual/type/getSettings`)

export const addScene = (data) => post(`/api/Cgs/Portal/Visual/Add`, data)

export const addScada = (data) => post(`/scada/api/user/topology/empty`, data)

export const updateSceneTypeSetting = (data) => post(`/api/visual/type/updateSettings`, data, { headers: { 'Content-Type': 'application/json' } })

export const updateSceneType = (data) => post(`/api/visual/type/update`, data, { headers: { 'Content-Type': 'application/json' } })

export const deleteScene = (data) => post(`/api/Cgs/Portal/Visual/Delete`, qs.stringify(data))

export const delScada = (id) => DELETE(`/scada/api/user/topology/${id}`)

export const getThemeList = () => get(`/api/app/theme/list`)

export const editScene = (data) => post(`/api/Cgs/Portal/Visual/Edit`, data)

export const getRoleList = (params) => get(`/api/Role/List?${qs.stringify(params)}`)

export const getRoleInfo = (params) => get(`/api/Role/Get?${qs.stringify(params)}`)

export const updateRoleUser = (params) => post('/api/Role/UpdateUser', params)

export const getAppInfo = (params) => get(`/api/app/worksheet/info/listByAppId?${qs.stringify(params)}&type=0&type=1&type=2&type=3`)

export const getAppDetail = (str, config) => get('/api/app/detail?' + str, null, config)

export const getAppAuth = () => get('/api/Authority/GetAppAuth')

export const addRole = (params) => post('/api/Role/Add', params)

export const editRole = (params) => post('/api/Role/Edit', params)

export const deleteRole = (id) => post(`/api/Role/Delete?id=${id}`)

export const uploadApp = (data) => post(`/api/app/import`, data, { headers: { "Content-Type": "multipart/form-data" }, timeout: 1000 * 60 * 5 })

export const getSceneDetail = (data) => get(`/api/Cgs/Portal/Visual/Detail?${qs.stringify(data)}`)

export const uploadFile = (data) => post(`/api/upload-file`, data, { headers: { "Content-Type": "multipart/form-data" } })

export const uploadZip = (data) => post(`/api/Cgs/Portal/Import`, data, { headers: { "Content-Type": "multipart/form-data" }, timeout: 1000 * 60 * 10 })

//code_Engine
export const addCodeEngine = (id) => post(`/code_engine/add/${id}`)

export const deleteCodeEngine = (id) => post(`/code_engine/delete/${id}`)

export const procCodeEngine = (id) => get(`/code_engine/delete/${id}/`)









