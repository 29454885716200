<template>
    <div class="portal-menu-table">
        <div class="portal-menu-table-wrapper">
            <div class="portal-menu-table-header">
                <cgs-tabs v-model:activeKey="state.categoryActiveKey" mode="button" @change="onChangeCategory">
                    <cgs-tab-pane v-for="item in state.categoryList" :key="item.id">
                        <template #tab>
                            <span>{{ item.label }}</span>
                        </template>
                    </cgs-tab-pane>

                    <template #rightExtra>
                        <div class="portal-menu-table-header-right">
                            <addButton :type="state.categoryActiveKey" @createdServer="createdServer"></addButton>
                            <cgs-button shape="circle" :ghost="state.showType !== 'card'"
                                @click="switchShowType('card')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-kapian"></span>
                                </template>
                            </cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'table'"
                                @click="switchShowType('table')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-liebiao"></span>
                                </template>
                            </cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'icon'"
                                @click="switchShowType('icon')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-tubiaopailie"></span>
                                </template>
                            </cgs-button>
                        </div>
                    </template>
                </cgs-tabs>
            </div>
            <div class="portal-menu-table-content">
                <div class="portal-menu-table-content-wrapper">
                    <div class="portal-menu-table-content-main">
                        <serverList ref="publishedServerListRef" :type="state.categoryActiveKey"
                            :displayMapType="state.showType">
                        </serverList>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    computed,
    reactive,
    ref
} from 'vue'
import serverList from "./server-list.vue"
import addButton from "./add-button.vue"


export default defineComponent({
    components: {
        serverList,
        addButton,
    },
    setup () {
        const sceneType = 'DataServer'

        const state = reactive({
            categoryActiveKey: 'mapServer',
            showType: 'card',
            categoryList: [
                {
                    id: 'mapServer',
                    label: '地图服务'
                }, {
                    id: 'd2TileServer',
                    label: '瓦片服务'
                }, {
                    id: 'modelServer',
                    label: '模型服务'
                }, {
                    id: 'd3TileServer',
                    label: '倾斜摄影服务'
                }, {
                    id: 'drawingServer',
                    label: '图纸服务'
                }, {
                    id: 'styleList',
                    label: '样式列表'
                }
            ],

        })

        const publishedServerListRef = ref(null)

        const hasAddSceneAuth = computed(() => {
            let _hasAuth = window.authManager.addSceneAuth()
            return _hasAuth
        })
        const hasImportSceneAuth = computed(() => {
            let _hasAuth = window.authManager.importSceneAuth()
            return _hasAuth
        })
        const createdServer = () => {
            console.log(13212);
            publishedServerListRef.value.createdServer()
        }
        const switchShowType = (type) => {
            if (state.showType === type) return
            state.showType = type
        }

        return {
            sceneType,
            state,
            hasImportSceneAuth,
            hasAddSceneAuth,
            publishedServerListRef,
            switchShowType,
            createdServer
        };
    }
})
</script>

<style lang="less" scoped>
.portal-menu-table {
    width: 100%;
    height: 100%;
}

.portal-menu-table-wrapper {
    width: 100%;
    height: 100%;
    padding: 8px 48px 8px 48px;
    display: flex;
    flex-direction: column;
}

.portal-menu-table-header {
    width: 100%;
    margin-top: 16px;
}

.portal-menu-table-header-right {
    display: flex;
}

.portal-menu-table-header-right>button {
    margin-left: 5px;
}

.portal-menu-table-content {
    flex: 1;
    height: 0;
}

.portal-menu-table-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.portal-menu-table-content-main {
    flex: 1;
    height: 0;
}

.portal-menu-table-content-footer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>