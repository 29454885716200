<template>
    <div class="portal-system-role-addRole">
        <cgs-modal :visible="dialogVisible" :title="submitState.state ==='add'? '添加角色' : '修改角色'" @close="$emit('cancelAddRoleModal')" :footer="null">
            <template #content>
                <cgs-form
                :labelCol="{span:4, offset: 0}"
                    :model="formState"
                    @finish="() => {$emit('formRePassSubmit',formState)}"
                >
                    <cgs-form-item
                    label="角色名称"
                    name="roleName"
                    :rules="[{ required: true, message: '请输入编码' }]"
                    >
                        <cgs-input v-model:value="formState.roleName" />
                    </cgs-form-item>
                    <cgs-form-item
                    label="功能权限"
                    name="checkAll"
                    >
                        <div class="portal-system-role-addRole-checkbox">
                            <a-checkbox
                            v-model:checked="formState.checkAll"
                            :indeterminate="formState.indeterminate"
                            @change="onCheckAllChange"
                            >
                            全选
                            </a-checkbox>
                        </div>
                        <a-checkbox-group v-model:value="formState.checkedList">
                            <a-checkbox v-for="item in authorities" :key="item.ID" :value="item.ID">{{ item.Name }}</a-checkbox>
                        </a-checkbox-group>
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddRoleModal')" class="portal-system-role-addRole-button">取 消</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-system-role-addRole-button">确 定</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    watch
} from 'vue'
export default defineComponent({
    props: {
        dialogVisible:{
            type: Boolean,
            default: false,
        },
        submitState: {
            type: Object,
            default: () => {},
        },
        authorities: {
            type: Array,
            default: () => [],
        }
    },
    setup(props) {
        const formState = reactive({
            roleName:'',
            checkedList:[],
            indeterminate: false,
            checkAll: false,
        })
        const onCheckAllChange = (e) => {
            let authorities = [];
            for (let i = 0; i < props.authorities.length; i++) {
                authorities.push(props.authorities[i].ID);
            }
            Object.assign(formState, {
                checkedList: e.target.checked ? authorities : [],
                checkAll: true,
            });
        }
        watch(() => formState.checkedList, val => {
                formState.indeterminate = !!val.length && val.length < props.authorities.length;
                formState.checkAll = val.length ===  props.authorities.length;
            },
        );
        watch(() => props.dialogVisible, () => {
            if (props.submitState.state === 'updata' && props.dialogVisible === true) {
                let data = props.submitState.scene
                formState.id = data.id
                formState.roleName = data.roleName
                formState.checkedList = data.roleAuthorities
                formState.indeterminate = true,
                formState.checkAll = data.checkAll
            }else if(props.submitState.state === 'add' && props.dialogVisible === true){
                formState.roleName = ''
                formState.checkedList = []
                formState.indeterminate = true,
                formState.checkAll = false
            }
        })
        return {
            formState,
            onCheckAllChange
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-role-addRole-button{
    margin: 0px 15px;
}
.portal-system-role-addRole-checkbox{
    margin-top: 6px;
    margin-bottom: 15px;
}
</style>