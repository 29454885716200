<template>
    <!-- <div class="root"> -->
    <cgs-config-provider ref="configProvider" :locale="$i18n.locale">
        <router-view v-slot="{ Component }">
            <!-- <keep-alive> -->
            <component :is="Component" />
            <!-- </keep-alive> -->
        </router-view>
    </cgs-config-provider>
    <!-- </div> -->

</template>

<script>
import {
    defineComponent,
    ref,
    onMounted
} from 'vue'
import AuthManager from '@js/authManager'
export default defineComponent({
    name: 'App',
    components: {},
    setup () {
        const configProvider = ref(null);
        onMounted(() => {
            // configProvider.value.setThemeName('dark')
            let authManager = new AuthManager()
            authManager.init()
        })
        return {
            configProvider
        }
    }
})
</script>

<style lang="less">
#app {
    height: 100%;
    width: 100%;
    font-family: 'alibaba_puhuitiregular';
}

.root {
    height: 100%;
    width: 100%
}
</style>