<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 19:21:10
!-->

<template>
    <cgs-modal class="scene-portal-menu-authority-role-role-modal" v-model:visible="vis" :title="title"
        @close="closeModal" style="width: 1000px">
        <template #content>
            <div class="scene-portal-menu-authority-role-role-modal-content">
                <div class="scene-portal-menu-authority-role-role-modal-content-wrapper">
                    <div class="scene-portal-menu-authority-role-role-modal-name">
                        <!-- <div class="scene-portal-menu-authority-role-role-modal-label">
                            <span>*</span>
                            <span>{{ $t('auth.sceneRoleAuthorityModal.name') }}</span>
                        </div>
                        <div class="scene-portal-menu-authority-role-role-modal-value">
                            <cgs-input v-model:value="state.roleName"></cgs-input>
                        </div> -->
                        <cgs-form ref="formRef" :model="state" style="width: 100%;" :label-col="{ span: 2, }"
                            autocomplete="off" labelAlign="left">
                            <cgs-form-item :label="$t('auth.appRoleAuthorityModal.name')" name="roleName"
                                :rules="[{ required: true, message: '请输入角色名称' }]">
                                <cgs-input v-model:value="state.roleName" />
                            </cgs-form-item>
                            <cgs-form-item :label="$t('auth.appRoleAuthorityModal.desc')">
                                <cgs-input v-model:value="state.desc"></cgs-input>
                            </cgs-form-item>
                        </cgs-form>
                    </div>
                    <!-- <div class="scene-portal-menu-authority-role-role-modal-desc">
                        <div class="scene-portal-menu-authority-role-role-modal-label">
                            <span>{{ $t('auth.sceneRoleAuthorityModal.desc') }}</span>
                        </div>
                        <div class="scene-portal-menu-authority-role-role-modal-value">
                            <cgs-input v-model:value="state.desc"></cgs-input>
                        </div>
                    </div> -->
                    <div class="scene-portal-menu-authority-role-role-modal-detail">
                        <div class="scene-portal-menu-authority-role-role-modal-label">
                            <span>{{ $t('auth.sceneRoleAuthorityModal.permissions') }}</span>
                        </div>
                        <div class="scene-portal-menu-authority-role-role-modal-detail-content">
                            <div class="scene-portal-menu-authority-role-role-modal-detail-item">
                                <div class="scene-portal-menu-authority-role-role-modal-detail-label">
                                    <span>{{ $t('auth.sceneRoleAuthorityModal.gateway') }}</span>
                                </div>
                                <div class="scene-portal-menu-authority-role-role-modal-detail-value">
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-all-checkbox">
                                        <cgs-checkbox v-model:checked="state.sceneAuthConfig.portal.checkAll"
                                            :indeterminate="state.sceneAuthConfig.portal.indeterminate"
                                            @change="onCheckAll('portal', $event)">
                                            {{ $t('common.Select') }}
                                        </cgs-checkbox>
                                    </div>
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-checkbox-group">
                                        <cgs-checkbox-group v-model:value="state.sceneAuthConfig.portal.value"
                                            :options="state.sceneAuthConfig.portal.options"
                                            @change="onChangeCheckGroup('portal')">
                                        </cgs-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div class="scene-portal-menu-authority-role-role-modal-detail-item">
                                <div class="scene-portal-menu-authority-role-role-modal-detail-label">
                                    <span>{{ $t('auth.sceneRoleAuthorityModal.build') }}</span>
                                </div>
                                <div class="scene-portal-menu-authority-role-role-modal-detail-value">
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-all-checkbox">
                                        <cgs-checkbox v-model:checked="state.sceneAuthConfig.builder.checkAll"
                                            :indeterminate="state.sceneAuthConfig.builder.indeterminate"
                                            @change="onCheckAll('builder', $event)">
                                            {{ $t('common.Select') }}
                                        </cgs-checkbox>
                                    </div>
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-checkbox-group">
                                        <cgs-checkbox-group v-model:value="state.sceneAuthConfig.builder.value"
                                            :options="state.sceneAuthConfig.builder.options"
                                            @change="onChangeCheckGroup('builder')">
                                        </cgs-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div class="scene-portal-menu-authority-role-role-modal-detail-item">
                                <div class="scene-portal-menu-authority-role-role-modal-detail-label">
                                    <span>{{ $t('auth.sceneRoleAuthorityModal.Modeling') }}</span>
                                </div>
                                <div class="scene-portal-menu-authority-role-role-modal-detail-value">
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-all-checkbox">
                                        <cgs-checkbox v-model:checked="state.sceneAuthConfig.editor.checkAll"
                                            :indeterminate="state.sceneAuthConfig.editor.indeterminate"
                                            @change="onCheckAll('editor', $event)">
                                            {{ $t('common.Select') }}
                                        </cgs-checkbox>
                                    </div>
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-checkbox-group">
                                        <cgs-checkbox-group v-model:value="state.sceneAuthConfig.editor.value"
                                            :options="state.sceneAuthConfig.editor.options"
                                            @change="onChangeCheckGroup('porteditoral')">
                                        </cgs-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div class="scene-portal-menu-authority-role-role-modal-detail-item">
                                <div class="scene-portal-menu-authority-role-role-modal-detail-label">
                                    <span>{{ $t('auth.sceneRoleAuthorityModal.develop') }}</span>
                                </div>
                                <div class="scene-portal-menu-authority-role-role-modal-detail-value">
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-all-checkbox">
                                        <cgs-checkbox v-model:checked="state.sceneAuthConfig.studio.checkAll"
                                            :indeterminate="state.sceneAuthConfig.studio.indeterminate"
                                            @change="onCheckAll('studio', $event)">
                                            {{ $t('common.Select') }}
                                        </cgs-checkbox>
                                    </div>
                                    <div class="scene-portal-menu-authority-role-role-modal-detail-checkbox-group">
                                        <cgs-checkbox-group v-model:value="state.sceneAuthConfig.studio.value"
                                            :options="state.sceneAuthConfig.studio.options"
                                            @change="onChangeCheckGroup('studio')">
                                        </cgs-checkbox-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="scene-portal-menu-authority-role-role-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch,
    getCurrentInstance,
    ref
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    useRouter
} from 'vue-router'
import {
    getAppAuth,
    getRoleInfo,
    editRole,
    addRole
} from '@api/scene'

export default defineComponent({
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'add' //add update
        },
        roleId: {
            type: String,
            default: undefined
        }
    },

    setup (props, ctx) {
        const {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()

        const router = useRouter();
        const formRef = ref()
        const state = reactive({
            roleName: '',
            desc: '',
            currentSceneAuth: [],
            currentViewAuth: {},
            sceneAuthList: [],
            sceneAuthConfig: {
                portal: {
                    type: 'PORTAL',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                },
                builder: {
                    type: 'BUILDER',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                },
                editor: {
                    type: 'EDITOR',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                },
                studio: {
                    type: 'STUDIO',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                }
            },
            roleInfo: {}
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })

        let title = computed(() => {

            return props.mode === 'add' ? t('auth.addRole') : t('auth.updateRole')

        })
        onMounted(() => {
            _init()
        })
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                _init()
            }
        })
        const _init = async () => {
            try {
                proxy.$loading.open()
                if (props.mode === 'update' && props.roleId) {
                    let _roleInfoParams = {
                        id: props.roleId,
                        visualType: sceneType.value
                    }
                    let _roleInfoResult = await getRoleInfo(_roleInfoParams)
                    if (_roleInfoResult.code === 200) {
                        _setCurrentRoleInfo(_roleInfoResult.data)
                    }
                }
                let _appAuthResult = await getAppAuth()
                if (_appAuthResult.code === 200) {
                    state.sceneAuthList = _appAuthResult.data.map(_item => {
                        _item['label'] = _item.alia
                        _item['value'] = _item.name
                        return _item
                    })
                    _setSceneAuth()
                }
                proxy.$loading.close()
            } catch (err) {
                proxy.$loading.close()
                console.error(err)
            }
        }
        let sceneType = computed(() => {
            return router.currentRoute.value.params.type
        })
        let sceneId = computed(() => {
            return router.currentRoute.value.params.id
        })
        // let mode = computed(() => {
        //     return props.roleId === undefined ? 'add' : 'update'
        // })
        const _setCurrentRoleInfo = (roleInfo) => {
            state.roleName = roleInfo.roleName
            state.desc = roleInfo.desc
            state.currentSceneAuth = roleInfo.appAuths
        }
        const _setSceneAuth = () => {
            let types = Object.keys(state.sceneAuthConfig)
            types.forEach(type => {
                let _obj = state.sceneAuthConfig[type]
                if (_obj) {
                    let _type = _obj.type
                    let _group = _obj.group
                    let _list = []
                    let _value = []
                    state.sceneAuthList.forEach(item => {
                        if (item.type === _type && item.group === _group) {
                            let _name = item.name
                            _list.push(item)
                            if (state.currentSceneAuth.includes(_name)) {
                                _value.push(_name)
                            }
                        }
                    })
                    _obj.options = _list
                    _obj.value = _value
                }
            })

        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => {
            try {
                let res = await formRef.value.validateFields();
                console.log(res);
            } catch (err) {
                console.log(err);
            }
            if (state.roleName === "") {
                // proxy.$message.error('角色名称为空')
                return
            }
            let params = {
                roleName: state.roleName,
                desc: state.desc,
                formAuthType: 0,
                type: "APP_ROLE",
                appAuths: []
            }
            Object.values(state.sceneAuthConfig).forEach(item => {
                item.value.forEach(_auth => {
                    params.appAuths.push(_auth)
                })
            })
            if (props.mode === 'add') {
                try {
                    proxy.$loading.open()
                    params.visualId = sceneId.value
                    let _result = await addRole(params)
                    if (_result.code === 200) {
                        proxy.$message.success('新增成功')
                        params.id = _result.data.roleId
                        ctx.emit('addSuccess', params)
                        close()
                    } else {
                        proxy.$message.error('新增失败')
                    }
                    proxy.$loading.close()
                } catch (err) {
                    console.error(err)
                    proxy.$message.error('新增失败')
                    proxy.$loading.close()
                }
            } else {
                try {
                    proxy.$loading.open()
                    params.id = props.roleId
                    let _result = await editRole(params)
                    if (_result.code === 200) {
                        proxy.$message.success(t('common.modifySuccess'))
                        ctx.emit('editSuccess', params)
                        close()
                    } else {
                        proxy.$message.error(t('common.modifyFail'))
                    }
                    proxy.$loading.close()
                } catch (err) {
                    console.error(err)
                    proxy.$message.error(t('common.modifyFail'))
                    proxy.$loading.close()
                }
            }
        }
        const onCheckAll = (type) => {
            if (state.sceneAuthConfig[type].checkAll) {
                state.sceneAuthConfig[type].value = state.sceneAuthConfig[type].options.map(option =>
                    option.value)
            } else {
                state.sceneAuthConfig[type].indeterminate = false
                state.sceneAuthConfig[type].value = []
            }
        }
        const onChangeCheckGroup = (type) => {
            state.sceneAuthConfig[type].indeterminate = !!state.sceneAuthConfig[type].value.length &&
                state.sceneAuthConfig[type].value.length < state.sceneAuthConfig[type].options.length;
            state.sceneAuthConfig[type].checkAll = (state.sceneAuthConfig[type].value.length === state
                .sceneAuthConfig[type].options.length)
        }
        return {
            vis,
            state,
            title,
            formRef,
            close,
            closeModal,
            ok,
            onCheckAll,
            onChangeCheckGroup

        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.scene-portal-menu-authority-role-role-modal-content {
    .text-1();
}

.scene-portal-menu-authority-role-role-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.scene-portal-menu-authority-role-role-modal-name,
.scene-portal-menu-authority-role-role-modal-desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.scene-portal-menu-authority-role-role-modal-name {
    margin-bottom: 0;
}

.scene-portal-menu-authority-role-role-modal-label {
    width: 100px;
}

.scene-portal-menu-authority-role-role-modal-value {
    flex: 1;
}

.scene-portal-menu-authority-role-role-modal-detail-item {
    display: flex;
    flex-direction: row;
    // align-items: center;
    margin-bottom: 8px;
}

.scene-portal-menu-authority-role-role-modal-detail-label {
    width: 100px;
}

.scene-portal-menu-authority-role-role-modal-detail-value {
    flex: 1;
    width: 0;
}

.scene-portal-menu-authority-role-role-modal-detail-content {
    color: @font-color-2;
}
</style>