<!--
*@description 
*@author 杜超峰
*@date 2023/04/27 15:04:04
!-->

<template>
    <cgs-modal class="portal-menu-organization-edit-modal" v-model:visible="vis" :title="$t('modifyModal.modifyInfo')"
        @close="closeModal">
        <template #content>
            <div class="portal-menu-organization-edit-modal-content">
                <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }" labelAlign="left">
                    <cgs-form-item style="display: none;" :label="$t('modifyModal.orgCategory')" name="orgCategory">
                        <cgs-select v-model:value="formState.orgCategory" disabled>
                            <cgs-select-option :value="parseInt(option.categoryValue)"
                                v-for="option in state.orgOptions" :key="option.categoryValue">{{ option.categoryKey
                                }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item label="工作空间" name="spaceId">
                        <cgs-select v-model:value="formState.workspaceId" :allowClear="true">
                            <cgs-select-option :value="option.id" v-for="option in state.spaceOptions"
                                :key="option.id">{{
        option.name
    }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.category')" name="Category"
                        :rules="[{ required: true, message: $t('modifyModal.categoryNullError') }]">
                        <cgs-select v-model:value="formState.Category">
                            <cgs-select-option :value="parseInt(option.categoryValue)" v-for="option in categoryOptions"
                                :key="option.categoryValue">{{ option.categoryKey }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.title')" name="Title"
                        :rules="[{ required: true, message: $t('modifyModal.titleNullError') }]">
                        <cgs-input v-model:value="formState.Title" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.password')" name="Password">
                        <cgs-input v-model:value="formState.Password" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.theme')" name="themeId">
                        <cgs-select v-model:value="formState.themeId" allowClear>
                            <cgs-select-option :value="option.id" v-for="option in state.themeOptions" :key="option.id">
                                {{ option.name }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.status')" name="Status">
                        <cgs-switch class="portal-menu-table-edit-modal-switch" v-model:checked="formState.Status"
                            :checkedValue="1" :unCheckedValue="0" :checkedChildren="$t('modifyModal.published')"
                            :unCheckedChildren="$t('modifyModal.unpublished')" />
                    </cgs-form-item>
                </cgs-form>
            </div>
        </template>
        <template #footer>
            <div class="portal-menu-organization-edit-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch,
    getCurrentInstance,
    ref
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    getThemeList,
    editScene
} from '@api/scene'
import { getWorkSpaceList } from "@/api/workSpace"

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        scene: {
            type: Object,
            default: undefined
        },
        categoryOptions: {
            type: Array,
            default: () => []
        },
        sceneType: {
            type: String,
            default: ''
        },
        spaceOptions: {
            type: [Array, undefined],
            default: undefined
        },
        themeOptions: {
            type: [Array, undefined],
            default: undefined
        }

    },

    setup (props, ctx) {
        const {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        const formState = reactive({
            // Category: props.scene.category,
            // ID: props.scene.id,
            // Password: props.scene.password,
            // Status: props.scene.status,
            // Title: props.scene.title,
            // orgCategory: props.scene.orgCategory,
            // themeId: props.scene.themeId

            Category: '',
            ID: '',
            Password: '',
            Status: '',
            Title: '',
            orgCategory: '',
            themeId: '',
            workspaceId: ""
        });
        const sceneForm = ref(null)
        const state = reactive({
            orgOptions: [],
            spaceOptions: [],
            themeOptions: []
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        watch(() => props.scene, () => {
            if (props.scene) {
                formState.Category = props.scene.category
                formState.ID = props.scene.id
                formState.Password = props.scene.password
                formState.Status = props.scene.status
                formState.Title = props.scene.title
                formState.orgCategory = props.scene.orgCategory
                formState.themeId = props.scene.themeId
                formState.workspaceId = props.scene.workspaceId

            }

        })
        watch(() => props.themeOptions, (newValue) => {
            let _list = newValue ? newValue : []
            state.themeOptions = JSON.parse(JSON.stringify(_list))
        })
        watch(() => props.spaceOptions, (newValue) => {
            let _list = newValue ? newValue : []
            state.spaceOptions = JSON.parse(JSON.stringify(_list))
        })
        onMounted(() => {
            _init()
        })
        const _init = async () => {
            try {
                if (!props.themeOptions) {
                    let _themeResult = await getThemeList()
                    if (_themeResult.code === 200) {
                        state.themeOptions = _themeResult.data
                    }
                } else {
                    state.themeOptions = JSON.parse(JSON.stringify(props.themeOptions))
                }
                if (!props.spaceOptions) {
                    let _workspaseResult = await getWorkSpaceList()
                    if (_workspaseResult.code === 200) {
                        state.spaceOptions = _workspaseResult.data
                    }
                } else {
                    state.spaceOptions = JSON.parse(JSON.stringify(props.spaceOptions))
                }
            } catch (err) {
                console.error(err)
            }
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => {
            try {
                await sceneForm.value.validateFields()
                proxy.$loading.open()
                formState.workspaceId = formState.workspaceId ? formState.workspaceId : ""
                let params = {
                    Visual: formState,
                    VisualType: props.sceneType
                }
                let _result = await editScene(params)
                if (_result.code === 200) {
                    proxy.$message.success(t('common.modifySuccess'))
                    ctx.emit('success')
                    close()
                } else {
                    proxy.$message.error(t('common.modifyFail'))
                }
                proxy.$loading.close()
            } catch (err) {
                console.error(err)
                proxy.$loading.close()
                proxy.$message.error(t('common.modifyFail'))
            }
        }
        return {
            state,
            formState,
            vis,
            sceneForm,
            close,
            closeModal,
            ok

        }
    },
})
</script>

<style lang='less' scoped>
.portal-menu-organization-edit-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>

<style>
.portal-menu-table-edit-modal-switch .ant-switch-inner {
    width: 38px;
}
</style>