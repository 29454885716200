import {
  createRouter,
  createWebHistory
} from "vue-router";
import Init from '@/views/init/index.vue'
import Login from '@/views/login/index.vue'
import FindPassword from '@/views/findPassword/index.vue'
import Register from '@/views/register/index.vue'
// import Main from '@/views/main/index.vue'
import Menu from '@/views/menu/index.vue'
import Table from '@/views/menu/table/index.vue'
// import BasePlus from '@/views/menu/basePlus/index.vue'
import TwoD from '@/views/menu/twoD/index.vue'
import ThreeD from '@/views/menu/threeD/index.vue'
import Earth from '@/views/menu/earth/index.vue'
import Bim from '@/views/menu/bim/index.vue'
import Workflow from '@/views/menu/workflow/index.vue'
import Static from '@/views/menu/static/index.vue'
import IoT from '@/views/menu/iot/index.vue'
import DAS from '@/views/menu/das/index.vue'
import GeoModel from '@/views/menu/geoModel/index.vue'
import Volume from '@/views/menu/volume/index.vue'
import CAD from '@/views/menu/cad/index.vue'
import CadDesign from '@/views/menu/cadDesign/index.vue'
import Report from '@/views/menu/report/index.vue'
import Category from '@/views/menu/category/index.vue'
import Organization from '@/views/menu/organization/index.vue'

import DataServer from '@/views/menu/dataServer/index.vue'
import SCADA from '@/views/menu/scada/index.vue'
import FileStorage from '@/views/menu/fileStorage/index.vue'
import CodeEngine from '@/views/menu/codeEngine/index.vue'
import NewEarth from '@/views/menu/newEarth/index.vue'
import LargeScreen from '@/views/menu/largeScreen/index.vue'
import AI from '@/views/menu/ai/index.vue'

import Authority from '@/views/menu/authority/index.vue'


import Account from '@/views/account/index.vue'
import Safe from '@/views/account/safe.vue'

import System from '@/views/system/index.vue'
import SystemHome from '@/views/system/home.vue'
import Theme from '@/views/system/theme.vue'
import User from '@/views/system/user.vue'
import Dept from '@/views/system/dept.vue'
import Job from '@/views/system/job.vue'
import License from '@/views/system/license.vue'
import Finance from '@/views/system/finance.vue'
import Apk from '@/views/system/apk.vue'
import Role from '@/views/system/role.vue'
import tenant from '@/views/system/tenant.vue'

// import AppStore from '@/views/appStore/index.vue'
import Home from "@/views/home/index.vue"
import WorkSpace from '@/views/home/workSpace/index.vue'
import Workbench from "@/views/home/workbench/index.vue"
import SpaceAuthority from '@/views/home/workSpace/component/authority.vue'
// import WorksheetShare from '@/views/worksheetShare/index.vue'
// import {
//   getToken
// } from "@/utils/author"



const routes = [{
  path: '/',
  redirect: '/home'
},
{
  path: '/init',
  name: 'init',
  component: Init,
  meta: {
    requiresAuth: false,
    keepAlive: false
  }
},
{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    requiresAuth: false,
    keepAlive: false
  }
},
{
  path: '/findPassword',
  name: 'findPassword',
  component: FindPassword,
  meta: {
    requiresAuth: false,
    keepAlive: false
  }
},
{
  path: '/register',
  name: 'register',
  component: Register,
  meta: {
    requiresAuth: false,
    keepAlive: false
  }
},
{
  path: '/menu',
  name: 'menu',
  component: Menu,
  meta: {
    requiresAuth: true,
    keepAlive: true
  },
  children: [{
    path: 'App',
    name: 'App',
    component: Table,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'TwoD',
    name: 'TwoD',
    component: TwoD,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'ThreeD',
    name: 'ThreeD',
    component: ThreeD,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'AI',
    name: 'AI',
    component: AI,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'Earth',
    name: 'Earth',
    component: Earth,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'NewEarth',
    name: 'NewEarth',
    component: NewEarth,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'Bim',
    name: 'Bim',
    component: Bim,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'Workflow',
    name: 'Workflow',
    component: Workflow,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'Static',
    name: 'Static',
    component: Static,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'DAS',
    name: 'DAS',
    component: DAS,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'IoT',
    name: 'IoT',
    component: IoT,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'GeoModel',
    name: 'GeoModel',
    component: GeoModel,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'DataServer',
    name: 'DataServer',
    component: DataServer,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'SCADA',
    name: 'SCADA',
    component: SCADA,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'Volume',
    name: 'Vtk',
    component: Volume,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'CAD',
    name: 'CAD',
    component: CAD,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'CadDesign',
    name: 'CadDesign',
    component: CadDesign,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'Report',
    name: 'Report',
    component: Report,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'FileStorage',
    name: 'FileStorage',
    component: FileStorage,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'CodeEngine',
    name: 'CodeEngine',
    component: CodeEngine,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'LargeScreen',
    name: 'LargeScreen',
    component: LargeScreen,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'authority/:type/:id',
    name: 'authority',
    component: Authority,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'category',
    name: 'category',
    component: Category,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'organization/:id',
    name: 'organization',
    component: Organization,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }]
},
{
  path: "/home",
  name: "home",
  redirect: "/home/workbench",
  component: Home,
  meta: {
    requiresAuth: true,
    keepAlive: true
  },
  children: [{
    path: 'workSpace/:id',
    name: 'workSpace',
    component: WorkSpace,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'workbench',
    name: 'workbench',
    component: Workbench,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  },
  {
    path: 'spaceAuthority/:id',
    name: 'spaceAuthority',
    component: SpaceAuthority,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'authority/:type/:id',
    name: 'authoritySpace',
    component: Authority,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  },]
},
{
  path: '/account',
  name: 'account',
  component: Account,
  meta: {
    requiresAuth: true,
    keepAlive: true
  },
  children: [{
    path: 'safe',
    name: 'safe',
    component: Safe,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }]
},
{
  path: '/system',
  name: 'system',
  component: System,
  meta: {
    requiresAuth: true,
    keepAlive: true
  },
  children: [{
    path: 'home',
    name: 'systemHome',
    component: SystemHome,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'theme',
    name: 'theme',
    component: Theme,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'user',
    name: 'user',
    component: User,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  }, {
    path: 'dept',
    name: 'dept',
    component: Dept,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'job',
    name: 'job',
    component: Job,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'license',
    name: 'license',
    component: License,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'finance',
    name: 'finance',
    component: Finance,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'apk',
    name: 'apk',
    component: Apk,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'role',
    name: 'role',
    component: Role,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }, {
    path: 'tenant',
    name: 'tenant',
    component: tenant,
    meta: {
      requiresAuth: true,
      keepAlive: true
    }
  }]
},
// {
//   path: '/store',
//   name: 'store',
//   component: AppStore,
// },
  // {
  //   path: '/worksheetRowShare/:shareId',
  //   name: 'worksheetshare',
  //   component: WorksheetShare,
  // }
]

let router = createRouter({
  history: createWebHistory(),
  routes
})
// router.beforeEach((to, from, next) => {
//   // ...
//   // 返回 false 以取消导航
//   if (to.meta?.requiresAuth) {
//     if (getToken()) {
//       next()
//     } else {
//       next({
//         path: '/login'
//       })
//     }
//   } else {
//     next()
//   }
// })
export default router