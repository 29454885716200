<template>
    <div class="portal-public-main-layout">
        <div class="portal-public-main-layout-wrapper">
            <div class="portal-public-main-layout-header card-box-shadow">
                <slot name="header"></slot>
            </div>
            <div class="portal-public-main-layout-content">
                <div class="portal-public-main-layout-left">
                    <slot name="left"></slot>
                </div>
                <div class="portal-public-main-layout-right">
                    <slot name="right"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent,
    } from 'vue'
    export default defineComponent({
        components: {},
        props: {
            menuExtend: {
                type: Boolean,
                default: true
            }
        },
        setup() {
            return {
            }
        }
    })
</script>

<style lang="less" scoped>
    @import "~@style/less/theme.less";

    .portal-public-main-layout {
        width: 100%;
        height: 100%;
        background-color: @background-color;
    }

    .portal-public-main-layout-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .portal-public-main-layout-header {
        height: 56px;
        background-color: @card-color;
        z-index: 1;
        .border(0, 0, 1px, 0)
    }

    .portal-public-main-layout-content {
        flex: 1;
        height: 0;
        display: flex;
    }

    .portal-public-main-layout-left {
        // width: 220px;
        background-color: @card-color;
        .border(0, 1px, 0, 0);
    }

    .portal-public-main-layout-right {
        flex: 1;
        width: 0;
        overflow: auto;
        // margin-top: 16px;
    }

    .portal-public-main-layout-left-extend {
        width: 50px;
    }
</style>