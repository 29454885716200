<!--
*@description 
*@author 杜超峰
*@date 2023/04/26 21:42:08
!-->

<template>
    <div class="portal-public-scene-icon" :style="{ background: themeColor }">
        <span class="portal-public-scene-icon-span" :class="[iconFontFamily, showIcon]"
            :style="{ fontSize: iconSize }"></span>
    </div>
</template>

<script>
import {
    defineComponent,
    computed
} from 'vue'
import {
    profix,
    default_class_name,
    font_family,
    defaultIcon_app,
    getThemeObjByClassName
} from '@js/tableIconAndTheme'

export default defineComponent({
    components: {},
    props: {
        icon: {
            type: String,
            default: ''
        },
        themeName: {
            type: String,
            default: ''
        },
        iconSize: {
            type: String,
            default: '27px'
        },
    },

    setup (props) {
        let iconFontFamily = font_family
        let showIcon = computed({
            get: () => {
                let _icon = props.icon
                if (props.icon === '') _icon = defaultIcon_app
                return profix + _icon
            },
            set: () => { }
        })
        let themeColor = computed({
            get: () => {
                let _color = ''
                let _themeName = props.themeName
                if (props.themeName === '') _themeName = default_class_name
                let _themeObj = getThemeObjByClassName(_themeName)
                _color = _themeObj.color
                return _color
            },
            set: () => { }
        })
        return {
            iconFontFamily,
            showIcon,
            themeColor
        }
    },
})
</script>

<style lang='less' scoped>
@import '~@style/less/theme.less';

.portal-public-scene-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.portal-public-scene-icon-span {
    font-size: 28px;
    ;
    color: @foreground-color-dark-background;
}
</style>