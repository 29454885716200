<template>
    <div class="portal-system-dept-table">
        <div class="portal-system-dept-table-wrapper">
            <cgs-table :columns="showColumns" :data-source="sceneList" mode="portal" size="small" :pagination="false"
                position="bottomCenter" :backgroundTransparent="true">
                <template #headerCell="{column}">
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-dept-table-action">
                            <cgs-input style="width: 188px;" v-model:value="state.searchInput" :placeholder="$t('remind.searchRemind')"
                                @change="changeSearchInput" />
                        </div>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>


<script>
    import {
        computed,
        defineComponent,
        reactive,
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    const columns = [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        customRender: ({
            index
        }) => `${index + 1}`,
    }, {
        title: '账号',
        dataIndex: 'userName',
        key: 'userName',
        width: 300,
    }, {
        title: '用户名称',
        dataIndex: 'userAlia',
        key: 'userAlia',
        width: 300,
    }, {
        title: '用户角色',
        dataIndex: 'userRole',
        key: 'userRole',
        width: 150,
    }, {
        title: '职位',
        dataIndex: 'jobs',
        key: 'jobs',
        width: 150,
    }, {
        title: '',
        dataIndex: 'action',
        key: 'action',
    }];
    export default defineComponent({
        components: {},
        props: {
            sceneList: {
                type: Array,
                default: () => []
            },
            currentPagination: {
                type: Object,
                default: () => {}
            },
        },

        setup(props, ctx) {
            const {
                t
            } = useI18n()
            const state = reactive({
                searchInput: ''
            })
            const showColumns = computed(() => {
                return columns.map(_item => {
                    _item.title = t('systemInfo.deptTable.' + _item.key)
                    return _item
                })
            })
            const clikMenuItemHandler = (scene, params) => {
                ctx.emit('clikMenuItem', scene, params)
            }
            const handleChange = (roles, column) => {
                ctx.emit('handleChangeUserRole', roles, column)
            }
            const changeSearchInput = () => {
                ctx.emit('searchInput', state.searchInput)
            }
            return {
                showColumns,
                state,
                changeSearchInput,
                clikMenuItemHandler,
                handleChange
            }
        },
    })
</script>

<style scoped lang="less">
    .portal-system-dept-table {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .portal-system-dept-table-wrapper {
        width: 100%;
        height: 100%;
    }

    .portal-system-dept-table-action {
        width: 100%;
        height: 100%;
        text-align: right;
    }
</style>