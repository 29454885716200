<template>
    <div class="portal-menu-dataServer-add-modal">
        <publish-style-list-button v-if="type === 'styleList'" @created="createdServer"></publish-style-list-button>
        <publish-model-server-button v-if="type === 'modelServer'" @created="createdServer"
            :publish-type="['file', 'resource']"></publish-model-server-button>
        <publish-map-server-button v-if="type === 'mapServer'" @created="createdServer" :publish-type="['file', 'resource']"
            :acceptFolder="true"></publish-map-server-button>
        <publish-drawing-server-button v-if="type === 'drawingServer'"
            @created="createdServer"></publish-drawing-server-button>
        <publish-d3-tile-server-button v-if="type === 'd3TileServer'"
            @created="createdServer"></publish-d3-tile-server-button>
        <publish-d2-tile-server-button v-if="type === 'd2TileServer'"
            @created="createdServer"></publish-d2-tile-server-button>
    </div>
</template>

<script>
import {
    defineComponent
} from 'vue'


export default defineComponent({
    props: {
        type: {
            type: String,
            default: '',
        },
    },
    setup(props, ctx) {
        const createdServer = () => {
            ctx.emit('createdServer')
        }

        return {
            createdServer
        }
    },
})
</script>

<style lang='less' scoped>
.portal-menu-table-add-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>

<style>
.portal-menu-table-add-modal-switch .ant-switch-inner {
    width: 38px;
}
</style>