<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:21:13
!-->

<template>
    <div class="portal-system-finance-card">
        <div class="portal-system-finance-card-wrapper">
            <div class="portal-system-finance-card-cover">
                <div class="portal-system-finance-card-icon">
                    <span class="portal-system-finance-card-icon-span cgs-portal-iconfont cgs-portal-icon-yue"></span>
                </div>
            </div>
            <div class="portal-system-finance-card-info">
                <div class="portal-system-finance-card-title"><span>{{ title }}</span></div>
                <div class="portal-system-finance-card-num"><span>{{ num }}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent
    } from 'vue'

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                default: ''
            },
            num: {
                type: Number,
                default: undefined
            }
        },

        setup() {
            return {
            }
        },
    })
</script>

<style lang='less' scoped>
    @import "~@style/less/theme.less";

    .portal-system-finance-card {
        //margin: 5px;
        // width: 24.2%;
        // margin: 0.4%;
        // height: 100px;
        width: 320px;
        border-radius: 6px;
        .border();
        background: @card-color;
        overflow: hidden;
    }

    .portal-system-finance-card-wrapper {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 16px;
    }

    .portal-system-finance-card-cover {
        width: 80px;
        height: 80px;
        position: relative;
        overflow: hidden;
    }

    .portal-system-finance-card-info {
        flex: 1;
        .text-1();
        color: @font-color-1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 8px;
        display: flex;
        align-items: center;
    }

    .portal-system-finance-card-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 8px;
    }

    // .portal-system-finance-card-desc {
    //     margin-top: 24px;
    //     .secondary-text();
    //     color: @font-color-2;
    // }

    .portal-system-finance-card-published {
        color: @primary-color;
    }

    .portal-system-finance-card-unpublish {
        color: @font-color-2;
    }

    .portal-system-finance-card-icon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: @primary-color;
    }

    .portal-system-finance-card-icon-span {
        font-size: 28px;
    }

    .portal-system-finance-card-num{
        .text-1();
        color: @primary-color;
    }
</style>