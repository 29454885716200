<template>
  <div>
    <h3 class="portal-home-work-flow-title little-title">{{ $t('homeBench.allWorkSpace') }}</h3>
    <div class="portal-home-work-collect-app">
      <!-- <AppCard></AppCard> -->
      <AppIcon :sceneList="state.sceneList" @enter="enterScene" @clikMenuItem="clikSceneMenuItem"
        @clikCollect="clikSceneCollect"> </AppIcon>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watch, defineEmits, onMounted } from "vue"
import AppIcon from "../../workSpace/component/appIcon.vue"
import { getWorkSpaceList } from "@/api/workSpace"
const props = defineProps({
  sceneList: {
    type: Array,
    default: undefined
  }
})
const state = reactive({
  sceneList: []
})

watch(() => props.sceneList, (newVal) => {
  if (newVal) {
    let _list = newVal ? newVal : []
    state.sceneList = JSON.parse(JSON.stringify(_list))
  }
})



onMounted(() => {
  init()
})
const init = async () => {
  if (!props.sceneList) {
    let allSpace = await getWorkSpaceList()
    if (allSpace.code === 200) {
      allSpace.data.forEach(item => {
        item.title = item.name
      })
      state.sceneList = allSpace.data
    }
  } else {
    state.sceneList = JSON.parse(JSON.stringify(props.sceneList))
  }
}


const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect"])
const enterScene = (scene) => {
  emit("enterScene", scene)
}
const clikSceneMenuItem = (scene, menuItemParams) => {
  emit("clikSceneMenuItem", scene, menuItemParams)
}
const clikSceneCollect = (scene) => {
  emit("clikSceneCollect", scene)
}
</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-work-flow-title {
  font-size: 16px;
  color: @font-color-1;
  margin-bottom: 10px;
}
</style>
