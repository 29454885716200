<!--
*@description 
*@author 杜超峰
*@date 2023/04/23 11:29:38
!-->

<template>
    <div class="portal-menu-left-nav" :class="menuCollapse ? '' : 'portal-menu-left-nav-not-collapse'">
        <div class="portal-menu-left-nav-wrapper">
            <div class="portal-menu-left-nav-header">
                <div class="portal-menu-left-nav-header-left">
                    <!-- <span class="text-1 font-color-1" v-if="!menuCollapse">{{ showMode === 'sceneTypeClassify' ? $t('scene') :
                        $t('organization') }}</span> -->
                    <span class="text-1 font-color-1" v-if="!menuCollapse">{{ showMode === 'sceneTypeClassify'
        ? $t('scene') : '首页' }}</span>
                    <span
                        class="portal-menu-left-nav-switch-icon cgs-portal-iconfont cgs-portal-icon-qiehuan font-color-3"
                        @click="onSwitchHandler"></span>
                </div>
                <div class="portal-menu-left-nav-header-right" v-if="!menuCollapse && showAddButton">
                    <cgs-button type="text" @click="addSceneTypeHandler">
                        <span
                            class="portal-menu-left-nav-add-icon cgs-portal-iconfont cgs-portal-icon-tianjia font-color-2"></span>
                    </cgs-button>
                </div>
            </div>
            <div class="portal-menu-left-nav-content">
                <div class="portal-menu-left-nav-content-wrapper">
                    <div class="portal-menu-left-nav-content-item selectable-item"
                        :class="item.key === currentActiveKey ? 'selected-item' : ''" v-for="item in middleMenuList"
                        :key="item.key" @click="clickMiddleMenuItemHandler(item)">
                        <div class="portal-menu-left-nav-content-item-left">
                            <span class="portal-menu-left-nav-content-item-icon" :class="item.icon"></span>
                            <span class="portal-menu-left-nav-content-item-title" v-if="!menuCollapse">{{ item.label
                                }}</span>
                        </div>
                        <span class="portal-menu-left-nav-content-item-more"
                            v-if="!menuCollapse && showMiddleMenuMore && moreMenuList.length > 0">
                            <cgs-more :menuList="moreMenuList"
                                @clikMenuItem="(params) => clickMenuItemHandler(params, item)"></cgs-more>
                        </span>
                    </div>
                </div>

            </div>
            <div class="portal-menu-left-nav-footer">
                <div class="portal-menu-left-nav-footer-wrapper">
                    <div class="portal-menu-left-nav-footer-item selectable-item"
                        :class="item.key === currentActiveKey ? 'selected-item' : ''" v-for="item in showFootMenuList"
                        :key="item.key" @click="clickFooterMenuItemHandler(item)">
                        <span class="portal-menu-left-nav-footer-item-icon" :class="item.icon"></span>
                        <span class="portal-menu-left-nav-footer-item-title" v-if="!menuCollapse">{{ item.label
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    reactive,

} from 'vue'

import {
    useI18n
} from 'vue-i18n'
import useHeader from "../../store/modules/header"

export default defineComponent({
    props: {
        title: {
            type: String,
            default: ''
        },
        showAddButton: {
            type: Boolean,
            default: false
        },
        showMiddleMenuMore: {
            type: Boolean,
            default: true
        },
        moreMenuList: {
            type: Array,
            default: () => []
        },
        middleMenuList: {
            type: Array,
            default: () => []
        },
        activeKey: {
            type: String,
            default: ''
        },
        showMode: {
            type: String,
            default: ''
        }
    },


    setup (props, ctx) {
        const {
            t
        } = useI18n()
        const headerStore = useHeader()
        const state = reactive({
            // middleMenuList: [{
            //     key: '2d',
            //     label: '2D',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-a-2D'
            // }, {
            //     key: '3d',
            //     label: '3D',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-a-3D'
            // }, {
            //     key: 'table',
            //     label: 'Table',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-APP'
            // }]
        })

        const footMenuList = [{
            key: 'category',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-fenleiguanli'
        }, {
            key: 'store',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-yingyongshichang'
        }, {
            key: 'theme',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zhuti'
        }, {
            key: 'universe',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-Earth'
        }]
        const currentActiveKey = computed({
            get: () => props.activeKey,
            set: (newVal) => {
                ctx.emit('update:activeKey', newVal)
            }
        })


        const showFootMenuList = computed(() => {
            return footMenuList.map(_item => {
                _item['label'] = t('footMenu.' + _item.key)
                return _item
            })
        })
        const menuCollapse = computed(() => headerStore.menuCollapse)



        const clickMiddleMenuItemHandler = (menuItem) => {
            ctx.emit('clickMiddleMenu', menuItem)
        }
        const clickFooterMenuItemHandler = (menuItem) => {
            ctx.emit('clickFooterMenu', menuItem)
        }
        const addSceneTypeHandler = () => {
            ctx.emit('addSceneType')
        }
        const onSwitchHandler = () => {
            ctx.emit('switchMode')
        }
        const clickMenuItemHandler = (params, item) => {
            ctx.emit('clickMiddleMenuMore', params, item)
        }





        return {
            showFootMenuList,
            state,
            clickMiddleMenuItemHandler,
            clickFooterMenuItemHandler,
            addSceneTypeHandler,
            currentActiveKey,
            onSwitchHandler,
            clickMenuItemHandler,
            menuCollapse
        }
    },
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-menu-left-nav {
    height: 100%;
}

.portal-menu-left-nav-not-collapse {
    width: 300px;
}

.portal-menu-left-nav-wrapper {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portal-menu-left-nav-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    .border(0, 0, 1px, 1)
}

.portal-menu-left-nav-header-left {
    display: flex;
    align-items: center;
}

.portal-menu-left-nav-switch-icon {
    font-size: 12px;
    margin-left: 8px;
    cursor: pointer;
}

.portal-menu-left-nav-content {
    flex: 1;
    height: 0;
    overflow-y: auto;
}

.portal-menu-left-nav-footer {
    .border(1px, 0, 0, 0);
    .text-1();
    .font-color-1();
}

.portal-menu-left-nav-footer-wrapper {
    margin: 20px 0px;
}

.portal-menu-left-nav-content-item,
.portal-menu-left-nav-footer-item {
    padding: 8px;
    border-radius: 5px;
    margin: 2px 0px;


}

.portal-menu-left-nav-content-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portal-menu-left-nav-content-item-title,
.portal-menu-left-nav-footer-item-title {
    margin-left: 8px;
    padding-right: 10px;
    width: 100px;

}

.portal-menu-left-nav-content-item-left {
    width: 90%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.portal-menu-left-nav-content {
    .text-1();
    .font-color-1();
}

.portal-menu-left-nav-content-wrapper {
    margin-top: 8px;
    overflow: hidden;
}

.portal-menu-left-nav-content-item-more {
    display: none;
    float: right;
    // transition: all 0.3s;
}

.portal-menu-left-nav-content-wrapper-work {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.portal-menu-left-nav-content-item-work {
    height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @font-color-1;
    padding: 10px;
    cursor: pointer;
}

.portal-menu-left-nav-content-item-work:hover {
    background: @selected-color;
}

.portal-menu-left-nav-content-item-work-item {
    display: flex;
    align-items: center;


}

.portal-menu-left-nav-content-item-work-item-icon {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.portal-menu-left-nav-content-item-work-item-icon-space {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @selected-color;

    span {
        font-size: 12px;
    }
}

.portal-menu-left-nav-content-item-icon {
    font-size: 14px;
    margin-left: 5px;
}

.selectWork {
    background: @selected-color;
}



::v-deep(.ant-menu-sub.ant-menu-inline) {
    background: @foreground-color-dark-background;
}

.portal-menu-left-nav-content-item:hover .portal-menu-left-nav-content-item-more {
    display: unset;
}

::v-deep(.cgs-design-sub-menu.ant-menu-submenu-open) {
    background: @selected-color;
    border-radius: 5px;
    color: @font-color-1;
}

::v-deep(.ant-menu-light .ant-menu-submenu-title:hover) {
    background: @selected-color;
    border-radius: 5px;
    color: @font-color-1;
}

::v-deep(.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow) {
    color: @font-color-1;
}

::v-deep(.ant-menu-inline) {
    border-right: none;
}

::v-deep(.ant-menu-item-group-title) {
    padding-left: 10px !important;
}

::v-deep(.ant-menu-submenu-title) {
    padding-left: 5px !important;
}

::v-deep(.ant-menu-sub.ant-menu-inline>.ant-menu-item) {
    height: 100% !important;
    padding-left: 10px !important;
}

.item-type {
    margin-right: 5px;
    color: @font-color-3;
    font-size: 12px;
}
</style>