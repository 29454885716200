<template>
    <div class="portal-system-license">
        <div class="portal-system-license-wrapper">
            <div class="portal-system-license-header">
                <div class="portal-system-license-header-title">
                    {{$t('systemInfo.license')}}
                </div>
                <div>
                    <cgs-button ghost class="portal-system-license-add-license" @click="dialogVisible = true">
                        <template #icon>
                            <span class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-license-icon"></span>
                        </template>
                        {{$t('systemInfo.addLicense')}}</cgs-button>
                    <span class="cgs-portal-iconfont cgs-portal-icon-xiangqing portal-system-license-add-license-particulars" @click="handleDetail"></span>
                </div>
            </div>
            <div class="portal-system-license-content">
                <div class="portal-system-user-content-table">
                    <licenseTable :sceneList="tableData" :radioStatus="radioStatus" @handleEnableLicense="handleEnableLicense"></licenseTable>
                </div>
            </div>
        </div>
        <cgs-modal :visible="detailDialogFormVisible" :title="$t('common.detail')" @close="detailDialogFormVisible = false" :footer="null">
            <template #content>
                <div>
                    {{$t('systemInfo.serverId')}}
                </div>
                <div>
                    <span id="copyMachineId">{{machineId}}</span>
                    <span
                        class="portal-system-user-copy"
                        @click="handleCopy('copyMachineId')"
                    >{{$t('common.copy')}}</span>
                </div>
            </template>
        </cgs-modal>
        <addLicenseModal :dialogVisible="dialogVisible" @cancelAddlicenseModal="cancelAddlicenseModal" @addlicenseModal="addlicenseModal"></addLicenseModal>
    </div>
</template>

<script>
    import {
        defineComponent,
        ref,
        getCurrentInstance,
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    import {
        getLicenseList,
        enableLicense,
        addLicense,
        getMachineID,
        copyDomText
    } from '@/api/tenant'
    import licenseTable from './components/license-table.vue'
    import addLicenseModal from './components/addLicense.vue'

    export default defineComponent({
        components: {
            licenseTable,
            addLicenseModal
        },
        setup() {
            let {
                proxy
            } = getCurrentInstance();
            const {
                t
            } = useI18n()
            const tableData = ref([])
            const radioStatus = ref('')
            const dialogVisible = ref(false)
            const machineId = ref('')
            const detailDialogFormVisible = ref(false)
            const getList = () => {
                getLicenseList().then((res) => {
                    if(res && res.success){
                        tableData.value = res.data
                        for (let i = 0; i < tableData.value.length; i++) {
                            tableData.value[i].key = i
                        }
                        for (let i = 0; i < tableData.value.length; i++) {
                            if(tableData.value[i].status === 1){
                                radioStatus.value = tableData.value[i].id;
                                break;
                            }
                        }
                    }
                })
            }
            const handleEnableLicense = (record) => {
                enableLicense({
                    id: record
                }).then((res) => {
                    if(res && res.success){
                        proxy.$message.success(t('common.enableSuccess'));
                        getList();
                    }else {
                        proxy.$message.error(t('common.enableFail'));
                    }
                })
            }
            const cancelAddlicenseModal = () => {
                dialogVisible.value = false
            }
            const addlicenseModal = (values) => {
                dialogVisible.value = false;
                addLicense({
                    License: values.licenseInput
                }).then((res) => {
                    if(res && res.success){
                        proxy.$message.success(t('common.createSuccess'));
                        getList();
                    }else {
                        proxy.$message.error(res.msg);
                    }
                }).catch((res)=>{
                    proxy.$message.error(res.msg);
                })
            }
            const handleDetail = () => {
                machineId.value = '';
                getMachineID().then((res) => {
                    detailDialogFormVisible.value = true;
                    if(res && res.Code === 200){
                        machineId.value = res.Msg;
                    }else {
                        machineId.value = t('systemInfo.notFoundServer')
                    }
                })
            }
            const handleCopyOk = () => {
                proxy.$message.success(t('common.copySuccess'));
            }
            const handleCopyErr = () => {
                proxy.$message.error(t('common.copyFail'));
            }
            const handleCopy = (domid) => {
                let res = copyDomText(domid)
                if(res){
                    proxy.$message.success(t('common.copySuccess'));
                }else{
                    proxy.$message.error(t('common.copyFail'));
                }
            }
            getList()
            return {
                getList,
                handleEnableLicense,
                cancelAddlicenseModal,
                addlicenseModal,
                handleDetail,
                handleCopyOk,
                handleCopyErr,
                handleCopy,
                tableData,
                radioStatus,
                dialogVisible,
                detailDialogFormVisible,
                machineId
            }
        }
    })
</script>

<style lang="less" scoped>
    @import "~@style/less/theme.less";
    .portal-system-license{
        width: 100%;
        height: 100%;
        padding: 24px 48px 8px 48px;
        overflow: hidden;
    }
    .portal-system-license-wrapper{
        width: 100%;
        height: 100%;
    }
    .portal-system-license-header{
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .portal-system-license-header-title{
        .little-title;
        color: @font-color-1;
    }
    .portal-system-license-add-license{
        margin-right: 10px;
    }
    .portal-system-license-icon{
        color: @font-color-3;
        cursor: pointer
    }
    .portal-system-user-copy{
        color: @primary-color;
        margin-left: 20px;
        cursor: pointer
    }
    .portal-system-license-icon{
        font-size: 10px;
        color: @font-color-1;
        margin-right: 5px;
    }
    .portal-system-license-add-license-particulars{
        cursor: pointer
    }
</style>