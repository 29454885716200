<template>
    <div class="portal-system-user-addUser">
        <cgs-modal :visible="dialogVisible" :title="dialogTitle" @close="$emit('cancelAddDeptModal')" :footer="null">
            <template #content>
                <cgs-form :labelCol="{span:4, offset: 0}" :model="formState" @finish="addDeptModalHandle">
                    <cgs-form-item :label="$t('systemInfo.deptName')" name="deptName" :rules="[{ required: true, message: '请输入部门名称' }]">
                        <cgs-input v-model:value="formState.deptName" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.deptInfo')" name="info">
                        <cgs-input v-model:value="formState.property.info" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.deptState')" name="state">
                        <cgs-input v-model:value="formState.property.state" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.deptStartTime')" name="startTime">
                        <cgs-date-picker v-model:value="formState.property.startTime" show-time
                            format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.deptEndTime')" name="endTime">
                        <cgs-date-picker v-model:value="formState.property.endTime" show-time
                            format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" />
                    </cgs-form-item>
                    <div class="portal-system-user-addUser-footer-input">
                        <cgs-form-item :label="$t('systemInfo.deptLon')" name="lon">
                            <cgs-input-number v-model:value="formState.property.lon" />
                        </cgs-form-item>
                        <cgs-form-item :label="$t('systemInfo.deptlat')" name="lat">
                            <cgs-input-number v-model:value="formState.property.lat" />
                        </cgs-form-item>
                    </div>
                    <cgs-form-item :label="$t('systemInfo.deptHeadUser')" name="deptName" v-if="formState.id">
                        <cgs-select v-model:value="formState.headUsers" mode="tags" style="width: 100%">
                            <cgs-select-option :value="item.userId" v-for="item in userTableData" :key="item.userId">{{item.userAlia}}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item label="部门标签" name="deptName" v-if="formState.id">
                        <cgs-select v-model:value="formState.labelIds" mode="tags" style="width: 100%">
                            <cgs-select-option :value="item.id" v-for="item in formState.deptLabelList" :key="item.id">{{item.name}}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddDeptModal')" class="portal-system-user-addUser-button">{{ $t('common.cancel') }}
                        </cgs-button>
                        <cgs-button type="primary" html-type="submit" class="portal-system-user-addUser-button">{{ $t('common.ok') }}
                        </cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
    import {
        defineComponent,
        reactive,
        watch
    } from 'vue'
    import {
        getDeptLabelList,
    } from "@/api/tenant";

    export default defineComponent({
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            dialogTitle: {
                type: String,
                default: '',
            },
            formData: {
                type: Object,
                default: () => {},
            },
            userTableData: {
                type: Array,
                default: () => [],
            }
        },
        setup(props, ctx) {
    
            const formState = reactive({
                parentId: '',
                deptName: '',
                property: {},
                headUsers: [],
                deptLabelList: [],
                labelIds: []
            })
            watch(() => props.dialogVisible, () => {
                if (props.dialogVisible === true) {
                    formState.deptName = props.formData.parentLabel
                    formState.parentId = props.formData.parentId
                    formState.property = props.formData.property
                    formState.id = props.formData.id
                    formState.labelIds = props.formData.labelIds || []
                    getDeptLabelList().then((res) => {
                        if (res.code === 200) {
                            formState.deptLabelList = res.data.reverse();
                        }
                    });
                }
            })
            watch(() => props.userTableData, (nv) => {
                formState.headUsers = nv.filter(item => item.isDirector).map(item => item.userId)
            })
            const addDeptModalHandle = () => {
                props.userTableData.forEach(user => {
                    user.isDirector = formState.headUsers.includes(user.userId)
                })
                ctx.emit('addDeptModal',formState, formState.headUsers)
            }
            return {
                formState,
                addDeptModalHandle,
            }
        },
    })
</script>

<style lang='less' scoped>
    .portal-system-user-addUser-button {
        margin: 0px 15px;
    }

    // .portal-system-user-addUser-footer-input{
    //     display: flex;
    //     justify-content: space-between;
    //     // align-items: center;
    // }
</style>