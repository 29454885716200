<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted } from "vue";
const emit = defineEmits(['cardClick'])
const props = defineProps({
    name: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    list: {
        type: Array,
        default: () => {
            return []
        }
    },
})
const handleClick = (item) => {
    emit('cardClick', item)
}
onMounted(() => { });
onUnmounted(() => { })
</script>

<template>
    <div class="portal-public-serach-menu-card">
        <div class="portal-public-serach-menu-card-wrapper">
            <div class="portal-public-serach-menu-card-header">
                <span class="cgs-portal-iconfont font-color-2" :class="props.icon"></span>
                {{ props.name }}
            </div>
            <div class="portal-public-serach-menu-card-inner">
                <div class="portal-public-serach-menu-card-item" @click="() => handleClick(item)" v-for="item in list"
                    :key="item.id">
                    <div class="portal-public-serach-menu-card-title">
                        <span class="portal-public-serach-menu-card-icon cgs-portal-iconfont font-color-2"
                            :class="props.icon"></span>
                        {{ item.title }}
                    </div>
                    <div class="portal-public-serach-menu-card-group">
                        {{ item.desc }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-public-serach-menu-card {
    .portal-public-serach-menu-card-wrapper {
        padding: 10px;
        .border(0, 0, 1px, 0);

        .portal-public-serach-menu-card-header {
            display: flex;
            align-items: center;
            .font-color-2();

            .cgs-portal-iconfont {
                margin-right: 6px;
            }
        }

        .portal-public-serach-menu-card-inner {}

        .portal-public-serach-menu-card-item {
            padding: 10px;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            border-radius: 5px;
        }

        .portal-public-serach-menu-card-item:hover {
            background: @selected-color;

            .portal-public-serach-menu-card-title,
            .portal-public-serach-menu-card-group {
                .font-color-1();
            }
        }

        .portal-public-serach-menu-card-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 5px;
            font-size: 12px;
            margin-right: 6px;
            background-color: @primary-color;
        }

        .portal-public-serach-menu-card-title {
            .font-color-2();
        }

        .portal-public-serach-menu-card-group {

            .font-color-3();
        }
    }
}

.portal-public-serach-menu-card:last-child {
    .portal-public-serach-menu-card-wrapper {
        padding-bottom: 0;
        .border(0, 0, 0, 0)
    }
}

.portal-public-serach-menu-card:first-child {
    .portal-public-serach-menu-card-wrapper {
        padding-top: 0;
    }
}
</style>