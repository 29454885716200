<template>
    <div class="portal-system-theme-themeImg">
        <cgs-radio-group v-model:value="state.backType" v-if="typeSwitch">
            <div>
                <cgs-radio value="color" @change="typeIsColor">颜色</cgs-radio>
            </div>
            <div class="portal-system-theme-radio">
                <cgs-radio value="img" @change="typeIsImg">图片</cgs-radio>
            </div>
        </cgs-radio-group>
        <div v-if="!typeSwitch || (typeSwitch && state.backType === 'color')">
            <cgs-color-picker v-model:pureColor="state.backValue" pickerType="chrome" format="rgb"
                :disableAlpha="false" />
        </div>
        <div v-else-if="typeSwitch && state.backType === 'img'" class="portal-system-theme-themeImg-upload">
            <div class="portal-system-theme-themeImg-upload-img">
                <div class="portal-system-theme-themeImg-backImgShow" v-if="this.value.indexOf('url') >= 0" :style="{
                    'background-image': state.backValue,
                    'background-size': state.backImgSize,
                    'background-position': '50% 50%',
                }">
                    <span class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-theme-themeImg-clearImg"
                        @click="clearBackImg"></span>
                </div>
                <cgs-upload v-else name="file" v-model:file-list="fileList" :multiple="true" action="/api/upload-file"
                    @change="handleImgChange">
                    <cgs-button>
                        <template #icon>
                            <span class="cgs-portal-iconfont cgs-portal-icon-shangchuan"></span>
                        </template>
                        上传图片，5MB以内
                    </cgs-button>
                </cgs-upload>
            </div>
            <div class="portal-system-theme-themeImg-upload-size">
                <div class="portal-system-theme-themeImg-select-title">图片填充方式:</div>
                <div>
                    <cgs-select v-model:value="state.backImgSize" style="width: 114px;">
                        <cgs-select-option value="cover">
                            居中填满
                        </cgs-select-option>
                        <cgs-select-option value="100% 100%">
                            拉伸填满
                        </cgs-select-option>
                    </cgs-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    watch,
    onMounted,
    getCurrentInstance,
    ref
} from 'vue'

export default defineComponent({
    props: {
        typeSwitch: {
            type: Boolean,
            default: false,
        },
        imgSize: {
            type: String,
            default: 'cover',
            validator (value) {    //validator可以叫做属性的检查器  //value形式参数，代表用户传入的值，可以在该方法中进行操作
                if (value !== 'cover' && value !== '100% 100%') {
                    return false;
                } else {
                    return true;
                }
            }
        },
        value: String
    },
    setup (props, ctx) {
        let { proxy } = getCurrentInstance();
        const state = reactive({
            backType: 'img',
            backImgSize: props.imgSize || 'cover',
            backValue: props.value || '#ffffff',
            colorValue: '#ffffff',
            imgValue: '',
        })
        const fileList = ref([])
        const clearBackImg = () => {
            state.backValue = 'rgba(0,0,0,1)';
            state.imgValue = '';
            fileList.value = []
        }
        const typeIsColor = () => {
            state.backValue = state.colorValue;
        }
        const typeIsImg = () => {
            if (state.imgValue) {
                state.backValue = state.imgValue;
            }
        }
        const handleImgChange = (res) => {
            if (res.file.status === 'uploading') {
                // return;
            }
            if (res.file.status === 'done') {
                if (res.file.response.code === 200) {
                    state.backValue = `url("/file/${res.file.response.data.link}")`;
                    state.imgValue = state.backValue;
                } else {
                    proxy.$message.error('上传失败');
                }
            }

            if (res.file.status === 'error') {
                proxy.$message.error('上传失败');
            }
        }
        watch(() => state.backImgSize, () => {
            ctx.emit('backImgSizeChange', state.backImgSize);
        },
            { deep: true, immediate: true }
        )
        watch(() => state.backValue, () => {
            ctx.emit('backChange', state.backValue);
        },
            { deep: true, immediate: true }
        )
        watch(() => props.value, (newData) => {
            state.backValue = newData;
            if (props.typeSwitch) {
                if (state.backValue.indexOf('url') >= 0) {
                    state.backType = 'img';
                    state.imgValue = state.backValue;
                } else {
                    state.backType = 'color';
                    state.colorValue = state.backValue;
                }
            }
        })
        onMounted(() => {
            if (props.typeSwitch) {
                if (state.backValue.indexOf('url') >= 0) {
                    state.backType = 'img';
                    state.imgValue = state.backValue;
                } else {
                    state.backType = 'color';
                    state.colorValue = state.backValue;
                }
            }
        })
        return {
            state,
            fileList,
            clearBackImg,
            handleImgChange,
            typeIsColor,
            typeIsImg
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-system-theme-themeImg {
    display: flex;
    align-items: center;
    height: 60px;
}

.portal-system-theme-radio {
    margin-top: 10px;
}

.portal-system-theme-themeImg-upload {
    display: flex;
}

.portal-system-theme-themeImg-upload-img {
    margin-right: 30px;
    margin-top: 10px;
}

.portal-system-theme-themeImg-upload-size {
    display: flex;
    align-items: center;
    margin-right: 30px;
    // height: 34px;
}

.portal-system-theme-themeImg-backImgShow {
    border-radius: 4px;
    position: relative;
    width: 180px;
    height: 60px;
    margin-bottom: 10px;

    .portal-system-theme-themeImg-clearImg {
        display: none;
        position: absolute;
        transform: rotate(45deg);
        top: 2px;
        right: 5px;
        cursor: pointer;
    }

    &:hover {
        .portal-system-theme-themeImg-clearImg {
            display: block;
        }
    }
}

.portal-system-theme-themeImg-select-title {
    margin-right: 10px;
}
</style>