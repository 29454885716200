<template>
    <main-layout class="portal-system">
        <template #header>
            <main-header>
                <template #prefix-icon>
                    <span
                        class="portal-system-change-icon cgs-portal-iconfont cgs-portal-icon-pailiefangshi font-color-3"
                        @click="switchLeftCollapse"></span>
                </template>
                <template #middle>
                </template>
            </main-header>
        </template>
        <template #left>
            <left-nav :tenantList="showTenantList" :menuCollapse="state.menuCollapse"
                v-model:active-key="state.activeRouterName" @click-menu="onClickMenu"></left-nav>
        </template>
        <template #right>
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
                </keep-alive>
                <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
            </router-view>
        </template>
    </main-layout>
</template>

<script>
import {
    computed,
    defineComponent,
    reactive,
    onActivated,
    onMounted,
    getCurrentInstance
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import mainLayout from '@components/main-layout.vue'
import mainHeader from '@components/main-header.vue'
import leftNav from './left-nav.vue'
import {
    useRouter,
    useRoute,
    onBeforeRouteUpdate
} from 'vue-router'


export default defineComponent({
    components: {
        mainLayout,
        mainHeader,
        leftNav
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        let router = useRouter()
        let route = useRoute()
        let tenantList = [{
            key: 'systemHome',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-shouye'
        }, {
            key: 'tenant',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zuhuguanli'
        }, {
            key: 'apk',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-APKguanli'
        }, {
            key: 'license',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-miyueguanli'
        }, {
            key: 'dept',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-bumenguanli'
        }, {
            key: 'job',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zhiwei'
        }, {
            key: 'role',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-jiaoseguanli'
        }, {
            key: 'user',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-yonghuguanli'
        }, {
            key: 'theme',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zhutiguanli'
        }, {
            key: 'finance',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zhangwuguanli'
        }]
        const state = reactive({
            activeRouterName: '',
            menuCollapse: false
        })
        const onClickMenu = (key) => {
            router.push({
                name: key
            })
        }
        onActivated(() => {
            _toSubRouter(route)
        })
        onBeforeRouteUpdate(to => {
            _toSubRouter(to)
        })
        const showTenantList = computed(() => {
            let _menuList = tenantList.map(_item => {
                _item.label = t('systemInfo.' + _item.key)
                return _item
            })
            if (!window.authManager.systemTenantAuth()) {
                _menuList = _menuList.filter(_item => _item.key !== 'tenant')
            }
            if (!window.authManager.systemAPKAuth()) {
                _menuList = _menuList.filter(_item => _item.key !== 'apk')
            }
            if (!window.authManager.systemLicenseAuth()) {
                _menuList = _menuList.filter(_item => _item.key !== 'license')
            }

            return _menuList
        })
        const switchLeftCollapse = () => {
            state.menuCollapse = !state.menuCollapse
        }
        const _toSubRouter = (currentRoute) => {
            if (currentRoute.name === 'system') {
                if (state.sceneTypeList.length > 0) {
                    router.push({
                        name: state.sceneTypeList[0].typeId
                    })
                    state.activeRouterName = state.sceneTypeList[0].typeId
                }
            } else {
                let _matchedRouterList = currentRoute.matched
                let _menuRouter = _matchedRouterList.find(_router => _router.name === 'system')
                if (_menuRouter) {
                    state.activeRouterName = currentRoute.name
                }
            }
        }
        onMounted(() => {
            proxy.$ws.on('current_license', (data) => {
                if (data.Timeout) {
                    proxy.$message.warning(t('systemInfo.licenseOverdueMessage'))
                }
            })
            _toSubRouter(route)
        })
        return {
            state,
            showTenantList,
            onClickMenu,
            switchLeftCollapse
        }
    }
})
</script>

<style lang="less" scoped>
.portal-system-container {
    width: 100%;
    height: 100%;
}

.portal-system-change-icon {
    cursor: pointer;
    font-size: 14px;
}
</style>