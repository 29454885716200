<template>
  <div class="portal-public-card-view">
    <div class="portal-public-card-view-wrapper">
      <main-card v-for="item in sceneList" :key="item.id" :ident="item.id" :title="item.title"
        :editable="hasEditAuth(item)" :publish="item.status === 0 ? false : true"
        :more-menu-list="currentMoreMenuList(item)" :cover="item.backgroundUrl" :appType="item.typeName"
        :isCollect="item.isCollect" @clikMenuItem="clikMenuItemHandler(item, $event)" @clickEdit="onClickEdit(item)"
        @clickCollect="clickCollect(item)"></main-card>
    </div>
  </div>
</template>

<script>
import {
  defineComponent
} from 'vue'
import mainCard from './main-card.vue'
import {
  useI18n
} from 'vue-i18n'

export default defineComponent({
  components: {
    mainCard
  },
  props: {
    sceneList: {
      type: Array,
      default: () => []
    },
    moreMenuList: {
      type: Array,
      default: () => []
    }
  },

  setup (props, ctx) {
    const clikMenuItemHandler = (scene, params) => {
      ctx.emit('clikMenuItem', scene, params)
    }
    const {
      t
    } = useI18n()
    const onClickEdit = (scene) => {
      ctx.emit('enter', scene)
    }
    const clickCollect = (scene) => {

      ctx.emit('clikCollect', scene)
    }
    const hasEditAuth = (scene) => {
      return window.authManager.editSceneAuth(scene.id)
    }
    const towDMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'preview',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
      disabled: (sceneId) => {
        return !window.authManager.previewSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: (sceneId) => {
        return !window.authManager.exportSceneAuth(sceneId)
      }
    },

    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    const appMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: (sceneId) => {
        return !window.authManager.exportSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    const IotMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    let pageMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'preview',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
      disabled: true
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: true
    },
    {
      title: '',
      key: 'path',
      icon: 'cgs-portal-iconfont cgs-portal-icon-lianjie',
      disabled: false
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    let vtkMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'preview',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
      disabled: (sceneId) => {
        return !window.authManager.previewSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: true
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    const currentMoreMenuList = (scene) => {
      let _moreMenuList = towDMoreMenuList
      if (scene.appType === 'TwoD' || scene.appType === 'ThreeD' || scene.appType === 'Earth' || scene.appType === 'CAD' || scene.appType === "SCADA" || scene.appType === "NewEarth") {
        _moreMenuList = towDMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'App' || scene.appType === 'Workflow' || scene.appType === 'GeoModel' || scene.appType === 'Volume') {
        _moreMenuList = appMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'Iot') {
        _moreMenuList = IotMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'Static') {
        _moreMenuList = pageMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'Vtk' || scene.appType === 'LargeScreen' || scene.appType === 'AI') {
        _moreMenuList = vtkMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else {
        _moreMenuList = IotMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      }

      let list = _moreMenuList.filter(_item => {
        let _bool = true
        let _disabled = _item.disabled
        if (_disabled instanceof Function) {
          _bool = !_disabled(scene.id)
        } else {
          _bool = !_disabled
        }
        return _bool
      })
      list.forEach(_item => {
        if (_item.key === "collect" && scene.isCollect) {
          _item.title = "取消收藏"
        }
      })
      return list
    }
    return {
      clikMenuItemHandler,
      onClickEdit,
      hasEditAuth,
      currentMoreMenuList,
      clickCollect
    }
  },
})
</script>

<style lang='less' scoped>
.portal-public-card-view {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.portal-public-card-view-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
</style>