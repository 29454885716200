<!--
*@description 
*@author 杜超峰
*@date 2023/04/23 11:29:38
!-->

<template>
    <div class="portal-home-left-nav" :class="menuCollapse ? '' : 'portal-home-left-nav-not-collapse'">
        <div class="portal-home-left-nav-wrapper">
            <div class="portal-home-left-nav-header">
                <div class="portal-home-left-nav-header-left">
                    <!-- <span class="text-1 font-color-1" v-if="!menuCollapse">{{ showMode === 'sceneTypeClassify' ? $t('scene') :
                        $t('organization') }}</span> -->
                    <span class="text-1 font-color-1" v-if="!menuCollapse">{{ showMode === 'sceneTypeClassify'
                        ? $t('scene') : $t('home') }}</span>
                    <span
                        class="portal-home-left-nav-switch-icon cgs-portal-iconfont cgs-portal-icon-qiehuan font-color-3"
                        @click="onSwitchHandler"></span>
                </div>
            </div>
            <div class="portal-home-left-nav-content">

                <div class="portal-home-left-nav-content-wrapper">
                    <cgs-menu style="width: 100%" v-model:openKeys="openKeys" mode="inline">
                        <cgs-sub-menu v-for="(item, index) in middleMenuList " :menuKey="item.key" :key="item.key"
                            @click.stop="changeWorkbench(item)">
                            <template #title>
                                <div class="portal-home-left-nav-content-wrapper-work" v-if="!menuCollapse">
                                    <div>
                                        <span>{{ $t(item.label) }}</span>
                                    </div>
                                    <div v-if="item.key == '2' && !menuCollapse">
                                        <!-- <span @click.stop="addWorkSpace"
                                            class="portal-home-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-tianjia">13123</span> -->
                                        <cgs-more :menu-list="settingMenuList" @clikMenuItem="clickMenuItemSetting">
                                            <template #triggerButton>
                                                <span
                                                    class="portal-home-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-tianjia"></span>
                                            </template>
                                        </cgs-more>
                                    </div>
                                </div>
                            </template>

                            <cgs-menu-item :key="item.key + '-' + index" :menuKey="item.key + '-' + index">
                                <draggable :list="item.children" ghost-class="ghost" chosen-class="chosenClass"
                                    animation="300" item-key="key" @update="updateCollect($event, item)">
                                    <template #item="{ element }">
                                        <div class="portal-home-left-nav-content-item-work"
                                            :class="[element.key === selectWork ? 'selectWork' : '', menuCollapse ? 'portal-home-left-nav-content-item-work-padding' : '']"
                                            @click.stop="changeWork(element)" @mouseenter="show(item, element)"
                                            @mouseleave="hide">
                                            <div class="portal-home-left-nav-content-item-work-item">
                                                <div class=" portal-home-left-nav-content-item-work-item-icon"
                                                    v-if="element.type !== 'workSpace'"
                                                    :style="{ background: themeColor(element.theme) }">
                                                    <span style="color: white;"
                                                        :class="[iconFontFamily, showIcon(element)]"></span>
                                                </div>
                                                <div class="portal-home-left-nav-content-item-work-item-icon-space"
                                                    v-if="element.type == 'workSpace'">
                                                    <span
                                                        class="portal-public-scene-icon-span cgs-portal-iconfont cgs-portal-icon-gongzuokongjian1"></span>
                                                </div>
                                                <span class="portal-home-left-nav-content-item-work-item-name"
                                                    v-if="!menuCollapse">{{
                                                        element.label }}</span>
                                            </div>
                                            <div>
                                                <span
                                                    v-if="element.type !== 'workSpace' && (elementKey === element.key) && item.key === itemKey && !menuCollapse"
                                                    class="portal-home-left-nav-content-item-work-item-type">{{
                                                        element.type }}</span>
                                                <span
                                                    v-if="element.type == 'workSpace' && (elementKey === element.key) && item.key != '2' && item.key === itemKey && !menuCollapse"
                                                    class="portal-home-left-nav-content-item-work-item-type">工作空间</span>
                                                <span style="color: #F2B026;"
                                                    v-if="element.isCollect && (elementKey === element.key) && item.key === itemKey && item.key == '1' && !menuCollapse"
                                                    @click.stop="collectWork(element, false)"
                                                    class="portal-home-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-a-shoucang-yishoucang"></span>
                                                <span style="color: #F2B026;"
                                                    v-if="element.isCollect && item.key == '2' && !menuCollapse"
                                                    @click.stop=" collectWork(element, false)"
                                                    class="portal-home-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-a-shoucang-yishoucang"></span>
                                                <span
                                                    v-if="!element.isCollect && (elementKey === element.key) && !menuCollapse"
                                                    @click.stop="collectWork(element, true)"
                                                    class="portal-home-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-shoucang"></span>

                                            </div>
                                        </div>
                                    </template>
                                </draggable>


                            </cgs-menu-item>
                        </cgs-sub-menu>
                    </cgs-menu>
                </div>
            </div>
            <div class="portal-home-left-nav-footer">
                <div class="portal-home-left-nav-footer-wrapper">
                    <div class="portal-home-left-nav-footer-item selectable-item"
                        :class="item.key === currentActiveKey ? 'selected-item' : ''" v-for=" item in showFootMenuList "
                        :key="item.key" @click="clickFooterMenuItemHandler(item)">
                        <span class="portal-home-left-nav-footer-item-icon" :class="item.icon"></span>
                        <span class="portal-home-left-nav-footer-item-title" v-if="!menuCollapse">{{ item.label
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
        <AddSpaceModal :visible="addVis" @close="addVis = false" @ok="initList" />
        <ImportModal :spaceList="spaceList" :visible="importModal" @close="importModal = false" @ok="importCallback">
        </ImportModal>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    reactive, ref,
    onMounted,
    getCurrentInstance,
    watch,
} from 'vue'
import { useRouter } from 'vue-router'
import useHeader from "../../store/modules/header"

import {
    profix,
    default_class_name,
    font_family,
    defaultIcon_app,
    getThemeObjByClassName
} from '@js/tableIconAndTheme'
import {
    useI18n
} from 'vue-i18n'
import draggable from "vuedraggable";
import {
    getSceneDetail
} from '@api/scene'
import {
    getUserInfo
} from '@/api/user'

import { setOriginPath, setUserAuthority } from "@/utils/author"

import { addFavorite, updateCollectSort, addRecentUsed } from "@/api/workSpace"
import AddSpaceModal from "./workSpace/component/addSpaceModal.vue"
import ImportModal from "./workSpace/component/importModal.vue"

export default defineComponent({
    props: {
        title: {
            type: String,
            default: ''
        },
        showAddButton: {
            type: Boolean,
            default: false
        },
        showMiddleMenuMore: {
            type: Boolean,
            default: true
        },
        moreMenuList: {
            type: Array,
            default: () => []
        },
        middleMenuList: {
            type: Array,
            default: () => []
        },
        activeKey: {
            type: String,
            default: ''
        },
        showMode: {
            type: String,
            default: ''
        }
    },
    components: {
        draggable,
        AddSpaceModal,
        ImportModal
    },

    setup(props, ctx) {
        const {
            t
        } = useI18n()
        const { proxy } = getCurrentInstance()
        const headerStore = useHeader()
        const state = reactive({
            // middleMenuList: [{
            //     key: '2d',
            //     label: '2D',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-a-2D'
            // }, {
            //     key: '3d',
            //     label: '3D',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-a-3D'
            // }, {
            //     key: 'table',
            //     label: 'Table',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-APP'
            // }]
        })
        const router = useRouter()
        const addVis = ref(false)
        const importModal = ref(false)
        let iconFontFamily = font_family
        const elementKey = ref("")
        const itemKey = ref("")

        const openKeys = ref(["1", "2"])
        // const selectWork = ref('')

        const settingMenuList = [{
            title: '',
            key: 'addWorkSpace',
            icon: 'cgs-portal-iconfont cgs-portal-icon-tianjia',
        },
        {
            title: '',
            key: 'import',
            icon: 'cgs-portal-iconfont cgs-portal-icon-daoru',
        },].map(_item => {
            _item['title'] = t('sceneMoreMenu.' + _item.key)
            return _item
        })
        const footMenuList = [{
            key: 'category',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-fenleiguanli'
        }, {
            key: 'store',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-yingyongshichang'
        }, {
            key: 'theme',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zhuti'
        }, {
            key: 'universe',
            label: '',
            icon: 'cgs-portal-iconfont cgs-portal-icon-Earth'
        }]

        const spaceList = computed(() => {
            let list = []
            if (props.middleMenuList && props.middleMenuList[1] && props.middleMenuList[1].children && props.middleMenuList[1].children.length > 0) {
                list = props.middleMenuList[1].children
            }
            return list
        })

        const currentActiveKey = computed({
            get: () => props.activeKey,
            set: (newVal) => {
                ctx.emit('update:activeKey', newVal)
            }
        })
        let themeColor = (theme) => {
            let _color = ''
            let _themeName = theme
            if (theme === '') _themeName = default_class_name
            let _themeObj = getThemeObjByClassName(_themeName)
            _color = _themeObj.color
            return _color
        }
        const selectWork = computed({
            get: () => {
                let select = ""
                if (router.currentRoute.value.name == "workSpace") {
                    props.middleMenuList.forEach(item => {
                        item.children.forEach(_item => {
                            if (_item.id === router.currentRoute.value.params.id) {
                                select = _item.key
                            }
                        })
                    })
                }
                return select
            },
            set: (newVal) => newVal
        })
        const showFootMenuList = computed(() => {
            return footMenuList.map(_item => {
                _item['label'] = t('footMenu.' + _item.key)
                return _item
            })
        })

        const menuCollapse = computed(() => headerStore.menuCollapse)
        watch(() => props.middleMenuList, () => {

        })
        onMounted(() => {

        })
        const show = (item, subItem) => {
            itemKey.value = item.key
            elementKey.value = subItem.key
        }
        const hide = () => {
            itemKey.value = ""
            elementKey.value = ""
        }
        const showIcon = (item) => {
            let _icon = item.icon
            if (item.icon === '') _icon = defaultIcon_app
            return profix + _icon
        }
        const clickMiddleMenuItemHandler = (menuItem) => {
            ctx.emit('clickMiddleMenu', menuItem)
        }
        const clickFooterMenuItemHandler = (menuItem) => {
            ctx.emit('clickFooterMenu', menuItem)
        }

        const updateCollect = async (e, item) => {
            if (item.key === "1") {
                // 我的收藏
                let ids = item.children.map(_item => _item.id)
                await updateCollectSort({ collectIds: ids })
            } else {
                let ids = item.children.map(_item => _item.id)
                await updateCollectSort({ workspaceIds: ids })
                console.log(e, item);
            }
            ctx.emit('sortspace')

        }

        const onSwitchHandler = () => {
            router.push({
                path: "/menu"
            })
        }

        const changeWork = async (item, type = 'edit') => {
            await addRecentUsed({
                id: item.id
            })
            if (item.appType) {
                let url = ""
                if (item.appType === "TwoD") {
                    url = `/2d/viewer.html?ProjId=${item.projectID}&mode=${type}`
                    window.open(url, '_blank');
                } else if (item.appType === "App") {
                    setOriginPath(router.currentRoute.value.path)
                    url = '/table/' + item.id
                    window.open(url, '_self');
                } else if (item.appType === "ThreeD") {
                    url = `/3d/viewer.html?ProjId=${item.projectID}&mode=${type}`
                    window.open(url, '_blank');
                } else if (item.appType === "Earth") {
                    url = `/earth/viewer.html?ProjId=${item.projectID}&mode=${type}`

                    window.open(url, '_blank');
                } else if (item.appType === "Workflow") {
                    setOriginPath(router.currentRoute.value.path)
                    url = '/algo-workflow/' + item.id
                    window.open(url, '_self');
                } else if (item.appType === "Static") {
                    let _result = await getSceneDetail({
                        ID: item.id,
                        VisualType: "Static"
                    })
                    if (_result.code === 200) {
                        let config = _result.data.config;
                        url = `/file/${config.itemUrl}`
                        window.open(url, '_blank');
                    }
                } else if (item.appType === "IoT") {
                    let url = '/iot/' + item.id
                    window.open(url, '_blank');
                } else if (item.appType === "GeoModel") {
                    setOriginPath(router.currentRoute.value.path)
                    url = `/geo-model/main/${item.projectID}`;
                    window.open(url, '_self');
                } else if (item.appType === "CAD") {

                    url = `/cgscad/viewer.html?ProjId=${item.projectID}&mode=${type}`;
                    window.open(url, '_blank');
                } else if (item.appType === "Vtk") {

                    url = `/volume/viewer.html?ProjId=${item.projectID}&mode=${type}`;
                    window.open(url, '_blank');
                } else if (item.appType === "DAS") {
                    url = '/das/' + item.id
                    window.open(url, '_blank');
                } else if (item.appType === "FileStorage") {
                    setOriginPath(router.currentRoute.value.path)
                    url = '/file-storage/home/' + item.id
                    window.open(url, '_self');
                } else if (item.appType === "NewEarth") {
                    setOriginPath(router.currentRoute.value.path)
                    url = `/earthplus/viewer/${item.id}/${type}`
                    window.open(url, '_blank');
                } else if (item.appType === "SCADA") {
                    if (type == "edit") {
                        let url = `/scada/?id=${item.sceneID}`

                        window.open(url, '_blank');

                    } else {
                        let url = `/scada/preview?id=${item.sceneID}&r=${new Date().getTime()}`
                        window.open(url, '_blank');

                    }
                } else if (item.appType === "CodeEngine") {
                    let url = `/code_engine/proc/${item.id}/`
                    window.open(url, '_blank');
                } else if (item.appType === "CadDesign") {
                    let url = `/cgsCadDesign/viewer.html?ProjId=${item.projectID}&mode=preview`;
                    window.open(url, '_blank');
                } else if (item.appType === "Report") {
                    let url = '/cgsReport/' + item.id
                    window.open(url, '_self');
                } else if (item.appType === "LargeScreen") {
                    setOriginPath(router.currentRoute.value.path)
                    url = `/cgsLargeScreen/viewer/${item.id}/${type}`
                    window.open(url, '_blank');
                } else if (item.appType === "AI") {
                    url = `/cgsAI/${item.id}`
                    window.open(url, '_blank');
                }

            } else {
                router.push({
                    path: `/home/workSpace/${item.id}`
                })
            }
        }
        const changeWorkbench = (item) => {
            if (item.key == 1) {
                router.push({
                    path: `/home/workbench`
                })
            }
        }

        const addWorkSpace = () => {
            addVis.value = true
        }

        const clickMenuItemSetting = (val) => {
            if (val.key === "addWorkSpace") {
                addVis.value = true
            } else if (val.key === "import") {
                importModal.value = true
            }
        }
        const updateUserAuthority = () => {
            return new Promise((resolve, reject) => {
                getUserInfo().then(infoRes => {
                    if (infoRes.data && infoRes.success) {
                        setUserAuthority(infoRes.data);
                        window.authManager.init();
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        }
        const importCallback = () => {
            initList()
            updateUserAuthority()
        }
        const collectWork = async (item, bool) => {
            let collect = await addFavorite({
                id: item.id,
                isCollect: bool
            })
            if (bool) {
                collect.code === 200 ? proxy.$message.success('收藏成功') : proxy.$message.error('收藏失败')
            } else {
                collect.code === 200 ? proxy.$message.success('取消收藏成功') : proxy.$message.error('取消收藏失败')
            }

            ctx.emit('collectWork')
        }
        const initList = () => {
            ctx.emit('collectWork')
        }

        return {
            showFootMenuList,
            state,
            addVis,
            clickMiddleMenuItemHandler,
            clickFooterMenuItemHandler,
            currentActiveKey,
            onSwitchHandler,
            selectWork,
            openKeys,
            changeWork,
            changeWorkbench,
            addWorkSpace,
            collectWork,
            iconFontFamily,
            showIcon, initList,
            elementKey, itemKey, show, hide, themeColor,
            updateCollect,
            menuCollapse,
            settingMenuList,
            clickMenuItemSetting,
            spaceList,
            importModal,
            importCallback
        }
    },
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-home-left-nav {
    height: 100%;
}

.portal-home-left-nav-not-collapse {
    width: 300px;
}

.portal-home-left-nav-wrapper {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portal-home-left-nav-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    .border(0, 0, 1px, 1)
}

.portal-home-left-nav-header-left {
    display: flex;
    align-items: center;
}

.portal-home-left-nav-switch-icon {
    font-size: 12px;
    margin-left: 8px;
    cursor: pointer;
}

.portal-home-left-nav-content {
    flex: 1;
    height: 0;
    overflow-y: auto;
}

.portal-home-left-nav-footer {
    .border(1px, 0, 0, 0);
    .text-1();
    .font-color-1();
}

.portal-home-left-nav-footer-wrapper {
    margin: 20px 0px;
}

.portal-home-left-nav-content-item,
.portal-home-left-nav-footer-item {
    padding: 8px;
    border-radius: 5px;
    margin: 2px 0px;
}

.portal-home-left-nav-content-item-title,
.portal-home-left-nav-footer-item-title {
    margin-left: 8px;
}

.portal-home-left-nav-content {
    .text-1();
    .font-color-1();
}

.portal-home-left-nav-content-wrapper {
    margin-top: 8px;
    overflow: hidden;
}

.portal-home-left-nav-content-item-more {
    display: none;
    float: right;
    // transition: all 0.3s;
}

.portal-home-left-nav-content-wrapper-work {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.portal-home-left-nav-content-item-work {
    height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @font-color-1;
    padding: 10px;
    cursor: pointer;
}


.portal-home-left-nav-content-item-work-padding {
    padding: 0 0 0 5px !important;
}

.portal-home-left-nav-content-item-work:hover {
    background: @selected-color;
}

.portal-home-left-nav-content-item-work-item {
    display: flex;
    align-items: center;


}

.portal-home-left-nav-content-item-work-item-icon {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.portal-home-left-nav-content-item-work-item-icon-space {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(146deg, #23C5D0 13%, #59A4EA 105%);

    span {
        font-size: 12px;
    }
}

.portal-home-left-nav-content-item-icon {
    font-size: 14px;
    margin-left: 5px;
}

.selectWork {
    background: @selected-color;
}

::v-deep(.ant-menu) {
    background: @card-color !important;
}

::v-deep(.ant-menu-sub.ant-menu-inline) {
    background: @foreground-color-dark-background;
}

.portal-home-left-nav-content-item:hover .portal-home-left-nav-content-item-more {
    display: unset;
}

::v-deep(.cgs-design-sub-menu.ant-menu-submenu-open) {
    background: @selected-color;
    border-radius: 5px;
    color: @font-color-1;
}

::v-deep(.ant-menu-light .ant-menu-submenu-title:hover) {
    background: @selected-color;
    border-radius: 5px;
    color: @font-color-1;
}

::v-deep(.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow) {
    color: @font-color-1;
}

::v-deep(.ant-menu-inline) {
    border-right: none;
}

::v-deep(.ant-menu-item-group-title) {
    padding: 0 !important;
}

::v-deep(.ant-menu-submenu-title) {
    padding-left: 5px !important;
    color: @font-color-1;
}

::v-deep(.ant-menu-sub.ant-menu-inline>.ant-menu-item) {
    height: 100% !important;
    padding: 0 !important;
}

.portal-home-left-nav-content-item-work-item-type {
    margin-right: 5px;
    color: @font-color-3;
    font-size: 12px;
}

.portal-home-left-nav-content-item-work-item-name {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    margin-left: 5px;
}

.portal-public-scene-icon-span {
    font-size: 26px;
    color: #fff;

}
</style>