<template>
    <div class="portal-system-finance-table">
        <div class="portal-system-finance-table-wrapper">
            <cgs-table :columns="columns" :data-source="sceneList" backgroundTransparent mode="portal"
                :pagination="false">
            </cgs-table>
        </div>
    </div>
</template>


<script>
    import {
        defineComponent,
    } from 'vue'
    const columns = [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '25%',
        customRender: ({
            index
        }) => `${index + 1}`,
    }, {
        title: '时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
        width: '25%',
    }, {
        title: '类型',
        dataIndex: 'in_type',
        key: 'in_type',
        width: '25%',
    }, {
        title: '金额',
        dataIndex: 'changeValue',
        key: 'changeValue',
        width: '25%',
    }];
    export default defineComponent({
        components: {},
        props: {
            sceneList: {
                type: Array,
                default: () => []
            },
        },

        setup() {
            return {
                columns
            }
        },
    })
</script>

<style scoped lang="less">
    .portal-system-finance-table {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .portal-system-finance-table-wrapper {
        width: 100%;
        height: 100%;
    }
</style>