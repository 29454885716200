<template>
  <div>
    <cgs-table :columns="showColumns" :data-source="foldList" :pagination="false" mode="portal" size="small"
      :backgroundTransparent="true" :defaultExpandAllRows="true">
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.key === 'index'">
          <div style="display: none;">
            {{ text }}
          </div>
        </template>
        <template v-if="column.key === 'title'">
          <div class="portal-public-table-view-title" @click="onEnterHandler(record)">
            <div v-if="record.status == 'App'">
              {{ record.title }}
            </div>
            <div v-if="record.status !== 'App'" class="portal-public-table-view-title-icon">
              <scene-icon :icon="record.icon" :themeName="record.theme" iconSize="12px"></scene-icon>
            </div>
            <div v-if="record.status !== 'App'" class="portal-public-table-view-title-value">
              <span>{{ text }}</span>
              <span class="app-card-text-type-tab">{{ record.typeName }}</span>
            </div>
          </div>
        </template>
        <template v-else-if="column.key === 'status'">
          <span class="portal-public-table-view-status-nopublish" v-if="text === 0">{{
      $t('sceneShowType.published')
    }}</span>
          <span v-else-if="record.status == 'App'">应用类型</span>
          <span class="portal-public-table-view-status-published" v-else>{{
      $t('sceneShowType.unpublish')
    }}</span>

        </template>
        <template v-else-if="column.key === 'action'">
          <div class="portal-public-table-view-action">
            <span style="color: #F2B026;" v-if="record.isCollect && record.status != 'App'" @click="collectWork(record)"
              class="collect  cgs-portal-iconfont cgs-portal-icon-a-shoucang-yishoucang"></span>
            <span v-if="!record.isCollect && record.status != 'App'" @click="collectWork(record)"
              class="collect portal-menu-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-shoucang"></span>
            <cgs-more :menu-list="currentMoreMenuList(record)" @clikMenuItem="clikMenuItemHandler(record, $event)"
              v-if="currentMoreMenuList.length > 0 && record.status != 'App'">
            </cgs-more>
          </div>

        </template>
      </template>
    </cgs-table>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import {
  useI18n
} from 'vue-i18n'
import sceneIcon from '@components/scene-icon.vue'
const {
  t
} = useI18n()
const columns = [{
  title: '',
  dataIndex: 'index',
  key: 'index',
  fixed: 'left',
  width: 50,
  customRender: ({
    index
  }) => `${index + 1}`,
}, {
  title: '',
  dataIndex: 'title',
  key: 'title',
  width: 500,
}, {
  title: '',
  dataIndex: 'username',
  key: 'username',
  width: 150,
}, {
  title: '',
  dataIndex: 'status',
  key: 'status',
  width: 150,
}, {
  title: '',
  dataIndex: 'action',
  key: 'action',
  fixed: 'right'
}];
const showColumns = computed(() => {
  return columns.map(_item => {
    _item['title'] = t('sceneShowType.table.' + _item.key)
    return _item
  })
})

const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect"])
const props = defineProps({
  sceneList: {
    type: Array,
    default: () => []
  }
})
// const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect"])
const foldList = computed(() => {
  let list = props.sceneList.reduce((prev, cur, i) => {
    if (!prev.some(item => item.title === cur.typeName)) {
      prev.push({ title: cur.typeName, status: "App", key: i, children: [] });
    }
    return prev;
  }, []);

  list.forEach(item => {
    props.sceneList.forEach(scene => {
      if (scene.typeName === item.title) {
        item.children.push(scene);
      }
    })
  })
  return list
})
const clikMenuItemHandler = (scene, params) => {
  emit('clikMenuItem', scene, params)
}
// const rowClick = (record) => {
//  return {
//   onClick: () => {
//    hasEditAuth(record) && emit('enter', record)
//   }
//  }
// }
const onEnterHandler = (record) => {
  if (record.status === "App") return
  emit('enter', record)
}
const collectWork = (record) => {
  emit('clikCollect', record)
}
// const hasEditAuth = (scene) => {
//  return window.authManager.editSceneAuth(scene.id)
// }

const towDMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'preview',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
  disabled: (sceneId) => {
    return !window.authManager.previewSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: (sceneId) => {
    return !window.authManager.exportSceneAuth(sceneId)
  }
},

{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
const appMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: (sceneId) => {
    return !window.authManager.exportSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
const IotMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
let pageMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'preview',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
  disabled: true
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: true
},
{
  title: '',
  key: 'path',
  icon: 'cgs-portal-iconfont cgs-portal-icon-lianjie',
  disabled: false
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
let vtkMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'preview',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
  disabled: (sceneId) => {
    return !window.authManager.previewSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: true
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
const currentMoreMenuList = (scene) => {
  let _moreMenuList = towDMoreMenuList
  if (scene.appType === 'TwoD' || scene.appType === 'ThreeD' || scene.appType === 'Earth' || scene.appType === 'CAD' || scene.appType === "SCADA" || scene.appType === "NewEarth") {
    _moreMenuList = towDMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'App' || scene.appType === 'Workflow' || scene.appType === 'GeoModel' || scene.appType === 'Volume') {
    _moreMenuList = appMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'Iot') {
    _moreMenuList = IotMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'Static') {
    _moreMenuList = pageMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'Vtk') {
    _moreMenuList = vtkMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else {
    _moreMenuList = IotMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  }


  let list = _moreMenuList.filter(_item => {
    let _bool = true
    let _disabled = _item.disabled
    if (_disabled instanceof Function) {
      _bool = !_disabled(scene.id)
    } else {
      _bool = !_disabled
    }
    return _bool
  })

  return list
}

</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-public-table-view {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.portal-public-table-view-wrapper {
  width: 100%;
  height: 100%;
}

.portal-public-table-view-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.portal-public-table-view-title-icon {
  width: 25px;
  height: 25px;
  margin-right: 8px;

}

.portal-public-table-view-status-nopublish {
  color: @font-color-2;
}

.portal-public-table-view-status-published {
  color: @font-color-1;
}

.portal-public-table-view-action {
  width: 100%;
  height: 100%;
  text-align: right;
}

.portal-public-table-view-title-value {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-card-text-type-tab {
  font-size: 12px;
  color: @font-color-3;
  background: @selected-color;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 3px;
  .border(1px, 1px, 1px, 1px);
}

.collect {
  margin-right: 5px;
}
</style>