<template>
    <div class="portal-system-user-table">
        <div class="portal-system-user-table-wrapper">
            <cgs-table :columns="columns" :data-source="tableList" mode="portal" size="small" :pagination="false"
                :backgroundTransparent="true" position="bottomCenter">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-user-table-action">
                            <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler(record, $event)">
                            </cgs-more>
                        </div>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent,
    } from 'vue'
    const columns = [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        customRender: ({
            index
        }) => `${index + 1}`,
    }, {
        title: '模块名',
        dataIndex: 'categoryKey',
        key: 'categoryKey',
        width: 500,
    }, {
        title: '模块值',
        dataIndex: 'categoryValue',
        key: 'categoryValue',
        width: 150,
    }, {
        title: '',
        dataIndex: 'action',
        key: 'action',
    }];
    export default defineComponent({
        components: {},
        props: {
            tableList: {
                type: Array,
                default: () => []
            },
            moreMenuList: {
                type: Array,
                default: () => []
            },
        },

        setup(porps, ctx) {
            const clikMenuItemHandler = (scene, params) => {
                ctx.emit('clikMenuItem', scene, params)
            }
            return {
                columns,
                clikMenuItemHandler,
            }
        },
    })
</script>

<style scoped>
    .portal-system-user-table-action {
        width: 100%;
        height: 100%;
        text-align: right;
    }
</style>