<script setup>
import globalSearch from '@components/global-search/index.vue'
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from 'vue-router';
import {
    getSceneDetail
} from '@api/scene'
import { addRecentUsed } from "@api/workSpace"
import { getSearchProject } from "@/api/category"
import { setOriginPath } from "@/utils/author"
const searchResultList = ref([])
const searchInput = ref('')
const router = useRouter()
const handleGlobalSearch = async (value) => {
    // 防止搜索内容更新过快最后赋值结果出错
    searchInput.value = value
    let result = await getSearchProject({
        title: value
    })
    if (result.code === 200) {
        for (let index = 0; index < result.data.length; index++) {
            const item = result.data[index];

            //todo DataServer分类不进行搜索
            if (item.Type === 'DataServer') continue

            let arr = []
            for (let i = 0; i < item.Data.length; i++) {
                const element = item.Data[i];
                for (let j = 0; j < element.Data.length; j++) {
                    const pro = element.Data[j];
                    arr.push({
                        id: pro.id,
                        projectID: pro.projectID,
                        title: pro.title,
                        desc: element.Name,
                        type: item.Type,
                    })
                }
            }
            item.list = arr
        }
        if (searchInput.value === value) {
            searchResultList.value = result.data
        }
    }
}
const handleProjectSelect = async (project) => {
    console.log(project);
    await addRecentUsed({
        id: project.id
    })
    if (project.type === 'TwoD') {
        let url = `/2d/viewer.html?ProjId=${project.projectID}&mode=edit`
        window.open(url, '_blank');
    } else if (project.type === 'ThreeD') {
        let url = `/3d/viewer.html?ProjId=${project.projectID}&mode=edit`
        window.open(url, '_blank');
    } else if (project.type === 'Earth') {
        let url = `/earth/viewer.html?ProjId=${project.projectID}&mode=edit`
        window.open(url, '_blank');
    } else if (project.type === 'Workflow') {
        window.open('/algo-workflow/' + project.id, '_blank');
    } else if (project.type === 'Static') {
        let _result = await getSceneDetail({
            ID: project.id,
            VisualType: 'Static'
        })
        if (_result.code === 200) {
            let config = _result.data.config;
            let url = `/file/${config.sceneUrl}`
            window.open(url, '_blank');
        }
    } else if (project.type === 'App') {
        setOriginPath(router.currentRoute.value.path)
        let url = '/table/' + project.id
        window.open(url, '_self');
    } else if (project.type === 'IoT') {
        let url = '/iot/' + project.id
        window.open(url, '_blank');
    } else if (project.type === 'GeoModel') {
        let url = `/geo-model/main/${project.projectID}`;
        window.open(url, '_blank');
    } else if (project.type === 'Vtk') {
        let url = `/volume/viewer.html?ProjId=${project.projectID}&mode=edit`;
        window.open(url, '_blank');
    } else if (project.type === 'CAD') {
        let url = `/cgscad/viewer.html?ProjId=${project.projectID}&mode=edit`;
        window.open(url, '_blank');
    } else if (project.type === 'DataServer') {
        // let url = `/cgscad/viewer.html?ProjId=${project.projectID}&mode=edit`;
        // window.open(url, '_blank');
    } else if (project.type === 'DAS') {
        let url = '/das/' + project.id
        window.open(url, '_blank');
    } else if (project.type === 'CadDesign') {
        let url = `/cgsCadDesign/?appId=${project.id}`
        window.open(url, '_blank');
    } else if (project.type === 'SCADA') {
        let url = `/scada/?id=${project.id}`

        window.open(url, '_blank');

    } else if (project.type === 'FileStorage') {
        let url = `/file-storage/home/${project.id}`
        window.open(url, '_blank');
    } else if (project.type === 'CodeEngine') {
        let url = `/code_engine/proc/${project.id}`
        window.open(url, '_blank');
    } else if (project.type === 'NewEarth') {
        let url = `/earthplus/viewer/${project.id}/edit`
        window.open(url, '_blank');
    }
}

onMounted(() => { });
onUnmounted(() => { })
</script>

<template>
    <div>
        <global-search @searchChange="handleGlobalSearch" :list="searchResultList" @cardClick="handleProjectSelect">
        </global-search>
    </div>
</template>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-menu-search-nodata {
    padding: 20px;
    text-align: center;
    .font-color-3();
}
</style>