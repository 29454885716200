<template>
    <div class="portal-init">
        <div class="portal-init-container">
            <div class="portal-init-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-init-container-title">
                <span class="portal-init-container-info1">{{ title }}</span>
            </div>
            <div class="portal-init-container-form">
                <cgs-form ref="sceneForm" :model="formState" :hideRequiredMark="true"
                    class="portal-init-container-form-main">
                    <cgs-form-item name="tenantName" :rules="[{ required: true, message: '请输入注册账号!' }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-gerenzhanghu portal-init-container-form-icon"></span>
                            <span>组织</span>
                        </template>
                        <cgs-input class="portal-init-container-form-input" v-model:value="formState.tenantName"
                            @press-enter="handleInit" placeholder="请输入组织名称" />
                    </cgs-form-item>

                    <cgs-form-item name="userName" :rules="[{ required: true, message: '请输入注册账号!' }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-gerenzhanghu portal-init-container-form-icon"></span>
                            <span>账号</span>
                        </template>
                        <cgs-input class="portal-init-container-form-input" v-model:value="formState.userName"
                            @press-enter="handleInit" placeholder="请输入注册账号" />
                    </cgs-form-item>

                    <cgs-form-item name="password" :rules="[{ required: true, message: '请输入密码!' },{pattern:`^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,}$`, message: '请输入字母、数字、特殊字符任意两种， 最少8位!'}]">
                        <template #label>
                            <span class="cgs-portal-iconfont cgs-portal-icon-mima portal-init-container-form-icon"></span>
                            <span>密码</span>
                        </template>
                        <cgs-input class="portal-init-container-form-input" type="password"
                            v-model:value="formState.password" @press-enter="handleInit" placeholder="请输入密码" />
                    </cgs-form-item>
                </cgs-form>
            </div>
            <div class="portal-init-container-button">
                <cgs-button type="primary" :loading="buttonLoading" @click="handleInit">{{ buttonLoadText }}</cgs-button>
            </div>
        </div>
        <a class="portal-init-registration-info" href="http://beian.miit.gov.cn" target="_blank">{{ registration }}</a>
    </div>
</template>
<script>
import {
    defineComponent,
    ref,
    reactive,
    computed,
    onMounted,
    getCurrentInstance
} from 'vue';
import {
    useRouter
} from 'vue-router'
import {
    watchSystemInfo,
    setConfig
} from '@/utils/author.js'
import {
    getConfigApi,
    initializeDataBase
} from "@/api/user";

export default defineComponent({
    name: "init",
    components: {
    },
    setup() {
        let {
            proxy
        } = getCurrentInstance();
        let router = useRouter()
        const formState = reactive({
            tenantName: '',
            userName: '',
            password: '',
        })
        const sceneForm = ref(null)
        const buttonLoading = ref(false)
        const buttonLoadText = ref("初始化")
        const state = reactive({
            sysInfo: {},
        })
        
        const handleInit = () => {
            try{
                sceneForm.value.validateFields()
                buttonLoading.value = true;
                buttonLoadText.value = "初始化中...";

                const param = {
                    tenantName: formState.tenantName,
                    username: formState.userName,
                    password: formState.password
                }

                initializeDataBase(param).then(res=>{
                    if(res && res.success){

                        getConfigApi().then((res) => {
                            if(res && res.success){
                                setConfig(res.data);
                                router.push({path: '/login'});
                            }
                            buttonLoading.value = false;
                            buttonLoadText.value = "初始化";
                        })
                    }else {
                        proxy.$message.error("初始化失败！")
                    }
                }).catch(() => {
                    buttonLoading.value = false;
                    buttonLoadText.value = "初始化";
                });
            }catch(err){
                buttonLoading.value = false;
                buttonLoadText.value = "初始化";
                console.error(err)
            }
        }

        onMounted(() => {
            watchSystemInfo((newVal) => {
                state.sysInfo = newVal
            })
        })
        const title = computed(() => {
            let _title = ''
            if (state.sysInfo && state.sysInfo.name) {
                _title = state.sysInfo.name.toUpperCase()
            }
            return _title
        })
        const registration = computed(() => {
            let _registration = ''
            if (state.sysInfo && state.sysInfo.domainRegistrationInfo) {
                _registration = state.sysInfo.domainRegistrationInfo
            }
            return _registration
        })
        const logo = computed(() => {
            let _logo = ''
            if (state.sysInfo && state.sysInfo.logo) {
                _logo = state.sysInfo.logo
            }
            return _logo
        })
        return {
            formState,
            buttonLoading,
            buttonLoadText,
            state,
            title,
            registration,
            logo,
            sceneForm,

            handleInit
        }
    }
});
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

/* .vue-auth-box_{
    top: 50%;
  } */
.portal-init {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-init-container {
    z-index: 100;
    /* align-items: center; */
    padding: 40px 30px;
    border-radius: 5px;
    background-color: white;
    text-align: center;
    width: 445px;
    // height: 410px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.portal-init-container-logo {
    width: 100%;
    height: 40px;
}

.portal-init-container-logo img {
    width: 40px;
    height: 40px;
}

.portal-init-container-title {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.portal-init-container-info1 {
    .big-title
}

.portal-init-container-form {
    text-align: left;
}

.portal-init-container-button {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 42px;
}

.portal-init-container-button button {
    width: 100%;
    height: 42px;
}


.portal-init-registration-info {
    color: #333;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #FFF;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}

.portal-init-registration-info:hover {
    color: #222;
    text-decoration: underline
}

.portal-init-container-form-icon {
    margin-right: 5px;
}
</style>

<style scoped>
.portal-init-container-form-input.cgs-design-input.ant-input {
    line-height: 32px;
    border-radius: 5px;
}
</style>

<style>
.portal-init-container-form-main.cgs-design-form .ant-form-item-label>label {
    line-height: 32px;
    height: 41px;
    color: #333333;
    width: 78px;
}

.portal-init-container-form-main .ant-form-item label:after {
    content: none;
}</style>