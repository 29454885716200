import {get,post} from "@/axios/http.js"
import qs from 'qs'

export const getList = (params) => get(`/api/Cgs/Portal/Category/ListPage?${qs.stringify(params)}`)

export const delObj = (params) => post(`/api/Cgs/Portal/Category/Delete?${qs.stringify(params)}`)

export const addObj = (data) => post('/api/Cgs/Portal/Category/Add',data)

export const updateObj = (data) => post('/api/Cgs/Portal/Category/Edit',data)


export const getSearchProject = (params) => get(`/api/Cgs/Portal/Visual/search?${qs.stringify(params)}`)