<template>
    <div class="portal-system-theme">
        <div class="portal-system-theme-title">
            <div class="portal-system-theme-title-left">
                {{ $t('systemInfo.theme') }}
            </div>
            <div>
                <cgs-button class="portal-system-theme-title-right-button" @click="styleImportDialog = true">
                    <template #icon>
                        <span class="cgs-portal-iconfont cgs-portal-icon-daoru portal-system-theme-icon"></span>
                    </template>
                    {{ $t('common.import') }}</cgs-button>
                <cgs-button @click="handleExport">
                    <template #icon>
                        <span class="cgs-portal-iconfont cgs-portal-icon-daochu portal-system-theme-icon"></span>
                    </template>
                    {{ $t('common.export') }}</cgs-button>
            </div>
        </div>
        <div class="portal-system-theme-content">
            <div class="portal-system-theme-content-layout">
                <div class="portal-system-theme-content-layout-item">
                    <div class="portal-system-theme-content-layout-item-title">{{ $t('systemInfo.themeInfo.theme') }}
                    </div>
                    <div class="portal-system-theme-designStyleList">
                        <label v-for="item in themeList" :key="item.id"
                            class="portal-system-theme-designStyleList-label">
                            <input type="radio" class="portal-system-theme-designStyleRadio" name="themeStyle"
                                v-model="selectThemeId" :value="item.id">
                            <div class="portal-system-theme-designStyle"
                                :style="{ background: item.appTheme._cgsThereBackground, 'background-size': item.appTheme._cgsBackImgType }"
                                :class="[selectThemeId === item.id ? 'selected' : '']"
                                @click="() => changeDesignStyle(item)">
                                <template
                                    v-if="(selectThemeId === item.id && selectLayout === '布局1') || (selectThemeId !== item.id && item.layoutSettings === '布局1' || !item.layoutSettings)">
                                    <div class="portal-system-theme-headerStyle"
                                        :style="{ background: item.appTheme._headerBackground }">
                                    </div>
                                    <div class="portal-system-theme-containerStyle">
                                        <div class="portal-system-theme-leftPartStyle"
                                            :style="{ background: item.appTheme._contentBackground }">
                                        </div>
                                        <div class="portal-system-theme-contentPartStyle"
                                            :style="{ 'background-color': item.appTheme._pageContentBack, 'background-image': item.appTheme._pageContentBack, 'background-size': item.appTheme._pageBackImgSize, 'background-position': 'center center' }">
                                            <div class="portal-system-theme-hightColor"
                                                :style="{ background: item.appTheme._contentFontColor }">
                                            </div>
                                            <div class="portal-system-theme-selectColor"
                                                :style="{ background: item.appTheme._elActive }"></div>
                                            <div class="portal-system-theme-iconColor"
                                                :style="{ background: item.appTheme._buttonCancel }"></div>
                                        </div>
                                    </div>
                                </template>
                                <template
                                    v-else-if="(selectThemeId === item.id && selectLayout === '布局2') || (selectThemeId !== item.id && item.layoutSettings === '布局2')">
                                    <div class="portal-system-theme-headerStyle"
                                        :style="{ background: item.appTheme._headerBackground }">
                                    </div>
                                    <div class="portal-system-theme-containerStyle">
                                        <div class="portal-system-theme-contentPartStyle_2"
                                            :style="{ 'background-color': item.appTheme._pageContentBack, 'background-image': item.appTheme._pageContentBack, 'background-size': item.appTheme._pageBackImgSize, 'background-position': 'center center' }">
                                            <div class="portal-system-theme-hightColor"
                                                :style="{ background: item.appTheme._contentFontColor }"></div>
                                            <div class="portal-system-theme-selectColor"
                                                :style="{ background: item.appTheme._elActive }">
                                            </div>
                                            <div class="portal-system-theme-iconColor"
                                                :style="{ background: item.appTheme._buttonCancel }">
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template
                                    v-else-if="(selectThemeId === item.id && selectLayout === '布局3') || (selectThemeId !== item.id && item.layoutSettings === '布局3')">
                                    <div class="portal-system-theme-headerStyle"
                                        :style="{ background: item.appTheme._headerBackground }">
                                        <div class="portal-system-theme-headerStyle-logo"
                                            :style="{ background: item.appTheme._pageLogoChartsBack || '#24263a', 'background-size': item.appTheme._pageLogoChartImgSize || 'cover' }">
                                        </div>
                                    </div>
                                    <div class="portal-system-theme-containerStyle">
                                        <div class="portal-system-theme-leftPartStyle"
                                            :style="{ background: item.appTheme._cgsThereLeftListBackground || '#24263a' }">
                                            <div class="portal-system-theme-leftPartStyle-fontColor"
                                                :style="{ background: item.appTheme._elLeftListActive || '#ffffff' }">
                                            </div>
                                        </div>
                                        <div class="portal-system-theme-contentPartStyle"
                                            :style="{ 'background-color': item.appTheme._pageContentBack, 'background-image': item.appTheme._pageContentBack, 'background-size': item.appTheme._pageBackImgSize, 'background-position': 'center center' }">
                                            <div class="portal-system-theme-hightColor"
                                                :style="{ background: item.appTheme._contentFontColor }">
                                            </div>
                                            <div class="portal-system-theme-selectColor"
                                                :style="{ background: item.appTheme._elActive }"></div>
                                            <div class="portal-system-theme-iconColor"
                                                :style="{ background: item.appTheme._buttonCancel }"></div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </label>
                        <div class="portal-system-theme-designStyle portal-system-theme-addTheme"
                            @click="addThemeVisible = true">
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-theme-addTheme-icon"></span>
                        </div>
                    </div>
                    <!-- <div>
                        <cgs-button type="primary">添加主题</cgs-button>
                    </div> -->
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.themeName') }}</div>
                    <input class="portal-system-theme-set-item-name" v-model="selectName">
                </div>
                <div class="portal-system-theme-content-layout-item">
                    <div class="portal-system-theme-content-layout-item-title">{{ $t('systemInfo.themeInfo.layout') }}
                    </div>
                    <div class="portal-system-theme-designStyleList">
                        <cgs-radio-group v-model:value="selectLayout">
                            <cgs-radio value="布局1">布局1</cgs-radio>
                            <cgs-radio value="布局2">布局2</cgs-radio>
                            <cgs-radio value="布局3">布局3</cgs-radio>
                        </cgs-radio-group>
                    </div>
                    <!-- <div>
                        <cgs-button type="primary">添加布局</cgs-button>
                    </div> -->
                </div>
            </div>
            <div class="portal-system-theme-content-set-theme" v-if="formData.value">
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.page') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">背景</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cgsThereBackground"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">高亮颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._hoverBackColor" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字体高亮颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._elActive" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">边框颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._borderColor" pickerType="chrome" />
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item" v-if="selectLayout === '布局3'">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.leftListTitle') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">背景颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cgsThereLeftListBackground"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字体高亮颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._elLeftListActive"
                                pickerType="chrome" />
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.pageHeader') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">背景</div>
                            <cgs-color-picker v-model:pureColor="formData.value._headerBackground"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字体高亮颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._headerTitleFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字体颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._headerFontColor" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-size">
                            <div class="portal-system-theme-set-item-color-title">字体大小</div>
                            <cgs-input-number :formatter="value => `${value}px`"
                                :parser="value => value.replace('px', '')"
                                v-model:value="formData.value._headerFontSize" :min="1" :max="100" />
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item" v-if="selectLayout === '布局3'">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.tableHeader') }}</div>
                    <div class="portal-system-theme-set-img">
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">导航背景</div>
                            <theme-img :value="formData.value._pageNavChartsBack" typeSwitch
                                :imgSize="formData.value._pageNavChartImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_pageNavChartImgSize')"
                                @backChange="(value) => handleChange(value, '_pageNavChartsBack')"></theme-img>
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item" v-if="selectLayout === '布局3'">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.logoHeader') }}</div>
                    <div class="portal-system-theme-set-img">
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">logo (500*78) </div>
                            <theme-img :value="formData.value._pageLogoChartsBack" typeSwitch
                                :imgSize="formData.value._pageLogoChartImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_pageLogoChartImgSize')"
                                @backChange="(value) => handleChange(value, '_pageLogoChartsBack')"></theme-img>
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item" v-if="selectLayout === '布局3'">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.navHeader') }}</div>
                    <div class="portal-system-theme-set-img">
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">默认背景</div>
                            <theme-img :value="formData.value._NavChartsBack" typeSwitch
                                :imgSize="formData.value._NavChartImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_NavChartImgSize')"
                                @backChange="(value) => handleChange(value, '_NavChartsBack')"></theme-img>
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item" v-if="selectLayout === '布局3'">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.navHeader') }}</div>
                    <div class="portal-system-theme-set-img">
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">选中背景</div>
                            <theme-img :value="formData.value._NavChartsSelectBack" typeSwitch
                                :imgSize="formData.value._NavChartsSelectImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_NavChartsSelectImgSize')"
                                @backChange="(value) => handleChange(value, '_NavChartsSelectBack')"></theme-img>
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.pageTheme') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">背景</div>
                            <cgs-color-picker v-model:pureColor="formData.value._contentBackground"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字体颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._contentFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">图表颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._IconColor" pickerType="chrome" />
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.table') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字体颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._tableFontColor" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">背景</div>
                            <theme-img :value="formData.value._tableBackColor" typeSwitch
                                :imgSize="formData.value._tableBackImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_tableBackImgSize')"
                                @backChange="(value) => handleChange(value, '_tableBackColor')"></theme-img>
                        </div>

                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">奇数行颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._tableOddFontColor"
                                pickerType="chrome" />
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.card') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">背景颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardBackColo" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">标题颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardTitleFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">字段标题颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardFieldTitleFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">数值颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value.cardValueFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">空图片背景颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardNoneImgBackColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">空图片文字颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardNoneImgFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">空置填充颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardFieldTitleFontColor"
                                pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">边框颜色</div>
                            <cgs-color-picker v-model:pureColor="formData.value._cardBorderColor" pickerType="chrome" />
                        </div>
                    </div>

                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.customPage') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">背景</div>
                            <theme-img :value="formData.value._pageContentBack" typeSwitch
                                :imgSize="formData.value._pageBackImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_pageBackImgSize')"
                                @backChange="(value) => handleChange(value, '_pageContentBack')"></theme-img>
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.customPageChart') }}
                    </div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-select-img">
                            <div class="portal-system-theme-set-item-color-title">背景</div>
                            <theme-img :value="formData.value._pageChartsBack" typeSwitch
                                :imgSize="formData.value._pageChartImgSize"
                                @backImgSizeChange="(value) => handleChange(value, '_pageChartImgSize')"
                                @backChange="(value) => handleChange(value, '_pageChartsBack')"></theme-img>
                        </div>
                    </div>
                </div>
                <div class="portal-system-theme-set-item">
                    <div class="portal-system-theme-set-item-title">{{ $t('systemInfo.themeInfo.otherSetting') }}</div>
                    <div class="portal-system-theme-set-color">
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">普通按钮</div>
                            <cgs-color-picker v-model:pureColor="formData.value._buttonNormal" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">确定按钮</div>
                            <cgs-color-picker v-model:pureColor="formData.value._buttonSure" pickerType="chrome" />
                        </div>
                        <div class="portal-system-theme-set-item-color">
                            <div class="portal-system-theme-set-item-color-title">取消按钮</div>
                            <cgs-color-picker v-model:pureColor="formData.value._buttonCancel" pickerType="chrome" />
                        </div>
                    </div>
                </div>

                <div class="portal-system-theme-set-item">
                    <cgs-button @click="handelDeleteTheme">
                        <template #icon>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-shanchu portal-system-theme-set-icon"></span>
                        </template>
                        {{ $t('common.delete') }}</cgs-button>
                    <cgs-button type="primary" style="margin-left: 10px;" @click="handelUpdateTheme">
                        <template #icon>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-baocun portal-system-theme-set-icon"></span>
                        </template>
                        {{ $t('common.save') }}</cgs-button>
                </div>
            </div>
        </div>
        <addThemeModal :addThemeVisible="addThemeVisible" @cancelAddThemeModal="cancelAddThemeModal"
            @AddThemeOk="AddThemeOk">
        </addThemeModal>
        <styleImport :styleImportDialog="styleImportDialog" :jsonData="jsonData" @importOk="importOk"
            @cancelImport="cancelImport"></styleImport>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    ref,
    getCurrentInstance,
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import appDesignStyle from '@/assets/json/appDesignStyle.json'
import themeImg from './components/themeImg.vue';
import addThemeModal from './components/addTheme.vue';
import styleImport from './components/styleImport.vue';
import {
    getThemeList,
    addTheme,
    updateTheme,
    deleteTheme
} from '@/api/tenant.js'
import {
    saveAs
} from 'file-saver';
export default defineComponent({
    components: {
        themeImg,
        addThemeModal,
        styleImport
    },
    props: {},

    setup () {
        let {
            proxy
        } = getCurrentInstance();
        const {
            t
        } = useI18n()
        const formData = reactive({})
        const themeList = ref([])
        const selectName = ref('')
        const selectThemeId = ref('')
        const selectLayout = ref('')
        const oldSelectColor = ref({})
        const addThemeVisible = ref(false)
        const styleImportDialog = ref(false)
        const jsonData = ref(null)
        const handleChange = (value, key) => {
            if (!value) {
                formData.value[key] = 'rgba(0,0,0,0)';
            } else {
                formData.value[key] = value;
            }
        }
        const cancelAddThemeModal = () => {
            addThemeVisible.value = false
        }
        const AddThemeOk = (themeName) => {
            addTheme({
                name: themeName,
                appTheme: appDesignStyle
            }).then(res => {
                if (res.code !== 200) {
                    proxy.$message.error('common.createFail')
                } else {
                    if (res.data && res.data.id) {
                        selectThemeId.value = res.data.id
                    }
                    getThemeData();
                    proxy.$message.success(t('common.createSuccess'))
                }
            })
            addThemeVisible.value = false;
        }
        const getThemeData = () => {
            getThemeList().then((res) => {
                if (res.code == 200) {
                    themeList.value = res.data;
                    if (themeList.value && themeList.value.length > 0) {
                        themeList.value = themeList.value.map((item) => {
                            let obj = {}
                            for (let key in item.appTheme) {
                                if (key.indexOf('--') == 0) {
                                    obj[key.replace(/--/, '_')] = item.appTheme[key]
                                } else {
                                    obj[key] = item.appTheme[key]
                                }
                            }
                            item.appTheme = obj
                            return item
                        })
                        if (!selectThemeId.value) {
                            formData.value = themeList.value[0].appTheme;
                            let _keys_ = Object.keys(themeList.value[0].appTheme).join(';');
                            if (!_keys_.includes('tableOddFontColor')) {
                                formData.value['_tableOddFontColor'] = '#24263a'
                            }
                            if (themeList.value[0].layoutSettings === '布局3') {
                                if (!_keys_.includes('pageNavChartsBack')) {
                                    formData.value['_pageNavChartsBack'] = '#24263a'
                                    formData.value['_pageNavChartImgSize'] = 'cover'
                                    formData.value['_pageLogoChartsBack'] = '#24263a'
                                    formData.value['_pageLogoChartImgSize'] = 'cover'
                                }
                                if (!_keys_.includes('_NavChartsBack')) {
                                    formData.value['_NavChartsBack'] = '#24263a'
                                    formData.value['_NavChartImgSize'] = 'cover'
                                    formData.value['_NavChartsSelectBack'] = '#24263a'
                                    formData.value['_NavChartsSelectImgSize'] = 'cover'
                                }
                                if (!_keys_.includes('cgsThereLeftListBackground')) {
                                    formData.value['_cgsThereLeftListBackground'] = '#24263a'
                                    formData.value['_elLeftListActive'] = '#FFFFFF'
                                }
                            }
                            oldSelectColor.value = JSON.parse(JSON.stringify(themeList.value[0].appTheme))
                            selectName.value = themeList.value[0].name
                            selectThemeId.value = themeList.value[0].id;
                            selectLayout.value = themeList.value[0].layoutSettings
                        } else {
                            themeList.value.forEach((item) => {
                                if (item.id === selectThemeId.value) {
                                    formData.value = item.appTheme
                                    selectName.value = item.name
                                    selectLayout.value = item.layoutSettings
                                    oldSelectColor.value = JSON.parse(JSON.stringify(item.appTheme))
                                }
                            })
                        }
                        if (!selectLayout.value) {
                            selectLayout.value = "布局1"
                        }
                    } else {
                        let obj = {}
                        for (let key in appDesignStyle) {
                            if (key.indexOf('--') == 0) {
                                obj[key.replace(/--/, '_')] = appDesignStyle[key]
                            } else {
                                obj[key] = appDesignStyle[key]
                            }
                        }
                        formData.value = obj
                        selectName.value = ''
                        selectThemeId.value = 'appDesignStyle'
                        selectLayout.value = "布局1"
                    }
                } else {
                    proxy.$message.error('失败')
                }
            })
        }

        const changeDesignStyle = (item) => {
            selectLayout.value = item.layoutSettings || '布局1';
            formData.value = item.appTheme;
            let _keys_ = Object.keys(formData.value).join(';');
            if (!_keys_.includes('tableOddFontColor')) {
                formData.value['_tableOddFontColor'] = '#24263a'
            }
            if (item.layoutSettings === '布局3') {
                if (!_keys_.includes('pageNavChartsBack')) {
                    formData.value['_pageNavChartsBack'] = '#24263a'
                    formData.value['_pageNavChartImgSize'] = 'cover'
                    formData.value['_pageLogoChartsBack'] = '#24263a'
                    formData.value['_pageLogoChartImgSize'] = 'cover'
                }
                if (!_keys_.includes('_NavChartsBack')) {
                    formData.value['_NavChartsBack'] = '#24263a'
                    formData.value['_NavChartImgSize'] = 'cover'
                    formData.value['_NavChartsSelectBack'] = '#24263a'
                    formData.value['_NavChartsSelectImgSize'] = 'cover'
                }
                if (!_keys_.includes('cgsThereLeftListBackground')) {
                    formData.value['_cgsThereLeftListBackground'] = '#24263a'
                    formData.value['_elLeftListActive'] = '#FFFFFF'
                }
            }
            let oldSelectTheme = themeList.value.find(t => t.id === selectThemeId.value)
            if (oldSelectTheme && oldSelectColor.value) {
                oldSelectTheme.appTheme = JSON.parse(JSON.stringify(oldSelectColor.value))
            }
            selectName.value = item.name;
            selectThemeId.value = item.id;
            oldSelectColor.value = JSON.parse(JSON.stringify(formData.value))
        }
        const changeLayout = (layout) => {
            let _keys_ = Object.keys(formData.value).join(';');
            if (!_keys_.includes('tableOddFontColor')) {
                formData.value['_tableOddFontColor'] = '#24263a'
            }
            if (layout === '布局3') {
                if (!_keys_.includes('pageNavChartsBack')) {
                    formData.value['_pageNavChartsBack'] = '#24263a'
                    formData.value['_pageNavChartImgSize'] = 'cover'
                    formData.value['_pageLogoChartsBack'] = '#24263a'
                    formData.value['_pageLogoChartImgSize'] = 'cover'
                }
                if (!_keys_.includes('_NavChartsBack')) {
                    formData.value['_NavChartsBack'] = '#24263a'
                    formData.value['_NavChartImgSize'] = 'cover'
                    formData.value['_NavChartsSelectBack'] = '#24263a'
                    formData.value['_NavChartsSelectImgSize'] = 'cover'
                }
                if (!_keys_.includes('cgsThereLeftListBackground')) {
                    formData.value['_cgsThereLeftListBackground'] = '#24263a'
                    formData.value['_elLeftListActive'] = '#FFFFFF'
                }
            }

            selectLayout.value = layout
        }
        const handelUpdateTheme = () => {
            let obj = {}
            for (let key in formData.value) {
                if (key.indexOf('_') == 0) {
                    obj[key.replace(/_/, '--')] = formData.value[key]
                }
            }
            updateTheme({
                id: selectThemeId.value,
                appTheme: obj,
                layoutSettings: selectLayout.value,
                name: selectName.value || '未命名'
            }).then((res) => {
                if (res.code !== 200) {
                    proxy.$message.error('更新失败');
                } else {
                    proxy.$message.success('更新成功');
                    getThemeData()
                }
            })
        }

        const handelDeleteTheme = () => {
            proxy.$confirm({
                title: '提示',
                content: '是否确认永久删除?',
                async onOk () {
                    try {
                        deleteTheme({
                            id: selectThemeId.value
                        }).then((res) => {
                            if (res.code !== 200) {
                                proxy.$message.error('删除失败');
                            } else {
                                proxy.$message.success('删除成功');
                                selectThemeId.value = ''
                                getThemeData()
                            }
                        })
                    } catch (err) {
                        console.error(err)
                        proxy.$message.error('删除失败');
                    }
                },
                onCancel () { },
            });
        }
        const importOk = (value) => {
            formData.value = Object.assign(formData.value, value);
            styleImportDialog.value = false
            proxy.$message.success('导入成功');
        }
        const cancelImport = () => {
            styleImportDialog.value = false
        }
        const handleExport = () => {
            const data = JSON.stringify(formData.value)
            const blob = new Blob([data], {
                type: 'application/json'
            })
            saveAs(blob, `${randomString(12)}.json`)
        }
        const randomString = (num) => {
            num = num || 32;
            let str = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
            let strLen = str.length;
            let randomString = "";
            for (let i = 0; i < num; i++) {
                randomString += str.charAt(Math.floor(Math.random() * strLen));
            }
            return randomString
        }
        getThemeData()
        return {
            formData,
            selectName,
            themeList,
            selectThemeId,
            selectLayout,
            addThemeVisible,
            styleImportDialog,
            jsonData,
            handleChange,
            getThemeData,
            changeDesignStyle,
            changeLayout,
            handelUpdateTheme,
            handelDeleteTheme,
            handleExport,
            randomString,
            cancelAddThemeModal,
            AddThemeOk,
            importOk,
            cancelImport,
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-system-theme {
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
    overflow-y: auto;
    overflow-x: hidden;
}

.portal-system-theme-title {
    display: flex;
    justify-content: space-between
}

.portal-system-theme-title-left {
    .little-title;
    color: @font-color-1;
}

.portal-system-theme-designStyleList-label {
    margin-bottom: 10px;
}

.portal-system-theme-title-right-button {
    margin-right: 10px;
}

.portal-system-theme-content-layout-item,
.portal-system-theme-set-item {
    display: flex;
    margin: 10px 0px;
}

.portal-system-theme-content-layout-item-title,
.portal-system-theme-set-item-title {
    .text-1;
    color: @font-color-1;
    width: 108px;
    margin: 10px 0px;
}

.portal-system-theme-set-item-name {
    .text-1;
    color: @font-color-2;
    margin: 10px 0px;
    border: unset;
    border-bottom: 1px dashed;
    background-color: unset;
    padding-left: 8px;
    text-align: center;
    padding-right: 8px;
}

.portal-system-theme-set-color {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 108px);
}

.portal-system-theme-set-item-color {
    display: flex;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.portal-system-theme-set-item-color-title {
    .text-1;
    color: @font-color-2;
    margin-right: 5px;
}

.portal-system-theme-select-img {
    display: flex;
    // align-items: center;
    margin: 10px 0px;
}

.portal-system-theme-icon {
    font-size: 10px;
    color: @font-color-1;
    margin-right: 5px;
}

.portal-system-theme-designStyleList {
    margin: 10px 0px;
    display: flex;
    flex-wrap: wrap;

    .portal-system-theme-designStyleRadio {
        display: none;
    }

    .portal-system-theme-designStyle {
        width: 180px;
        height: 120px;
        background-color: #eee;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
        cursor: pointer;

        &:hover:not(.selected) {
            border: 4px solid @primary-color;
        }

        &.selected {
            border: 4px solid @primary-color;
        }

        .portal-system-theme-headerStyle {
            height: 14px;
            width: 120px;
            background-color: #aaa;
            margin-bottom: 3px;
            border-radius: 4px 4px 0 0;
        }

        .portal-system-theme-headerStyle-logo {
            height: 10px;
            width: 30px;
            margin-top: 2px;
            margin-left: 5px;
        }

        .portal-system-theme-containerStyle {
            height: 64px;
            width: 120px;
            display: flex;
        }

        .portal-system-theme-leftPartStyle {
            height: 100%;
            width: 20px;
            margin-right: 3px;
            border-bottom-left-radius: 4px;
            background-color: #aaa;

            .portal-system-theme-leftPartStyle-fontColor {
                width: 60%;
                margin-left: 20%;
                margin-top: 10px;
                border-radius: 2px;
                height: 3%;
            }
        }

        .portal-system-theme-contentPartStyle {
            height: 100%;
            width: calc(100% - 23px);
            border-bottom-right-radius: 4px;
            background-color: #aaa;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 11px;

            >div {
                height: 7px;
                margin-bottom: 5px;
                border-radius: 2px;
            }

            .portal-system-theme-hightColor {
                width: 50px;
                background: rgb(178, 130, 110);
            }

            .portal-system-theme-selectColor {
                width: 64px;
                background: rgb(115, 160, 154);
            }

            .portal-system-theme-iconColor {
                width: 30px;
                background: rgb(115, 160, 154);
            }
        }

        .portal-system-theme-contentPartStyle_2 {
            height: 100%;
            width: 100%;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            background-color: #aaa;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 11px;

            >div {
                height: 7px;
                margin-bottom: 5px;
                border-radius: 2px;
            }

            .portal-system-theme-hightColor {
                width: 50px;
                background: rgb(178, 130, 110);
            }

            .portal-system-theme-selectColor {
                width: 64px;
                background: rgb(115, 160, 154);
            }

            .portal-system-theme-iconColor {
                width: 30px;
                background: rgb(115, 160, 154);
            }
        }
    }

    .portal-system-theme-addTheme {
        background: transparent;
        border: 2px solid #aaa;

        &:hover {
            >i {
                color: @primary-color;
            }
        }

        >i {
            color: #aaa;
            font-size: 50px;
        }
    }
}

.portal-system-theme-addTheme-icon {
    font-size: 40px;
}

.portal-system-theme-set-icon {
    font-size: 10px;
    margin-right: 5px;
}

.portal-system-theme-set-item-size {
    display: flex;
    align-items: center;
}
</style>