<!--
*@description 
*@author 杜超峰
*@date 2023/04/23 11:29:38
!-->

<template>
    <div class="portal-account-left-nav" :class="menuCollapse ? '' : 'portal-account-left-nav-not-collapse'">
        <div class="portal-account-left-nav-content">
            <div class="portal-account-left-nav-content-wrapper">
                <div class="portal-account-left-nav-content-item selectable-item" v-for="item in showAccountList"
                    :key="item.key" @click="clickAccountItemHandler(item)">
                    <span class="portal-account-left-nav-content-item-icon" :class="item.icon"></span>
                    <span class="portal-account-left-nav-content-item-title"
                        v-if="!menuCollapse">{{ item.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        computed,
        defineComponent
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'

    export default defineComponent({
        props: {
            menuCollapse: {
                type: Boolean,
                default: false
            }
        },

        setup(props, ctx) {
            const {
                t
            } = useI18n()
            const accountList = [{
                key: 'safe',
                label: '',
                icon: 'cgs-portal-iconfont cgs-portal-icon-anquanshezhi'
            }]
            const showAccountList = computed(() => {
                return accountList.map(_item => {
                    _item.label = t('accountInfo.' + _item.key)
                    return _item
                })
            })
            const clickAccountItemHandler = (accountItem) => {
                ctx.emit('clickMenu', accountItem.key)
            }
            return {
                showAccountList,
                clickAccountItemHandler,
            }
        },
    })
</script>

<style lang="less" scoped>
    @import "~@style/less/theme.less";

    .portal-account-left-nav {
        height: 100%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .portal-account-left-nav-not-collapse {
        width: 300px;
    }

    .portal-account-left-nav-content {
        flex: 1;
    }

    .portal-account-left-nav-content-item {
        padding: 8px;
    }

    .portal-account-left-nav-content-item:hover {
        border-radius: 5px;
    }

    .portal-account-left-nav-content-item-title {
        margin-left: 8px;
    }

    .portal-account-left-nav-content {
        .text-1();
        .font-color-1();
    }

    .portal-account-left-nav-content-wrapper {
        margin-top: 8px;
    }
</style>