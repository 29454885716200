<template>
      <div class="portal-home-workSpace">
            <div class="portal-home-work">
                  <div class="portal-home-work-header">
                        <div class="portal-home-work-header-left">
                              <cgs-tooltip>
                                    <template #title>{{ spaceInfo.name }}</template>
                                    <span class="portal-home-work-header-left-title">
                                          {{ spaceInfo.name }}
                                    </span>
                              </cgs-tooltip>
                              <span v-if="spaceInfo.isCollect" style="color:#F2B026"
                                    class=" app-card-text-info-icon  cgs-portal-iconfont cgs-portal-icon-a-shoucang-yishoucang"></span>
                              <cgs-more :menu-list="settingMenuList" @clikMenuItem="clikMenuItemSetting"
                                    v-if="settingMenuList.length > 0">
                                    <template #triggerButton>
                                          <span
                                                class=" app-card-text-info-icon  cgs-portal-iconfont cgs-portal-icon-shezhi"></span>
                                    </template>
                              </cgs-more>
                              <cgs-more :menu-list="types" @clikMenuItem="clikMenuItemHandler">
                                    <template #triggerButton>
                                          <span class="app-card-text-info-icon portal-home-work-header-text"
                                                @click.prevent>{{ selectType }}
                                                <span class=" cgs-portal-iconfont cgs-portal-icon-xia"></span>
                                          </span>
                                    </template>
                              </cgs-more>

                        </div>
                        <div class="portal-home-work-header-right">
                              <cgs-tabs mode="button" @change="onChangeCategory">
                                    <!-- <cgs-tab-pane v-for="item in categoryList" :key="item.categoryValue">
      <template #tab>
       <span>{{ item.categoryKey }}</span>
      </template>
</cgs-tab-pane> -->
                                    <template #rightExtra>

                                          <div class="portal-home-table-header-right" shape="circle">
                                                <cgs-button style="margin-right: 20px;" @click="vis = true"
                                                      v-if="isAddApp">
                                                      {{ $t('homeBench.createApp') }}
                                                </cgs-button>
                                                <cgs-tooltip>
                                                      <template #title>
                                                            {{ $t("homeBench.sort") }}
                                                      </template>
                                                      <cgs-button class="portal-home-table-header-right-btn"
                                                            shape="circle" :ghost="isFold" @click="isFold = !isFold">
                                                            <template #icon>
                                                                  <span
                                                                        class="cgs-portal-iconfont cgs-portal-icon-fenlei"></span>
                                                            </template>
                                                      </cgs-button>
                                                </cgs-tooltip>
                                                <div class="portal-home-table-header-right-points"></div>
                                                <cgs-tooltip>
                                                      <template #title>
                                                            {{ $t("homeBench.cardView") }}
                                                      </template>
                                                      <cgs-button class="portal-home-table-header-right-btn"
                                                            shape="circle" :ghost="showType !== 'card'"
                                                            @click="switchShowType('card')">
                                                            <template #icon>
                                                                  <span
                                                                        class="cgs-portal-iconfont cgs-portal-icon-kapian"></span>
                                                            </template>
                                                      </cgs-button>
                                                </cgs-tooltip>
                                                <cgs-tooltip>
                                                      <template #title>
                                                            {{ $t("homeBench.listView") }}
                                                      </template>
                                                      <cgs-button class="portal-home-table-header-right-btn"
                                                            shape="circle" :ghost="showType !== 'table'"
                                                            @click="switchShowType('table')">
                                                            <template #icon>
                                                                  <span
                                                                        class="cgs-portal-iconfont cgs-portal-icon-liebiao"></span>
                                                            </template>
                                                      </cgs-button>
                                                </cgs-tooltip>
                                                <cgs-tooltip>
                                                      <template #title>
                                                            {{ $t("homeBench.tableView") }}
                                                      </template>
                                                      <cgs-button class="portal-home-table-header-right-btn"
                                                            shape="circle" :ghost="showType !== 'icon'"
                                                            @click="switchShowType('icon')">
                                                            <template #icon>
                                                                  <span
                                                                        class="cgs-portal-iconfont cgs-portal-icon-tubiaopailie"></span>
                                                            </template>
                                                      </cgs-button>
                                                </cgs-tooltip>



                                          </div>
                                    </template>
                              </cgs-tabs>
                        </div>
                  </div>
                  <div class="portal-home-work-centent">
                        <AppIcon :sceneList="sceneList" v-if="showType === 'icon' && !isFold" @enter="enterScene"
                              @clikMenuItem="clikSceneMenuItem" @clikCollect="clikSceneCollect">
                        </AppIcon>
                        <AppCard :sceneList="sceneList" v-if="showType === 'card' && !isFold"
                              @clikMenuItem="clikSceneMenuItem" @enter="enterScene" @clikCollect="clikSceneCollect">
                        </AppCard>
                        <AppTable :sceneList="sceneList" v-if="showType === 'table' && !isFold" @enter="enterScene"
                              @clikMenuItem="clikSceneMenuItem" @clikCollect="clikSceneCollect">
                        </AppTable>
                        <FoldAppIcon v-if="showType === 'icon' && isFold" :sceneList="sceneList" @enter="enterScene"
                              @clikMenuItem="clikSceneMenuItem" @clikCollect="clikSceneCollect"></FoldAppIcon>
                        <FoldAppCard v-if="showType === 'card' && isFold" :sceneList="sceneList" @enter="enterScene"
                              @clikMenuItem="clikSceneMenuItem" @clikCollect="clikSceneCollect"></FoldAppCard>
                        <FoldAppTable v-if="showType === 'table' && isFold" :sceneList="sceneList" @enter="enterScene"
                              @clikMenuItem="clikSceneMenuItem" @clikCollect="clikSceneCollect">
                        </FoldAppTable>
                  </div>
                  <AddAppModal :vis="vis" @close="vis = false" @success="initView"></AddAppModal>
                  <EditModal v-model:visible="editModal" :scene="spaceInfo" @success="initLeft"
                        @close="editModal = false">
                  </EditModal>
                  <EditTwoDAddModal v-show="edit.twoDvis.visible" v-model:visible="edit.twoDvis.visible"
                        :scene="edit.twoDvis.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.twoDvis.sceneType" @close="() => { edit.twoDvis.visible = false }"
                        @success="initView" />
                  <EditThreeDAddModal v-show="edit.threeDvis.visible" v-model:visible="edit.threeDvis.visible"
                        :scene="edit.threeDvis.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.threeDvis.sceneType" @close="() => { edit.threeDvis.visible = false }"
                        @success="initView" />
                  <EditTableModal v-show="edit.tableVis.visible" v-model:visible="edit.tableVis.visible"
                        :scene="edit.tableVis.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.tableVis.sceneType" @close="() => { edit.tableVis.visible = false }"
                        @success="initView" />
                  <EditEarthModal v-show="edit.EarthModal.visible" v-model:visible="edit.EarthModal.visible"
                        :scene="edit.EarthModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.EarthModal.sceneType" @close="() => { edit.EarthModal.visible = false }"
                        @success="initView" />
                  <EditWorkFlowModal v-show="edit.WorkFlowModal.visible" v-model:visible="edit.WorkFlowModal.visible"
                        :scene="edit.WorkFlowModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.WorkFlowModal.sceneType" @close="() => { edit.workSpaceId.visible = false }"
                        @success="initView" />
                  <EditPageModal v-show="edit.PageModal.visible" v-model:visible="edit.PageModal.visible"
                        :scene="edit.PageModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.PageModal.sceneType" @close="() => { edit.PageModal.visible = false }"
                        @success="initView" />
                  <EditIotModal v-show="edit.IotModal.visible" v-model:visible="edit.IotModal.visible"
                        :scene="edit.IotModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.IotModal.sceneType" @close="() => { edit.IotModal.visible = false }"
                        @success="initView" />
                  <EditGeoModelModal v-show="edit.GeoModelModal.visible" v-model:visible="edit.GeoModelModal.visible"
                        :scene="edit.GeoModelModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.GeoModelModal.sceneType" @close="() => { edit.twoDvis.GeoModelModal = false }"
                        @success="initView" />
                  <EditVolumeModal v-show="edit.VolumeModal.visible" v-model:visible="edit.VolumeModal.visible"
                        :scene="edit.VolumeModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.VolumeModal.sceneType" @close="() => { edit.VolumeModal.visible = false }"
                        @success="initView" />
                  <EditCadModal v-show="edit.CadModal.visible" v-model:visible="edit.CadModal.visible"
                        :scene="edit.CadModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.CadModal.sceneType" @close="() => { edit.CadModal.visible = false }"
                        @success="initView" />
                  <EditDasModal v-show="edit.DasModal.visible" v-model:visible="edit.DasModal.visible"
                        :scene="edit.DasModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.DasModal.sceneType" @close="() => { edit.DasModal.visible = false }"
                        @success="initView" />
                  <EditFileStorageModal v-show="edit.FileStorageModal.visible" :themeOptions="edit.themeOptions"
                        :spaceOptions="edit.spaceOptions" v-model:visible="edit.FileStorageModal.visible"
                        :scene="edit.FileStorageModal.scene" :category-options="edit.categoryList"
                        :scene-type="edit.FileStorageModal.sceneType"
                        @close="() => { edit.FileStorageModal.visible = false }" @success="initView" />
                  <EditNewEarthModal v-show="edit.NewEarthModal.visible" v-model:visible="edit.NewEarthModal.visible"
                        :scene="edit.NewEarthModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.NewEarthModal.sceneType" @close="() => { edit.NewEarthModal.visible = false }"
                        @success="initView" />
                  <EditScadaModal v-show="edit.ScadaModal.visible" v-model:visible="edit.ScadaModal.visible"
                        :scene="edit.ScadaModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.ScadaModal.sceneType" @close="() => { edit.ScadaModal.visible = false }"
                        @success="initView" />
                  <EditCodeEngineModal v-show="edit.CodeEngineModal.visible"
                        v-model:visible="edit.CodeEngineModal.visible" :scene="edit.CodeEngineModal.scene"
                        :category-options="edit.categoryList" :scene-type="edit.CodeEngineModal.sceneType"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        @close="() => { edit.CodeEngineModal.visible = false }" @success="initView" />
                  <EditCadDesignModal v-show="edit.CadDesignModal.visible" v-model:visible="edit.CadDesignModal.visible"
                        :scene="edit.CadDesignModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.CadDesignModal.sceneType"
                        @close="() => { edit.CadDesignModal.visible = false }" @success="initView" />
                  <EditReportModal v-show="edit.ReportModal.visible" v-model:visible="edit.ReportModal.visible"
                        :scene="edit.ReportModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.ReportModal.sceneType" @close="() => { edit.ReportModal.visible = false }"
                        @success="initView" />
                  <EditLargeScreenModal v-show="edit.LargeScreenModal.visible"
                        v-model:visible="edit.LargeScreenModal.visible" :scene="edit.LargeScreenModal.scene"
                        :category-options="edit.categoryList" :themeOptions="edit.themeOptions"
                        :spaceOptions="edit.spaceOptions" :scene-type="edit.LargeScreenModal.sceneType"
                        @close="() => { edit.LargeScreenModal.visible = false }" @success="initView" />
                  <EditAIModal v-show="edit.AIModal.visible" v-model:visible="edit.AIModal.visible"
                        :scene="edit.AIModal.scene" :category-options="edit.categoryList"
                        :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
                        :scene-type="edit.AIModal.sceneType" @close="() => { edit.AIModal.visible = false }"
                        @success="initView" />

                  <DeriveModal v-show="edit.deriveModalConfig.visible" v-model:visible="edit.deriveModalConfig.visible"
                        @close="() => { edit.deriveModalConfig.visible = false }" :scene="edit.deriveModalConfig.scene">
                  </DeriveModal>
                  <copy-path-modal v-model:visible="edit.copyPathModalConfig.visible"
                        :scene="edit.copyPathModalConfig.scene" :scene-type="edit.copyPathModalConfig.appType"
                        @close="closeCopyPathModal">
                  </copy-path-modal>
                  <ExportModal v-model:visible="exportVisible" @close="() => { exportVisible = false }"
                        :sceneList="sceneList" @ok="exportWorkSpace">
                  </ExportModal>
            </div>


      </div>
</template>

<script setup>

import { ref, computed, watch, onMounted, getCurrentInstance, reactive, inject } from "vue"
import { useRouter } from 'vue-router'
import { getThemeList } from '@api/scene'
import { addRecentUsed, getWorkSpace, deleteWorkSpace, addFavorite, getUserList, getWorkSpaceList } from "@api/workSpace"

import AppIcon from "./component/appIcon.vue"
import AppCard from "./component/appCard.vue"
import AppTable from "./component/appTable.vue"
import AddAppModal from "./component/addAppModal.vue"
import ExportModal from "./component/exportModal.vue"
import EditModal from "./component/editModal.vue"
import EditTwoDAddModal from '@/views/menu/twoD/edit-modal.vue'
import EditThreeDAddModal from '@/views/menu/threeD/edit-modal.vue'
import EditTableModal from '@/views/menu/table/edit-modal.vue'
import EditEarthModal from '@/views/menu/earth/edit-modal.vue'
import EditWorkFlowModal from '@/views/menu/workflow/edit-modal.vue'
import EditPageModal from '@/views/menu/static/edit-modal.vue'
import EditIotModal from '@/views/menu/iot/edit-modal.vue'
import EditGeoModelModal from '@/views/menu/geoModel/edit-modal.vue'
import EditVolumeModal from '@/views/menu/volume/edit-modal.vue'
import EditCadModal from '@/views/menu/cad/edit-modal.vue'
// import DataServerModal from '@/views/menu/dataServer/edit-modal.vue'
import EditDasModal from '@/views/menu/das/edit-modal.vue'
import EditFileStorageModal from '@/views/menu/fileStorage/edit-modal.vue'
import EditNewEarthModal from '@/views/menu/newEarth/edit-modal.vue'
import DeriveModal from "@/views/menu/table/derive-modal.vue"
import copyPathModal from "@/views/menu/static/copy-path-modal.vue"

import EditScadaModal from '@/views/menu/scada/edit-modal.vue'
import EditCodeEngineModal from '@/views/menu/codeEngine/edit-modal.vue'
import EditCadDesignModal from '@/views/menu/cadDesign/edit-modal.vue'
import EditReportModal from '@/views/menu/report/edit-modal.vue'
import EditLargeScreenModal from '@/views/menu/largeScreen/edit-modal.vue'
import EditAIModal from '@/views/menu/ai/edit-modal.vue'
import FoldAppIcon from "./component/foldAppIcon.vue"
import FoldAppCard from "./component/foldAppCard.vue"
import FoldAppTable from "./component/foldAppTable.vue"


import { getUserAuthority, setOriginPath } from "@/utils/author"

import {
      useI18n
} from 'vue-i18n'
import {
      getSceneTypeSetting,
      getList,
      deleteScene,
      getCategory,
      getSceneDetail
} from '@api/scene'


const showType = ref('icon')
const {
      t
} = useI18n()
let {
      proxy
} = getCurrentInstance()
const workSpacList = inject('workSpacList')
const collectList = inject('collectList')
const router = useRouter()
const spaceInfo = ref({})
const vis = ref(false)
const editModal = ref(false)
const exportVisible = ref(false)
const isAddApp = ref(false)
const isFold = ref(false)
const spaceId = computed(() => {
      return router.currentRoute.value.params.id
})
let settingMenuList = ref([])
const authority = getUserAuthority()
const edit = reactive({
      categoryList: [],
      categoryActiveKey: '',
      deriveModalConfig: {},
      twoDvis: {},
      threeDvis: {},
      tableVis: {},
      EarthModal: {},
      WorkFlowModal: {},
      PageModal: {},
      IotModal: {},
      GeoModelModal: {},
      VolumeModal: {},
      CadModal: {},
      DataServerModal: {},
      DasModal: {},
      FileStorageModal: {},
      NewEarthModal: {},
      copyPathModalConfig: {},
      ScadaModal: {},
      CodeEngineModal: {},
      CadDesignModal: {},
      ReportModal: {},
      LargeScreenModal: {},
      AIModal: {},
      themeOptions: [],
      spaceOptions: []


})
const sceneList = ref([])
const selectType = ref("所有类型")
const types = ref([{
      title: '所有类型',
      key: 'all',
      icon: '',

}])
onMounted(async () => {
      let res = await getSceneTypeSetting()
      let list = []
      res.data.forEach(item => {
            list.push({
                  title: item.typeName,
                  key: item.typeId,
                  icon: item.icon,
            })
      })
      let _type = list.filter(item => {
            if (item.key === "DataServer" || item.key === "IoT") {
                  return
            } else {
                  return item
            }
      })
      types.value.push(..._type)

      let _themeResult = await getThemeList()
      let _workspaseResult = await getWorkSpaceList()

      if (_themeResult.code === 200) {
            edit.themeOptions = _themeResult.data
      }

      if (_workspaseResult.code === 200) {
            edit.spaceOptions = _workspaseResult.data
      }


})


const getSpaceList = async () => {
      if (!spaceId.value) return
      let info = await getWorkSpace(spaceId.value)

      let dataSource = await getUserList(spaceId.value)
      if (info.code === 200) {
            spaceInfo.value = info.data
      }
      let user = dataSource.data?.find(item => item.userId === authority.userId)
      if (user) {
            if (user.roleName === "WorkspaceOwner") {
                  isAddApp.value = true
                  settingMenuList.value =
                        [{
                              title: '',
                              key: 'auth',
                              icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
                        },
                        {
                              title: '',
                              key: 'edit',
                              icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
                        },
                        {
                              title: '',
                              key: 'export',
                              icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
                        },
                        {
                              title: '',
                              key: 'delete',
                              icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
                        }].map(_item => {
                              _item['title'] = t('sceneMoreMenu.' + _item.key)
                              return _item
                        })
            } else if (user.roleName === "WorkspaceCreator") {
                  isAddApp.value = true
                  settingMenuList.value = []
            } else {
                  isAddApp.value = false
                  settingMenuList.value = []
            }
      } else {
            isAddApp.value = false
            settingMenuList.value = []
      }
      let list = await getList({ workspaceId: spaceId.value })
      if (list.code === 200) {
            list.data.records.forEach((item) => {
                  types.value.forEach(_item => {
                        if (item.appType === _item.key) {
                              item.typeName = _item.title
                        }
                  })
            })
            sceneList.value = list.data.records
      }
      selectType.value = "所有类型"
}
watch(() => spaceId.value, (val) => {
      if (val) {
            getSpaceList()
      }


}, { deep: true, immediate: true })


// getSpaceList()

const initView = () => {
      // console.log(workSpacList);
      getSpaceList()
}
const initLeft = () => {

      // console.log(workSpacList);
      getSpaceList()
      workSpacList()
}

const switchShowType = (type) => {
      showType.value = type
}

const clikMenuItemHandler = async (val) => {
      if (val.key === "all") {
            selectType.value = "所有类型"
            let list = await getList({ workspaceId: spaceId.value })
            if (list.code === 200) {
                  list.data.records.forEach((item) => {
                        types.value.forEach(_item => {
                              if (item.appType === _item.key) {
                                    item.typeName = _item.title
                              }
                        })
                  })
                  sceneList.value = list.data.records
            }
      } else {
            let current = types.value.find(item => item.key === val.key)
            if (current) {
                  selectType.value = current.title
                  let list = await getList({ workspaceId: spaceId.value, VisualType: val.key })
                  if (list.code === 200) {
                        list.data.records.forEach((item) => {
                              types.value.forEach(_item => {
                                    if (item.appType === _item.key) {
                                          item.typeName = _item.title
                                    }
                              })
                        })
                        sceneList.value = list.data.records
                  }
            }
      }

}
const clikMenuItemSetting = (val) => {
      if (val.key === "auth") {
            router.push({
                  path: `/home/spaceAuthority/${spaceInfo.value.id}`
            })
      } else if (val.key === "edit") {
            editModal.value = true
      } else if (val.key === "delete") {
            proxy.$confirm({
                  title: '提示',
                  content: '是否确认永久删除?',
                  async onOk() {
                        try {
                              let res = await deleteWorkSpace(spaceInfo.value.id)
                              if (res.code === 200) {
                                    proxy.$message.success('删除成功')
                                    workSpacList("delete")
                                    router.push({
                                          path: "/home/workbench"
                                    })
                              } else {
                                    proxy.$message.warning(res.msg)
                              }
                        } catch (err) {
                              proxy.$message.warning(err.msg)
                              console.error(err)
                        }
                  },
                  onCancel() { },
            });
      } else if (val.key === "export") {
            exportVisible.value = true
      }
}

const enterScene = async (scene, type = 'edit') => { //差异化
      await addRecentUsed({
            id: scene.id
      })
      let url = ""
      if (scene.appType === "TwoD") {
            url = `/2d/viewer.html?ProjId=${scene.projectID}&mode=${type}`
            window.open(url, '_blank');
      } else if (scene.appType === "App") {
            setOriginPath(router.currentRoute.value.path)
            url = '/table/' + scene.id
            window.open(url, '_self');
      } else if (scene.appType === "ThreeD") {
            url = `/3d/viewer.html?ProjId=${scene.projectID}&mode=${type}`
            window.open(url, '_blank');
      } else if (scene.appType === "Earth") {
            url = `/earth/viewer.html?ProjId=${scene.projectID}&mode=${type}`

            window.open(url, '_blank');
      } else if (scene.appType === "Workflow") {
            setOriginPath(router.currentRoute.value.path)
            url = '/algo-workflow/' + scene.id
            window.open(url, '_self');
      } else if (scene.appType === "Static") {
            let _result = await getSceneDetail({
                  ID: scene.id,
                  VisualType: "Static"
            })
            if (_result.code === 200) {
                  let config = _result.data.config;
                  url = `/file/${config.sceneUrl}`
                  window.open(url, '_blank');
            }
      } else if (scene.appType === "IoT") {
            let url = '/iot/' + scene.id
            window.open(url, '_blank');
      } else if (scene.appType === "GeoModel") {
            setOriginPath(router.currentRoute.value.path)
            url = `/geo-model/main/${scene.projectID}`;
            window.open(url, '_self');
      } else if (scene.appType === "CAD") {

            url = `/cgscad/viewer.html?ProjId=${scene.projectID}&mode=${type}`;
            window.open(url, '_blank');
      } else if (scene.appType === "Vtk") {

            url = `/volume/viewer.html?ProjId=${scene.projectID}&mode=${type}`;
            window.open(url, '_blank');
      } else if (scene.appType === "DAS") {
            url = '/das/' + scene.id
            window.open(url, '_blank');
      } else if (scene.appType === "FileStorage") {
            setOriginPath(router.currentRoute.value.path)
            url = '/file-storage/home/' + scene.id
            window.open(url, '_self');
      } else if (scene.appType === "NewEarth") {
            setOriginPath(router.currentRoute.value.path)
            url = `/earthplus/viewer/${scene.id}/${type}`
            window.open(url, '_blank');
      } else if (scene.appType === "SCADA") {
            if (type == "edit") {
                  let url = `/scada/?id=${scene.sceneID}`
                  window.open(url, '_blank');

            } else {
                  let url = `/scada/preview?id=${scene.sceneID}&r=${new Date().getTime()}`
                  window.open(url, '_blank');
            }
      } else if (scene.appType === "CodeEngine") {
            let url = `/code_engine/proc/${scene.id}/`
            window.open(url, '_blank');
      } else if (scene.appType === "CadDesign") {
            if (type == "edit") {
                  let url = `/cgsCadDesign/?appId=${scene.id}`
                  window.open(url, '_blank');
            } else {
                  let url = `/cgsCadDesign/viewer.html?ProjId=${scene.projectID}&mode=preview`;
                  window.open(url, '_blank');
            }
      } else if (scene.appType === "Report") {
            let url = '/cgsReport/' + scene.id
            window.open(url, '_self');
      } else if (scene.appType === "LargeScreen") {
            setOriginPath(router.currentRoute.value.path)
            url = `/cgsLargeScreen/viewer/${scene.id}/${type}`
            window.open(url, '_blank');
      } else if (scene.appType === "AI") {
            setOriginPath(router.currentRoute.value.path)
            url = `/cgsAI/${scene.id}`
            window.open(url, '_blank');
      }

}

const sceneControls = async (scene) => {
      if (scene.appType === "TwoD") {
            edit.twoDvis = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "App") {
            edit.tableVis = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "ThreeD") {
            edit.threeDvis = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "Earth") {
            edit.EarthModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "Workflow") {
            edit.WorkFlowModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "Static") {
            edit.PageModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "IoT") {
            edit.IotModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "GeoModel") {
            edit.GeoModelModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "CAD") {
            edit.CadModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "Vtk") {
            edit.VolumeModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "DAS") {
            edit.DasModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "FileStorage") {
            edit.FileStorageModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "NewEarth") {
            edit.NewEarthModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }

      } else if (scene.appType === "SCADA") {
            edit.ScadaModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }

      } else if (scene.appType === "CodeEngine") {
            edit.CodeEngineModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "CadDesign") {
            edit.CadDesignModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "Report") {
            edit.ReportModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      } else if (scene.appType === "LargeScreen") {
            edit.LargeScreenModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      }else if (scene.appType === "AI") {
            edit.AIModal = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
                  workSpaceId: scene.workspaceId || ""
            }
      }
      try {

            let _categoryResult = await getCategory(scene.appType)
            if (_categoryResult.code === 200) {
                  edit.categoryList = _categoryResult.data
                  _setCategoryActiveKey()
            }
      } catch (err) {
            console.error(err)
      }
}

const sceneDerive = (scene) => {
      if (scene.appType === "App") {
            edit.deriveModalConfig = {
                  visible: true,
                  scene: scene,
            }
      } else {
            proxy.$confirm({
                  title: '提示',
                  content: '确定导出?',
                  onOk() {
                        let url =
                              `/api/Cgs/Portal/Export?visualId=${scene.id}&visualType=${scene.appType}`;
                        window.open(url);
                  },
                  onCancel() { },
            });
      }
}
const _setCategoryActiveKey = (key) => {
      if (key === undefined || key === null) {
            if (edit.categoryList.length > 0) {
                  edit.categoryActiveKey = edit.categoryList[0].categoryValue
            }
      } else {
            edit.categoryActiveKey = key
      }
}

const clikSceneMenuItem = (scene, menuItemParams) => {

      if (menuItemParams.key === 'edit') {
            sceneControls(scene)
      } else if (menuItemParams.key === 'export') { //差异化

            sceneDerive(scene)
      } else if (menuItemParams.key === 'delete') {
            deleteSceneById(scene)
      } else if (menuItemParams.key === 'auth') {
            router.push({
                  path: `/home/authority/${scene.appType}/${scene.id}`
            })
      } else if (menuItemParams.key === "preview") {
            enterScene(scene, 'preview')
      } else if (menuItemParams.key === "path") {
            // enterScene(scene, 'edit')
            edit.copyPathModalConfig = {
                  scene: scene,
                  visible: true,
                  sceneType: scene.appType,
            }
      }
}
const deleteSceneById = (scene) => {
      proxy.$confirm({
            title: '提示',
            content: '是否确认永久删除?',
            async onOk() {
                  try {
                        let params = {
                              ID: scene.id,
                              VisualType: scene.appType
                        }
                        let _result = await deleteScene(params)
                        if (_result.code === 200) {
                              getSpaceList()

                        }
                  } catch (err) {
                        console.error(err)
                  }
            },
            onCancel() { },
      });
}
const closeCopyPathModal = () => {
      edit.copyPathModalConfig.visible = true
}
const clikSceneCollect = async (scene) => {
      const collect = await addFavorite({
            isCollect: !scene.isCollect,
            id: scene.id,
      })
      if (scene.isCollect) {
            // 取消收藏
            if (collect.code === 200) {
                  proxy.$message.success('取消收藏成功')
            } else {
                  proxy.$message.error('取消收藏失败')
            }

      } else {
            // 收藏
            if (collect.code === 200) {
                  proxy.$message.success('收藏成功')
            } else {
                  proxy.$message.error('收藏失败')
            }
      }
      initLeft()
}

const exportWorkSpace = async (obj) => {

      // spaceId.value

      // let res = await exportSpace({
      //       id: spaceId.value,
      //       base: obj
      // })
      let json = JSON.stringify(obj)
      let url = "/api/workspace/export?id=" + spaceId.value + "&base=" + json
      window.open(url)

}

watch(() => collectList, () => {
      getSpaceList()
}, { deep: true })

</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-workSpace {
      position: relative;
      width: 100%;
      height: 100%;
}

.portal-home-work-header-text {
      font-size: 14px !important;
      color: @primary-color !important;
      line-height: 22px;
      margin-bottom: 10px;
}

.portal-home-work {
      width: 100%;
      height: 100%;
      padding: 25px;
}

.portal-home-work-authority {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
}

.app-card-text-info-icon {
      font-size: 16px;
      margin-left: 10px;
      color: @font-color-1;


}



.portal-home-work-header-left-title {
      display: inline-block;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      line-height: 1;
      font-size: 20px;
      color: @font-color-1;

}

.portal-home-work-header {
      display: flex;
      justify-content: space-between;
}

.portal-home-table-header-right {
      display: flex;
      align-items: center;
}

.portal-home-table-header-right-btn {
      margin: 0 3px;
}

.portal-home-table-header-right-points {
      width: 1px;
      height: 20px;
      background: @border-color;
      margin: 0 5px;
}
</style>