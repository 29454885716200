<template>
    <div class="portal-system-theme-addTheme">
        <cgs-modal :visible="styleImportDialog" title="导入主题" @close="$emit('cancelImport')">
            <template #content>
                <div style="text-align:center">
                    <!-- 此处action需为有效的任意接口——当前为官网范例接口 -->
                    <cgs-upload :maxCount="1" action="/api/upload-file" ref="upload" accept=".json" :fileList="data"
                        :on-success="onSuccess" @remove="onRemove" @change="handelImportChange">
                        <!-- <p class="ant-upload-drag-icon">
                            <inboxOutlined></inboxOutlined>
                        </p>
                        <div class="ant-upload-text">将文件拖到此处，或点击上传</div>
                        <div class="ant-upload-hint">
                            上传json文件，且只能上传 1 个文件
                        </div> -->
                        <cgs-button>
                            <template #icon>
                                <span class="cgs-portal-iconfont cgs-portal-icon-a-2D"></span>
                            </template>
                            上传json文件，且只能上传 1 个文件
                        </cgs-button>
                    </cgs-upload>
                </div>
            </template>
            <template #footer>
                <cgs-button type="primary" @click="$emit('cancelImport')">关闭</cgs-button>
                <cgs-button type="primary" @click="importConfirm">确定</cgs-button>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    getCurrentInstance,
    ref
} from 'vue'

export default defineComponent({
    props: {
        styleImportDialog: {
            type: Boolean,
            default: false,
        },
        jsonData: {
            type: Array,
            default: () => []
        }
    },
    setup (props, ctx) {
        let { proxy } = getCurrentInstance();
        const data = ref(props.jsonData)
        const fileList = ref([])

        const handelImportChange = (file) => {
            let _file = null
            if (file.originFileObj) {
                _file = file.originFileObj
            } else if (file.file && file.file.originFileObj) {
                _file = file.file.originFileObj
            } else {
                return
            }
            let reader = new FileReader();
            reader.readAsText(_file);
            reader.onload = (e) => {
                data.value = [];
                data.value = JSON.parse(e.target.result);
            };
        }

        const onSuccess = (file, value, val) => {

            console.log(file, value, val);
        }
        const importConfirm = () => {
            proxy.$confirm({
                title: '导入后原数据会被覆盖，确定导入吗?", "温馨提示',
                onOk () {

                    ctx.emit('importOk', data.value)
                    data.value = [];
                },
                onCancel () {
                },
            });
        }
        const onRemove = () => {
            data.value = [];
        }
        return {
            importConfirm,
            onRemove,
            handelImportChange,
            onSuccess,
            fileList,
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

::v-deep(.ant-upload-text-icon) {
    display: none;
}
</style>