import {
  createI18n
} from 'vue-i18n'
import zhCNLocale from "@/lang/zh-CN";
import enUSLocale from "@/lang/en-US";
import {getCurrentLanguage} from "@/utils/tool"

const i18n = createI18n({
  locale: getCurrentLanguage(),
  legacy: false,
  messages: {
    'zh-CN': {
      ...zhCNLocale
    },
    'en-US': {
      ...enUSLocale
    }
  }
})

export default i18n