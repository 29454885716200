<!--
*@description 
*@author 杜超峰
*@date 2023/04/27 15:04:04
!-->

<template>
    <cgs-modal class="portal-menu-table-add-modal" v-model:visible="vis" :title="$t('createScene')" @close="closeModal">
        <template #content>
            <div class="portal-menu-table-add-modal-content">
                <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }" labelAlign="left">
                    <cgs-form-item :label="$t('modifyModal.orgCategory')" name="orgCategory">
                        <cgs-select v-model:value="formState.orgCategory">
                            <cgs-select-option :value="parseInt(option.categoryValue)"
                                v-for="option in state.orgOptions" :key="option.categoryValue">{{ option.categoryKey }}
                            </cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.category')" name="Category"
                        :rules="[{ required: true, message: $t('modifyModal.categoryNullError') }]">
                        <cgs-select v-model:value="formState.Category">
                            <cgs-select-option :value="parseInt(option.categoryValue)" v-for="option in categoryOptions"
                                :key="option.categoryValue">{{ option.categoryKey }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.title')" name="Title"
                        :rules="[{ required: true, message: $t('modifyModal.titleNullError') }]">
                        <cgs-input v-model:value="formState.Title" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('modifyModal.password')" name="Password">
                        <cgs-input v-model:value="formState.Password" />
                    </cgs-form-item>
                    <!-- <cgs-form-item :label="$t('modifyModal.theme')" name="themeId">
                        <cgs-select v-model:value="formState.themeId">
                            <cgs-select-option :value="option.id" v-for="option in state.themeOptions" :key="option.id">
                                {{option.name}}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item> -->
                    <cgs-form-item :label="$t('modifyModal.status')" name="Status">
                        <cgs-switch class="portal-menu-table-add-modal-switch" v-model:checked="formState.Status"
                            :checkedValue="1" :unCheckedValue="0" :checkedChildren="$t('modifyModal.published')"
                            :unCheckedChildren="$t('modifyModal.unpublished')" />
                    </cgs-form-item>
                </cgs-form>
            </div>
        </template>
        <template #footer>
            <div class="portal-menu-table-add-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    getCurrentInstance,
    ref,
    watch
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    getUserInfo
} from '@/api/user'
import {
    setUserAuthority,
} from "@/utils/author.js";
import {
    getCategory,
    getThemeList,
    addScene
} from '@api/scene'

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        categoryOptions: {
            type: Array,
            default: () => []
        },
        sceneType: {
            type: String,
            default: ''
        },
        selectCategory: {
            type: String,
            default: ''
        }
    },

    setup (props, ctx) {
        const {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        const sceneForm = ref(null)
        const formState = reactive({
            Category: '',
            // ID: '',
            Password: '',
            Status: 0,
            Title: '',
            orgCategory: null,
            themeId: ''
        });
        const state = reactive({
            orgOptions: [],
            themeOptions: []
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        watch(() => props.selectCategory, () => {
            if (props.selectCategory) {
                formState.Category = parseInt(props.selectCategory)
            }
        })
        watch(() => props.workSpaceId, () => {
            if (props.workSpaceId) {
                formState.workspaceId = props.workSpaceId
            }
        })
        onMounted(() => {
            _init()
        })
        const _init = async () => {
            try {
                let _orgResult = await getCategory('Org')
                let _themeResult = await getThemeList()
                if (_orgResult.code === 200) {
                    state.orgOptions = _orgResult.data
                }
                if (_themeResult.code === 200) {
                    state.themeOptions = _themeResult.data
                }
            } catch (err) {
                console.error(err)
            }
        }
        const updateUserAuthority = () => {
            return new Promise((resolve, reject) => {
                getUserInfo().then(infoRes => {
                    if (infoRes.data && infoRes.success) {
                        setUserAuthority(infoRes.data);
                        window.authManager.init();
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => {
            try {
                await sceneForm.value.validateFields()
                proxy.$loading.open()
                let params = {
                    Visual: formState,
                    VisualType: props.sceneType,
                    isPortal: true
                }
                let _result = await addScene(params)
                if (_result.code === 200) {
                    proxy.$message.success(t('common.createSuccess'))
                    await updateUserAuthority()
                    ctx.emit('success')
                    close()
                } else {
                    proxy.$message.error('common.createFail')
                }
                proxy.$loading.close()
            } catch (err) {
                console.error(err)
                proxy.$loading.close()
                // proxy.$message.error(t('common.modifyFail'))
            }
        }
        return {
            state,
            formState,
            vis,
            sceneForm,
            close,
            closeModal,
            ok

        }
    },
})
</script>

<style lang='less' scoped>
.portal-menu-table-add-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>

<style>
.portal-menu-table-add-modal-switch .ant-switch-inner {
    width: 38px;
}
</style>