<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 19:21:10
!-->

<template>
    <cgs-modal class="portal-public-select-department-modal" v-model:visible="vis"
        :title="$t('selectDepartmentModal.title')" @close="closeModal">
        <template #content>
            <div class="portal-public-select-department-modal-content">
                <div class="portal-public-select-department-modal-search">
                    <cgs-input v-model:value="state.searchValue" :placeholder="$t('selectDepartmentModal.placeholder')"
                        background-transparent>
                        <template #prefix>
                            <span
                                class="portal-public-select-department-modal-search-icon cgs-portal-iconfont cgs-portal-icon-sousuo"></span>
                        </template>
                    </cgs-input>
                </div>
                <div class="portal-public-select-department-modal-user-list">
                    <div class="portal-public-select-department-modal-user-list-wrapper">
                        <cgs-tree v-model:expandedKeys="state.expandedKeys" v-model:checkedKeys="state.checkedKeys"
                            checkable checkStrictly :selectable="false" :tree-data="treeData"
                            :fieldNames="state.treeFieldNames">
                            <!-- <template #title="{ name }">
                                <template>{{ name }}</template>
                            </template> -->
                            <template #title="item">
                                <span> {{ item.name }}</span>
                            </template>
                        </cgs-tree>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="portal-public-select-department-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch
} from 'vue'
import {
    getDepartmentList
} from '@api/user'

export default defineComponent({
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        defaultSelectedList: {
            type: Array,
            default: () => []
        },
        disabledIds: {
            type: Array,
            default: () => []
        }
    },

    setup (props, ctx) {
        const state = reactive({
            searchValue: '',
            departmentList: [],
            expandedKeys: [],
            checkedKeys: {
                checked: [],
                halfChecked: []
            },
            treeFieldNames: { children: 'children', title: 'name', key: 'id' }
        })

        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        const showDepartmentList = computed(() => {
            return state.departmentList.filter(_item => _item.name.indexOf(state.searchValue) >= 0)
        })
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                state.checkedKeys.checked = props.defaultSelectedList
            }
        })
        const treeData = computed(() => {
            let _data = []
            if (state.departmentList && state.departmentList.length > 0) {
                let _nodeMap = {}
                let _rootNode = []
                state.departmentList.forEach(_item => {
                    let _id = _item.id;
                    if (props.disabledIds.includes(_id)) _item.disabled = true
                    _nodeMap[_id] = _item
                })
                state.departmentList.forEach(_item => {
                    let _parentId = _item.parentId;
                    if (_parentId !== '0') {
                        if (_nodeMap[_parentId]) {
                            if (!_nodeMap[_parentId].children) {
                                _nodeMap[_parentId].children = []
                            }
                            _nodeMap[_parentId].children.push(_item)
                        }
                    } else {
                        _rootNode.push(_item)
                    }
                })
                _data = _rootNode
            }
            return _data
        })

        watch(treeData, () => {
            state.expandedKeys = treeData.value.map(_item => _item.id)
        })

        onMounted(() => {
            _init()
        })
        const _init = async () => {
            try {
                let _departmentResult = await getDepartmentList()
                if (_departmentResult.code === 200) {
                    state.departmentList = _departmentResult.data
                }
            } catch (err) {
                console.error(err)
            }
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => {
            let _selectedDepartmentList = state.departmentList.filter(_department => state.checkedKeys.checked
                .includes(_department.id))
            ctx.emit('selected', _selectedDepartmentList)
            close()
        }
        const onChangeChecked = (id) => {
            let _index = state.currentSelectedIdList.findIndex(item => item === id)
            if (_index < 0) {
                state.currentSelectedIdList.push(id)
            } else {
                state.currentSelectedIdList.splice(_index, 1)
            }
        }
        return {
            vis,
            state,
            showDepartmentList,
            close,
            closeModal,
            ok,
            onChangeChecked,

            treeData,


        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-public-select-department-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portal-public-select-department-modal-search {
    margin-bottom: 16px;
}

.portal-public-select-department-modal-search .cgs-design-input.ant-input-affix-wrapper>input.ant-input,
.portal-public-select-department-modal-search .cgs-design-input.cgs-design-input-background-transparent.ant-input-affix-wrapper {
    border-radius: 20px;
}

.portal-public-select-department-modal-user-item {
    padding: 8px 0;
}

.portal-public-select-department-modal-user-name {
    margin-left: 8px;
    .text-1();
    color: @font-color-1;
}

.portal-public-select-department-modal-user-avatar {
    margin-left: 8px;
}

.portal-public-select-department-modal-user-list-wrapper {
    max-height: 400px;
    overflow-y: auto;
}
</style>