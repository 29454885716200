import { defineStore } from 'pinia'
import { ref } from 'vue'

const useHeader = defineStore('header', () => {
 const menuCollapse = ref(false)

 const changeCollapse = () => {
  menuCollapse.value = !menuCollapse.value
 }

 return {
  menuCollapse,
  changeCollapse
 }
})

export default useHeader