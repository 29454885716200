<template>
 <div class="portal-home-work-flow">
  <h3 class="portal-home-work-flow-title little-title">{{ $t('homeBench.myFlow') }}</h3>
  <div class="portal-home-work-flow-box">
   <div class="portal-home-work-flow-state">
    <div class="portal-home-work-flow-state-item" @click="toFlow(1)">
     <div class="portal-home-work-flow-state-item-icon daiban">
      <img src="@/assets/image/daiban.svg" alt="">
      <!-- <span class="cgs-portal-iconfont cgs-portal-icon-a-daiban1"></span> -->
     </div>
     <div class="portal-home-work-flow-title-name">
      <div>{{ $t('homeBench.myToDoList') }}</div>
      <div class="portal-home-work-flow-title-num">{{ todo_handle }}</div>
     </div>

    </div>
    <div class="portal-home-work-flow-state-item" @click="toFlow(0)">
     <div class="portal-home-work-flow-state-item-icon wofaqide">
      <!-- <img src="@/assets/image/initiate.png" alt=""> -->
      <span class="cgs-portal-iconfont cgs-portal-icon-wofaqide" style="margin-right: 4px;"></span>
     </div>
     <div class="portal-home-work-flow-title-name">
      <div>{{ $t('homeBench.iInitiated') }}</div>
      <!-- <div class="portal-home-work-flow-title-num">{{ my_start }}</div> -->
     </div>
    </div>
    <div class="portal-home-work-flow-state-item" @click="toFlow(2)">
     <div class="portal-home-work-flow-state-item-icon yiwancheng">
      <!-- <img src="@/assets/image/complete.png" alt=""> -->
      <span class="cgs-portal-iconfont cgs-portal-icon-yiwancheng"></span>
     </div>
     <div class="portal-home-work-flow-title-name">{{ $t('homeBench.completed') }}</div>
    </div>
   </div>
   <!-- <div class="portal-home-work-flow-window">
    <img src="@/assets/image/window.png" alt="">
   </div> -->
  </div>
 </div>
</template>

<script setup>
import { getTaskCount } from "@/api/workSpace"
import { onMounted, ref } from "vue"

const toFlow = (val) => {
 window.open(`/table/my_flow/${val}`, '_self')
}
const todo_handle = ref(0)
const my_start = ref(0)

onMounted(async () => {
 let data = await getTaskCount()
 my_start.value = data.data[0]
 todo_handle.value = data.data[1]
})
</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-work-flow-title {
 font-size: 16px;
 color: @font-color-1;
 margin-bottom: 10px;
}

.portal-home-work-flow-title-name {
 font-size: 16px;
 color: @font-color-1;
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.portal-home-work-flow-title-num {
 font-size: 18px;
 font-weight: 600;
}

.portal-home-work-flow-box {
 width: 100%;
 height: 105px;
 background: @background-color;

 display: flex;
 align-items: center;
 justify-content: space-between;
}

.portal-home-work-flow-state {
 display: flex;
 align-items: center;
 justify-content: space-between;
 width: 100%;

}

.portal-home-work-flow-window {
 margin-right: 21px;
}

.portal-home-work-flow-state-item {
 width: 33%;
 height: 100%;
 background: @card-color;
 display: flex;
 align-items: center;
 cursor: pointer;
 color: @font-color-1;
 padding: 15px 0 15px 15px;
 border-radius: 7px;
 .border();
}

.portal-home-work-flow-state-item:hover {
 .card-box-shadow();
}

.portal-home-work-flow-state-item-icon {
 margin-right: 20px;
 width: 65px;
 height: 65px;
 display: flex;
 align-items: center;
 justify-content: center;

 border-radius: 50%;

 img {
  width: 32px;
  height: 32px;
 }

 span {
  font-size: 26px;
  color: #fff;
 }
}

.daiban {
 background: linear-gradient(152deg, #74E3AE 7%, #26B860 91%);
}

.wofaqide {
 background: linear-gradient(148deg, #A9CFFF 13%, #4797FF 90%);
}

.yiwancheng {
 background: linear-gradient(153deg, #FFDA8C 15%, #F2B026 89%);
}

.portal-home-work-icon {
 font-size: 40px;
 color: @primary-color;
}
</style>