<template>
    <div class="portal-system-theme-addTheme">
        <cgs-modal :visible="addThemeVisible" title="添加主题" @close="$emit('cancelAddThemeModal')">
            <template #content>
                <cgs-form
                    :model="formState"
                >
                    <cgs-form-item
                    label="主题名"
                    name="themeName"
                    :rules="[{ required: true, message: '请输入主题名' }]"
                    >
                        <cgs-input v-model:value="formState.themeName" />
                    </cgs-form-item>
                </cgs-form>
            </template>
            <template #footer>
                <cgs-button type="primary" @click="$emit('cancelAddThemeModal')">关闭</cgs-button>
                <cgs-button type="primary" @click="handleOk">确定</cgs-button>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    getCurrentInstance,
} from 'vue'

export default defineComponent({
    props: {
        addThemeVisible:{
            type: Boolean,
            default: false,
        }
    },
    setup(props,ctx) {
        let { proxy } = getCurrentInstance();
        const formState = reactive({
            themeName:'',
        })
        const handleOk = () => {
            if(formState.themeName !== ''){
                ctx.emit('AddThemeOk',formState.themeName)
            }else{
                proxy.$message.error('请输入主题名')
            }
        }
        return {
            formState,
            handleOk,
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";
</style>