<template>
    <div>
        <cgs-modal :title="$t('accountInfo.modifyPassword')" width="500px" v-model:visible="vis" @close="closeModal">
            <template #content>
                <cgs-form :model="formPassword" labelAlign="left" layout="vertical" ref="sceneForm">
                    <cgs-form-item :validateStatus="state.errorInfo" :help="state.errormessage"
                        :label="$t('accountInfo.modifyPassword')"
                        :rules="[{ required: true, message: $t('accountInfo.oldPasswordNullError'), trigger: 'blur' }]"
                        name="oldPassword">
                        <cgs-input v-model:value="formPassword.oldPassword" type="password"></cgs-input>
                        <!-- <span>123</span> -->
                    </cgs-form-item>
                    <cgs-form-item :label="$t('accountInfo.newPassword')"
                        :rules="[{ required: true, message: $t('accountInfo.newPasswordNullError', 1), trigger: 'blur' },
                        { pattern: `^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,}$`, message: $t('remind.passwordRegulationError'), trigger: 'blur' }]"
                        name="newPassword">
                        <cgs-input v-model:value="formPassword.newPassword" type="password"></cgs-input>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('accountInfo.confirmPassword')" :rules="[{ required: true, message: $t('accountInfo.confirmPasswordNullError', 2), trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur', }]" name="confirmPassword">
                        <cgs-input v-model:value="formPassword.confirmPassword" type="password"></cgs-input>
                    </cgs-form-item>
                </cgs-form>
            </template>
            <template #footer>
                <div class="portal-account-safe-modal-footer">
                    <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                    <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
                </div>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    getCurrentInstance,
    computed,
    ref,
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    changeUserPassword,
} from "@/api/account";

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },

    setup (props, ctx) {
        let {
            proxy
        } = getCurrentInstance();
        const {
            t
        } = useI18n()
        const formPassword = reactive({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
        const sceneForm = ref(null)
        const state = reactive({
            errorInfo: "",
            errormessage: ""
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const validatePass2 = async (_rule, value) => {
            if (value === '') {
                return Promise.reject('请输入确认密码');
            } else if (value !== formPassword.newPassword) {
                return Promise.reject("两次输入的密码不一致");
            } else {
                return Promise.resolve();
            }
        };
        const ok = async () => {
            try {
                if (formPassword.oldPassword === "") {
                    state.errormessage = t('accountInfo.oldPasswordNullError')
                    state.errorInfo = "error"
                }
                await sceneForm.value.validateFields()
                proxy.$loading.open()

                let res = await changeUserPassword({
                    oldPassword: formPassword.oldPassword,
                    newPassword: formPassword.newPassword,
                    confirmPassword: formPassword.confirmPassword
                })
                if (res && res.code === 200 && res.success) {
                    proxy.$message.success(t('common.modifySuccess'));
                    close()
                } else {
                    if (res.msg == "Old password is not correct.") {
                        state.errormessage = "旧密码错误,请重新输入"
                        state.errorInfo = "error"
                    } else {
                        proxy.$message.error(t('common.modifyFail'))
                    }

                }
                proxy.$loading.close()
            } catch (err) {
                console.error(err)
                proxy.$loading.close()

                // state.errorInfo = err.msg
                if (err.msg == "Old password is not correct.") {
                    state.errorInfo = "error"
                    state.errormessage = "旧密码错误,请重新输入"
                }
            }
        }
        return {
            sceneForm,
            formPassword,
            vis,
            state,
            validatePass2,
            closeModal,
            ok
        }
    },
})
</script>

<style>
.portal-account-safe-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>