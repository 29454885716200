<template>
    <div class="portal-system-user-table">
        <div class="portal-system-user-table-wrapper">
            <cgs-table :columns="showColumns" :data-source="sceneList" mode="portal" size="small" :pagination="false"
                :backgroundTransparent="true" position="bottomCenter">
                <template #headerCell="{ column }">
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-user-table-action">
                            <cgs-input style="width: 188px;" v-model:value="state.searchInput"
                                :placeholder="$t('remind.searchRemind')" @change="changeSearchInput" />
                        </div>
                    </template>
                </template>
                <template #bodyCell="{ column, record, text }">
                    <template v-if="column.key === 'userState'">
                        <cgs-select ref="select" :value="text" style="width: 120px"
                            @change="handleChangeState($event, record)">
                            <cgs-select-option v-for="item in state_options" :disabled="item.disabled" :key="item.id"
                                :value="item.id">{{ item.name }}</cgs-select-option>
                        </cgs-select>
                    </template>
                    <template v-if="column.key === 'userRole'">
                        <cgs-select ref="select" :value="text" style="width: 120px"
                            @change="handleChange($event, record)">
                            <cgs-select-option v-for="item in role_options" :disabled="item.disabled" :key="item.id"
                                :value="item.id">{{ item.roleName }}</cgs-select-option>
                        </cgs-select>
                    </template>
                    <template v-else-if="column.key === 'action'">
                        <div class="portal-system-user-table-action">
                            <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler(record, $event)">
                            </cgs-more>
                        </div>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    reactive,
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
const columns = [{
    title: '',
    dataIndex: 'index',
    key: 'index',
    width: 50,
    customRender: ({
        index
    }) => `${index + 1}`,
}, {
    title: '',
    dataIndex: 'userName',
    key: 'userName',
    width: 400,
}, {
    title: '',
    dataIndex: 'userAlia',
    key: 'userAlia',
    width: 400,
}, {
    title: '',
    dataIndex: 'userDeptNames',
    key: 'userDeptNames',
    width: 150,
}, {
    title: '',
    dataIndex: 'jobs',
    key: 'jobs',
    width: 200,
}, {
    title: '',
    dataIndex: 'userRole',
    key: 'userRole',
    width: 150,
}, {
    title: '',
    dataIndex: 'userState',
    key: 'userState',
    width: 150,
}, {
    title: '',
    dataIndex: 'action',
    key: 'action',
}];
export default defineComponent({
    components: {
    },
    props: {
        sceneList: {
            type: Array,
            default: () => []
        },
        moreMenuList: {
            type: Array,
            default: () => []
        },
        role_options: {
            type: Array,
            default: () => []
        },
    },

    setup (porps, ctx) {
        const { t } = useI18n()
        const state = reactive({
            searchInput: ''
        })
        const state_options = [{
            id: 0,
            name: "启用"
        }, {
            id: 1,
            name: "未启用"
        }]
        const showColumns = computed(() => {
            return columns.map(_item => {
                _item.title = t('systemInfo.userInfo.' + _item.key)
                return _item
            })
        })
        const clikMenuItemHandler = (scene, params) => {
            ctx.emit('clikMenuItem', scene, params)
        }
        const handleChange = (roles, column) => {
            ctx.emit('handleChangeUserRole', roles, column)
        }
        const handleChangeState = (roles, column) => {
            ctx.emit('handleChangeState', roles, column)
        }
        const changeSearchInput = () => {
            ctx.emit('searchInput', state.searchInput)
        }
        return {
            showColumns,
            state,
            clikMenuItemHandler,
            handleChange,
            handleChangeState,
            changeSearchInput,
            state_options
        }
    },
})
</script>

<style scoped>
.portal-system-user-table-action {
    width: 100%;
    height: 100%;
    text-align: right;
}
</style>