<template>
    <div class="portal-system-apk-table">
        <div class="portal-system-apk-table-wrapper">
            <cgs-table :columns="showColumns" :data-source="tableData" mode="portal" size="small" :pagination="false"
                :backgroundTransparent="true" position="bottomCenter">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-apk-table-action">
                            <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler(record, $event)" v-if="isShowButton(record.roleAlias)">
                            </cgs-more>
                        </div>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>

<script>
    import {
      computed,
        defineComponent,
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    const columns = [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        customRender: ({
            index
        }) => `${index + 1}`,
    }, {
        title: '角色名称',
        dataIndex: 'roleName',
        key: 'roleName',
        width: 500,
    },{
        title: '用户数量',
        dataIndex: 'userSize',
        key: 'userSize',
        width: 150,
    }, {
        title: '',
        dataIndex: 'action',
        key: 'action',
    }];
    export default defineComponent({
        components: {
        },
        props: {
            tableData: {
                type: Array,
                default: () => []
            },
            moreMenuList: {
                type: Array,
                default: () => []
            },
        },

        setup(porps, ctx) {
            const {
                t
            } = useI18n()
            const showColumns = computed(() => {
                return columns.map(_item => {
                    _item.title = t('systemInfo.roleInfo.'+_item.key)
                    return _item
                })
            })
            const clikMenuItemHandler = (scene, params) => {
                ctx.emit('clikMenuItem', scene, params)
            }
            const handleChange = (roles,column) => {
                ctx.emit('handleChangeapkRole', roles, column)
            }
            const isShowButton = (roleAlias) => {
                if(!roleAlias){
                    return true;
                }
                return !roleAlias.includes('Administrator')
            }
            return {
                showColumns,
                clikMenuItemHandler,
                handleChange,
                isShowButton
            }
        },
    })
</script>

<style scoped>

.portal-system-apk-table-action{
    width: 100%;
    height: 100%;
    text-align: right;
}
</style>