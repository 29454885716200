<!--
*@description 
*@author 杜超峰
*@date 2023/04/23 10:28:29
!-->

<template>
    <div class="portal-avatar">
        <div class="portal-avatar-wrapper" :style="{width: bgSize+'px', height: bgSize+'px'}">
            <span class="primary-bg-font-color little-title" :style="{fontSize: size+'px'}">{{showName}}</span>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent,
        computed
    } from 'vue'

    export default defineComponent({
        props: {
            name: {
                type: String,
                default: ''
            },
            size: {
                type: Number,
                default: 18
            },
            bgSize: {
                type: Number,
                default: 30
            }
        },

        setup(props) {
            let showName = computed(() => {
                let _name = ''
                if (props.name && props.name !== '' && props.name.length > 0) {
                    _name = props.name[0]
                }
                return _name
            })
            return {
                showName
            }
        },
    })
</script>

<style lang="less" scoped>
    @import "~@style/less/theme.less";

    .portal-avatar {
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .portal-avatar-wrapper {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: @primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>