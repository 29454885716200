<template>
  <div class="cgs-portal-dep-label-modal">
    <cgs-modal
      :visible="dialogVisible"
      title="部门标签管理"
      @close="$emit('cancelAddDeptModal')"
      :footer="null"
    >
      <template #content>
        <a-button style="margin-bottom: 8px" @click="handleAdd">添加</a-button>
        <a-table
          bordered
          :data-source="dataSource"
          :columns="columns"
          size="small"
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'name'">
              <div class="cgs-portal-dep-label-editable-cell">
                <div
                  v-if="editableData[record.id]"
                  class="editable-cell-input-wrapper"
                >
                  <a-input
                    v-model:value="editableData[record.id].name"
                    :maxlength="20"
                    @blur="save(record.id)"
                  />
                </div>
                <div v-else class="editable-cell-text-wrapper">
                  {{ text || " " }}
                  <span
                    class="cgs-portal-iconfont cgs-portal-icon-xiugai editable-cell-icon"
                    @click="edit(record.id)"
                  ></span>
                </div>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation'">
              <a-popconfirm
                v-if="dataSource.length"
                title="是否删除？"
                @confirm="onDelete(record.id)"
              >
                <span
                  class="cgs-portal-iconfont cgs-portal-icon-shanchu"
                  style="color: rgb(255, 85, 0)"
                ></span>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </template>
    </cgs-modal>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, ref } from "vue";
import { cloneDeep } from "lodash-es";
import {
  getDeptLabelList,
  saveDeptLabel,
  updateDeptLabel,
  deleteDeptLabel,
} from "@/api/tenant";

export default defineComponent({
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    let { proxy } = getCurrentInstance();
    const columns = [
      {
        title: "名称",
        dataIndex: "name",
        width: "70%",
      },
      {
        title: "操作",
        dataIndex: "operation",
      },
    ];
    const dataSource = ref([]);
    getDeptLabelList().then((res) => {
      if (res.code === 200) {
        dataSource.value = res.data.reverse();
      }
    });
    const editableData = reactive({});
    const getDataItemById = (id) =>
      dataSource.value.find((item) => id === item.id);
    const edit = (id) => {
      editableData[id] = cloneDeep(getDataItemById(id));
    };
    const save = (id) => {
      const name = editableData[id].name;
      if (!name) {
        proxy.$message.error("标签不可为空");
        return;
      }
      if (
        dataSource.value.find((item) => name === item.name && id !== item.id)
      ) {
        proxy.$message.error("标签重复");
        return;
      }
      const result = Object.assign(getDataItemById(id), editableData[id]);
      if (result.id === "newId") delete result.id;
      result.id
        ? updateDeptLabel(result)
        : saveDeptLabel({ name: result.name }).then((res) => {
            if (res.code === 200) {
              result.id = res.data.id;
            }
          });
      delete editableData[id];
    };
    const onDelete = (id) => {
      dataSource.value = dataSource.value.filter((item) => item.id !== id);
      deleteDeptLabel(id);
    };
    const handleAdd = () => {
      if (dataSource.value.find((item) => "newId" === item.id)) {
        proxy.$message.error("正在创建中");
        return;
      }
      const newData = {
        name: "",
        id: "newId",
      };
      dataSource.value.unshift(newData);
      edit(newData.id);
    };
    return {
      columns,
      onDelete,
      handleAdd,
      dataSource,
      editableData,
      edit,
      save,
    };
  },
});
</script>

<style lang="less" scoped>
.cgs-portal-dep-label-editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
    display: flex;
    align-items: center;
  }

  .editable-cell-icon {
    position: absolute;
    right: 0;
    width: 20px;
    font-size: 12px;
    display: none;
  }

  &:hover .editable-cell-icon {
    display: inline-block;
  }
}
.cgs-portal-dep-label-modal {
  .cgs-portal-iconfont {
    cursor: pointer;
  }
}
</style>
