<template>
    <div class="portal-public-main-header">
        <div class="portal-public-main-header-wrapper">
            <div class="portal-public-main-header-left">
                <slot name="prefix-icon" class="portal-public-main-header-prefix-icon"></slot>
                <div class="portal-public-main-header-system-message" @click="toHome">
                    <div class="portal-public-main-header-logo">
                        <img class="portal-public-main-header-logo-img" :src="systemLogo">
                    </div>
                    <div class="portal-public-main-header-name">
                        <span>{{ systemName + ' Portal' }}</span>
                    </div>
                </div>
            </div>
            <div class="portal-public-main-header-middle">
                <slot name="middle"></slot>
            </div>
            <div class="portal-public-main-header-right">
                <div class="portal-public-main-header-lang">
                    <cgs-more :menu-list="state.langMenuList" @clikMenuItem="selectLangHandler">
                        <template #triggerButton>
                            <div class="portal-public-main-header-lang-trigger">
                                <span class="cgs-portal-iconfont cgs-portal-icon-guojihua"></span>
                                <div class="portal-public-main-header-lang-label">{{ showLang }}</div>
                            </div>
                        </template>
                    </cgs-more>
                </div>

                <cgs-more :menu-list="showMenuList" @clikMenuItem="clikMenuItemHandler">
                    <template #triggerButton>
                        <div class="portal-public-main-header-user">
                            <cgs-avatar :name="userName"></cgs-avatar>
                            <span class="portal-public-main-header-username font-color-2 text-1">{{ userUserName
                                }}</span>
                        </div>
                    </template>
                </cgs-more>
            </div>


        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    computed,
    getCurrentInstance
} from 'vue'
import {
    useRouter
} from 'vue-router'
import {
    useI18n
} from 'vue-i18n'
import {
    watchSystemInfo,
    getUserInfo,
    setLanguage
} from '@/utils/author'
import {
    getCurrentLanguage
} from '@/utils/tool'
import cgsAvatar from './avatar.vue'
import {
    clearUserMessage
} from "@/utils/author"
import {
    logout
} from "@api/user"

export default defineComponent({
    components: {
        cgsAvatar
    },
    setup () {
        const { proxy } = getCurrentInstance()
        const i18n = useI18n()
        const t = i18n.t
        let router = useRouter()
        let state = reactive({
            sysInfo: {},
            userInfo: {},
            currentLang: getCurrentLanguage(),
            langMenuList: [{
                title: '简体中文',
                key: 'zh-CN',
            }, {
                title: 'English',
                key: 'en-US',
            }],
            menuList: [{
                title: '',
                key: 'account',
                icon: 'cgs-portal-iconfont cgs-portal-icon-gerenzhanghu'
            },
            {
                title: '',
                key: 'system',
                icon: 'cgs-portal-iconfont cgs-portal-icon-zuzhiguanli'
            },
            {
                title: '',
                key: 'logout',
                icon: 'cgs-portal-iconfont cgs-portal-icon-anquantuichu'
            }
            ]
        })
        onMounted(() => {
            watchSystemInfo((newVal) => {
                state.sysInfo = newVal
            })
            state.userInfo = getUserInfo()

            // state.currentLang = getCurrentLanguage()
        })

        const showLang = computed(() => {
            let _title = ''
            let _langOption = state.langMenuList.find(_item => _item.key === state.currentLang)
            if (_langOption) {
                _title = _langOption.title
            }
            return _title
        })
        const showMenuList = computed(() => {
            let _menuList = []
            let _originMenuList = state.menuList.map(_menu => {
                _menu['title'] = t('userMenu.' + _menu.key)
                return _menu
            })
            if (window.authManager.systemAuth()) {
                _menuList = _originMenuList
            } else {
                _menuList = _originMenuList.filter(_item => _item.key !== 'system')
            }
            return _menuList
        })
        const systemLogo = computed(() => {
            let _logo = ''
            if (state.sysInfo && state.sysInfo.logo) {
                _logo = state.sysInfo.logo
            }
            return _logo
        })
        const systemName = computed(() => {
            let _name = ''
            if (state.sysInfo && state.sysInfo.name) {
                _name = state.sysInfo.name
            }
            return _name
        })
        const userName = computed(() => {
            let _name = ''
            if (state.userInfo && state.userInfo.Name) {
                _name = state.userInfo.Name
            }
            return _name
        })
        const userUserName = computed(() => {
            let _name = ''
            if (state.userInfo && state.userInfo.Username) {
                _name = state.userInfo.Username
            }
            return _name
        })
        const clikMenuItemHandler = async (params) => {
            if (params.key === 'account') {
                router.push({
                    path: '/account/safe'
                })
            } else if (params.key === 'system') {
                router.push({
                    path: '/system/home'
                })
            } else if (params.key === 'logout') {
                let _result = await logout()
                if (_result.code === 200) {
                    clearUserMessage()
                    router.push({
                        path: '/login'
                    })
                } else {
                    proxy.$message.error("退出失败")
                }
            }
        }
        const selectLangHandler = (params) => {
            if (state.currentLang !== params.key) {
                state.currentLang = params.key
                setLanguage(params.key)
                i18n.locale.value = params.key
                // proxy.$t.locale.value = params.key
            }
        }
        const toHome = () => {
            router.push({
                name: 'home'
            })
        }
        return {
            state,
            systemLogo,
            systemName,
            userName,
            userUserName,
            showMenuList,
            showLang,
            clikMenuItemHandler,
            selectLangHandler,
            toHome
        }
    }
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-public-main-header {
    width: 100%;
    height: 100%;
    padding: 0px 16px;
}

.portal-public-main-header-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.portal-public-main-header-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.portal-public-main-header-system-message {
    margin-left: 24px;
    display: inline-flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.portal-public-main-header-logo-img {
    width: 30px;
    height: 30px;
}

.portal-public-main-header-name {
    font-size: 18px;
    color: @font-color-1;
    font-weight: 700;
    margin-left: 8px;
}

.portal-public-main-header-username {
    margin-left: 8px;
}

.portal-public-main-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.portal-public-main-header-user {
    cursor: pointer;
}

.portal-public-main-header-lang {
    margin-left: 2px;
    margin-right: 8px;
}

.portal-public-main-header-lang-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.portal-public-main-header-lang-label {
    min-width: 70px;
    margin-left: 4px;
}
</style>