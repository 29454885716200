<!--
*@description 
*@author 杜超峰
*@date 2023/04/27 15:04:04
!-->

<template>
    <cgs-modal class="portal-menu-table-edit-modal" v-model:visible="vis" :title="$t('modifyModal.modifyInfo')"
        @close="closeModal">
        <template #content>
            <div class="portal-menu-table-edit-modal-content">
                <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }" labelAlign="left">

                    <cgs-form-item :label="$t('modifyModal.title')" name="Title"
                        :rules="[{ required: true, message: $t('modifyModal.titleNullError') }]">
                        <cgs-input v-model:value="formState.Title" />
                    </cgs-form-item>

                    <!-- <cgs-form-item :label="$t('modifyModal.ThemeIcon')" name="ThemeIcon">
                        <div class="portal-menu-table-edit-modal-theme-icon">
                            <div class="portal-menu-table-edit-modal-theme" style="background:#999"
                                :class="[iconFontFamily, showIcon]" @click="openSelectThemeModal"></div>
                            <cgs-select-theme-icon v-model:visible='state.themeModelVisible' activeType="icon"
                                v-model:iconName="formState.icon" :left="state.themeIconLeft"
                                :top="state.themeIconTop + 20" v-model:themeClassName="formState.theme"
                                @themeChanged="changeTheme" @iconChanged="changeIcon">
                            </cgs-select-theme-icon>
                        </div>
                    </cgs-form-item> -->
                </cgs-form>
            </div>
        </template>
        <template #footer>
            <div class="portal-menu-table-edit-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch,
    getCurrentInstance,
    ref
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    getCategory,
    getThemeList,
} from '@api/scene'
import { updateWorkSpace } from "@api/workSpace"
import {
    profix,
    default_class_name,
    font_family,
    defaultIcon_app,
    getThemeObjByClassName
} from '@js/tableIconAndTheme'
import { useRouter } from 'vue-router'

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        scene: {
            type: Object,
            default: undefined
        },
        categoryOptions: {
            type: Array,
            default: () => []
        },
        sceneType: {
            type: String,
            default: ''
        }
    },

    setup (props, ctx) {
        const {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        const router = useRouter()
        const formState = reactive({
            // Category: props.scene.category,
            // ID: props.scene.id,
            // Password: props.scene.password,
            // Status: props.scene.status,
            // Title: props.scene.title,
            // orgCategory: props.scene.orgCategory,
            // themeId: props.scene.themeId

            Category: '',
            ID: '',
            Password: '',
            Status: '',
            Title: '',
            orgCategory: '',
            themeId: '',
            icon: '',
            theme: '',
        });
        const sceneForm = ref(null)
        const state = reactive({
            orgOptions: [],
            themeOptions: [],
            themeModelVisible: false,
            themeIconLeft: 0,
            themeIconTop: 0,
        })

        let iconFontFamily = font_family
        let showIcon = computed({
            get: () => {
                let _icon = formState.icon
                if (formState.icon === '') _icon = defaultIcon_app
                return profix + _icon
            },
            set: () => { }
        })
        let themeColor = computed({
            get: () => {
                let _color = ''
                let _themeName = formState.theme
                if (formState.theme === '') _themeName = default_class_name
                let _themeObj = getThemeObjByClassName(_themeName)
                _color = _themeObj?.color ? _themeObj?.color : "rgba(255, 185, 0, 1)"
                return _color
            },
            set: () => { }
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        const workSpaceId = computed(() => router.currentRoute.value.params.id)
        watch(() => props.scene, () => {
            if (props.scene) {
                formState.Category = props.scene.category
                formState.ID = props.scene.id
                formState.Password = props.scene.password
                formState.Status = props.scene.status
                formState.Title = props.scene.name
                formState.orgCategory = props.scene.orgCategory
                formState.themeId = props.scene.themeId
                formState.icon = props.scene.icon
                formState.theme = props.scene.theme
            }

        })
        onMounted(() => {
            _init()
        })
        const _init = async () => {
            try {
                let _orgResult = await getCategory('Org')
                let _themeResult = await getThemeList()
                if (_orgResult.code === 200) {
                    state.orgOptions = _orgResult.data
                }
                if (_themeResult.code === 200) {
                    state.themeOptions = _themeResult.data
                }
            } catch (err) {
                console.error(err)
            }
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const openSelectThemeModal = (e) => {
            state.themeIconLeft = e.x
            state.themeIconTop = e.y
            state.themeModelVisible = true
        }
        const changeTheme = (theme) => {
            formState.theme = theme
        }
        const changeIcon = (icon) => {
            formState.icon = icon
        }
        const ok = async () => {
            try {
                await sceneForm.value.validateFields()
                proxy.$loading.open()
                let params = {
                    id: workSpaceId.value,
                    name: formState.Title,
                    icon: "gongzuokongjian"
                }
                let _result = await updateWorkSpace(params)
                if (_result.code === 200) {
                    proxy.$message.success(t('common.modifySuccess'))
                    ctx.emit('success')
                    close()
                } else {
                    proxy.$message.error(t('common.modifyFail'))
                }
                proxy.$loading.close()
            } catch (err) {
                console.error(err)
                proxy.$loading.close()
                proxy.$message.error(t('common.modifyFail'))
            }
        }
        return {
            state,
            formState,
            vis,
            sceneForm,
            iconFontFamily,
            showIcon,
            themeColor,
            close,
            closeModal,
            openSelectThemeModal,
            changeTheme,
            changeIcon,
            ok

        }
    },
})
</script>

<style lang='less' scoped>
.portal-menu-table-edit-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portal-menu-table-edit-modal-theme {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    color: #FFFFFF;
    cursor: pointer;
}
</style>

<style>
.portal-menu-table-edit-modal-switch .ant-switch-inner {
    width: 38px;
}
</style>