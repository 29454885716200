<template>
  <div class="portal-system-job">
    <div class="portal-system-job-header">
      <div class="portal-system-job-header-title">
        {{ $t("systemInfo.job") }}
      </div>
      <span>
        <cgs-button ghost @click="addJobHandle()">
          <template #icon>
            <span
              class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-job-icon"
            ></span>
          </template>
          {{ $t("systemInfo.addJob") }}
        </cgs-button>
        <cgs-upload
          action="/api/app/worksheet/info/upload-excel"
          :show-upload-list="false"
          @change="importJobListHandle"
        >
          <cgs-button ghost>
            <template #icon>
              <span
                class="cgs-portal-iconfont cgs-portal-icon-daoru portal-system-job-icon"
              ></span>
            </template>
            {{ $t("common.import") }}
          </cgs-button>
        </cgs-upload>

        <cgs-button ghost @click="exportJobListHandle">
          <template #icon>
            <span
              class="cgs-portal-iconfont cgs-portal-icon-daochu1 portal-system-job-icon"
            ></span>
          </template>
          {{ $t("common.export") }}
        </cgs-button>
      </span>
    </div>
    <div class="portal-system-job-content-container">
      <div class="portal-system-job-list-container">
        <cgs-input v-model:value="state.searchInput" />
        <div>
          <div
            v-for="item in state.jobData.filter((item) =>
              item.name.includes(state.searchInput)
            )"
            :key="item.id"
            @click="selectJobItem(item)"
            :class="{
              'portal-system-job-list-container-item-select':
                state.selectJobItem && state.selectJobItem.id === item.id,
            }"
          >
            <span>{{ item.name }}</span>
            <span>
              <a-tooltip placement="top">
                <template #title>
                  <span>{{ $t("common.edit") }}</span>
                </template>
                <span
                  class="portal-system-job-title-icon-item cgs-portal-iconfont cgs-portal-icon-xiugai clickable-icon"
                  @click.stop="addJobHandle(item)"
                ></span>
              </a-tooltip>
              <a-tooltip placement="top">
                <template #title>
                  <span>{{ $t("common.delete") }}</span>
                </template>
                <span
                  class="portal-system-job-title-icon-item cgs-portal-iconfont cgs-portal-icon-shanchu clickable-icon"
                  @click.stop="deleteJobItem(item)"
                ></span>
              </a-tooltip>
            </span>
          </div>
        </div>
      </div>
      <div class="portal-system-job-content">
        <div class="portal-system-job-content-table" v-if="state.selectJobItem">
          <div class="portal-system-job-header-title">
            {{ state.selectJobItem.name }}
          </div>
          <div class="portal-system-job-content-table-button">
            <cgs-button @click="addUserToJobHandle" type="primary">
              {{ $t("systemInfo.userInfo.addUser") }}
            </cgs-button>
            <cgs-button
              ghost
              @click="moveOutHandle"
              :disabled="!state.selectedRowKeys.length"
            >
              {{ $t("common.moveOut") }}
            </cgs-button>
            <cgs-button
              ghost
              @click="exportUserHandle"
              :disabled="!state.selectedRowKeys.length"
            >
              {{ $t("common.export") }}
            </cgs-button>
          </div>
          <cgs-table
            mode="portal"
            size="small"
            :backgroundTransparent="true"
            emptyText="暂无用户"
            :row-selection="{
              selectedRowKeys: state.selectedRowKeys,
              onChange: selectRowHandle,
            }"
            rowKey="userId"
            :columns="columns"
            :data-source="
              state.jobUserList.filter((item) =>
                item.nickname.includes(state.searchUserInput)
              )
            "
            :pagination="{
              showQuickJumper: true,
              showSizeChanger: true,
              size: 'default',
              // position: ['bottomCenter']
            }"
          >
            <template #headerCell="{ column }">
              <template v-if="column.key === 'action'">
                <div class="portal-system-dept-table-action">
                  <cgs-input
                    style="width: 188px"
                    v-model:value="state.searchUserInput"
                    :placeholder="$t('remind.searchRemind')"
                  />
                </div>
              </template>
            </template>
          </cgs-table>
        </div>
        <a-empty
          v-else
          class="portal-system-job-content-empty"
          description="可以根据成员属性去创建职位，如，技术、生产、销售设置后应用和工作流可以直接选择职位"
        />
      </div>
    </div>
    <addJobModal
      :dialogVisible="state.dialogVisible"
      :dialogTitle="state.dialogTitle"
      :formData="state.jobItem"
      @cancel="cancelAddJobModal"
      @save="addJobModal"
    ></addJobModal>
    <addUsers
      v-model:visible="state.userVisible"
      v-model:selectUsers="state.selectUsers"
      @save="saveUserToJobHandle"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import {
  addJob,
  deleteJob,
  updateJob,
  getJobList,
  importJobList,
  getUserFormJob,
  addUserToJob,
  moveUserToJob,
  exportUser,
} from "@/api/tenant";
import addJobModal from "./components/addJob.vue";
import addUsers from "./components/addUsers.vue";

export default defineComponent({
  components: {
    addJobModal,
    addUsers,
  },
  props: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const columns = [
      {
        title: "姓名",
        dataIndex: "nickname",
      },
      {
        title: "部门",
        dataIndex: "deptName",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: 200,
      },
    ];
    const state = reactive({
      jobData: [],
      dialogVisible: false,
      dialogTitle: "",
      searchInput: "",
      searchUserInput: "",
      jobItem: undefined,
      selectedRowKeys: [],
      jobUserList: [],
      userVisible: false,
      selectUsers: [],
      selectJobItem: null,
    });
    const initJobList = () => {
      getJobList().then((res) => {
        if (res.success && res.data.length) {
          state.jobData = res.data;
        }
      });
    };
    initJobList();
    const addJobHandle = (jobItem) => {
      state.dialogTitle = jobItem ? "编辑职位" : "新建职位";
      state.dialogVisible = true;
      state.jobItem = jobItem;
    };
    const cancelAddJobModal = () => {
      state.dialogTitle = "";
      state.dialogVisible = false;
    };
    const addJobModal = (data) => {
      if (
        state.jobItem &&
        data.id === state.jobItem.id &&
        data.name === state.jobItem.name
      ) {
        cancelAddJobModal();
        return;
      }
      const params = data.id
        ? {
            promise: updateJob,
            sk: "modifySuccess",
            ek: "modifyFail",
          }
        : {
            promise: addJob,
            sk: "addSuccess",
            ek: "addFail",
          };
      params
        .promise(data)
        .then((res) => {
          if (res.success) {
            proxy.$message.success(t("common." + params.sk));
          } else {
            proxy.$message.error(t("common." + params.ek));
          }
          initJobList();
          cancelAddJobModal();
        })
        .catch((e) => {
          proxy.$message.error(t("common." + params.ek) + ":" + (e.msg || ""));
        });
    };

    const importJobListHandle = (res) => {
      const filePath = res?.file?.response?.data?.url;
      if (filePath) {
        importJobList({ filePath }).then((res) => {
          if (res.success) {
            proxy.$message.success(t("common.import") + t("common.success"));
            initJobList();
          }
        });
      }
    };
    const exportJobListHandle = () => {
      window.open("/api/job/export");
    };
    const deleteJobItem = (item) => {
      proxy.$confirm({
        title: t("common.remind"),
        content: t("remind.rermanentDeletionRemind"),
        async onOk() {
          deleteJob({
            id: item.id,
          }).then((res) => {
            if (res.code != 200) {
              proxy.$message.error(res.msg);
              return;
            }
            proxy.$message.success(t("common.deleteSuccess"));
            state.jobData.splice(state.jobData.indexOf(item), 1);
            if (state.selectJobItem && state.selectJobItem.id === item.id) {
              state.selectJobItem = undefined;
            }
          }).catch((e) => {
            proxy.$message.error(e.msg);
          });
        },
        onCancel() {},
      });
    };
    const getUserFormJobHandle = (jobId) => {
      getUserFormJob({ jobId }).then((res) => {
        if (res.success) {
          state.jobUserList = res.data;
        }
      });
    };
    const selectJobItem = (item) => {
      state.selectJobItem = item;
      state.searchUserInput = "";
      getUserFormJobHandle(item.id);
    };
    const addUserToJobHandle = () => {
      state.userVisible = true;
      state.selectUsers = state.jobUserList.map((item) => item.userId);
    };
    const saveUserToJobHandle = () => {
      addUserToJob({
        jobId: state.selectJobItem.id,
        userIds: state.selectUsers,
      }).then((res) => {
        if (res.success) {
          getUserFormJobHandle(state.selectJobItem.id);
        }
      });
    };
    const selectRowHandle = (keys) => {
      state.selectedRowKeys = keys;
    };
    const moveOutHandle = () => {
      moveUserToJob({
        jobId: state.selectJobItem.id,
        userIds: state.selectedRowKeys,
      }).then((res) => {
        if (res.success) {
          getUserFormJobHandle(state.selectJobItem.id);
          proxy.$message.success(t("common.moveOut") + t("common.success"));
        }
      });
    };
    const exportUserHandle = () => {
      exportUser({
        userIds: state.selectedRowKeys,
      }).then((res) => {
        let link = document.createElement("a");
        let blogw = new Blob([res]);
        let objectUrl = window.URL.createObjectURL(blogw); //创建一个新的url对象
        link.href = objectUrl;
        let file_name = "template.xlsx"; //文件命名，可以随便取
        link.download = file_name; //  下载的时候自定义的文件名
        link.click();
        window.URL.revokeObjectURL(objectUrl);
      });
    };
    return {
      columns,
      state,
      addJobHandle,
      cancelAddJobModal,
      addJobModal,
      importJobListHandle,
      exportJobListHandle,
      deleteJobItem,
      selectJobItem,
      addUserToJobHandle,
      saveUserToJobHandle,
      selectRowHandle,
      moveOutHandle,
      exportUserHandle,
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-system-job {
  width: 100%;
  height: 100%;
  padding: 24px 48px 8px 48px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .portal-system-job-header-title {
    .little-title;
    color: @font-color-1;
  }
  .portal-system-job-header {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > span {
      display: flex;
      > * {
        margin-left: 10px;
      }
    }
  }
  .portal-system-job-content-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    .portal-system-job-list-container {
      width: 300px;
      margin-right: 24px;
      > div {
        height: calc(100% - 45px);
        margin-top: 10px;
        overflow: auto;
        > div {
          line-height: 32px;
          display: flex;
          justify-content: space-between;
          padding: 0 5px;
          cursor: pointer;
          &:hover,
          &.portal-system-job-list-container-item-select {
            background: var(--cgs-selected-color-08);
          }
        }
      }
    }
  }
  .portal-system-job-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    .border();
    .portal-system-job-content-table {
      flex: 1;
      height: 0;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
      .portal-system-job-content-table-button {
        margin: 20px 0 10px 0;
        button {
          margin-right: 10px;
        }
      }
    }
    .portal-system-job-content-empty {
      margin: auto;
      max-width: 312px;
    }
  }
  .portal-system-job-icon {
    font-size: 10px;
    color: @font-color-1;
    margin-right: 5px;
  }

  .portal-system-job-title-icon-item {
    margin: 0px 5px;
    font-size: 12px;
  }
}
</style>
