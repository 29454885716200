<template>
  <div class="authority">
    <div class="authority-header">
      <span class=" authority-header-icon cgs-portal-iconfont cgs-portal-icon-fanhui" @click="back"></span>
      <span class="authority-header-title">{{ info.name }}</span>
    </div>

    <div class="portal-menu-authority-admin-role-content">
      <cgs-table :columns="showColumns" :data-source="dataSource" :pagination="false" mode="portal" size="small"
        :backgroundTransparent="true">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'operation'">
            <div class="portal-menu-authority-admin-role-operation">
              <cgs-button style="display: none;" ghost @click="deleteRoleUser">
                <template #icon>
                  <span class="cgs-portal-iconfont cgs-portal-icon-shanchu"></span>
                </template>
                <span class="portal-menu-authority-admin-role-button-text">{{ $t('auth.adminRole.PersonnelRemoval')
                  }}</span>
              </cgs-button>
              <cgs-button ghost @click="openSelectDepartmentModal">
                <template #icon>
                  <span class="cgs-portal-iconfont cgs-portal-icon-tianjia"></span>
                </template>
                <span class="portal-menu-authority-admin-role-button-text">{{ $t('auth.adminRole.Department')
                  }}</span>
              </cgs-button>
              <cgs-button style="margin-right: 10px;" ghost @click="openSelectUserModal">
                <template #icon>
                  <span class="cgs-portal-iconfont cgs-portal-icon-tianjia"></span>
                </template>
                <span class="portal-menu-authority-admin-role-button-text">{{ $t('auth.adminRole.AddPeople') }}</span>
              </cgs-button>
            </div>
          </template>
        </template>
        <template #bodyCell="{ column, record, text, index }">
          <template v-if="column.key === 'index'">
            <div class="portal-menu-authority-admin-role-checkbox">
              <cgs-checkbox style="display: none;" :checked="checkedList.includes(record.userId)"
                @change="onChangeCheckedList(record.userId)"></cgs-checkbox>
              <span class="portal-menu-authority-admin-role-index">{{ index + 1 }}</span>
            </div>
          </template>
          <template v-if="column.key === 'role'">
            <cgs-select ref="select" :value="record.roleAlias" style="width: 120px"
              @change="handleChange($event, record)">
              <cgs-select-option v-for="item in role_options" :key="item.id" :value="item.id">{{ item.roleAlias
                }}</cgs-select-option>
            </cgs-select>
          </template>
          <template v-if="column.key === 'type'">
            <span v-if="text === 'user'">{{ $t('auth.typeCollection.user') }}</span>
            <span v-else-if="text === 'department'">{{ $t('auth.typeCollection.department') }}</span>
          </template>
          <template v-if="column.key === 'operation'">
            <div class="portal-menu-authority-admin-edit">
              <span class="portal-menu-authority-admin-edit-title" @click="removeUser(record)">删除</span>
            </div>
          </template>
        </template>
      </cgs-table>
    </div>
    <!-- <div class="portal-menu-authority-admin-role-footer">
        <cgs-pagination v-model:current="state.pagination.current" v-model:pageSize="state.pagination.pageSize"
          show-quick-jumper show-size-changer :total="state.pagination.total" @change="onChangePagination" />
      </div> -->
    <select-user-modal v-model:visible="selectUserModalConfig.visible" :disabled-ids="[ownerUserId]"
      :default-selected-list="currentUserIdList" @selected="onSelectedUser"></select-user-modal>
    <select-department-modal v-model:visible="selectDepartmentModalConfig.visible"
      :default-selected-list="currentDepartmentIdList" @selected="onSelectedDepartment"></select-department-modal>
  </div>

</template>

<script setup>
import { onMounted, computed, ref, reactive, getCurrentInstance } from "vue"
import { useRouter } from "vue-router"
import { useI18n } from 'vue-i18n'
import selectUserModal from '@/components/select-user-modal.vue'
import selectDepartmentModal from '@/components/select-department-modal.vue'
import { getUserList, updateUser, addUser, deleteUser } from "@/api/workSpace"
import { getUserAuthority } from "@/utils/author"
import { getWorkSpace } from "@api/workSpace"

const columns = [{
  title: '序号/全选',
  dataIndex: 'appNum',
  key: 'index'
},
{
  title: '名称',
  dataIndex: 'nickName',
  key: 'name',
},
{
  title: '用户角色',
  dataIndex: 'roleAlias',
  key: 'role',
},
{
  title: '类型',
  dataIndex: 'type',
  key: 'type',
},
{
  title: '部门',
  dataIndex: 'department',
  key: 'department',
},
{
  title: '加入时间',
  dataIndex: 'addTime',
  key: 'addTime',
},
{
  title: '',
  dataIndex: 'operation',
  key: 'operation',
  width: '350px',
  fixed: 'right'
}
]
const role_options = [{
  roleAlias: "拥有者",
  roleName: "WorkspaceOwner",
  id: "WorkspaceOwner"
}, {
  roleAlias: "应用创建者",
  roleName: "WorkspaceCreator",
  id: "WorkspaceCreator"

}, {
  roleAlias: "应用用户",
  roleName: "WorkspaceUser",
  id: "WorkspaceUser"
}]
const router = useRouter()
const { t } = useI18n()
const { proxy } = getCurrentInstance()


const selectUserModalConfig = reactive({
  visible: false
})
const selectDepartmentModalConfig = reactive({
  visible: false
})
const currentUserIdList = ref([])
const info = ref({})
const dataSource = ref([])
const checkedList = ref([])
const authority = getUserAuthority()
const workSpaceId = computed(() => router.currentRoute.value.params.id)
const showColumns = computed(() => {
  return columns.map(_item => {
    _item.title = t('auth.adminRoleTalbe.' + _item.key)
    return _item
  })
})
// const showDataSource = computed(() => {
//   let _pagination = state.pagination
//   return dataSource.value.filter((item, index) => {
//     return index >= (_pagination.pageSize * (_pagination.current - 1)) && index < _pagination
//       .pageSize * _pagination.current
//   })
// })
onMounted(async () => {
  init()
  // _init()
})

const init = async () => {
  let _info = await getWorkSpace(workSpaceId.value)
  if (_info.code === 200) {
    info.value = _info.data
  }
  let res = await getUserList(workSpaceId.value)
  dataSource.value = res.data
  dataSource.value.forEach(item => {
    if (item.roleName === "WorkspaceOwner") {
      item.roleAlias = "拥有者"
    } else if (item.roleName === "WorkspaceCreator") {
      item.roleAlias = "应用创建者"
    } else if (item.roleName === "WorkspaceUser") {
      item.roleAlias = "应用用户"
    }
  })
}

const back = () => {
  router.go(-1)
}
// const _init = () => {
//   if (props.roleList.length > 0) {
//     state.roleConfig = props.roleList[0]
//   } else {
//     state.roleConfig = undefined
//   }
// }

const handleChange = async (e, item) => {
  let owner = dataSource.value.filter(val => val.roleName === "WorkspaceOwner")
  if (owner.length === 1 && owner[0].userId === item.userId) {
    proxy.$message.warning('拥有者最少剩余一个')
    return
  }
  let _item = role_options.find(_item => _item.roleName === e)
  item.roleAlias = _item.roleAlias
  item.roleName = _item.roleName
  let res = await updateUser({
    workspaceId: workSpaceId.value,
    userId: item.userId,
    roleName: item.roleName
  })
  if (res.code === 200) {
    proxy.$message.success('修改成功')
  } else {
    proxy.$message.error('修改失败')
  }
  init()
}

const onChangeCheckedList = (id) => {
  let _index = checkedList.value.findIndex(_item => _item === id)
  if (_index < 0) {
    checkedList.value.push(id)
  } else {
    checkedList.value.splice(_index, 1)
  }
}

const openSelectUserModal = () => {
  // currentUserIdList.value = dataSource.value.map(item => item.userId)
  selectUserModalConfig.visible = true
}
const openSelectDepartmentModal = () => {
  selectDepartmentModalConfig.visible = true
}
const onSelectedUser = async (userList) => {
  try {
    // userList = userList.sort((user1, user2) => {
    //   let _id1 = user1.userId
    //   let _id2 = user2.userId
    //   let _i1 = _id1 === ownerUserId.value ? 1 : 0
    //   let _i2 = _id2 === ownerUserId.value ? 1 : 0
    //   return _i2 - _i1
    // })
    let _userIdList = userList.map(_user => _user.userId)
    let params = {
      // departmentIds: currentDepartmentIdList.value,
      workspaceId: workSpaceId.value,
      userId: authority.userId,
      userIds: _userIdList,
      roleName: "WorkspaceUser"
    }
    let res = await addUser(params)
    console.log(res);
    init()
    // state.roleConfig.users = userList.map(_user => {
    //   return {
    //     avatar: "",
    //     isOwner: _user.userId === ownerUserId.value,
    //     nickName: _user.name,
    //     userId: _user.userId
    //   }
    // })
    // ctx.emit('update:roleList', [state.roleConfig])
    proxy.$message.success(t('auth.authInfo.renewSuccess'))
  } catch (err) {
    console.error(err)
    proxy.$message.error(t('auth.authInfo.renewFail'))
  }
}
const onSelectedDepartment = async (departmentList) => {
  try {
    let _departmentIdList = departmentList.map(_user => _user.id)
    let params = {
      deptIds: _departmentIdList,
      workspaceId: workSpaceId.value,
      userId: authority.userId,
      roleName: "WorkspaceUser"
    }
    let res = await addUser(params)
    console.log(res);
    init()
    proxy.$message.success(t('auth.authInfo.renewSuccess'))
  } catch (err) {
    console.error(err)
    proxy.$message.error(t('auth.authInfo.renewFail'))
  }
}

const removeUser = (record) => {
  console.log(record);
  try {
    if (record.roleName === "WorkspaceOwner") {
      let owner = dataSource.value.filter(val => val.roleName === "WorkspaceOwner")
      if (owner.length === 1) {
        proxy.$message.warning('拥有者最少剩余一个')
        return
      }
    }

    proxy.$confirm({
      title: t('common.remind'),
      content: t('auth.authInfo.deleteRemind'),
      async onOk () {
        // const ids = checkedList.value.map(_item => _item.id)
        let params = {
          workspaceId: workSpaceId.value,
          userId: record.userId
        }

        let res = await deleteUser(params)
        if (res.code === 200) {
          init()
          checkedList.value = []
          proxy.$message.success(t('auth.authInfo.renewSuccess'))
        } else {
          proxy.$message.error(res.msg)
        }

      },
      onCancel () { },
    });

  } catch (err) {
    console.error(err)
    proxy.$message.error(err.msg)
    proxy.$message.error(t('auth.authInfo.renewFail'))
  }
}

const deleteRoleUser = () => {
  try {
    if (checkedList.value.length === 0) return

    proxy.$confirm({
      title: t('common.remind'),
      content: t('auth.authInfo.deleteRemind'),
      async onOk () {

        const ids = checkedList.value.map(_item => _item.id)
        let params = {
          ids: ids,
          workspaceId: workSpaceId.value,
          userId: authority.userId
        }

        let res = await deleteUser(params)
        console.log(res);

        // let _departmentIdList = currentDepartmentIdList.value.filter(_item => !state.checkedList
        //   .includes(
        //     _item))
        // let _userIdList = currentUserIdList.value.filter(_item => !state.checkedList.includes(_item))
        // let params = {
        //   departmentIds: _departmentIdList,
        //   id: roleId.value,
        //   userIds: _userIdList
        // }
        // await _updateRoleUserAndDepartment(params)
        // state.roleConfig.departments = state.roleConfig.departments.filter(_item => _departmentIdList
        //   .includes(_item.id))
        // state.roleConfig.users = state.roleConfig.users.filter(_item => _userIdList.includes(_item
        //   .userId))
        // ctx.emit('update:roleList', [state.roleConfig])
        checkedList.value = []
        proxy.$message.success(t('auth.authInfo.renewSuccess'))
      },
      onCancel () { },
    });

  } catch (err) {
    console.error(err)
    proxy.$message.error(t('auth.authInfo.renewFail'))
  }
}
</script>

<style scoped lang="less">
@import '~@style/less/theme.less';

.authority {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.authority-header-title {
  margin-left: 10px;
  height: 40px;
  color: @font-color-1;
}

.portal-menu-authority-admin-role-content {
  flex: 1;
  height: 0;
  overflow-y: auto;
}

.portal-menu-authority-admin-role-button-text {
  margin-left: 4px;
}

.portal-menu-authority-admin-role-operation {
  display: flex;
  flex-direction: row-reverse;
  // justify-content: space-between;
}

.portal-menu-authority-admin-role-index {
  margin-left: 8px;
}

.portal-menu-authority-admin-edit {
  display: flex;
  flex-direction: row-reverse;
}

.portal-menu-authority-admin-edit-title {
  cursor: pointer;
}

.portal-menu-authority-admin-edit-title:hover {
  color: @primary-color;
}

.authority-header-icon {
  color: @font-color-1;
}
</style>