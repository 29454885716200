<!--
*@description 
*@author 杜超峰
*@date 2023/10/25 15:18:33
!-->

<template>
    <cgs-modal class="portal-menu-table-copy-path-modal" v-model:visible="vis" :title="$t('copyPathModal.scenePath')"
        @close="closeModal" style="width: 700px">
        <template #content>
            <cgs-spin :spinning="state.spinning">
                <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 2 }"
                    :wrapper-col="{ span: 22 }" labelAlign="left">
                    <cgs-form-item :label="$t('copyPathModal.url')" name="url">
                        <a-row type="flex" justify="space-between">
                            <a-col :span="19">
                                <cgs-input ref="urlInput" v-model:value="formState.url" disabled />
                            </a-col>
                            <a-col :span="4">
                                <cgs-button type='primary' :disabled="copyButtonDisabled" @click="copyUrl">
                                    {{$t('common.copy')}}</cgs-button>
                            </a-col>
                        </a-row>
                    </cgs-form-item>
                </cgs-form>
            </cgs-spin>
        </template>
        <template #footer>
            <div class="portal-menu-table-copy-path-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script setup>
    import {
        computed,
        defineEmits,
        defineProps,
        reactive,
        watch,
        ref,
        getCurrentInstance
    } from 'vue'
    import {
        getSceneDetail
    } from '@api/scene'


    let {
        proxy
    } = getCurrentInstance()

    const props = defineProps({
        visible: {
            type: Boolean,
            require: true,
            default: false
        },
        scene: {
            type: Object
        },
        sceneType: {
            type: String,
            require: true,
            default: 'Static'
        }
    })

    const emits = defineEmits(['update:visible', 'close'])

    const urlInput = ref(null)

    let vis = computed({
        get: () => {
            return props.visible
        },
        set: (val) => {
            emits('update:visible', val)
        }
    })
    const copyButtonDisabled = computed({
        get: () => {
            return formState.url === ''
        },
        set: () => {}
    })
    watch(() => props.scene, async (newValue) => {
        if (props.scene) {
            state.spinning = true
            try {
                let _result = await getSceneDetail({
                    ID: newValue.id,
                    VisualType: props.sceneType
                })
                if (_result.code === 200) {
                    let config = _result.data.config;
                    let url = `${window.location.origin}/file/${config.sceneUrl}`
                    formState.url = url
                }
            } catch (err) {
                console.error(err)
            }

            state.spinning = false
        }
    })
    const state = reactive({
        spinning: false
    })
    const formState = reactive({
        url: ''
    })
    const close = () => {
        emits('close')
        vis.value = false
    }
    const closeModal = () => {
        close()
    }
    const copyUrl = () => {
        let _dom = urlInput.value.$el;
        let range = document.createRange();
        range.selectNode(_dom);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        proxy.$message.success("复制成功")
    }
</script>

<style lang='less' scoped>

</style>