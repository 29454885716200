import router from './index'
import {
    getToken,
    getEntry,
    deleteEntry,
    getConfig,
    setConfig,
    getFastLogin,
    setEntry,
    getOriginPath,
    deleteOriginPath
} from '@/utils/author'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css'
import {
    getConfigApi
} from "@/api/user";

// NProgress.configure({showSpinner: false});
router.beforeEach((to, from, next) => {

    let pageAuths = JSON.parse(localStorage.getItem('UserAuthority') || "{}").pageAuths
    let fastLogin = getFastLogin()
    if (pageAuths?.length && fastLogin?.pageUrl) {
        setEntry({
            type: 'self'
        })
        window.history.replaceState(null, "", fastLogin.pageUrl);
        window.history.go(0);
        return
    }

    const originPath = getOriginPath()
    if (originPath) {
        deleteOriginPath()
    }

    const meta = to.meta || {};
    // const isMenu = meta.menu === undefined ? to.query.menu : meta.menu;
    // store.commit('SET_IS_MENU', isMenu === undefined);
    let entry = getEntry()
    let entryType = typeof (entry) === 'object' && entry?.type ? entry.type : 'self'
    if (entryType === 'self') {
        if (getToken()) {
            if (to.path === '/login' || to.path === '/init') { //如果登录成功访问登录页跳转到主页
                next({
                    path: '/menu'
                })
            } else {
                //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
                //if no token, then next to login
                //else to home
                next()
            }
        } else {
            //判断是否需要认证，没有登录访问去登录页
            if (meta.requiresAuth === false) {
                if (to.path === '/init') {
                    resolvePath(to, next)
                } else {
                    next()
                    // resolvePath(to, next)
                }
            } else {
                resolvePath(to, next)
            }
        }
    } else {
        if (to.path === '/login' || to.path === '/init' || to.path === '/menu') {
            deleteEntry()
            window.opener.parent.postMessage('expired', entry.path)
            window.close()
        } else {
            next()
        }
    }

})

router.afterEach(() => {
    // NProgress.done();
    // let title = store.getters.tag.label;
    // let i18n = store.getters.tag.meta.i18n;
    // title = router.$avueRouter.generateTitle(title, i18n)
    // //根据当前的标签也获取label的值动态设置浏览器标题
    // router.$avueRouter.setTitle(title);
});

router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if (isChunkLoadFailed) {
        window.location.reload();
        // router.replace(router.history.pending.fullPath);
    } else {
        console.log(error)
    }
});

function resolvePath (to, next) {
    let config = getConfig();
    if (!config) {
        getConfigApi().then((res) => {
            if (res && res.success) {
                setConfig(res.data)
                let config1 = res.data;
                if (!config1 || !config1.initialized) {
                    toInit(to, next)
                } else {
                    next('/login')
                }
            } else {
                toInit(to, next)
            }
        })
    } else {
        if (!config.initialized) {
            toInit(to, next)
        } else {
            next('/login')
        }
    }
}

function toInit (to, next) {
    to.path === '/init' ? next() : next('/init')
}