<template>
  <cgs-modal
    :visible="dialogVisible"
    :title="dialogTitle"
    @close="$emit('cancel')"
    :footer="null"
  >
    <template #content>
      <a-form
        ref="formRef"
        :labelCol="{ span: 4, offset: 0 }"
        :model="formState"
        @finish="$emit('save', formState)"
      >
        <a-form-item
          :label="$t('systemInfo.jobName')"
          name="name"
          :rules="[
            { required: true, message: '请输入职位名称' },
            {
              max: 10,
              message: '职位最多十个字符',
            },
          ]"
        >
          <cgs-input v-model:value="formState.name" :maxlength="10">
            <template #suffix>
              <span style="color: rgba(0, 0, 0, 0.3)"
                >{{ formState.name.length }}/10</span
              >
            </template>
          </cgs-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <cgs-button
            @click="$emit('cancel')"
            class="portal-system-user-addUser-button"
            >{{ $t("common.cancel") }}
          </cgs-button>
          <cgs-button
            type="primary"
            html-type="submit"
            class="portal-system-user-addUser-button"
            >{{ $t("common.ok") }}
          </cgs-button>
        </a-form-item>
      </a-form>
    </template>
  </cgs-modal>
</template>

<script>
import { defineComponent, reactive, ref, watch } from "vue";

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const formState = reactive({
      id: "",
      name: "",
    });
    const formRef = ref();
    watch(
      () => props.dialogVisible,
      () => {
        if (props.dialogVisible === true) {
          formState.name = props.formData.name || "";
          formState.id = props.formData.id;
        } else if (formRef.value) {
          formRef.value.clearValidate();
        }
      }
    );
    return {
      formState,
      formRef,
    };
  },
});
</script>

<style lang="less" scoped>
.portal-system-user-addUser-button {
  margin: 0px 15px;
}
</style>
