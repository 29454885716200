<!--
*@description 
*@author 杜超峰
*@date 2023/04/26 16:03:48
!-->

<template>
    <cgs-modal class="portal-menu-scene-type-manager-modal" v-model:visible="vis" :title="$t('appTypeManagement')" @close="closeModal">
        <template #content>
            <div class="portal-menu-scene-type-manager-modal-content">
                <div class="portal-menu-scene-type-manager-modal-content-header">
                    <span>{{ $t('appType') }}</span>
                </div>
                <div class="portal-menu-scene-type-manager-modal-content-main">
                    <div class="portal-menu-scene-type-manager-modal-item" v-for="item in state.settingList"
                        :key="item.key" @click="changeCheckedTypeId(item.key)">
                        <div class="portal-menu-scene-type-manager-modal-item-content">
                            <span class="portal-menu-scene-type-manager-modal-item-icon" :class="item.icon"></span>
                            <span>{{item.originName}}</span>
                        </div>
                        <div class="portal-menu-scene-type-manager-modal-item-checkbox">
                            <cgs-checkbox :checked="state.checkedTypeIds.includes(item.key)"></cgs-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="portal-menu-scene-type-manager-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
    import {
        computed,
        defineComponent,
        onMounted,
        reactive,
        getCurrentInstance,
        watch
    } from 'vue'
    import {
        getSceneTypeSetting,
        updateSceneTypeSetting
    } from '@api/scene'
    import {
        useI18n
    } from 'vue-i18n'

    export default defineComponent({
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            currentSceneTypeList: {
                type: Array,
                default: () => []
            }
        },

        setup(props, ctx) {
            let {
                proxy
            } = getCurrentInstance();
            const {
                t
            } = useI18n()
            const state = reactive({
                settingList: [],
                checkedTypeIds: []
            })
            let vis = computed({
                get: () => {
                    return props.visible
                },
                set: (val) => {
                    ctx.emit('update:visible', val)
                }
            })
            onMounted(() => {
                _initSettingList()
                _initCheckedTypeId()
            })
            watch(() => props.visible, (newVal) => {
                if(newVal) _initCheckedTypeId()
            })
            watch(() => props.currentSceneTypeList, () => {
                _initCheckedTypeId()
                _initSettingList()
            })
            const _initSettingList = async () => {
                try {
                    let _settingResult = await getSceneTypeSetting()
                    if (_settingResult.code === 200) {
                        state.settingList = _settingResult.data.map(_item => {
                            return {
                                key: _item.typeId,
                                label: _item.typeName,
                                originName: _item.originName,
                                icon: _item.icon,
                                type: 'sceneType'
                            }
                        })
                    }
                } catch (err) {
                    console.error(err)
                }
            }
            const _initCheckedTypeId = () => {
                state.checkedTypeIds = props.currentSceneTypeList.map(_item => _item.key)
            }
            const close = () => {
                ctx.emit('close')
                vis.value = false
            }
            const closeModal = () => {
                close()
            }
            const changeCheckedTypeId = (id) => {
                let _index = state.checkedTypeIds.findIndex(_item => _item === id)
                if (_index >= 0) {
                    state.checkedTypeIds.splice(_index, 1)
                } else {
                    state.checkedTypeIds.push(id);
                }
            }
            const ok = async () => {
                try {
                    proxy.$loading.open()
                    let settings = state.settingList.map(_item => {
                        let typeId = _item.key
                        let show = state.checkedTypeIds.includes(typeId)
                        return {
                            typeId,
                            show
                        }
                    })
                    let newSceneTypeSettingList = state.settingList.filter(_item => {
                        return state.checkedTypeIds.includes(_item.key)
                    })
                    let params = {
                        settings
                    }
                    let _result = await updateSceneTypeSetting(params)
                    if (_result.code === 200) {
                        ctx.emit('updateSceneTypeSetting', JSON.parse(JSON.stringify(newSceneTypeSettingList)))
                        proxy.$message.success(t('common.success'))
                        close()
                    } else {
                        proxy.$message.error(t('common.fail'))
                    }
                    proxy.$loading.close()
                } catch (err) {
                    console.error(err)
                    proxy.$loading.close()
                }
            }
            return {
                state,
                vis,
                closeModal,
                changeCheckedTypeId,
                ok
            }
        },
    })
</script>

<style lang='less' scoped>
    @import "~@style/less/theme.less";

    .portal-menu-scene-type-manager-modal-content-main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;
    }

    .portal-menu-scene-type-manager-modal-item {
        width: 135px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        margin: 8px 10px;
        background: var(--cgs-selected-color);
        border-radius: 5px;
        cursor: pointer;
        justify-content: space-between;
    }

    .portal-menu-scene-type-manager-modal-item-content {
        color: @font-color-1;
    }

    .portal-menu-scene-type-manager-modal-content-header {
        color: @font-color-1;
    }

    .portal-menu-scene-type-manager-modal-item-icon {
        margin-right: 4px;
    }

    .portal-menu-scene-type-manager-modal-footer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>