<template>
    <div class="portal-system-tenant-recharge">
        <cgs-modal :visible="dialogVisiblerecharge" :title="`当前用户余额为：${userBalance}`" @close="$emit('cancelRecharge')" :footer="null">
            <template #content>
                <cgs-form
                :model="formState"
                @finish="(values) => {$emit('rechargeOK',values)}"
                >
                    <cgs-form-item
                    label="请输入要充值的额度"
                    name="rechargeBalance"
                    :rules="[{ required: true, message: '请输入' }]">
                        <cgs-input v-model:value="formState.rechargeBalance" />
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelRecharge')" class="portal-system-tenant-recharge-button">取 消</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-system-tenant-recharge-button">确 定</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
} from 'vue'

export default defineComponent({
    props: {
        dialogVisiblerecharge:{
            type: Boolean,
            default: false,
        },
        userBalance: {
            type: String,
            default: '',
        }
    },
    setup() {
        const formState = reactive({
            rechargeBalance:'',
        })
        return {
            formState,
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-tenant-recharge-button{
    margin: 0px 15px;
}
</style>