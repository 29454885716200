<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:06:54
!-->

<template>
    <div class="portal-public-view-icon">
        <div class="portal-public-view-icon-wrapper">
            <main-icon-card v-for="item in sceneList" :key="item.id" :ident="item.id" :title="item.title"
                :publish="item.status === 0 ? false : true" :icon="item.icon" :theme-name="item.theme"
                :editable="hasEditAuth(item)" :more-menu-list="currentMoreMenuList(item)"
                @clikMenuItem="clikMenuItemHandler(item, $event)" @clickEdit="onClickHandler(item)"></main-icon-card>
        </div>
    </div>
</template>

<script>
import {
    defineComponent
} from 'vue'
import mainIconCard from '@components/main-icon-card.vue'

export default defineComponent({
    components: {
        mainIconCard
    },
    props: {
        sceneList: {
            type: Array,
            default: () => []
        },
        moreMenuList: {
            type: Array,
            default: () => []
        }
    },

    setup (props, ctx) {
        const clikMenuItemHandler = (scene, params) => {
            ctx.emit('clikMenuItem', scene, params)
        }
        const onClickHandler = (scene) => {
            hasEditAuth(scene) && ctx.emit('enter', scene)
        }
        const hasEditAuth = (scene) => {
            return window.authManager.editSceneAuth(scene.id)
        }
        const currentMoreMenuList = (scene) => {
            return props.moreMenuList.filter(_item => {
                let _bool = true
                let _disabled = _item.disabled
                if (_disabled instanceof Function) {
                    _bool = !_disabled(scene.id)
                } else {
                    _bool = !_disabled
                }
                return _bool
            })
        }
        return {
            clikMenuItemHandler,
            onClickHandler,
            currentMoreMenuList,
            hasEditAuth
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-public-view-icon {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.portal-public-view-icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.portal-public-view-icon-card {
    //margin: 5px;
    width: 24.2%;
    margin: 0.4%;
    border-radius: 6px;
    .border();
    background: @card-color;
    overflow: hidden;

    @media screen and (min-width: 1800px) {
        width: 19.2%;
        margin: 0.4%;
    }
}

.portal-public-view-icon-card-cover {
    position: relative;
    overflow: hidden;
}

.portal-public-view-icon-card-cover:hover {
    // filter: blur(3px)
}

.portal-public-view-icon-card-info {
    width: 100%;
    padding: 10px;
    .text-1();
    color: @font-color-1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>