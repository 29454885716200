<template>
    <div class="portal-system-tenant-addTenant">
        <cgs-modal :visible="dialogVisible" :title="submitState.state ==='add'? '添加角色' : '修改角色'" @close="$emit('cancelAddTenantModal')" :footer="null">
            <template #content>
                <cgs-form
                :labelCol="{span:4, offset: 0}"
                    :model="formState"
                    @finish="() => {$emit('formRePassSubmit',formState)}"
                >
                    <cgs-form-item
                    label="租户名称："
                    name="tenantName"
                    :rules="[{ required: true, message: '请输入租户名称' }]"
                    >
                        <cgs-input v-model:value="formState.tenantName" />
                    </cgs-form-item>
                    <cgs-form-item
                    v-if="submitState.state === 'add'"
                    label="账号："
                    name="tenantUserName"
                    :rules="[{ required: true, message: '请输入账号' }]"
                    >
                        <cgs-input v-model:value="formState.tenantUserName" />
                    </cgs-form-item>
                    <cgs-form-item
                    v-if="submitState.state === 'add'"
                    label="密码："
                    name="tenantPassword"
                    :rules="[{ required: true, message: '请输入密码' }]"
                    >
                        <cgs-input v-model:value="formState.tenantPassword" />
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddTenantModal')" class="portal-system-tenant-addTenant-button">取 消</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-system-tenant-addTenant-button">确 定</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    watch
} from 'vue'
export default defineComponent({
    props: {
        dialogVisible:{
            type: Boolean,
            default: false,
        },
        submitState: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        const formState = reactive({
            roleName:'',
            checkedList:[],
            indeterminate: false,
            checkAll: false,
        })
        watch(() => props.dialogVisible, () => {
            if (props.submitState.state === 'updata' && props.dialogVisible === true) {
                let data = props.submitState.scene
                formState.tenantId = data.tenantId
                formState.tenantName = data.tenantName
            }else if(props.submitState.state === 'add' && props.dialogVisible === true){
                formState.tenantName = ''
                formState.tenantUserName = ''
                formState.tenantPassword = ''
            }
        })
        return {
            formState,
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-tenant-addTenant-button{
    margin: 0px 15px;
}
.portal-system-tenant-addTenant-checkbox{
    margin-top: 6px;
    margin-bottom: 15px;
}
</style>