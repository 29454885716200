import {
 post,
 get
} from "@/axios/http.js"
import qs from 'qs'

// 添加工作空间
export const addWorkSpace = (data) => post("/api/workspace/save", data)

// 获取工作空间列表
export const getWorkSpaceList = (params) => get(`/api/workspace/list?${qs.stringify(params)}`)

// 获取收藏列表
export const getCollectList = () => get("/api/workbench/collect/list")

// 添加收藏
export const addFavorite = (data) => post("/api/workbench/collect", data)

// 获取单个工作空间
export const getWorkSpace = (id) => get("/api/workspace/get" + "?id=" + id)

// 更新工作空间
export const updateWorkSpace = (data) => post("/api/workspace/update", data)

// 删除工作空间
export const deleteWorkSpace = (data) => post("/api/workspace/delete" + "?id=" + data)

// 获取成员列表
export const getUserList = (id) => get("/api/role/listUser" + "?workspaceId=" + id)

// 修改成员
export const updateUser = (data) => post("/api/role/updateUser", data)

// 添加成员或部门
export const addUser = (data) => post("/api/role/addUser", data)

// 删除成员或部门
export const deleteUser = (data) => post("/api/role/deleteUser", data)


// 获取流程信息
export const getTaskTodolist = (type) => get(`/api/automations/task/todoList?type=${type}`)

// 获取未读消息
export const getTaskCount = () => get("/api/automations/task/count")


export const getTaskDetail = (id) => get("/api/automations/task/detail" + "?id=" + id)


export const updateWorkflowTask = (data) => post("/api/automations/task/update", data)

export const getTaskHistory = (nodeId, autoInstanceId) => get(`/api/automations/instance/history?nodeId=${nodeId}` + `&autoInstanceId=${autoInstanceId}`)

// 添加最近使用
export const addRecentUsed = (data) => post("/api/workbench/recent", data)

// 最近使用列表
export const getRecentUsedList = (params) => get(`/api/workbench/recent/list?${qs.stringify(params)}`)

// 收藏排序
export const updateCollectSort = (data) => post("/api/workbench/collect/sort", data)


// 工作台配置
export const getWorkbenchConfig = () => get("/api/workbench/getConfig")

// 修改配置工作台
export const updateWorkbenchConfig = (data) => post("/api/workbench/updateConfig", data)

export const exportSpace = (data) => post("/api/workspace/export", data)


export const importSpace = (data) => post("/api/workspace/import", data, { headers: { "Content-Type": "multipart/form-data" } })