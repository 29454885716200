<template>
    <div class="portal-menu-table">
        <div class="portal-menu-table-wrapper">
            <div class="portal-menu-table-header">
                <cgs-tabs v-model:activeKey="state.sceneTypeActiveKey" mode="button" @change="onChangeSceneType">
                    <cgs-tab-pane v-for="item in currentSceneTypeList" :key="item.categoryKey">
                        <template #tab>
                            <span>{{ item.categoryKey }}</span>
                        </template>
                    </cgs-tab-pane>

                    <template #rightExtra>
                        <div class="portal-menu-table-header-right">
                            <cgs-button shape="circle" :ghost="state.showType !== 'card'"
                                @click="switchShowType('card')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-kapian"></span>
                                </template>
                            </cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'table'"
                                @click="switchShowType('table')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-liebiao"></span>
                                </template>
                            </cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'icon'"
                                @click="switchShowType('icon')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-tubiaopailie"></span>
                                </template>
                            </cgs-button>
                        </div>
                    </template>
                </cgs-tabs>
                <cgs-tabs v-model:activeKey="state.categoryActiveKey" @change="onChangeCategory">
                    <cgs-tab-pane v-for="item in currentCategoryList" :key="item.categoryKey">
                        <template #tab>
                            <span>{{ item.categoryKey }}</span>
                        </template>
                    </cgs-tab-pane>
                </cgs-tabs>
            </div>
            <div class="portal-menu-table-content">
                <div class="portal-menu-table-content-wrapper">
                    <div class="portal-menu-table-content-main">
                        <view-card :scene-list="currentSceneList" :more-menu-list="moreMenuList"
                            v-if="state.showType === 'card'" @clikMenuItem="clikSceneMenuItem" @enter="enterScene">
                        </view-card>
                        <view-table :scene-list="currentSceneList" :more-menu-list="moreMenuList"
                            v-else-if="state.showType === 'table'" @clikMenuItem="clikSceneMenuItem"
                            @enter="enterScene">
                        </view-table>
                        <view-icon :scene-list="currentSceneList" :more-menu-list="moreMenuList"
                            v-else-if="state.showType === 'icon'" @clikMenuItem="clikSceneMenuItem" @enter="enterScene">
                        </view-icon>
                    </div>
                    <div class="portal-menu-table-content-footer">
                        <cgs-pagination v-model:current="state.currentPagination.current"
                            v-model:pageSize="state.pageSize" show-quick-jumper show-size-changer
                            :page-size-options="pageSizeOptions" :total="state.currentPagination.total"
                            @change="onChangePagination" />
                    </div>
                </div>
            </div>
        </div>
        <edit-modal v-model:visible="state.editModalConfig.visible" :scene="state.editModalConfig.scene"
            :category-options="state.categoryList" :scene-type="state.sceneTypeActiveKey" @close="closeEditModal"
            @success="onEditSuccess">
        </edit-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    getCurrentInstance,
    computed,
    watch
} from 'vue'
import {
    useRouter
} from 'vue-router'
import viewCard from "@components/view-card.vue"
import viewTable from "@components/view-table.vue"
import viewIcon from "@components/view-icon.vue"
import editModal from "./edit-modal.vue"

import {
    getOrganization,
    getCategory
} from '@api/scene'


export default defineComponent({
    components: {
        viewCard,
        viewTable,
        viewIcon,
        editModal
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance()
        let router = useRouter()
        const sceneType = 'App'
        const showTypeMap = {
            icon: {
                pageSize: 50
            },
            table: {
                pageSize: 20
            },
            card: {
                pageSize: 20
            },
        }
        const pageSizeOptions = ['10', '20', '30', '40', '50']
        const state = reactive({
            sceneTypeActiveKey: '',
            categoryActiveKey: '',
            showType: 'icon',
            organizationList: [],
            categoryList: [],
            currentPagination: {
                current: 1,
                total: -1
            },
            pageSize: 20,
            editModalConfig: {
                visible: false,
                scene: undefined
            },
            addModalConfig: {
                visible: false
            }

        })
        let moreMenuList = [{
            title: '权限',
            key: 'auth',
            icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
            disabled: (sceneId) => {
                return !window.authManager.manageAuthSceneAuth(sceneId)
            }
        },
        {
            title: '修改',
            key: 'edit',
            icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
            disabled: (sceneId) => {
                return !window.authManager.editSceneAuth(sceneId)
            }
        },
        {
            title: '预览',
            key: 'preview',
            icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
            disabled: true
        },
        {
            title: '导出',
            key: 'export',
            icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
            disabled: (sceneId) => {
                return !window.authManager.exportSceneAuth(sceneId)
            }
        },
        {
            title: '删除',
            key: 'delete',
            icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
            disabled: true
        }
        ]
        onMounted(async () => {
            state.pageSize = showTypeMap[state.showType].pageSize
            _init()
        })
        const organizationId = computed(() => {
            return router.currentRoute.value.params.id
        })
        const currentSceneTypeList = computed(() => {
            let _list = []
            let _org = state.organizationList.find(_item => _item.id === organizationId.value)
            if (_org) {
                _list = _org.children
            }
            return _list
        })
        watch(currentSceneTypeList, () => {
            let index = currentSceneTypeList.value.find(item => item.categoryKey === state.sceneTypeActiveKey)
            if (currentSceneTypeList.value.length > 0 && !index) {
                state.sceneTypeActiveKey = currentSceneTypeList.value[0].categoryKey
            }
        })
        const currentCategoryList = computed(() => {
            let _list = []
            let _scene = currentSceneTypeList.value.find(_item => _item.categoryKey === state
                .sceneTypeActiveKey)
            if (_scene) {
                _list = _scene.children
            }
            return _list
        })
        const currentSceneList = computed(() => {
            let _list = []
            let _category = currentCategoryList.value.find(_item => _item.categoryKey === state
                .categoryActiveKey)
            if (_category) {
                _list = _category.visualList
            }
            return _list
        })
        const _init = async () => {
            try {
                let _result = await getOrganization()
                if (_result.code === 200) {
                    state.organizationList = _result.data
                    _setDefaultSceneTypeActiveKey()
                    _setDefaultCategoryActiveKey()
                }
            } catch (err) {
                console.error(err)
            }
        }
        const _setDefaultSceneTypeActiveKey = async () => {
            if (currentSceneTypeList.value.length > 0) {
                state.sceneTypeActiveKey = currentSceneTypeList.value[0].categoryKey
                let _categoryResult = await getCategory(state.sceneTypeActiveKey)
                if (_categoryResult.code === 200) {
                    state.categoryList = _categoryResult.data
                    state.categoryList.sort(function (a, b) {
                        return Number(a.categoryValue) - Number(b.categoryValue)
                    });
                }
            }
        }
        const _setDefaultCategoryActiveKey = () => {
            if (currentCategoryList.value.length > 0) {
                state.categoryActiveKey = currentCategoryList.value[0].categoryKey
            }
        }
        const switchShowType = (type) => {
            if (state.showType === type) return
            state.showType = type
            state.pageSize = showTypeMap[type].pageSize
        }
        const clikSceneMenuItem = (scene, menuItemParams) => {
            if (menuItemParams.key === 'edit') {
                state.editModalConfig = {
                    scene: scene,
                    visible: true
                }
            } else if (menuItemParams.key === 'export') { //差异化
                proxy.$confirm({
                    title: '提示',
                    content: '确定导出?',
                    onOk () {
                        let url = "/api/app/export?id=";
                        window.open(url + scene.id);
                    },
                    onCancel () { },
                });
            } else if (menuItemParams.key === 'auth') {
                router.push({
                    path: `/menu/authority/${sceneType}/${scene.id}`
                })
            }
        }
        const onChangeSceneType = async () => {
            _setDefaultCategoryActiveKey()
            let _categoryResult = await getCategory(state.sceneTypeActiveKey)
            if (_categoryResult.code === 200) {
                state.categoryList = _categoryResult.data
                state.categoryList.sort(function (a, b) {
                    return Number(a.categoryValue) - Number(b.categoryValue)
                });
            }
        }
        const onChangeCategory = () => {
            state.currentPagination = {
                current: 1,
                total: -1
            }
        }
        const onChangePagination = () => { }
        const closeEditModal = () => {
            state.editModalConfig.visible = false
        }
        const onEditSuccess = async () => {
            try {
                let _result = await getOrganization()
                if (_result.code === 200) {
                    state.organizationList = _result.data
                }
                if (!currentCategoryList.value.find(item => item.categoryKey === state.categoryActiveKey)) {
                    state.categoryActiveKey = currentCategoryList.value[0].categoryKey
                }
            } catch (err) {
                console.error(err)
            }
        }
        const enterScene = (scene) => { //差异化
            let url = '/table/' + scene.id
            window.open(url, '_self');
        }
        const openAddSceneModal = () => {
            state.addModalConfig.visible = true
        }
        return {
            sceneType,
            pageSizeOptions,
            state,
            organizationId,
            currentSceneTypeList,
            currentCategoryList,
            currentSceneList,
            switchShowType,
            moreMenuList,
            clikSceneMenuItem,
            onChangeSceneType,
            onChangeCategory,
            onChangePagination,
            closeEditModal,
            onEditSuccess,
            enterScene,
            openAddSceneModal
        };
    }
})
</script>

<style lang="less" scoped>
.portal-menu-table {
    width: 100%;
    height: 100%;
}

.portal-menu-table-wrapper {
    width: 100%;
    height: 100%;
    padding: 8px 48px 8px 48px;
    display: flex;
    flex-direction: column;
}

.portal-menu-table-header {
    width: 100%;
    margin-top: 16px;
}

.portal-menu-table-header-right {
    display: flex;
}

.portal-menu-table-header-right>button {
    margin-left: 5px;
}

.portal-menu-table-content {
    flex: 1;
    height: 0;
}

.portal-menu-table-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.portal-menu-table-content-main {
    flex: 1;
    height: 0;
}

.portal-menu-table-content-footer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>