<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 19:21:10
!-->

<template>
    <cgs-modal class="app-portal-menu-authority-role-role-modal" v-model:visible="vis" :title="title"
        @close="closeModal" style="width: 1200px">
        <template #content>
            <div class="app-portal-menu-authority-role-role-modal-content">
                <div class="app-portal-menu-authority-role-role-modal-content-wrapper">
                    <div class="app-portal-menu-authority-role-role-modal-name">
                        <cgs-form ref="formRef" :model="state" style="width: 100%;" :label-col="{ span: 2, }"
                            autocomplete="off" labelAlign="left">
                            <cgs-form-item :label="$t('auth.appRoleAuthorityModal.name')" name="roleName"
                                :rules="[{ required: true, message: '请输入角色名称' }]">
                                <cgs-input v-model:value="state.roleName" />
                            </cgs-form-item>
                            <cgs-form-item :label="$t('auth.appRoleAuthorityModal.desc')">
                                <cgs-input v-model:value="state.desc"></cgs-input>
                            </cgs-form-item>
                        </cgs-form>
                        <!-- <div class="app-portal-menu-authority-role-role-modal-label" style="color: red;">
                            <span>*</span>
                            <span>{{ $t('auth.appRoleAuthorityModal.name') }}</span>
                        </div>
                        <div class="app-portal-menu-authority-role-role-modal-value">
                            <cgs-input v-model:value="state.roleName"></cgs-input>
                        </div> -->
                    </div>
                    <!-- <div class="app-portal-menu-authority-role-role-modal-desc">
                        <div class="app-portal-menu-authority-role-role-modal-label">
                            <span>{{ $t('auth.appRoleAuthorityModal.desc') }}</span>
                        </div>
                        <div class="app-portal-menu-authority-role-role-modal-value">
                            <cgs-input v-model:value="state.desc"></cgs-input>
                        </div>
                    </div> -->
                    <div class="app-portal-menu-authority-role-role-modal-detail">
                        <div
                            class="app-portal-menu-authority-role-role-modal-label app-portal-menu-authority-role-role-modal-label-title">
                            <span>{{ $t('auth.appRoleAuthorityModal.permissions') }}</span>
                        </div>
                        <div class="app-portal-menu-authority-role-role-modal-detail-content">
                            <div class="app-portal-menu-authority-role-role-modal-detail-item">
                                <div class="app-portal-menu-authority-role-role-modal-detail-label">
                                    <span>{{ $t('auth.appRoleAuthorityModal.gateway') }}</span>
                                </div>
                                <div class="app-portal-menu-authority-role-role-modal-detail-value">
                                    <div class="app-portal-menu-authority-role-role-modal-detail-all-checkbox">
                                        <cgs-checkbox v-model:checked="state.sceneAuthConfig.portal.checkAll"
                                            :indeterminate="state.sceneAuthConfig.portal.indeterminate"
                                            @change="onCheckAll('portal', $event)">
                                            {{ $t('common.Select') }}
                                        </cgs-checkbox>
                                    </div>
                                    <div class="app-portal-menu-authority-role-role-modal-detail-checkbox-group">
                                        <cgs-checkbox-group v-model:value="state.sceneAuthConfig.portal.value"
                                            :options="state.sceneAuthConfig.portal.options"
                                            @change="onChangeCheckGroup('portal')">
                                        </cgs-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div class="app-portal-menu-authority-role-role-modal-detail-item">
                                <div class="app-portal-menu-authority-role-role-modal-detail-label">
                                    <span>{{ $t('auth.appRoleAuthorityModal.formDesign') }}</span>
                                </div>
                                <div class="app-portal-menu-authority-role-role-modal-detail-value">
                                    <div class="app-portal-menu-authority-role-role-modal-form-mode">
                                        <cgs-radio-group v-model:value="state.viewAuthConfig.type"
                                            :options="state.viewAuthConfig.options" />
                                    </div>
                                    <div class="app-portal-menu-authority-role-role-modal-form-mode-detail">
                                        <div class="app-portal-menu-authority-role-role-modal-form-mode-all"
                                            v-if="state.viewAuthConfig.type === 0">
                                            <cgs-select v-model:value="state.viewAuthConfig.allViewAuthConfig.value"
                                                :options="state.viewAuthConfig.allViewAuthConfig.options"
                                                style="width: 100%"></cgs-select>
                                        </div>
                                        <div class="app-portal-menu-authority-role-role-modal-form-mode-selectable"
                                            v-else style="margin-top: 10px">
                                            <div
                                                class="app-portal-menu-authority-role-role-modal-form-mode-selectable-gloal">
                                                <!-- 添加表单权限按钮  普通用户添加表单会因为没有权限而看不到新添加的表单 -->
                                                <!-- <cgs-checkbox-group
                                                    v-model:value="state.viewAuthConfig.selectableViewAuthConfig.globalAuth.value"
                                                    :options="state.viewAuthConfig.selectableViewAuthConfig.globalAuth.options">
                                                </cgs-checkbox-group> -->
                                            </div>
                                            <a-collapse v-model:activeKey="state.activeGroup">
                                                <a-collapse-panel
                                                    v-for="group in state.viewAuthConfig.selectableViewAuthConfig.dataSources"
                                                    :key="group.id" :header="group.name">
                                                    <cgs-table :key="group.id" :columns="group.columns"
                                                        :data-source="group.children" backgroundTransparent
                                                        mode="portal" :scroll="{ x: 1500, y: 400 }" :pagination="false">
                                                        <template #headerCell="{ column }">
                                                            <template v-if="column.key !== 'name'">
                                                                <cgs-checkbox v-model:checked="column.checkAll"
                                                                    :indeterminate="column.indeterminate"
                                                                    @change="onChangeSelectableCheckAll(column, group)">
                                                                    {{ column.title }}
                                                                </cgs-checkbox>
                                                            </template>
                                                        </template>
                                                        <template #bodyCell="{ column, record }">
                                                            <template v-if="column.key != 'name'">
                                                                <cgs-checkbox v-if="record.type !== 1"
                                                                    :checked="typeof record[column.key] === 'boolean' && record[column.key]"
                                                                    :indeterminate="record[column.key] === 'indeterminate'"
                                                                    @change="(e) => onChangeSelectableCheck(e, column, record, group)">
                                                                </cgs-checkbox>

                                                                <cgs-checkbox
                                                                    v-if="record.type === 1 && column.dataIndex === 'FORM_READ'"
                                                                    :checked="typeof record[column.key] === 'boolean' && record[column.key]"
                                                                    :indeterminate="record[column.key] === 'indeterminate'"
                                                                    @change="(e) => onChangeSelectableCheck(e, column, record, group)">
                                                                </cgs-checkbox>
                                                            </template>
                                                        </template>
                                                    </cgs-table>
                                                </a-collapse-panel>
                                            </a-collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="app-portal-menu-authority-role-role-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch,
    getCurrentInstance,
    ref
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    useRouter
} from 'vue-router'
import {
    getAppAuth,
    getAppDetail,
    getAppInfo,
    getRoleInfo,
    editRole,
    addRole
} from '@api/scene'

export default defineComponent({
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'add' //add update
        },
        roleId: {
            type: String,
            default: undefined
        }
    },

    setup (props, ctx) {
        const {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        const router = useRouter();
        const formRef = ref()
        const state = reactive({
            roleName: '',
            desc: '',
            activeGroup: [],
            currentSceneAuth: [],
            currentViewAuth: {},
            sceneAuthList: [],
            sceneAuthConfig: {
                portal: {
                    type: 'PORTAL',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                },
                builder: {
                    type: 'BUILDER',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                },
                editor: {
                    type: 'EDITOR',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                },
                studio: {
                    type: 'STUDIO',
                    group: '',
                    options: [],
                    value: [],
                    checkAll: false,
                    indeterminate: false
                }
            },
            groupList: [],
            appTableList: [],
            roleInfo: {},
            viewAuthConfig: {
                type: 0, // 'allViewAuth', 'selectableViewAuth'
                options: [{
                    label: '',
                    value: 0
                },
                {
                    label: '',
                    value: 1
                }
                ],
                allViewAuthConfig: {
                    options: [],
                    value: ''
                },
                selectableViewAuthConfig: {
                    globalAuth: {
                        includesAuth: ['FORM_ADD'],
                        options: [],
                        value: []
                    },
                    columns: [],
                    dataSources: [],
                    dataSource: []
                }
            }
        })
        state.viewAuthConfig.options[0].label = computed(() => {
            return t('auth.appRoleAuthorityModal.AllForms')
        })
        state.viewAuthConfig.options[1].label = computed(() => {
            return t('auth.appRoleAuthorityModal.SelectableForms')
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        let title = computed(() => {

            return props.mode === 'add' ? t('auth.addRole') : t('auth.updateRole')
        })

        onMounted(() => {
            _init()
        })
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                _init()
            }
        })
        const _init = async () => {
            try {
                proxy.$loading.open()
                let _appInfoParams = {
                    appId: sceneId.value,
                    // worksheetType: 0,
                    // worksheetType: 1,
                    // worksheetType: 2,
                    // worksheetType: 3,
                }
                let detail = await getAppDetail(`id=${sceneId.value}`)

                state.groupList = detail?.data.worksheetGroupList.map(item => ({ ...item, children: [] })) || []

                let _appInfoResult = await getAppInfo(_appInfoParams)
                if (_appInfoResult.code === 200) {
                    state.appTableList = _appInfoResult.data
                    state.appTableList.forEach(item => {
                        for (let index = 0; index < state.groupList.length; index++) {
                            const group = state.groupList[index];
                            if (item.groupId === group.id) {
                                group.children.push(item)
                                break;
                            }
                        }
                    })
                }

                if (props.mode === 'update' && props.roleId) {
                    let _roleInfoParams = {
                        id: props.roleId,
                        visualType: sceneType.value
                    }
                    let _roleInfoResult = await getRoleInfo(_roleInfoParams)
                    if (_roleInfoResult.code === 200) {
                        _setCurrentRoleInfo(_roleInfoResult.data)
                    }
                }
                let _appAuthResult = await getAppAuth()
                if (_appAuthResult.code === 200) {
                    state.sceneAuthList = _appAuthResult.data.map(_item => {
                        _item['label'] = _item.alia
                        _item['value'] = _item.name
                        return _item
                    })

                    _setSceneAuth()
                    _setViewAuth()
                }
                proxy.$loading.close()
            } catch (err) {
                console.error(err)
                proxy.$loading.close()
            }
        }
        let sceneType = computed(() => {
            return router.currentRoute.value.params.type
        })
        let sceneId = computed(() => {
            return router.currentRoute.value.params.id
        })
        // let mode = computed(() => {
        //     return props.roleId === undefined ? 'add' : 'update'
        // })
        const _setCurrentRoleInfo = (roleInfo) => {
            state.roleName = roleInfo.roleName
            state.desc = roleInfo.desc
            state.currentSceneAuth = roleInfo.appAuths
            state.currentViewAuth = roleInfo.viewAuths
            state.viewAuthConfig.type = roleInfo.formAuthType

            state.viewAuthConfig.selectableViewAuthConfig.dataSources = state.viewAuthConfig.selectableViewAuthConfig.dataSources.map(dataSource => {
                dataSource.children = dataSource.children.map(item => {
                    if (item.type === 1) {
                        Object.keys(roleInfo.formAuths).forEach(_key => {
                            if (_key === item.key) {
                                roleInfo.formAuths[_key].forEach(_val => {
                                    item[_val] = true
                                })
                            }

                        })
                        return item
                    }
                    return item

                })
                return dataSource
            })
            state.activeGroup = state.viewAuthConfig.selectableViewAuthConfig.dataSources.map(item => item.id)

        }
        const _setSceneAuth = () => {
            let types = Object.keys(state.sceneAuthConfig)
            types.forEach(type => {
                let _obj = state.sceneAuthConfig[type]
                if (_obj) {
                    let _type = _obj.type
                    let _group = _obj.group
                    let _list = []
                    let _value = []
                    state.sceneAuthList.forEach(item => {
                        if (item.type === _type && item.group === _group) {
                            let _name = item.name
                            _list.push(item)
                            if (state.currentSceneAuth.includes(_name)) {
                                _value.push(_name)
                            }
                        }
                    })
                    _obj.options = _list
                    _obj.value = _value
                }
            })

        }
        const _setViewAuth = async () => {
            let _allViewAuthList = []
            let _allViewAuthValue = ''
            let _selectableViewAuthList = []
            let _selectableViewAuthGlobeList = []
            let _selectableViewAuthGlobeValue = []
            state.sceneAuthList.forEach(_item => {
                let _type = _item.type
                let _group = _item.group
                let _name = _item.name
                if (_type === 'FORM') {
                    if (_group === 'DistributeSpecial') {
                        if (state.viewAuthConfig.selectableViewAuthConfig.globalAuth
                            .includesAuth.includes(_item.name)) {
                            _selectableViewAuthGlobeList.push(_item)
                            if (state.currentSceneAuth.includes(_name)) {
                                _selectableViewAuthGlobeValue.push(_name)
                            }
                        } else {
                            _selectableViewAuthList.push(_item)
                        }
                    } else if (_group === 'DistributeAll') {
                        _allViewAuthList.push(_item)
                        if (state.currentSceneAuth.includes(_name)) {
                            _allViewAuthValue = _name
                        }
                    }
                }
            })
            state.viewAuthConfig.allViewAuthConfig.options = _allViewAuthList
            if (_allViewAuthValue) {
                state.viewAuthConfig.allViewAuthConfig.value = _allViewAuthValue
            } else {
                state.viewAuthConfig.allViewAuthConfig.value = _allViewAuthList[_allViewAuthList.length - 1].name
            }



            state.viewAuthConfig.selectableViewAuthConfig.dataSources = state.groupList.map(dataSource => {
                dataSource.children = dataSource.children.map(_item => {
                    let _defaultFormAuthObj = _getDefaultViewAuthObj('', _selectableViewAuthList)
                    let _record = {
                        ..._defaultFormAuthObj
                    }
                    _record.key = _item.id
                    _record.formId = _item.id
                    _record.name = _item.name
                    _record.viewId = ''
                    _record.type = _item.type
                    _record.children = []
                    let _viewList = _item.viewList || []
                    _viewList?.forEach(_view => {
                        let _defaultViewAuthObj = _getDefaultViewAuthObj(_view.id,
                            _selectableViewAuthList)
                        let _viewRecord = {
                            ..._defaultViewAuthObj
                        }
                        _viewRecord.key = _item.id + _view.id
                        _viewRecord.viewId = _view.id
                        _viewRecord.name = _view.name
                        _viewRecord.formId = _item.id
                        _record.children.push(_viewRecord)
                    })
                    return _record
                })
                dataSource.columns = _selectableViewAuthList.map(
                    _item => {
                        return {
                            title: _item.label,
                            dataIndex: _item.value,
                            key: _item.value,
                            checkAll: false,
                            indeterminate: false,
                        }
                    })
                dataSource.columns.unshift({
                    title: '工作表',
                    dataIndex: 'name',
                    key: 'name',
                    fixed: 'right',
                    width: '200'
                })
                return dataSource
            })

            state.activeGroup = state.viewAuthConfig.selectableViewAuthConfig.dataSources.map(item => item.id)

            let _roleInfoParams = {
                id: props.roleId,
                visualType: sceneType.value
            }
            let _roleInfoResult = await getRoleInfo(_roleInfoParams)
            if (_roleInfoResult.code === 200) {

                _setCurrentRoleInfo(_roleInfoResult.data)
            }

            state.viewAuthConfig.selectableViewAuthConfig.dataSources.forEach(dataSource => {
                inspectTableCheckboxChecked(dataSource.columns, dataSource.children)
            })

            state.viewAuthConfig.selectableViewAuthConfig.globalAuth.options =
                _selectableViewAuthGlobeList
            state.viewAuthConfig.selectableViewAuthConfig.globalAuth.value =
                _selectableViewAuthGlobeValue
        }
        const inspectTableCheckboxChecked = (columns, dataSource) => {
            let _columnsChecked = {}
            let _indeterminateList = {}
            let _worksheetAuth = {}
            columns.forEach(_item => {
                if (_item.key !== 'name') {
                    _columnsChecked[_item.key] = []
                    _worksheetAuth[_item.key] = []
                    _indeterminateList[_item.key] = []
                }
            })
            for (let i = 0; i < dataSource.length; i++) {
                let _record = dataSource[i]
                let worksheetAuth = JSON.parse(JSON.stringify(_worksheetAuth))
                let _viewList = _record.children || []
                _viewList?.forEach(_view => {
                    Object.keys(worksheetAuth).forEach(item => {
                        if (_view[item]) {
                            worksheetAuth[item].push(_view.key)
                        }
                    })
                })
                Object.keys(worksheetAuth).forEach(item => {
                    if (_record.type === 1) {

                        return
                    }
                    if (worksheetAuth[item].length === _viewList.length) {
                        _record[item] = true
                    } else if (worksheetAuth[item].length > 0) {
                        _record[item] = 'indeterminate'
                    } else {
                        _record[item] = false
                    }
                })
                Object.keys(_columnsChecked).forEach(item => {
                    if (_record[item] === true) {
                        _columnsChecked[item].push(_record.key)
                    } else if (_record[item] === 'indeterminate') {
                        _indeterminateList[item].push(_record.key)
                    }
                })
            }
            Object.keys(_columnsChecked).forEach(item => {
                let _column = columns.find(c => c.key === item)
                if (_columnsChecked[item].length === state.appTableList.length) {
                    _column.checkAll = true
                    _column.indeterminate = false
                } else if (_columnsChecked[item].length > 0 || _indeterminateList[item].length > 0) {
                    _column.checkAll = false
                    _column.indeterminate = true
                } else {
                    _column.checkAll = false
                    _column.indeterminate = false
                }
            })
        }

        const _getDefaultViewAuthObj = (viewId, selectableViewAuthList) => {
            let _defaultViewAuthObj = {}
            let _currentViewAuthList = []
            if (props.mode === 'update' && viewId in state.currentViewAuth) {
                _currentViewAuthList = state.currentViewAuth[viewId].formAuths
            }
            selectableViewAuthList.forEach(_item => {
                _defaultViewAuthObj[_item.value] = _currentViewAuthList.includes(_item.value)
            })
            return _defaultViewAuthObj
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => {
            try {
                let res = await formRef.value.validateFields();
                console.log(res);
            } catch (err) {
                console.log(err);
            }
            if (state.roleName === "") {
                // proxy.$message.error('角色名称为空')
                return
            }
            let params = {
                roleName: state.roleName,
                desc: state.desc,
                formAuthType: state.viewAuthConfig.type,
                type: "APP_ROLE",
                appAuths: []
            }
            Object.values(state.sceneAuthConfig).forEach(item => {
                item.value.forEach(_auth => {
                    params.appAuths.push(_auth)
                })
            })
            if (state.viewAuthConfig.type === 0) {
                if (state.viewAuthConfig.allViewAuthConfig.value !== '') {
                    params.appAuths.push(state.viewAuthConfig.allViewAuthConfig.value)
                }
            } else {
                params.viewAuths = {}
                params.formAuths = {}
                let _dataSources = state.viewAuthConfig.selectableViewAuthConfig.dataSources
                params.appAuths = params.appAuths.concat(state.viewAuthConfig.selectableViewAuthConfig.globalAuth.value)
                _dataSources.forEach(dataSource => {
                    let _dataSource = dataSource.children
                    _dataSource.forEach(_item => {
                        if (_item.type === 1 && _item.FORM_READ) {
                            params.formAuths[_item.formId] = ["FORM_READ"]
                            return
                        }
                        let _viewList = _item.children || [];
                        _viewList?.forEach(_view => {
                            let _formId = _view.formId;
                            let _viewId = _view.viewId;
                            let _formAuths = []
                            let keys = Object.keys(_view)
                            keys.forEach(key => {
                                if (_view[key] === true) {
                                    _formAuths.push(key)
                                }
                            })
                            params.viewAuths[_viewId] = {
                                formId: _formId,
                                viewId: _viewId,
                                formAuths: _formAuths
                            }
                        })
                    })

                })
            }
            if (props.mode === 'add') {
                try {
                    proxy.$loading.open()
                    params.visualId = sceneId.value
                    let _result = await addRole(params)
                    if (_result.code === 200) {
                        proxy.$message.success(t('common.addSuccess'))
                        params.id = _result.data.roleId
                        ctx.emit('addSuccess', params)
                        close()
                    } else {
                        proxy.$message.error(t('common.addFail'))
                    }
                    proxy.$loading.close()
                } catch (err) {
                    console.error(err)
                    proxy.$message.error(t('common.addFail'))
                    proxy.$loading.close()
                }
            } else {
                try {
                    proxy.$loading.open()
                    params.id = props.roleId
                    let _result = await editRole(params)
                    if (_result.code === 200) {
                        proxy.$message.success(t('common.modifySuccess'))
                        ctx.emit('editSuccess', params)
                        close()
                    } else {
                        proxy.$message.error(t('common.modifyFail'))
                    }
                    proxy.$loading.close()
                } catch (err) {
                    console.error(err)
                    proxy.$message.error(t('common.modifyFail'))
                    proxy.$loading.close()
                }
            }
        }
        const onCheckAll = (type) => {
            if (state.sceneAuthConfig[type].checkAll) {
                state.sceneAuthConfig[type].value = state.sceneAuthConfig[type].options.map(option =>
                    option.value)
            } else {
                state.sceneAuthConfig[type].indeterminate = false
                state.sceneAuthConfig[type].value = []
            }
        }
        const onChangeCheckGroup = (type) => {
            state.sceneAuthConfig[type].indeterminate = !!state.sceneAuthConfig[type].value.length &&
                state.sceneAuthConfig[type].value.length < state.sceneAuthConfig[type].options.length;
            state.sceneAuthConfig[type].checkAll = (state.sceneAuthConfig[type].value.length === state
                .sceneAuthConfig[type].options.length)
        }
        const onChangeSelectableCheckAll = (column, _dataSource) => {
            let _key = column.key
            column.indeterminate = false
            let _columns = _dataSource.columns
            _dataSource.children.forEach(_item => {
                _item[_key] = column.checkAll
                if (_item.children) {
                    _item.children.forEach(child => {
                        child[_key] = column.checkAll
                    })
                }
                if (column.checkAll) {
                    if (_key === 'FORM_EDIT') {
                        _item["FORM_READ"] = column.checkAll
                        _columns.forEach(_val => {
                            if (_val.key === 'FORM_READ') {
                                _val.checkAll = column.checkAll
                            }
                        })
                        if (_item.children) {
                            _item.children.forEach(child => {
                                child["FORM_READ"] = column.checkAll
                            })
                        }
                    } else if (_key === 'FORM_DELETE') {
                        _item['FORM_READ'] = column.checkAll
                        _item['FORM_EDIT'] = column.checkAll
                        _columns.forEach(_val => {
                            if (_val.key === 'FORM_READ' || _val.key === 'FORM_EDIT') {
                                _val.checkAll = column.checkAll
                            }
                        })
                        if (_item.children) {
                            _item.children.forEach(child => {
                                child["FORM_READ"] = column.checkAll
                                child["FORM_EDIT"] = column.checkAll
                            })
                        }
                    } else if (_key === 'ROW_EDIT_OWN' || _key === 'ROW_DELETE_OWN') {
                        _item['ROW_READ_OWN'] = column.checkAll
                        _columns.forEach(_val => {
                            if (_val.key === 'ROW_READ_OWN') {
                                _val.checkAll = column.checkAll
                            }
                        })
                        if (_item.children) {
                            _item.children.forEach(child => {
                                child["ROW_READ_OWN"] = column.checkAll
                            })
                        }
                    } else if (_key === 'ROW_EDIT_ALL' || _key === 'ROW_DELETE_ALL') {
                        _item['ROW_READ_ALL'] = column.checkAll
                        _columns.forEach(_val => {
                            if (_val.key === 'ROW_READ_ALL') {
                                _val.checkAll = column.checkAll
                            }
                        })
                        if (_item.children) {
                            _item.children.forEach(child => {
                                child["ROW_READ_ALL"] = column.checkAll
                            })
                        }
                    }
                } else {
                    if (_key === 'FORM_READ') {
                        _item["FORM_EDIT"] = column.checkAll
                        _item["FORM_DELETE"] = column.checkAll
                        _columns.forEach(_val => {
                            if (_val.key === 'FORM_EDIT' || _val.key === 'FORM_DELETE') {
                                _val.checkAll = column.checkAll
                            }
                        })
                        if (_item.children) {
                            _item.children.forEach(child => {
                                child["FORM_EDIT"] = column.checkAll
                                child["FORM_DELETE"] = column.checkAll
                            })
                        }
                    } else if (_key === 'FORM_EDIT') {
                        _item['FORM_DELETE'] = column.checkAll
                        _columns.forEach(_val => {
                            if (_val.key === 'FORM_DELETE') {
                                _val.checkAll = column.checkAll
                            }
                        })
                        if (_item.children) {
                            _item.children.forEach(child => {
                                child["FORM_DELETE"] = column.checkAll
                            })
                        }
                    } else if (_key === 'ROW_READ_OWN') {
                        if (_item["ROW_READ_ALL"] === column.checkAll) {
                            _item['ROW_EDIT_ALL'] = column.checkAll
                            _item['ROW_EDIT_OWN'] = column.checkAll
                            _item['ROW_DELETE_ALL'] = column.checkAll
                            _item['ROW_DELETE_OWN'] = column.checkAll
                            _columns.forEach(_val => {
                                if (_val.key === 'ROW_EDIT_ALL' || _val.key === 'ROW_EDIT_OWN' || _val.key === 'ROW_DELETE_ALL' || _val.key === 'ROW_DELETE_OWN') {
                                    _val.checkAll = column.checkAll
                                }
                            })
                            if (_item.children) {
                                _item.children.forEach(child => {
                                    child['ROW_EDIT_ALL'] = column.checkAll
                                    child['ROW_EDIT_OWN'] = column.checkAll
                                    child['ROW_DELETE_ALL'] = column.checkAll
                                    child['ROW_DELETE_OWN'] = column.checkAll
                                })
                            }
                        }
                    } else if (_key === 'ROW_READ_ALL') {
                        if (_item['ROW_READ_OWN'] === column.checkAll) {
                            _item['ROW_EDIT_ALL'] = column.checkAll
                            _item['ROW_EDIT_OWN'] = column.checkAll
                            _item['ROW_DELETE_ALL'] = column.checkAll
                            _item['ROW_DELETE_OWN'] = column.checkAll
                            _columns.forEach(_val => {
                                if (_val.key === 'ROW_EDIT_ALL' || _val.key === 'ROW_EDIT_OWN' || _val.key === 'ROW_DELETE_ALL' || _val.key === 'ROW_DELETE_OWN') {
                                    _val.checkAll = column.checkAll
                                }
                            })
                            if (_item.children) {
                                _item.children.forEach(child => {
                                    child['ROW_EDIT_ALL'] = column.checkAll
                                    child['ROW_EDIT_OWN'] = column.checkAll
                                    child['ROW_DELETE_ALL'] = column.checkAll
                                    child['ROW_DELETE_OWN'] = column.checkAll
                                })
                            }
                        }
                    }
                }
            })
        }
        const onChangeSelectableCheck = (e, column, record, dataSource) => {
            let checked = e.target.checked
            let _key = column.key
            record[_key] = checked
            if (record.children) {
                record.children.forEach(_item => {
                    _item[_key] = record[_key]
                })
            }
            if (checked) {
                if (_key === 'FORM_EDIT') {
                    record["FORM_READ"] = checked
                    if (record.children) {
                        record.children.forEach(child => {
                            child["FORM_READ"] = record["FORM_READ"]
                        })
                    }
                } else if (_key === 'FORM_DELETE') {
                    record['FORM_READ'] = checked
                    record['FORM_EDIT'] = checked
                    if (record.children) {
                        record.children.forEach(child => {
                            child["FORM_READ"] = record["FORM_READ"]
                            child["FORM_EDIT"] = record["FORM_EDIT"]
                        })
                    }
                } else if (_key === 'ROW_EDIT_OWN' || _key === 'ROW_DELETE_OWN') {
                    record['ROW_READ_OWN'] = checked
                    if (record.children) {
                        record.children.forEach(child => {
                            child["ROW_READ_OWN"] = record["ROW_READ_OWN"]
                        })
                    }
                } else if (_key === 'ROW_EDIT_ALL' || _key === 'ROW_DELETE_ALL') {
                    record['ROW_READ_ALL'] = checked
                    if (record.children) {
                        record.children.forEach(child => {
                            child["ROW_READ_ALL"] = record["ROW_READ_ALL"]
                        })
                    }
                }
            } else {
                if (_key === 'FORM_READ') {
                    record["FORM_EDIT"] = checked
                    record["FORM_DELETE"] = checked
                    if (record.children) {
                        record.children.forEach(child => {
                            child["FORM_EDIT"] = record["FORM_EDIT"]
                            child["FORM_DELETE"] = record["FORM_DELETE"]
                        })
                    }
                } else if (_key === 'FORM_EDIT') {
                    record['FORM_DELETE'] = checked
                    if (record.children) {
                        record.children.forEach(child => {
                            child['FORM_DELETE'] = record['FORM_DELETE']
                        })
                    }
                } else if (_key === 'ROW_READ_OWN') {
                    if (record['ROW_READ_ALL'] === checked) {
                        record['ROW_EDIT_ALL'] = checked
                        record['ROW_EDIT_OWN'] = checked
                        record['ROW_DELETE_ALL'] = checked
                        record['ROW_DELETE_OWN'] = checked
                        if (record.children) {
                            record.children.forEach(child => {
                                child['ROW_EDIT_ALL'] = record['ROW_EDIT_ALL']
                                child['ROW_EDIT_OWN'] = record['ROW_EDIT_OWN']
                                child['ROW_DELETE_ALL'] = record['ROW_DELETE_ALL']
                                child['ROW_DELETE_OWN'] = record['ROW_DELETE_OWN']
                            })
                        }
                    }
                } else if (_key === 'ROW_READ_ALL') {
                    if (record['ROW_READ_OWN'] === checked) {
                        record['ROW_EDIT_ALL'] = checked
                        record['ROW_EDIT_OWN'] = checked
                        record['ROW_DELETE_ALL'] = checked
                        record['ROW_DELETE_OWN'] = checked
                        if (record.children) {
                            record.children.forEach(child => {
                                child['ROW_EDIT_ALL'] = record['ROW_EDIT_ALL']
                                child['ROW_EDIT_OWN'] = record['ROW_EDIT_OWN']
                                child['ROW_DELETE_ALL'] = record['ROW_DELETE_ALL']
                                child['ROW_DELETE_OWN'] = record['ROW_DELETE_OWN']
                            })
                        }
                    }
                }
            }
            inspectTableCheckboxChecked(dataSource.columns, dataSource.children)
        }
        return {
            vis,
            state,
            title,
            formRef,
            close,
            closeModal,
            ok,
            onCheckAll,
            onChangeCheckGroup,
            onChangeSelectableCheckAll,
            onChangeSelectableCheck
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.app-portal-menu-authority-role-role-modal-content {
    .text-1();
    max-height: 600px;
    overflow: auto;
}

.app-portal-menu-authority-role-role-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.app-portal-menu-authority-role-role-modal-name,
.app-portal-menu-authority-role-role-modal-desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.app-portal-menu-authority-role-role-modal-name {
    margin-bottom: 0;
}

.app-portal-menu-authority-role-role-modal-label {
    width: 100px;
}

.app-portal-menu-authority-role-role-modal-label-title {
    font-weight: 600;
    width: 400px;
}

.app-portal-menu-authority-role-role-modal-value {
    flex: 1;
}

.app-portal-menu-authority-role-role-modal-detail-item {
    display: flex;
    flex-direction: row;
    // align-items: center;
    margin-bottom: 8px;
}


.app-portal-menu-authority-role-role-modal-detail-label {
    width: 100px;
}

.app-portal-menu-authority-role-role-modal-detail-value {
    flex: 1;
    width: 0;
}

.app-portal-menu-authority-role-role-modal-detail-content {
    color: @font-color-2;
}
</style>