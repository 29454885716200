<!--
*@description 
*@author 杜超峰
*@date 2023/04/23 11:29:38
!-->

<template>
    <div class="portal-system-left-nav" :class="menuCollapse ? '' : 'portal-system-left-nav-not-collapse'">
        <div class="portal-system-left-nav-content">
            <div class="portal-system-left-nav-content-wrapper">
                <div class="portal-system-left-nav-content-item selectable-item"
                    :class="item.key === currentActiveKey ? 'selected-item' : ''" v-for="item in tenantList"
                    :key="item.key" @click="clickTenantItemHandler(item)">
                    <span class="portal-system-left-nav-content-item-icon" :class="item.icon"></span>
                    <span class="portal-system-left-nav-content-item-title" v-if="!menuCollapse">{{ item.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    computed
} from 'vue'

export default defineComponent({
    props: {
        tenantList: {
            type: Array,
            default: () => [],
        },
        activeKey: {
            type: String,
            default: ''
        },
        menuCollapse: {
            type: Boolean,
            default: false
        }
    },

    setup (props, ctx) {
        const currentActiveKey = computed({
            get: () => props.activeKey,
            set: (newVal) => {
                ctx.emit('update:activeKey', newVal)
            }
        })
        const clickTenantItemHandler = (tenantItem) => {
            ctx.emit('clickMenu', tenantItem.key)
        }
        return {
            currentActiveKey,
            clickTenantItemHandler,
        }
    },
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-system-left-nav {
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portal-system-left-nav-not-collapse {
    width: 300px;
}

.portal-system-left-nav-content {
    flex: 1;
}

.portal-system-left-nav-content-item {
    padding: 8px;
    border-radius: 5px;
    margin: 2px;
}

// .portal-system-left-nav-content-item:hover{
//     border-radius: 5px;
// }

.portal-system-left-nav-content-item-title {
    margin-left: 8px;
}

.portal-system-left-nav-content {
    .text-1();
    .font-color-1();
}

.portal-system-left-nav-content-wrapper {
    margin-top: 8px;
}
</style>