<template>
    <div class="portal-system-apk">
        <div class="portal-system-apk-wrapper">
            <div class="portal-system-apk-header">
                <div class="portal-system-apk-header-title">
                    APK管理
                </div>
                
                <div>
                    <cgs-button ghost class="portal-system-apk-add-apk" @click="appapkList">添加APK</cgs-button>
                </div>
            </div>
            <div class="portal-system-apk-content">
                <div class="portal-system-apk-content-table">
                    <apkTable :moreMenuList="moreMenuList" :sceneList="state.tableData" @clikMenuItem="clikMenuItem"></apkTable>
                </div>
            </div>
        </div>
        <addUpdataApk :dialogVisible="state.dialogVisible" :submitState="state.submitState" @cancelAddApkModal="state.dialogVisible = false" @formRePassSubmit="formRePassSubmit"></addUpdataApk>
    </div>
</template>

<script>
    import {
        defineComponent,
        reactive,
        getCurrentInstance
    } from 'vue'
    import {
        getApkList,
        deleteApk,
        addApk,
        editApk,
    } from '@/api/tenant'
    import apkTable from './components/apk-table.vue'
    import addUpdataApk from './components/addUpdataApk.vue'

    export default defineComponent({
        components: {
            apkTable,
            addUpdataApk
        },
        props: {},
        setup() {
            let {
                proxy
            } = getCurrentInstance();
            const state = reactive({
                tableData:[],
                submitState: {
                    state:'add',
                    scene:{}
                },
                dialogVisible: false
            })
            let moreMenuList = [
                {
                    title: '修改',
                    key: 'edit',
                    icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
                },
                {
                    title: '删除',
                    key: 'delete',
                    icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
                }
            ]
            const getList = () => {
                getApkList().then((res) => {
                    state.tableData = res.data
                })
            }
            const clikMenuItem = (scene, params) => {
                if (params.key === 'edit') {
                    let data = scene
                    state.submitState = {
                        scene: data,
                        state: 'updata'
                    }
                    state.dialogVisible= true
                } else if (params.key === 'delete') {
                    proxy.$confirm({
                        title: '提示',
                        content: '此操作将永久删除, 是否继续?',
                        onOk() {
                            deleteApk({
                                "id": scene.id
                            }).then((res) => {
                                if (res && res.code === 200) {
                                    proxy.$message.success("删除成功!")
                                    getList();
                                } else {
                                    proxy.$message.error("删除失败！")
                                }
                            }).catch((res) => {
                                proxy.$message.error(res.msg)
                            })
                        },
                        onCancel() {
                            proxy.$message.info('已取消删除')
                        },
                    });
                }
            }
            const formRePassSubmit = (values) => {
                state.dialogVisible = false;
                if (state.submitState.state === "add") {
                    addApk({
                        "code": values.code,
                        "version": values.version,
                        "link": values.link,
                        "desc": values.desc,
                    }).then((res) => {
                        if (res && res.success) {
                            proxy.$message.success("添加成功！")
                            getList();
                        } else {
                            proxy.$message.error("添加失败！")
                        }
                    })
                } else if (state.submitState.state === "updata") {
                    let row = values;
                    let data = {
                        "id": row.id,
                        "version": row.version,
                        "link": row.link,
                        "desc": row.desc,
                    }
                    editApk(data).then((res) => {
                        if (res && res.success) {
                            proxy.$message.success("修改成功！")
                            getList();
                        } else {
                            proxy.$message.error("修改失败！")
                        }
                    })
                }
            }
            const appapkList = () => {
                state.submitState = {
                        scene: {},
                        state: 'add'
                    }
                state.dialogVisible = true;
            }
            getList()
            return {
                moreMenuList,
                state,
                appapkList,
                clikMenuItem,
                formRePassSubmit
            }
        },
    })
</script>

<style lang="less" scoped>
    @import "~@style/less/theme.less";
    .portal-system-apk{
        width: 100%;
        height: 100%;
        padding: 24px 48px 8px 48px;
        overflow: hidden;
    }
    .portal-system-apk-wrapper{
        width: 100%;
        height: 100%;
    }
    .portal-system-apk-header{
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .portal-system-apk-header-title{
        .little-title;
        color: @font-color-1;
    }
    .portal-system-apk-add-apk{
        margin-left: 10px;
    }
    .portal-system-apk-content{
        width: 100%;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
    }
    .portal-system-apk-content-table{
        flex: 1;
        height: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .portal-system-apk-content-footer{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>