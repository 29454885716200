<template>
  <div>
    <h3 class="portal-home-work-flow-title little-title">{{ $t('homeBench.myCollect') }}</h3>
    <div class="portal-home-work-collect-app">
      <div class="portal-public-view-icon">
        <draggable :list="state.sceneList" class="portal-public-view-icon-wrapper" ghost-class="ghost"
          chosen-class="chosenClass" animation="300" item-key="key" @update="updateCollect($event, item)">
          <template #item="{ element }">
            <main-icon-card :key="element.id" :ident="element.id" :title="element.title"
              :publish="element.status === 0 ? false : true" :icon="element.icon" :theme-name="element.theme"
              :appType="element.typeName" :editable="hasEditAuth(element)"
              :more-menu-list="currentMoreMenuList(element)" :isCollect="element.isCollect"
              @clikMenuItem="clikSceneMenuItem(element, $event)" @clickEdit="onClickHandler(element)"
              @clickCollect="clikSceneCollect(element)"></main-icon-card>
          </template>
        </draggable>

      </div>
      <!-- <AppIcon :sceneList="state.sceneList" @enter="enterScene" @clikMenuItem="clikSceneMenuItem"
    @clikCollect="clikSceneCollect"> -->
      <!-- </AppIcon> -->
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watch, defineEmits, onMounted } from "vue"
import { updateCollectSort, getCollectList } from "@/api/workSpace"
// import AppIcon from "../../workSpace/component/appIcon.vue"
import mainIconCard from "../../workSpace/component/main-icon-card.vue"
import draggable from "vuedraggable";
import {
  useI18n
} from 'vue-i18n'
const props = defineProps({
  sceneList: {
    type: Array,
    default: undefined
  }
})
const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect", "sortCollect"])
const state = reactive({
  sceneList: []
})

const {
  t
} = useI18n()

watch(() => props.sceneList, (newVal) => {
  if (newVal) {
    let _list = newVal ? newVal : []
    state.sceneList = JSON.parse(JSON.stringify(_list))
  }
})


onMounted(() => {
  init()
})
const init = async () => {
  if (!props.sceneList) {
    let collect = await getCollectList()
    if (collect.code === 200) {
      collect.data.forEach(item => {
        item.title = item.name ? item.name : item.title
        item.typeName = item.appType
      })
      state.sceneList = collect.data
    }
  } else {
    state.sceneList = JSON.parse(JSON.stringify(props.sceneList))
  }
}

const updateCollect = async () => {
  let ids = state.sceneList.map(_item => _item.id)
  let collectSort = await updateCollectSort({ collectIds: ids })
  collectSort.code === 200 && emit("sortCollect")
}
const clikSceneMenuItem = (scene, menuItemParams) => {
  emit("clikSceneMenuItem", scene, menuItemParams)
}
const clikSceneCollect = (scene) => {
  emit("clikSceneCollect", scene)
}

const onClickHandler = (scene) => {
  // 判断是否有编辑权限
  if (scene.appType) {
    hasEditAuth(scene) && emit("enterScene", scene)
  } else {
    emit("enterScene", scene)
  }
}
const hasEditAuth = (scene) => {
  return window.authManager.editSceneAuth(scene.id)
}
const towDMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'preview',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
  disabled: (sceneId) => {
    return !window.authManager.previewSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: (sceneId) => {
    return !window.authManager.exportSceneAuth(sceneId)
  }
},

{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
const appMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: (sceneId) => {
    return !window.authManager.exportSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
const IotMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
let pageMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'preview',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
  disabled: true
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: true
},
{
  title: '',
  key: 'path',
  icon: 'cgs-portal-iconfont cgs-portal-icon-lianjie',
  disabled: false
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
let vtkMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'preview',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
  disabled: (sceneId) => {
    return !window.authManager.previewSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'export',
  icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
  disabled: true
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
let spaceMoreMenuList = [{
  title: '',
  key: 'auth',
  icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
  disabled: (sceneId) => {
    return !window.authManager.manageAuthSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
  disabled: (sceneId) => {
    return !window.authManager.editSceneAuth(sceneId)
  }
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
  disabled: (sceneId) => {
    return !window.authManager.deleteSceneAuth(sceneId)
  }
}
]
const currentMoreMenuList = (scene) => {
  let _moreMenuList = towDMoreMenuList
  if (scene.appType === 'TwoD' || scene.appType === 'ThreeD' || scene.appType === 'Earth' || scene.appType === 'CAD' || scene.appType === "SCADA" || scene.appType === "NewEarth") {
    _moreMenuList = towDMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'App' || scene.appType === 'Workflow' || scene.appType === 'GeoModel' || scene.appType === 'Volume') {
    _moreMenuList = appMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'Iot') {
    _moreMenuList = IotMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'Static') {
    _moreMenuList = pageMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (scene.appType === 'Vtk') {
    _moreMenuList = vtkMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else if (!scene.appType) {
    _moreMenuList = spaceMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  } else {
    _moreMenuList = IotMoreMenuList.map(_item => {
      _item['title'] = t('sceneMoreMenu.' + _item.key)
      return _item
    })
  }


  let list = _moreMenuList.filter(_item => {
    let _bool = true
    let _disabled = _item.disabled
    if (_disabled instanceof Function) {
      _bool = !_disabled(scene.id)
    } else {
      _bool = !_disabled
    }
    return _bool
  })
  return list
}

</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-work-collect-app {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
  min-height: 30px;
}

.portal-home-work-flow-title {
  font-size: 16px;
  color: @font-color-1;
  margin-bottom: 10px;
}

.portal-home-work-collect-app-drag {
  width: 100%;
  height: 100%;
  display: flex;

}

.portal-public-view-icon {
  width: 100%;
  height: 100%;
}

.portal-public-view-icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.portal-public-view-icon-card {
  //margin: 5px;
  width: 24.2%;
  margin: 0.4%;
  border-radius: 6px;
  .border();
  background: @card-color;
  overflow: hidden;

  @media screen and (min-width: 1800px) {
    width: 19.2%;
    margin: 0.4%;
  }
}

.portal-public-view-icon-card-cover {
  position: relative;
  overflow: hidden;
}

.portal-public-view-icon-card-cover:hover {
  // filter: blur(3px)
}

.portal-public-view-icon-card-info {
  width: 100%;
  padding: 10px;
  .text-1();
  color: @font-color-1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>