<template>
  <div>
    <div class="protal-home-recene little-title">
      <h3 class="portal-home-work-flow-title">{{ $t('homeBench.recentUse') }}</h3>
      <cgs-more :menu-list="showRecent" @clikMenuItem="clikMenuItemHandler">
        <template #triggerButton>
          <span class=" portal-home-work-header-text" @click.prevent>{{ $t("homeBenchRecent." + selectType) }}
            <span class=" cgs-portal-iconfont cgs-portal-icon-xia"></span>
          </span>
        </template>
      </cgs-more>
    </div>
    <div class="portal-home-work-collect-app">
      <!-- <AppCard></AppCard> -->
      <AppIcon :sceneList="state.sceneList" @enter="enterScene" @clikMenuItem="clikSceneMenuItem"
        @clikCollect="clikSceneCollect"> </AppIcon>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watch, defineEmits, ref, computed, onMounted } from "vue"
import AppIcon from "../../workSpace/component/appIcon.vue"
import { getRecentUsedList } from "@/api/workSpace"
import {
  useI18n
} from 'vue-i18n'
const props = defineProps({
  sceneList: {
    type: Array,
    default: undefined
  }
})

const {
  t
} = useI18n()
const state = reactive({
  sceneList: [],
  recent: [{
    title: '',
    lable: "TheLastDay",
    key: 1,
    icon: '',
  }, {
    title: '最近七天',
    lable: "TheLastWeek",
    key: 7,
    icon: '',
  }, {
    title: '近三十天',
    lable: "TheLastMonth",
    key: 30,
    icon: '',
  },]
})
const selectType = ref("TheLastWeek")

const showRecent = computed(() => {
  return state.recent.map(_item => {
    _item['title'] = t('homeBenchRecent.' + _item.lable)
    return _item
  })
})

watch(() => props.sceneList, (newVal) => {
  if (newVal) {
    let _list = newVal ? newVal : []
    state.sceneList = JSON.parse(JSON.stringify(_list))
  }
})



onMounted(() => {
  init()
})
const init = async () => {
  if (!props.sceneList) {
    let recent = await getRecentUsedList({ day: 7 })
    if (recent.code === 200) {
      recent.data.forEach(item => {
        item.title = item.name ? item.name : item.title
        item.typeName = item.appType
      })
      state.sceneList = recent.data
    }
  } else {
    state.sceneList = JSON.parse(JSON.stringify(props.sceneList))
  }
}

const emit = defineEmits(["enterScene", "clikSceneMenuItem", "clikSceneCollect", "changeRecent"])
const enterScene = (scene) => {
  emit("enterScene", scene)
}
const clikSceneMenuItem = (scene, menuItemParams) => {
  emit("clikSceneMenuItem", scene, menuItemParams)
}
const clikSceneCollect = (scene) => {
  emit("clikSceneCollect", scene)
}

const clikMenuItemHandler = (val) => {
  let current = showRecent.value.find(item => item.key === val.key)
  selectType.value = current.lable
  emit("changeRecent", val.key)
}
</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.protal-home-recene {
  display: flex;
}

.portal-home-work-flow-title {
  margin-right: 20px;
}

.portal-home-work-header-text {
  font-size: 14px !important;
  color: @primary-color !important;
}

.portal-home-work-flow-title {
  font-size: 16px;
  color: @font-color-1;
  margin-bottom: 10px;
}
</style>