<template>
    <main-layout class="portal-menu">
        <template #header>
            <main-header>
                <template #prefix-icon>
                    <span class="portal-menu-change-icon cgs-portal-iconfont cgs-portal-icon-pailiefangshi font-color-3"
                        @click="switchLeftCollapse"></span>
                </template>
                <template #middle>
                    <middle-header-search></middle-header-search>
                </template>
            </main-header>
        </template>
        <template #left>
            <left-nav :moreMenuList="showMoreMenuList" :middleMenuList="middleMenuList"
                :title="state.leftNavConfig.title" :showAddButton="state.leftNavConfig.showAddButton"
                :showMode="state.showMode" :showMiddleMenuMore="state.leftNavConfig.showMiddleMenuMore"
                :menuCollapse="state.menuCollapse" v-model:active-key="state.activeRouterName"
                @clickFooterMenu="onClickFooterMenu" @clickMiddleMenuMore="clickMiddleMenuMore"
                @click-middle-menu="onClickMiddleMenu" @addSceneType="onAddSceneType" @switchMode="onSwitchMode"
                @collectWork="init_workSpace">
            </left-nav>
        </template>
        <template #right>
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" :key="$route.name" @changeOrganization="onChangeOrganization"
                        v-if="$route.meta.keepAlive" />
                </keep-alive>
                <component :is="Component" :key="$route.name" @changeOrganization="onChangeOrganization"
                    v-if="!$route.meta.keepAlive" />
            </router-view>
        </template>
    </main-layout>
    <scene-type-manager-modal v-model:visible="state.sceneTypeManager.visible"
        :currentSceneTypeList="state.sceneTypeList" @updateSceneTypeSetting="onUpdateSceneTypeSetting">
    </scene-type-manager-modal>
    <edit-scene-type-name v-if="state.editSceneTypeName.visible" :config="state.editSceneTypeName"
        @ok="updateSceneTypeNameOk" @cancelModal="() => state.editSceneTypeName.visible = false"></edit-scene-type-name>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    onActivated,
    computed,
    getCurrentInstance,
} from 'vue'
import {
    useRouter,
    onBeforeRouteUpdate,
    useRoute
} from 'vue-router'
import {
    useI18n
} from 'vue-i18n'
import mainLayout from '@components/main-layout.vue'
import mainHeader from '@components/main-header.vue'
import leftNav from './left-nav.vue'
import sceneTypeManagerModal from './scene-type-manager-modal.vue'
import editSceneTypeName from './edit-scene-type-name.vue'
import middleHeaderSearch from "./middle-header-search.vue"
import {
    getSceneTypeList,
    getOrganization,
    updateSceneType
} from '@api/scene'

// 
import useHeader from "../../store/modules/header"
export default defineComponent({
    components: {
        mainLayout,
        mainHeader,
        middleHeaderSearch,
        leftNav,
        sceneTypeManagerModal,
        editSceneTypeName
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance()
        let router = useRouter()
        let route = useRoute()
        const {
            t
        } = useI18n()
        const headerStore = useHeader()
        const state = reactive({
            sceneTypeManager: {
                visible: false
            },
            editSceneTypeName: {
                visible: false,
                typeName: '',
                typeId: '',
            },
            sceneTypeList: [],
            activeRouterName: '',
            showMode: 'sceneTypeClassify', // organizationClassify
            leftNavConfig: {
                title: '场景',
                showMiddleMenuMore: true,
                showAddButton: true
            },
            organizationList: [],
            workSpaceList: [],
        })
        let moreMenuList = [{
            title: '重命名',
            key: 'rename',
            icon: 'cgs-portal-iconfont cgs-portal-icon-zhongmingming'
        },
        {
            title: '隐藏',
            key: 'hidden',
            icon: 'cgs-portal-iconfont cgs-portal-icon-yincang'
        },
            // {
            //     title: '删除',
            //     key: 'delete',
            //     icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
            // }
        ]
        onMounted(() => {
            proxy.$ws.on('current_license', (data) => {
                if (data.Timeout) {
                    proxy.$message.warning("License已过期，请重新申请！")
                }
            })
            if (route.name === 'organization') {
                state.showMode = 'organizationClassify'
            } else if (route.name === 'workbench' || route.name === 'workSpace') {
                state.showMode = "workbench"
            }
            _setLeftNavConfig(state.showMode)
            _init()
        })
        onActivated(() => {
            _toSubRouter(route)
        })
        onBeforeRouteUpdate(to => {
            _toSubRouter(to)
        })
        const showMoreMenuList = computed(() => {
            return moreMenuList.map(_item => {
                _item.title = t('common.' + _item.key)
                return _item
            })
        })
        const middleMenuList = computed(() => {
            let _list = []
            if (state.showMode === 'sceneTypeClassify') {
                _list = state.sceneTypeList
            } else if (state.showMode === 'organizationClassify') {
                _list = state.organizationList
            }
            return _list
        })
        const _init = () => {
            if (state.showMode === 'sceneTypeClassify') {
                _initSceneTypeList()
            } else if (state.showMode === 'organizationClassify') {
                _initOrganizationList()
            }

        }

        const _initSceneTypeList = async () => {
            try {
                let _sceneTypeResult = await getSceneTypeList()
                if (_sceneTypeResult.code === 200) {
                    state.sceneTypeList = _sceneTypeResult.data.map(_item => {
                        return {
                            key: _item.typeId,
                            label: _item.typeName,
                            icon: _item.icon,
                            type: 'sceneType'
                        }
                    })
                    _toSubRouter(route)
                }
            } catch (err) {
                console.error(err)
            }
        }
        const onChangeOrganization = async () => {
            if (state.showMode === 'organizationClassify') {
                _initOrganizationList()
            }
        }
        const _initOrganizationList = async () => {
            try {
                let _sceneTypeResult = await getOrganization()
                if (_sceneTypeResult.code === 200) {
                    state.organizationList = _sceneTypeResult.data.map(_item => {
                        return {
                            key: _item.id,
                            label: _item.categoryKey,
                            icon: 'cgs-portal-iconfont cgs-portal-icon-zuzhi',
                            value: _item.categoryValue,
                            type: 'organization'
                        }
                    })
                    _toSubRouter(route)
                }
            } catch (err) {
                console.error(err)
            }
        }

        const onClickMiddleMenu = (nav) => {
            if (nav.type === 'organization') {
                router.push({
                    path: `/menu/organization/${nav.key}`
                })
            } else if (nav.type === 'sceneType') {
                router.push({
                    name: nav.key
                })
            }

        }
        const onClickFooterMenu = (nav) => {
            if ('store' === nav.key) {
                window.open(window.origin + '/cgsStore/', '_blank')
                // router.push({name: 'store'})
            } else {
                router.push({
                    name: nav.key
                })
            }
        }
        const clickMiddleMenuMore = async (params, item) => {
            if ('rename' === params.key) {
                state.editSceneTypeName.typeName = item.label;
                state.editSceneTypeName.typeId = item.key;
                state.editSceneTypeName.visible = true;
            } else if ('hidden' === params.key) {
                let params = {
                    show: false,
                    typeId: item.key,
                }
                let _result = await updateSceneType(params)
                if (_result.code === 200) {
                    let index = state.sceneTypeList.findIndex(sceneType => sceneType.key === item
                        .key)
                    if (index > -1) {
                        if (state.sceneTypeList[index].key === route.name) {
                            onClickMiddleMenu(state.sceneTypeList[0])
                        }
                        state.sceneTypeList.splice(index, 1)
                    }
                }
            }
        }
        const onAddSceneType = () => {
            state.sceneTypeManager.visible = true
        }
        const onCloseSceneTypeManagerModal = () => { }
        const onUpdateSceneTypeSetting = (newSceneTypeList) => {
            state.sceneTypeList = newSceneTypeList
            let index = state.sceneTypeList.findIndex(sceneType => sceneType.key === route.name)
            if (index < 0 && state.sceneTypeList[0]) {
                onClickMiddleMenu(state.sceneTypeList[0])
            } else if (!state.sceneTypeList[0]) {
                router.push({
                    path: `/menu`
                })
            }
        }
        const updateSceneTypeNameOk = (config) => {
            state.sceneTypeList = state.sceneTypeList.map(item => {
                if (item.key === config.typeId) {
                    item.label = config.typeName;
                }
                return item
            })
        }
        const _toSubRouter = (currentRoute) => {
            if (currentRoute.name === 'menu') {
                if (state.sceneTypeList.length > 0) {
                    router.push({
                        name: state.sceneTypeList[0].key
                    })
                    state.activeRouterName = state.sceneTypeList[0].key
                }
            } else if (currentRoute.name === 'organization') {
                if (currentRoute.params.id !== 'null') {
                    state.activeRouterName = currentRoute.params.id
                } else {
                    if (state.organizationList.length > 0) {
                        let _key = state.organizationList[0].key
                        router.replace({
                            path: `/menu/organization/${_key}`
                        })
                        state.activeRouterName = _key
                    }
                }
            } else {
                let _matchedRouterList = currentRoute.matched
                let _menuRouter = _matchedRouterList.find(_router => _router.name === 'menu')
                if (_menuRouter) {
                    state.activeRouterName = currentRoute.name

                }
            }
        }
        const switchLeftCollapse = () => {
            headerStore.changeCollapse()
        }
        const _setLeftNavConfig = () => {


            state.showMode = 'sceneTypeClassify'
            state.leftNavConfig = {
                title: '场景',
                showMiddleMenuMore: true,
                showAddButton: true
            }

        }

        const onSwitchMode = () => {
            router.push({
                path: "/home/workbench"
            })
        }
        return {
            route,
            state,
            showMoreMenuList,
            middleMenuList,
            onClickMiddleMenu,
            onClickFooterMenu,
            onAddSceneType,
            onCloseSceneTypeManagerModal,
            onUpdateSceneTypeSetting,
            switchLeftCollapse,
            onSwitchMode,
            clickMiddleMenuMore,
            updateSceneTypeNameOk,
            onChangeOrganization,
        }
    }
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-menu-container {
    width: 100%;
    height: 100%;
}

.portal-menu-change-icon {
    cursor: pointer;
    font-size: 14px;
}
</style>