<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:06:54
!-->

<template>
    <div class="portal-public-card-view">
        <div class="portal-public-card-view-wrapper">
            <main-card v-for="item in sceneList" :key="item.id" :ident="item.id" :title="item.title"
                :editable="hasEditAuth(item)" :publish="item.status===0?false:true" :more-menu-list="currentMoreMenuList(item)"
                :cover="item.backgroundUrl" @clikMenuItem="clikMenuItemHandler(item, $event)"
                @clickEdit="onClickEdit(item)"></main-card>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent
    } from 'vue'
    import mainCard from './main-card.vue'

    export default defineComponent({
        components: {
            mainCard
        },
        props: {
            sceneList: {
                type: Array,
                default: () => []
            },
            moreMenuList: {
                type: Array,
                default: () => []
            }
        },

        setup(props, ctx) {
            const clikMenuItemHandler = (scene, params) => {
                ctx.emit('clikMenuItem', scene, params)
            }
            const onClickEdit = (scene) => {
                ctx.emit('enter', scene)
            }
            const hasEditAuth = (scene) => {
                return window.authManager.editSceneAuth(scene.id)
            }
            const currentMoreMenuList = (scene) => {
                return props.moreMenuList.filter(_item => {
                    let _bool = true
                    let _disabled = _item.disabled
                    if(_disabled instanceof Function){
                        _bool = !_disabled(scene.id)
                    } else {
                        _bool = !_disabled
                    }
                    return _bool
                })
            }
            return {
                clikMenuItemHandler,
                onClickEdit,
                hasEditAuth,
                currentMoreMenuList
            }
        },
    })
</script>

<style lang='less' scoped>
    .portal-public-card-view {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .portal-public-card-view-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }
</style>