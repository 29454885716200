<template>
    <div class="portal-menu-table">
        <div class="portal-menu-table-wrapper">
            <div class="portal-menu-table-header">
                <cgs-tabs v-model:activeKey="state.categoryActiveKey" mode="button" @change="onChangeCategory">
                    <cgs-tab-pane v-for="item in state.categoryList" :key="item.categoryValue">
                        <template #tab>
                            <span>{{ item.categoryKey }}</span>
                        </template>
                    </cgs-tab-pane>

                    <template #rightExtra>
                        <div class="portal-menu-table-header-right">
                            <!-- <cgs-button @click="importScene">{{ $t('import') }}</cgs-button> -->
                            <cgs-button ghost @click="openAddSceneModal" v-if="hasAddSceneAuth">{{ $t('createScene')
                                }}</cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'card'"
                                @click="switchShowType('card')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-kapian"></span>
                                </template>
                            </cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'table'"
                                @click="switchShowType('table')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-liebiao"></span>
                                </template>
                            </cgs-button>
                            <cgs-button shape="circle" :ghost="state.showType !== 'icon'"
                                @click="switchShowType('icon')">
                                <template #icon>
                                    <span class="cgs-portal-iconfont cgs-portal-icon-tubiaopailie"></span>
                                </template>
                            </cgs-button>
                        </div>
                    </template>
                </cgs-tabs>
            </div>
            <div class="portal-menu-table-content">
                <div class="portal-menu-table-content-wrapper">
                    <div class="portal-menu-table-content-main">
                        <view-card :scene-list="state.currentSceneList" :more-menu-list="showMoreMenuList"
                            v-if="state.showType === 'card'" @clikMenuItem="clikSceneMenuItem" @enter="enterScene">
                        </view-card>
                        <view-table :scene-list="state.currentSceneList" :more-menu-list="showMoreMenuList"
                            v-else-if="state.showType === 'table'" @clikMenuItem="clikSceneMenuItem"
                            @enter="enterScene">
                        </view-table>
                        <view-icon :scene-list="state.currentSceneList" :more-menu-list="showMoreMenuList"
                            v-else-if="state.showType === 'icon'" @clikMenuItem="clikSceneMenuItem" @enter="enterScene">
                        </view-icon>
                    </div>
                    <div class="portal-menu-table-content-footer">
                        <cgs-pagination v-model:current="state.currentPagination.current"
                            v-model:pageSize="state.pageSize" show-quick-jumper show-size-changer
                            :page-size-options="pageSizeOptions" :total="state.currentPagination.total"
                            @change="onChangePagination" />
                    </div>
                </div>
            </div>
        </div>
        <edit-modal v-model:visible="state.editModalConfig.visible" :scene="state.editModalConfig.scene"
            :category-options="state.categoryList" :scene-type="sceneType" @close="closeEditModal"
            @success="onEditSuccess">
        </edit-modal>
        <add-modal v-model:visible="state.addModalConfig.visible" :category-options="state.categoryList"
            :selectCategory="state.categoryActiveKey" :scene-type="sceneType" @close="closeAddModal"
            @success="onAddSuccess">
        </add-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    getCurrentInstance,
    computed
} from 'vue'
import {
    useRouter
} from 'vue-router'
import {
    useI18n
} from 'vue-i18n'
import viewCard from "@components/view-card.vue"
import viewTable from "@components/view-table.vue"
import viewIcon from "@components/view-icon.vue"
import editModal from "./edit-modal.vue"
import addModal from "./add-modal.vue"

import {
    getCategory,
    getList,
    deleteScene,
    uploadZip
} from '@api/scene'
import {
    getUserInfo
} from '@/api/user'
import { addRecentUsed } from "@/api/workSpace"
import {
    setUserAuthority,
    setOriginPath
} from "@/utils/author.js";


export default defineComponent({
    components: {
        viewCard,
        viewTable,
        viewIcon,
        editModal,
        addModal
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance()
        const {
            t
        } = useI18n()
        let router = useRouter()
        const sceneType = 'FileStorage'
        const showTypeMap = {
            icon: {
                pageSize: 50
            },
            table: {
                pageSize: 20
            },
            card: {
                pageSize: 20
            },
        }
        const pageSizeOptions = ['10', '20', '30', '40', '50']
        const state = reactive({
            categoryActiveKey: '',
            showType: 'icon',
            categoryList: [],
            currentSceneList: [],
            currentPagination: {
                current: 1,
                total: -1
            },
            pageSize: 20,
            editModalConfig: {
                visible: false,
                scene: undefined
            },
            addModalConfig: {
                visible: false
            }

        })
        let moreMenuList = [{
            title: '',
            key: 'auth',
            icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
            disabled: (sceneId) => {
                return !window.authManager.manageAuthSceneAuth(sceneId)
            }
        },
        {
            title: '',
            key: 'edit',
            icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
            disabled: (sceneId) => {
                return !window.authManager.editSceneAuth(sceneId)
            }
        },
        {
            title: '',
            key: 'preview',
            icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
            disabled: true
        },
        {
            title: '',
            key: 'export',
            icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
            disabled: true
        },
        {
            title: '',
            key: 'delete',
            icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
            disabled: (sceneId) => {
                return !window.authManager.deleteSceneAuth(sceneId)
            }
        }
        ]
        const showMoreMenuList = computed(() => {
            return moreMenuList.map(_item => {
                _item['title'] = t('sceneMoreMenu.' + _item.key)
                return _item
            })
        })
        onMounted(async () => {
            state.pageSize = showTypeMap[state.showType].pageSize
            _init()
        })
        const _init = async () => {
            try {
                let _categoryResult = await getCategory(sceneType)
                if (_categoryResult.code === 200) {
                    state.categoryList = _categoryResult.data
                    state.categoryList.sort(function (a, b) {
                        return Number(a.categoryValue) - Number(b.categoryValue)
                    });
                    _setCategoryActiveKey()
                    _getSceneList()
                }
            } catch (err) {
                console.error(err)
            }
        }
        const hasAddSceneAuth = computed(() => {
            let _hasAuth = window.authManager.addSceneAuth()
            return _hasAuth
        })
        const _getSceneList = async () => {
            try {
                let _listParams = {
                    Current: state.currentPagination.current,
                    Size: state.pageSize,
                    Category: state.categoryActiveKey,
                    VisualType: sceneType
                }
                let _listResult = await getList(_listParams)
                if (_listResult.code === 200) {
                    let _listData = _listResult.data
                    state.currentSceneList = _listData.records;
                    state.currentPagination.total = _listData.total;
                }
            } catch (err) {
                console.error(err)
            }
        }

        const _setCategoryActiveKey = (key) => {
            if (key === undefined || key === null) {
                if (state.categoryList.length > 0) {
                    state.categoryActiveKey = state.categoryList[0].categoryValue
                }
            } else {
                state.categoryActiveKey = key
            }
        }
        const switchShowType = (type) => {
            if (state.showType === type) return
            state.showType = type
            state.pageSize = showTypeMap[type].pageSize
            _getSceneList()
        }
        const clikSceneMenuItem = (scene, menuItemParams) => {
            if (menuItemParams.key === 'edit') {
                state.editModalConfig = {
                    scene: scene,
                    visible: true
                }
            } else if (menuItemParams.key === 'export') { //差异化
                proxy.$confirm({
                    title: '提示',
                    content: '确定导出?',
                    onOk () {
                        let url =
                            `/api/Cgs/Portal/Export?visualId=${scene.id}&visualType=${sceneType}`;
                        window.open(url);
                    },
                    onCancel () { },
                });
            } else if (menuItemParams.key === 'delete') {
                deleteSceneById(scene.id)
            } else if (menuItemParams.key === 'auth') {
                router.push({
                    path: `/menu/authority/${sceneType}/${scene.id}`
                })
            }
        }
        const deleteSceneById = (id) => {
            proxy.$confirm({
                title: '提示',
                content: '是否确认永久删除?',
                async onOk () {
                    try {
                        let params = {
                            ID: id,
                            VisualType: sceneType
                        }
                        let _result = await deleteScene(params)
                        if (_result.code === 200) {
                            _getSceneList()
                        }
                    } catch (err) {
                        console.error(err)
                    }
                },
                onCancel () { },
            });
        }
        const onChangeCategory = () => {
            state.currentPagination = {
                current: 1,
                total: -1
            }
            _getSceneList()
        }
        const onChangePagination = () => {
            _getSceneList()
        }
        const closeEditModal = () => {
            state.editModalConfig.visible = false
        }
        const closeAddModal = () => {
            state.addModalConfig.visible = false
        }
        const onEditSuccess = () => {
            _getSceneList()
        }
        const onAddSuccess = () => {
            _getSceneList()
        }
        const enterScene = async (scene) => { //差异化
            setOriginPath(router.currentRoute.value.path)
            await addRecentUsed({
                id: scene.id
            })
            let url = '/file-storage/home/' + scene.id
            window.open(url, '_self');
        }
        const updateUserAuthority = () => {
            return new Promise((resolve, reject) => {
                getUserInfo().then(infoRes => {
                    if (infoRes.data && infoRes.success) {
                        setUserAuthority(infoRes.data);
                        window.authManager.init();
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        }
        const openAddSceneModal = () => {
            state.addModalConfig.visible = true
        }
        const importScene = () => { //差异化
            var inputObj = document.createElement('input')
            inputObj.setAttribute('id', 'importZip');
            inputObj.setAttribute('type', 'file');
            inputObj.setAttribute('name', 'importZip');
            inputObj.setAttribute("style", 'visibility:hidden');
            document.body.appendChild(inputObj);
            inputObj.click();
            inputObj.addEventListener('change', async e => {
                if (e.target.files[0]) {
                    proxy.$loading.open();
                    try {
                        let formdata = new FormData();
                        formdata.append("file", e.target.files[0]);

                        formdata.append("category", state.categoryActiveKey);
                        formdata.append("visualType", sceneType);
                        let _result = await uploadZip(formdata)
                        if (_result.code === 200) {
                            proxy.$message.success("导入成功")
                            await updateUserAuthority()
                            _getSceneList()
                        } else {
                            proxy.$message.error("导入失败")
                        }
                        proxy.$loading.close();
                    } catch (err) {
                        proxy.$message.error("导入失败")
                        console.error(err)
                        proxy.$loading.close();
                    }
                }
            });
        }
        return {
            sceneType,
            pageSizeOptions,
            state,
            hasAddSceneAuth,
            switchShowType,
            showMoreMenuList,
            clikSceneMenuItem,
            onChangeCategory,
            onChangePagination,
            closeEditModal,
            onEditSuccess,
            enterScene,
            closeAddModal,
            onAddSuccess,
            openAddSceneModal,
            importScene
        };
    }
})
</script>

<style lang="less" scoped>
.portal-menu-table {
    width: 100%;
    height: 100%;
}

.portal-menu-table-wrapper {
    width: 100%;
    height: 100%;
    padding: 8px 48px 8px 48px;
    display: flex;
    flex-direction: column;
}

.portal-menu-table-header {
    width: 100%;
    margin-top: 16px;
}

.portal-menu-table-header-right {
    display: flex;
}

.portal-menu-table-header-right>button {
    margin-left: 5px;
}

.portal-menu-table-content {
    flex: 1;
    height: 0;
}

.portal-menu-table-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.portal-menu-table-content-main {
    flex: 1;
    height: 0;
}

.portal-menu-table-content-footer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>