<template>
    <div class="portal-system-tenant-table">
        <div class="portal-system-tenant-table-wrapper">
            <cgs-table :columns="columns" :data-source="tableData" mode="portal" size="small" :pagination="false"
                :backgroundTransparent="true" position="bottomCenter">
                <template #headerCell="{ column }">
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-tenant-table-action-header">
                            <cgs-input style="width: 188px;" v-model:value="state.searchInput" placeholder="输入关键字搜索"
                                @change="changeSearchInput" />
                        </div>
                    </template>
                </template>
                <template #bodyCell="{ column, record, text }">
                    <template v-if="column.key === 'tenantState'">
                        <cgs-select ref="select" :value="text" style="width: 120px"
                            @change="handleChangeState($event, record)">
                            <cgs-select-option v-for="item in state_options" :disabled="item.disabled" :key="item.id"
                                :value="item.id">{{ item.name }}</cgs-select-option>
                        </cgs-select>
                    </template>
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-tenant-table-action">
                            <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler(record, $event)">
                            </cgs-more>
                        </div>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
} from 'vue'
const columns = [{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 50,
    customRender: ({
        index
    }) => `${index + 1}`,
}, {
    title: '租户ID',
    dataIndex: 'tenantId',
    key: 'tenantId',
    width: 150,
}, {
    title: '租户名称',
    dataIndex: 'tenantName',
    key: 'tenantName',
    width: 500,
}, {
    title: '管理员账号',
    dataIndex: 'tenantAdminName',
    key: 'tenantAdminName',
    width: 150,
}, {
    title: '用户状态',
    dataIndex: 'tenantState',
    key: 'tenantState',
    width: 150,
}, {
    title: '',
    dataIndex: 'action',
    key: 'action',
}];
export default defineComponent({
    components: {
    },
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        moreMenuList: {
            type: Array,
            default: () => []
        },
    },

    setup (porps, ctx) {
        const state = reactive({
            searchInput: ''
        })
        const state_options = [{
            id: 0,
            name: "启用"
        }, {
            id: 1,
            name: "未启用"
        }]
        const clikMenuItemHandler = (scene, params) => {
            ctx.emit('clikMenuItem', scene, params)
        }

        const handleChangeState = (state, column) => {
            ctx.emit('handleChangeState', state, column)
        }
        const changeSearchInput = () => {
            ctx.emit('searchInput', state.searchInput)
        }
        return {
            columns,
            state,
            clikMenuItemHandler,
            changeSearchInput,
            state_options,
            handleChangeState
        }
    },
})
</script>

<style>
.portal-system-tenant-table-action-header,
.portal-system-tenant-table-action {
    width: 100%;
    height: 100%;
    text-align: right;
}
</style>