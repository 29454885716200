<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 17:21:43
!-->

<template>
  <div class="portal-account-safe">
    <div class="portal-account-safe-item-content">
      <div class="portal-account-safe-item-content-item">
        <div>
          <span class="portal-account-safe-item-content-item-icon cgs-portal-iconfont cgs-portal-icon-shouji"></span>
          <span class="portal-account-safe-item-content-item-text1">{{$t('accountInfo.phone')}}</span>
        </div>
        <div><span class="portal-account-safe-item-content-item-text2">{{$t('accountInfo.unbind')}}</span></div>
      </div>
      <div class="portal-account-safe-item-content-item">
        <div>
          <span class="portal-account-safe-item-content-item-icon cgs-portal-iconfont cgs-portal-icon-youxiang"></span>
          <span class="portal-account-safe-item-content-item-text1">{{$t('accountInfo.email')}}</span>
        </div>
        <div><span class="portal-account-safe-item-content-item-text2">{{$t('accountInfo.unbind')}}</span></div>
      </div>
      <div class="portal-account-safe-item-content-item">
        <div>
          <span class="portal-account-safe-item-content-item-icon cgs-portal-iconfont cgs-portal-icon-mima"></span>
          <span class="portal-account-safe-item-content-item-text1">{{$t('accountInfo.password')}}</span>
        </div>
        <div class="portal-account-safe-item-content-item-passwork" @click="handleChangePassword">
          <span
            class="portal-account-safe-item-content-item-passwork-icon cgs-portal-iconfont cgs-portal-icon-xiugai"></span>
          <span class="portal-account-safe-item-content-item-text3">{{$t('accountInfo.modify')}}</span>
        </div>
      </div>
    </div>
    <updataPassword v-model:visible="changePasswordVisible" @close="cancelModal"></updataPassword>
  </div>
</template>

<script>
  import updataPassword from './components/updata.vue'
  import {
    defineComponent,
    ref
  } from 'vue'

  export default defineComponent({
    components: {
      updataPassword
    },
    props: {},

    setup() {
      const changePasswordVisible = ref(false)
      const handleChangePassword = () => {
        changePasswordVisible.value = true
      }
      const cancelModal = () => {
        changePasswordVisible.value = false;
      }
      return {
        changePasswordVisible,
        handleChangePassword,
        cancelModal,
      }
    },
  })
</script>

<style lang='less' scoped>
  @import "~@style/less/theme.less";

  .portal-account-safe {
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
  }

  .portal-account-safe-item-content {
    // margin-left: 30px;
  }

  .portal-account-safe-item-content-item {
    // margin-top: 10px;
    height: 40px;
    width: 323px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .border(0, 0, 1px, 0)
  }

  .portal-account-safe-item-content-item-text1 {
    .text-1();
    color: @font-color-2;
  }

  .portal-account-safe-item-content-item-text2 {
    .text-1();
    color: @font-color-3;
  }

  .portal-account-safe-item-content-item-text3 {
    .text-1();
    color: @primary-color;
  }

  .portal-account-safe-item-content-item-icon {
    margin-right: 5px;
    font-size: 11px;
    color: @font-color-2;
  }

  .portal-account-safe-item-content-item-passwork {
    cursor: pointer
  }

  .portal-account-safe-item-content-item-passwork-icon {
    margin-right: 5px;
    font-size: 10px;
    color: @primary-color;
  }
</style>