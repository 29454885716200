<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:06:54
!-->

<template>
    <div class="portal-public-table-view">
        <div class="portal-public-table-view-wrapper">
            <cgs-table :columns="showColumns" :data-source="sceneList" :pagination="false" mode="portal" size="small"
                :backgroundTransparent="true">
                <template #bodyCell="{ column, record, text }">
                    <template v-if="column.key === 'title'">
                        <div class="portal-public-table-view-title" @click="onEnterHandler(record)">
                            <div class="portal-public-table-view-title-icon">
                                <scene-icon :icon="record.icon" :themeName="record.theme" iconSize="12px"></scene-icon>
                            </div>
                            <div class="portal-public-table-view-title-value">{{ text }}</div>
                        </div>
                    </template>
                    <template v-else-if="column.key === 'status'">
                        <span class="portal-public-table-view-status-nopublish"
                            v-if="text === 0">{{$t('sceneShowType.published')}}</span>
                        <span class="portal-public-table-view-status-published"
                            v-else>{{$t('sceneShowType.unpublish')}}</span>
                    </template>
                    <template v-else-if="column.key === 'action'">
                        <div class="portal-public-table-view-action">
                            <cgs-more :menu-list="currentMoreMenuList(record)"
                                @clikMenuItem="clikMenuItemHandler(record, $event)"
                                v-if="currentMoreMenuList.length > 0">
                            </cgs-more>
                        </div>

                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>

<script>
    import {
      computed,
        defineComponent
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    import sceneIcon from '@components/scene-icon.vue'
    export default defineComponent({
        components: {
            sceneIcon
        },
        props: {
            sceneList: {
                type: Array,
                default: () => []
            },
            moreMenuList: {
                type: Array,
                default: () => []
            }
        },

        setup(props, ctx) {
            const {
               t
            } = useI18n()
            const columns = [{
                title: '',
                dataIndex: 'index',
                key: 'index',
                fixed: 'left',
                width: 50,
                customRender: ({
                    index
                }) => `${index + 1}`,
            }, {
                title: '',
                dataIndex: 'title',
                key: 'title',
                width: 500,
            }, {
                title: '',
                dataIndex: 'username',
                key: 'username',
                width: 150,
            }, {
                title: '',
                dataIndex: 'status',
                key: 'status',
                width: 150,
            }, {
                title: '',
                dataIndex: 'action',
                key: 'action',
                fixed: 'right'
            }];
            const showColumns = computed(() => {
                return columns.map(_item => {
                    _item['title'] = t('sceneShowType.table.'+_item.key)
                    return _item
                })
            })
            const clikMenuItemHandler = (scene, params) => {
                ctx.emit('clikMenuItem', scene, params)
            }
            const rowClick = (record) => {
                return {
                    onClick: () => {
                        hasEditAuth(record) && ctx.emit('enter', record)
                    }
                }
            }
            const onEnterHandler = (record) => {
                ctx.emit('enter', record)
            }
            const hasEditAuth = (scene) => {
                return window.authManager.editSceneAuth(scene.id)
            }
            const currentMoreMenuList = (scene) => {
                return props.moreMenuList.filter(_item => {
                    let _bool = true
                    let _disabled = _item.disabled
                    if (_disabled instanceof Function) {
                        _bool = !_disabled(scene.id)
                    } else {
                        _bool = !_disabled
                    }
                    return _bool
                })
            }
            return {
                showColumns,
                clikMenuItemHandler,
                rowClick,
                onEnterHandler,
                currentMoreMenuList,
                hasEditAuth
            }
        },
    })
</script>

<style lang='less' scoped>
    @import "~@style/less/theme.less";

    .portal-public-table-view {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .portal-public-table-view-wrapper {
        width: 100%;
        height: 100%;
    }

    .portal-public-table-view-title {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .portal-public-table-view-title-icon {
        width: 25px;
        height: 25px;
        margin-right: 8px;
    }

    .portal-public-table-view-status-nopublish {
        color: @font-color-2;
    }

    .portal-public-table-view-status-published {
        color: @font-color-1;
    }

    .portal-public-table-view-action {
        width: 100%;
        height: 100%;
        text-align: right;
    }

    .portal-public-table-view-title-value {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>