<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 17:21:43
!-->

<template>
  <div class="portal-system-home">
    <div class="portal-system-home-wrapper">
      <div class="portal-system-home-header">
        <div>
          <div class="portal-system-home-header-title-circle"></div>
        </div>
        <div class="portal-system-home-header-title">
          <span class="portal-system-home-header-title-text-1 portal-system-home-header-title-font-1">{{ state.account.version }}&nbsp;</span>
          <span class="portal-system-home-header-title-font-2">{{state.account.isTrial ? `${$t('systemInfo.tryOut')}-` : ''}}{{ version }}</span>
          <span class="portal-system-home-header-title-font-2" style="margin-left: 48px">{{$t('systemInfo.remain')}}</span>
          <span class="portal-system-home-header-title-text-1 portal-system-home-header-title-primary">&nbsp;{{ remainingDays }}&nbsp;</span>
          <span class="portal-system-home-header-title-font-2">{{$t('systemInfo.day1')}}</span>
          <span class="portal-system-home-header-title-primary" style="margin-left: 48px">{{ state.account.endDate[0] }}</span>
          <span class="portal-system-home-header-title-font-2">{{$t('systemInfo.year')}}</span>
          <span class="portal-system-home-header-title-primary">{{ state.account.endDate[1] }}</span>
          <span class="portal-system-home-header-title-font-2">{{$t('systemInfo.month')}}</span>
          <span class="portal-system-home-header-title-primary">{{ state.account.endDate[2] }}</span>
          <span class="portal-system-home-header-title-font-2">{{`${$t('systemInfo.day2')} &nbsp; ${$t('systemInfo.expires')}`}}</span>
        </div>
      </div>
      <div class="portal-system-home-content" v-if="hasSetSystemHomeAuth">
        <div class="portal-system-home-content-item">
          <div class="portal-system-home-content-item-title">{{$t('systemInfo.logo')+':'}}</div>
          <img class="portal-system-home-content-item-img" :src="state.sysInfo.logo">
          <cgs-upload action="/api/Cgs/Portal/Visual/PutFile" v-model:file-list="state.logoFileList" :show-upload-list="state.showList"
            :before-upload="handleBeforeUpload" @change="handleSuccess">
            <cgs-button class="portal-system-home-content-item-button" type="primary">
              <template #icon>
                <span
                  class="cgs-portal-iconfont cgs-portal-icon-shangchuan portal-system-home-content-item-icon"></span>
              </template>
              {{$t('systemInfo.uploadLogo')}}
            </cgs-button>
          </cgs-upload>
          <cgs-button ghost class="portal-system-home-content-item-button-cancel" @click="handleCancelLogo">{{$t('common.cancel')}}
          </cgs-button>
        </div>
        <div class="portal-system-home-content-item">
          <div class="portal-system-home-content-item-title">{{$t('systemInfo.loginBg')+':'}}</div>
          <img class="portal-system-home-content-item-img" :src="state.sysInfo.bgImg">
          <cgs-upload class="upload-demo" action="/api/Cgs/Portal/Visual/PutFile" v-model:file-list="state.bgFileList" :show-upload-list="state.showList"
            :before-upload="handleBeforeUpload" @change="handleBgImgSuccess">
            <cgs-button class="portal-system-home-content-item-button" type="primary">
              <template #icon>
                <span
                  class="cgs-portal-iconfont cgs-portal-icon-shangchuan portal-system-home-content-item-icon"></span>
              </template>
              {{$t('systemInfo.uploadImg')}}
            </cgs-button>
          </cgs-upload>
          <cgs-button ghost class="portal-system-home-content-item-button-cancel" @click="handleCancelBgImg">{{$t('common.cancel')}}
          </cgs-button>
        </div>
        <div class="portal-system-home-content-item">
          <div class="portal-system-home-content-item-title">{{$t('common.theme')+':'}}</div>
          <cgs-select v-model:value="state.sysInfo.theme" class="portal-system-home-content-item-theme"
            @change="changeThemeHandler">
            <cgs-select-option value="cgs_light">{{$t('systemInfo.light')}}</cgs-select-option>
            <cgs-select-option value="cgs_dark">{{$t('systemInfo.dark')}}</cgs-select-option>
          </cgs-select>
        </div>
        <div class="portal-system-home-content-item">
          <div class="portal-system-home-content-item-title">{{$t('systemInfo.name')+':'}}</div>
          <cgs-input v-model:value="state.sysInfo.name" class="portal-system-home-content-item-input"></cgs-input>
        </div>
        <div class="portal-system-home-content-item">
          <div class="portal-system-home-content-item-title">{{$t('systemInfo.dominFiling')+':'}}</div>
          <cgs-input v-model:value="state.sysInfo.domainRegistrationInfo" class="portal-system-home-content-item-input">
          </cgs-input>
        </div>
        <div class="portal-system-home-content-button">
          <cgs-button class="portal-system-home-content-item-button" type="primary" @click="handleOk">
            <template #icon>
              <span class="cgs-portal-iconfont cgs-portal-icon-baocun portal-system-home-content-item-icon"></span>
            </template>
            {{$t('common.save')}}
          </cgs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    computed,
    getCurrentInstance,
    onMounted
  } from 'vue'
  import {
    getSystemVersion,
    getTenantInfo,
    getSystemInfo,
    setSystemInfo
  } from '@/utils/author'

  // import {
  //   getTenantInfo,
  // } from '@/api/auth'

  import {
    updateSysInfo
  } from '@/api/system'

  export default defineComponent({
    props: {},

    setup() {
      let {
        proxy
      } = getCurrentInstance();
      const state = reactive({
        sysInfo: {
          logo: '',
          bgimg: '',
          theme: 'cgs_light',
          name: 'MAPAAS',
          domainRegistrationInfo: '京ICP备18004264号2',
        },
        account: {
          // beginDate: ['', '', ''],
          endDate: ['2221', '01', '25'],
          isTrial: false,
          remainingDays: 72230,
          version: '企业版',
          timeout: false
        },
        systemVersion: {
          version: '',
          releaseType: ''

        },
        logoFileList: [],
        bgFileList: [],
        showList: false,
      })
      onMounted(() => {
        let tenantInfo = getTenantInfo()
        let systemInfo = getSystemInfo()
        state.account = tenantInfo.account;
        // state.tenantId = tenantInfo.tenantId;
        if (state.account.version === "") {
          state.account.version = "企业版"
        }
        state.account.beginDate = stringToDate(state.account.beginDate);
        state.account.endDate = stringToDate(state.account.endDate)
        state.sysInfo = systemInfo
      })
      const stringToDate = (str) => {
        let tempStrs = str.split(" ");
        return tempStrs[0].split("-");
      }
      const handleBgImgSuccess = (info) => {
        if (info.file.status === 'done') {
          state.sysInfo.bgImg = info.file.response.data.link
        }
      }
      const handleSuccess = (info) => {
        if (info.file.status === 'done') {
          state.sysInfo.logo = info.file.response.data.link
        }
      }
      const handleBeforeUpload = () => {
        if (releaseType.value === "PUBLIC") {
          return true
        } else if (releaseType.value === "PRIVATE") {
          if (state.account.version === "企业版") {
            return true
          } else {
            proxy.$message.warning("社区版不支持上传，敬请理解！")
            return false
          }
        }
      }
      const handleCancelLogo = () => {
        state.sysInfo.logo = ''
      }
      const handleCancelBgImg = () => {
        state.sysInfo.bgImg = ''
      }
      const handleOk = async () => {
        if (releaseType.value === "PRIVATE") {
          if (state.account.version !== "企业版") {
            proxy.$message.warning("社区版不支持修改名称，敬请理解！")
            return
          }
        }
        try {
          proxy.$loading.open()
          let _result = await updateSysInfo(state.sysInfo)
          if (_result.code === 200) {
            setSystemInfo(state.sysInfo)
            proxy.$message.success("保存成功！")
          }
          proxy.$loading.close()
        } catch (err) {
          console.error(err)
          proxy.$loading.close()
        }
      }
      const version = computed({
        get: () => {
          let _systemVersion = getSystemVersion()
          return _systemVersion.version
        },
        set: () => {}
      })
      const releaseType = computed({
        get: () => {
          let _systemVersion = getSystemVersion()
          return _systemVersion.releaseType
        },
        set: () => {}
      })
      const remainingDays = computed(() => {
        let _num = 72230
        if(state && state.account && state.account.remainingDays){
          _num = state.account.remainingDays.toFixed(0)
        }
        return _num
      })
      const hasSetSystemHomeAuth = computed(() => {
        return window.authManager.setSystemHomeAuth()
      })
      const changeThemeHandler = () => {
        window.themeManager.replaceTheme(state.sysInfo.theme)
      }
      return {
        state,
        version,
        releaseType,
        hasSetSystemHomeAuth,
        remainingDays,
        handleCancelLogo,
        handleCancelBgImg,
        handleBgImgSuccess,
        handleSuccess,
        handleBeforeUpload,
        handleOk,
        changeThemeHandler
      }
    },
  })
</script>

<style lang='less' scoped>
  @import "~@style/less/theme.less";

  .portal-system-home {
    width: 100%;
    height: 100%;
  }

  .portal-system-home-wrapper {
    width: 100%;
    height: 100%;
    // padding: 10px 30px;
    padding: 24px 48px 8px 48px;
  }

  .portal-system-home-header {
    height: 48px;
    width: 100%;
    .border(0, 0, 1px, 0);
    display: flex;
    align-items: center;
    padding-bottom: 8px;
  }

  .portal-system-home-header-title {
    display: flex;
    align-items: flex-end;
    margin-right: 30px;
    line-height: 1;
  }

  .portal-system-home-header-title-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid @primary-color;
    margin-right: 10px
  }

  .portal-system-home-header-title-text-1 {
    // .little-title;
    font-size: 24px;
  }
  

  .portal-system-home-header-title-primary{
    color: @primary-color;
  }

  .portal-system-home-header-title-font-1{
    color: @font-color-1;
  }

  .portal-system-home-header-title-font-2 {
    // .text-1();
    color: @font-color-2;
  }

  .portal-system-home-content {
    width: 400px;
    color: @font-color-1;
  }

  .portal-system-home-content-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .portal-system-home-content-item-img {
    width: 30px;
    height: 30px;
  }

  .portal-system-home-content-item-title {
    width: 107px;
    .text-1()
  }

  .portal-system-home-content-item-button {
    margin: 0 28px;
  }

  .portal-system-home-content-item-button,
  .portal-system-home-content-item-button-cancel {
    .text-2();
    width: 100px;
    height: 32px;
  }

  .portal-system-home-content-item-theme {
    width: 286px;
  }

  .portal-system-home-content-item-input {
    width: 286px;
  }

  .portal-system-home-content-button {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .portal-system-home-content-item-icon {
    font-size: 10px;
    margin-right: 5px;
  }
</style>