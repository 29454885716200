<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 10:10:49
!-->

<template>
  <div class="portal-menu-authority-admin-role">
    <div class="portal-menu-authority-admin-role-wrapper">
      <div class="portal-menu-authority-admin-role-header">
        <role-card :title="$t('auth.title')" :desc="$t('auth.desc')" icon="cgs-portal-iconfont cgs-portal-icon-guanliyuan"
          :num="dataSource.length"></role-card>
      </div>
      <div class="portal-menu-authority-admin-role-content">
        <cgs-table :columns="showColumns" :data-source="showDataSource" :pagination="false" mode="portal" size="small"
          :backgroundTransparent="true">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'operation'">
              <div class="portal-menu-authority-admin-role-operation">
                <cgs-button ghost @click="deleteRoleUser">
                  <template #icon>
                    <span class="cgs-portal-iconfont cgs-portal-icon-shanchu"></span>
                  </template>
                  <span class="portal-menu-authority-admin-role-button-text">{{ $t('auth.adminRole.PersonnelRemoval')
                  }}</span>
                </cgs-button>
                <cgs-button ghost @click="openSelectDepartmentModal">
                  <template #icon>
                    <span class="cgs-portal-iconfont cgs-portal-icon-tianjia"></span>
                  </template>
                  <span class="portal-menu-authority-admin-role-button-text">{{ $t('auth.adminRole.Department') }}</span>
                </cgs-button>
                <cgs-button ghost @click="openSelectUserModal">
                  <template #icon>
                    <span class="cgs-portal-iconfont cgs-portal-icon-tianjia"></span>
                  </template>
                  <span class="portal-menu-authority-admin-role-button-text">{{ $t('auth.adminRole.AddPeople') }}</span>
                </cgs-button>
              </div>
            </template>
          </template>
          <template #bodyCell="{ column, record, text, index }">
            <template v-if="column.key === 'index'">
              <div class="portal-menu-authority-admin-role-checkbox">
                <cgs-checkbox :checked="state.checkedList.includes(record.id)" :disabled="ownerUserId === record.id"
                  @change="onChangeCheckedList(record.id)"></cgs-checkbox>
                <span class="portal-menu-authority-admin-role-index">{{ index + 1 }}</span>
              </div>
            </template>
            <template v-if="column.key === 'type'">
              <span v-if="text === 'user'">{{ $t('auth.typeCollection.user') }}</span>
              <span v-else-if="text === 'department'">{{ $t('auth.typeCollection.department') }}</span>
            </template>
          </template>
        </cgs-table>
      </div>
      <div class="portal-menu-authority-admin-role-footer">
        <cgs-pagination v-model:current="state.pagination.current" v-model:pageSize="state.pagination.pageSize"
          show-quick-jumper show-size-changer :total="state.pagination.total" @change="onChangePagination" />
      </div>
    </div>
    <select-user-modal v-model:visible="state.selectUserModalConfig.visible" :disabled-ids="[ownerUserId]"
      :default-selected-list="currentUserIdList" @selected="onSelectedUser"></select-user-modal>
    <select-department-modal v-model:visible="state.selectDepartmentModalConfig.visible"
      :default-selected-list="currentDepartmentIdList" @selected="onSelectedDepartment"></select-department-modal>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  watch
} from 'vue'

import {
  useI18n
} from 'vue-i18n'
import roleCard from './role-card.vue'
import selectUserModal from '@/components/select-user-modal.vue'
import selectDepartmentModal from '@/components/select-department-modal.vue'
import {
  updateRoleUser
} from '@api/scene'
const columns = [{
  title: '序号/全选',
  dataIndex: 'index',
  key: 'index'
},
{
  title: '名称',
  dataIndex: 'name',
  key: 'name',
},
{
  title: '用户角色',
  dataIndex: 'role',
  key: 'role',
},
{
  title: '类型',
  dataIndex: 'type',
  key: 'type',
},
{
  title: '部门',
  dataIndex: 'department',
  key: 'department',
},
{
  title: '加入时间',
  dataIndex: 'addTime',
  key: 'addTime',
},
{
  title: '',
  dataIndex: 'operation',
  key: 'operation',
  width: '350px',
  fixed: 'right'
}
]
export default defineComponent({
  components: {
    roleCard,
    selectUserModal,
    selectDepartmentModal
  },
  props: {
    roleList: {
      type: Array,
      default: () => []
    }
  },

  setup(props, ctx) {
    const { t } = useI18n()
    const {
      proxy
    } = getCurrentInstance()
    const state = reactive({
      roleConfig: undefined,
      selectUserModalConfig: {
        visible: false
      },
      selectDepartmentModalConfig: {
        visible: false
      },
      checkedList: [],
      pagination: {
        current: 1,
        pageSize: 20,
        total: -1
      }
    })
    const showColumns = computed(() => {
      return columns.map(_item => {
        _item.title = t('auth.adminRoleTalbe.' + _item.key)
        return _item
      })
    })

    onMounted(() => {
      _init()
    })

    watch(() => props.roleList, () => {
      _init()
    })
    const roleId = computed(() => {
      return state.roleConfig.id
    })
    const ownerUserId = computed(() => {
      let _id = ''
      if (state.roleConfig) {
        let _userList = state.roleConfig.users;
        if (!_userList) {
          _userList = []
        }
        let _user = _userList.find(_user => _user.isOwner)
        if (_user) {
          _id = _user.userId
        }

      }
      return _id
    })
    const currentUserIdList = computed(() => {
      let _list = []
      if (state.roleConfig) {
        let _userList = state.roleConfig.users;
        if (!_userList) {
          _userList = []
        }
        let _userIdList = _userList.map(_user => {
          return _user.userId
        })
        _list = _userIdList
      }
      return _list
    })
    const currentDepartmentIdList = computed(() => {
      let _list = []
      if (state.roleConfig) {
        let _departmentList = state.roleConfig.departments;
        if (!_departmentList) {
          _departmentList = []
        }
        let _departmentIdList = _departmentList.map(_department => {
          return _department.id
        })
        _list = _departmentIdList
      }

      return _list
    })
    const dataSource = computed(() => {
      let _list = []
      if (state.roleConfig && state.roleConfig.users) {
        let _userList = state.roleConfig.users;
        let _departmentList = state.roleConfig.departments;
        let _userData = _userList.map(_user => {
          return {
            id: _user.userId,
            name: _user.nickName,
            department: '',
            type: 'user',
            role: _user.isOwner ? t('auth.Owner') : t('auth.AppAdministrator'),
            addTime: ''
          }
        })
        let _departmentData = _departmentList.map(_department => {
          return {
            id: _department.id,
            name: _department.name,
            department: '',
            type: 'department',
            role: t('auth.AppAdministrator'),
            addTime: ''
          }
        })
        _list = [..._userData, ..._departmentData]
      }

      return _list
    })
    watch(dataSource, () => {
      state.pagination.total = dataSource.value.length
    })
    const showDataSource = computed(() => {
      let _pagination = state.pagination
      return dataSource.value.filter((item, index) => {
        return index >= (_pagination.pageSize * (_pagination.current - 1)) && index < _pagination
          .pageSize * _pagination.current
      })
    })
    const _init = () => {
      if (props.roleList.length > 0) {
        state.roleConfig = props.roleList[0]
      } else {
        state.roleConfig = undefined
      }
    }
    const openSelectUserModal = () => {
      state.selectUserModalConfig.visible = true
    }
    const openSelectDepartmentModal = () => {
      state.selectDepartmentModalConfig.visible = true
    }
    const onSelectedUser = async (userList) => {
      try {
        userList = userList.sort((user1, user2) => {
          let _id1 = user1.userId
          let _id2 = user2.userId
          let _i1 = _id1 === ownerUserId.value ? 1 : 0
          let _i2 = _id2 === ownerUserId.value ? 1 : 0
          return _i2 - _i1
        })
        let _userIdList = userList.map(_user => _user.userId)
        let params = {
          departmentIds: currentDepartmentIdList.value,
          id: roleId.value,
          userIds: _userIdList
        }
        await _updateRoleUserAndDepartment(params)
        state.roleConfig.users = userList.map(_user => {
          return {
            avatar: "",
            isOwner: _user.userId === ownerUserId.value,
            nickName: _user.name,
            userId: _user.userId
          }
        })
        ctx.emit('update:roleList', [state.roleConfig])
        proxy.$message.success(t('auth.authInfo.renewSuccess'))
      } catch (err) {
        console.error(err)
        proxy.$message.error(t('auth.authInfo.renewFail'))
      }
    }
    const onSelectedDepartment = async (departmentList) => {
      try {
        let _departmentIdList = departmentList.map(_user => _user.id)
        let params = {
          departmentIds: _departmentIdList,
          id: roleId.value,
          userIds: currentUserIdList.value
        }
        await _updateRoleUserAndDepartment(params)
        state.roleConfig.departments = departmentList.map(_department => {
          return {
            adminId: _department.adminId,
            adminName: _department.adminName,
            ancestors: _department.ancestors,
            id: _department.id,
            name: _department.name,
            parentId: _department.parentId
          }
        })
        ctx.emit('update:roleList', [state.roleConfig])
        proxy.$message.success(t('auth.authInfo.renewSuccess'))
      } catch (err) {
        console.error(err)
        proxy.$message.error(t('auth.authInfo.renewFail'))
      }
    }
    const _updateRoleUserAndDepartment = (params) => {
      return new Promise((resolve, reject) => {
        try {
          updateRoleUser(params).then(result => {
            if (result.code === 200) {
              resolve(true)
            } else {
              reject(false)
            }
          })
        } catch (err) {
          console.error(err)
          reject(false)
        }
      })
    }
    const onChangeCheckedList = (id) => {
      let _index = state.checkedList.findIndex(_item => _item === id)
      if (_index < 0) {
        state.checkedList.push(id)
      } else {
        state.checkedList.splice(_index, 1)
      }
    }
    const deleteRoleUser = () => {
      try {
        if (state.checkedList.length === 0) return

        proxy.$confirm({
          title: t('common.remind'),
          content: t('auth.authInfo.deleteRemind'),
          async onOk() {
            let _departmentIdList = currentDepartmentIdList.value.filter(_item => !state.checkedList
              .includes(
                _item))
            let _userIdList = currentUserIdList.value.filter(_item => !state.checkedList.includes(_item))
            let params = {
              departmentIds: _departmentIdList,
              id: roleId.value,
              userIds: _userIdList
            }
            await _updateRoleUserAndDepartment(params)
            state.roleConfig.departments = state.roleConfig.departments.filter(_item => _departmentIdList
              .includes(_item.id))
            state.roleConfig.users = state.roleConfig.users.filter(_item => _userIdList.includes(_item
              .userId))
            ctx.emit('update:roleList', [state.roleConfig])
            state.checkedList = []
            proxy.$message.success(t('auth.authInfo.renewSuccess'))
          },
          onCancel() { },
        });

      } catch (err) {
        console.error(err)
        proxy.$message.error(t('auth.authInfo.renewFail'))
      }
    }
    const onChangePagination = () => { }
    return {
      showColumns,
      state,
      dataSource,
      showDataSource,
      columns,
      currentUserIdList,
      currentDepartmentIdList,
      ownerUserId,
      openSelectUserModal,
      openSelectDepartmentModal,
      onSelectedUser,
      onSelectedDepartment,
      onChangeCheckedList,
      deleteRoleUser,
      onChangePagination
    }
  },
})
</script>

<style lang='less' scoped>
@import '~@style/less/theme.less';

.portal-menu-authority-admin-role {
  width: 100%;
  height: 100%;
}

.portal-menu-authority-admin-role-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.portal-menu-authority-admin-role-header {
  height: 120px;
  display: flex;
  align-items: center;
}

.portal-menu-authority-admin-role-content {
  flex: 1;
  height: 0;
  overflow-y: auto;
}

.portal-menu-authority-admin-role-button-text {
  margin-left: 4px;
}

.portal-menu-authority-admin-role-operation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.portal-menu-authority-admin-role-index {
  margin-left: 8px;
}

.portal-menu-authority-admin-role-footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid @border-color;
  margin-top: 4px;
}
</style>