<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:21:13
!-->

<template>
  <div class="portal-public-main-card" ref="mainCard" :style="{height: cardHeight+'px'}">
    <div class="portal-public-main-card-wrapper">
      <div class="portal-public-main-card-cover">
        <div class="portal-public-main-card-mask">
          <img class="portal-public-main-card-cover-img" :src="showCover" alt="">
        </div>
        <div class="portal-public-main-card-edit" v-if="editable">
          <cgs-button type="primary" @click="clickEditHandler">{{$t('sceneShowType.edit')}}</cgs-button>
        </div>

        <!-- <div class="portal-public-main-card-publish">
          <span v-if="publish" class="portal-public-main-card-published">已发布</span>
          <span v-else class="portal-public-main-card-unpublish">未发布</span>
        </div> -->
      </div>
      <div class="portal-public-main-card-more">
        <cgs-more :vertical="true" :menu-list="moreMenuList" moreIconColorMode="dark" v-if="moreMenuList.length>0"
          @clikMenuItem="clikMenuItemHandler"></cgs-more>
      </div>
      <div class="portal-public-main-card-info">
        <span>{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    onBeforeUnmount
  } from 'vue'

  export default defineComponent({
    props: {
      ident: {
        type: String,
        default: undefined
      },
      title: {
        type: String,
        default: ''
      },
      cover: {
        type: String,
        default: require('@image/cardCover.png')
      },
      publish: {
        type: Boolean,
        default: undefined
      },
      moreMenuList: {
        type: Array,
        default: () => []
      },
      editable: {
        type: Boolean,
        default: false
      }
    },

    setup(props, ctx) {
      const mainCard = ref(null)
      let resizeHandler = null
      const cardHeight = ref(240)
      // const cardHeight = computed(() => {
      //   let _height = 240
      //   if(mainCard.value){
      //     _height = mainCard.value.clientWidth * 2 / 3;
      //   }
      //   return _height
      // })
      const showCover = computed(() => {
        let _cover = props.cover
        if (!_cover || _cover === '') {
          _cover = require('@image/cardCover.png')
        }
        return _cover
      })
      onMounted(() => {
        cardHeight.value = mainCard.value.clientWidth * 2 / 3;

        resizeHandler = window.addEventListener('resize', () => {
          if (!mainCard.value) {
            return
          }
          cardHeight.value = mainCard.value.clientWidth * 2 / 3;
        }, false)
      })
      onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeHandler)
      })
      const clikMenuItemHandler = (params) => {
        ctx.emit('clikMenuItem', params)
      }
      const clickEditHandler = () => {
        ctx.emit('clickEdit')
      }
      return {
        mainCard,
        cardHeight,
        clikMenuItemHandler,
        clickEditHandler,
        showCover
      }
    },
  })
</script>

<style lang='less' scoped>
  @import "~@style/less/theme.less";

  .portal-public-main-card {
    //margin: 5px;
    width: 24.2%;
    margin: 0.4%;
    border-radius: 6px;
    .border();
    background: @card-color;
    overflow: hidden;

    @media screen and (min-width: 1800px) {
      width: 19.2%;
      margin: 0.4%;
    }
  }

  .portal-public-main-card-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .portal-public-main-card-cover {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100% - 44px);
  }

  .portal-public-main-card-cover:hover {
    // filter: blur(3px)
  }

  .portal-public-main-card-cover-img {
    width: 100%;
    height: 100%;
  }

  .portal-public-main-card-info {
    width: 100%;
    padding: 10px;
    .text-1();
    color: @font-color-1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .portal-public-main-card-mask {
    width: 100%;
    height: 100%;
  }

  .portal-public-main-card-cover:hover .portal-public-main-card-mask {
    filter: blur(8px)
  }

  .portal-public-main-card-cover:hover .portal-public-main-card-edit {
    display: flex;
  }

  .portal-public-main-card-edit {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: none;
    justify-content: center;
    align-items: center;
  }

  .portal-public-main-card-publish {
    position: absolute;
    top: 16px;
    left: 16px;
    display: inline;
    border-radius: 2px;

  }

  .portal-public-main-card-unpublish {}

  .portal-public-main-card-more {
    position: absolute;
    top: 16px;
    right: 16px;
    display: inline;
    border-radius: 2px;
  }

  .portal-public-main-card-cover:hover+.portal-public-main-card-more {
    filter: blur(2px)
  }
</style>