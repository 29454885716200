<!--
*@description 
*@author 杜超峰
*@date 2023/04/24 17:21:13
!-->

<template>
    <div class="portal-public-main-icon-card" @mouseenter="collect = true" @mouseleave="collect = false">
        <div class="portal-public-main-icon-card-wrapper"
            :class="editable ? 'portal-public-main-icon-card-editable' : ''">
            <div class="portal-public-main-icon-card-cover" @click="clickEditHandler">
                <div class="portal-public-main-icon-card-cover-wrapper" v-if="appType">
                    <scene-icon :icon="icon" :themeName="themeName"></scene-icon>
                </div>
                <div class="portal-public-main-icon-card-cover-wrapper" v-if="!appType">
                    <div class="portal-public-main-icon-card-cover-wrapper-box">
                        <!-- <span class="portal-public-scene-icon-span" :class="[iconFontFamily, showIcon]"></span> -->
                        <span
                            class="portal-public-scene-icon-span cgs-portal-iconfont cgs-portal-icon-gongzuokongjian1"></span>
                    </div>

                </div>
            </div>
            <div class="portal-public-main-icon-card-info" @click="clickEditHandler">
                <div class="portal-public-main-icon-card-title"><span>{{ title }}</span></div>
                <div class="portal-public-main-icon-card-publish" v-if="publish !== undefined">
                    <span class="app-card-text-type-tab">{{ appType ? appType : $t('homeBench.workSpace') }}</span>
                    <span class="portal-public-main-icon-card-published" v-if="publish && appType">{{
                        $t('sceneShowType.published')
                    }}</span>
                    <span class="portal-public-main-icon-card-unpublish" v-if="!publish && appType">{{
                        $t('sceneShowType.unpublish')
                    }}</span>
                </div>
            </div>
            <div class="portal-public-main-icon-card-more">
                <span style="color: #F2B026;" v-if="isCollect" @click="collectWork"
                    class="collect  cgs-portal-iconfont cgs-portal-icon-a-shoucang-yishoucang"></span>
                <span v-if="collect && !isCollect" @click="collectWork"
                    class=" collect portal-menu-left-nav-content-item-icon cgs-portal-iconfont cgs-portal-icon-shoucang"></span>
                <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler"
                    v-if="moreMenuList.length > 0"></cgs-more>
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    ref, computed
} from 'vue'
import sceneIcon from '@components/scene-icon.vue'
import {
    profix,
    font_family,
    defaultIcon_app,

} from '@js/tableIconAndTheme'

export default defineComponent({
    components: {
        sceneIcon
    },
    props: {
        ident: {
            type: String,
            default: undefined
        },
        appType: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ''
        },
        publish: {
            type: Boolean,
            default: undefined
        },
        icon: {
            type: String,
            default: ''
        },
        themeName: {
            type: String,
            default: ''
        },
        moreMenuList: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: false
        },
        isCollect: {
            type: Boolean,
            default: false
        }
    },

    setup (props, ctx) {
        const clikMenuItemHandler = (params) => {
            ctx.emit('clikMenuItem', params)
        }
        const clickEditHandler = () => {
            if (props.appType) {
                props.editable && ctx.emit('clickEdit')
            } else {
                ctx.emit('clickEdit')
            }

        }
        const collectWork = () => {
            ctx.emit('clickCollect')
        }
        const collect = ref(false)
        let iconFontFamily = font_family
        let showIcon = computed({
            get: () => {
                let _icon = props.icon
                if (props.icon === '') _icon = defaultIcon_app
                return profix + _icon
            },
            set: () => { }
        })
        return {
            collect,
            clikMenuItemHandler,
            clickEditHandler,
            collectWork,
            showIcon,
            iconFontFamily
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-public-main-icon-card {
    //margin: 5px;
    width: 24.2%;
    height: 92px;
    margin: 0.4%;
    // height: 100px;
    border-radius: 6px;
    .border();
    background: @card-color;
    overflow: hidden;

    @media screen and (min-width: 1800px) {
        width: 19.2%;
        margin: 0.4%;
    }
}

.portal-public-main-icon-card:hover {
    .card-box-shadow();
}


.portal-public-main-icon-card-wrapper {
    display: flex;
    flex-direction: row;
    // height: 100%;
    // padding: 16px;
}

.portal-public-main-icon-card-editable {
    cursor: pointer;
}

.portal-public-main-icon-card-cover {
    width: 92px;
    height: 92px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portal-public-main-icon-card-cover-wrapper {
    width: 56px;
    height: 56px;
}

.portal-public-main-icon-card-cover-wrapper-box {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(146deg, #23C5D0 13%, #59A4EA 105%);
    display: flex;
    align-items: center;
    justify-content: center;

}

.portal-public-scene-icon-span {
    font-size: 26px;
    color: #fff;

}

.portal-public-main-icon-card-info {
    flex: 1;
    .text-1();
    color: @font-color-1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.portal-public-main-icon-card-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.portal-public-main-icon-card-publish {
    // margin-top: 24px;
}

.portal-public-main-icon-card-published {
    color: @primary-color;
}

.portal-public-main-icon-card-unpublish {
    color: @font-color-2;
}

.portal-public-main-icon-card-more {
    margin: 8px;
}

.app-card-text-type-tab {
    font-size: 12px;
    color: @font-color-3;
    background: @selected-color;
    margin-right: 10px;
    padding: 0 5px;
    border-radius: 3px;
    .border(1px, 1px, 1px, 1px);
}

.collect {
    margin-right: 5px;
}

.portal-menu-left-nav-content-item-icon {
    color: @font-color-1;
}
</style>