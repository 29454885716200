<template>
    <div class="portal-system-apk-table">
        <div class="portal-system-apk-table-wrapper">
            <cgs-table :columns="columns" :data-source="sceneList" mode="portal" size="small" :pagination="false"
                :backgroundTransparent="true" position="bottomCenter">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'action'">
                        <div class="portal-system-apk-table-action">
                            <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler(record, $event)">
                            </cgs-more>
                        </div>
                    </template>
                </template>
            </cgs-table>
        </div>
    </div>
</template>

<script>
    import {
        defineComponent,
    } from 'vue'
    const columns = [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        customRender: ({
            index
        }) => `${index + 1}`,
    }, {
        title: '编码',
        dataIndex: 'code',
        key: 'code',
        width: 150,
    },{
        title: '版本',
        dataIndex: 'version',
        key: 'version',
        width: 150,
    }, {
        title: '链接',
        dataIndex: 'link',
        key: 'link',
        width: 500,
    }, {
        title: '描述',
        dataIndex: 'desc',
        key: 'desc',
        width: 500,
    }, {
        title: '',
        dataIndex: 'action',
        key: 'action',
    }];
    export default defineComponent({
        components: {
        },
        props: {
            sceneList: {
                type: Array,
                default: () => []
            },
            moreMenuList: {
                type: Array,
                default: () => []
            },
        },

        setup(porps, ctx) {
            const clikMenuItemHandler = (scene, params) => {
                ctx.emit('clikMenuItem', scene, params)
            }
            const handleChange = (roles,column) => {
                ctx.emit('handleChangeapkRole', roles, column)
            }
            return {
                columns,
                clikMenuItemHandler,
                handleChange
            }
        },
    })
</script>

<style>
.portal-system-apk-table-action{
    width: 100%;
    height: 100%;
    text-align: right;
}
</style>