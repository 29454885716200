<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 19:21:10
!-->

<template>
    <cgs-modal class="portal-public-select-user-modal" v-model:visible="vis" :title="$t('selectUserModal.title')"
        @close="closeModal">
        <template #content>
            <div class="portal-public-select-user-modal-content">
                <div class="portal-public-select-user-modal-search">
                    <cgs-input v-model:value="state.searchValue" :placeholder="$t('selectUserModal.placeholder')"
                        background-transparent>
                        <template #prefix>
                            <span
                                class="portal-public-select-user-modal-search-icon cgs-portal-iconfont cgs-portal-icon-sousuo"></span>
                        </template>
                    </cgs-input>
                </div>
                <div class="portal-public-select-user-modal-user-list">
                    <div class="portal-public-select-user-modal-user-list-wrapper">
                        <div class="portal-public-select-user-modal-user-item" v-for="user in showUserList"
                            :key="user.userId">
                            <cgs-checkbox :checked="state.currentSelectedIdList.includes(user.userId)"
                                :disabled="disabledIds.includes(user.userId)"
                                @change="onChangeChecked(user.userId)"></cgs-checkbox>
                            <avatar class="portal-public-select-user-modal-user-avatar" :name="user.name" :size="8"
                                :bgSize="21"></avatar>
                            <span class="portal-public-select-user-modal-user-name">{{ user.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="portal-public-select-user-modal-footer">
                <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
                <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
            </div>
        </template>
    </cgs-modal>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    reactive,
    watch
} from 'vue'
import avatar from '@components/avatar.vue'
import {
    getUserList
} from '@api/user'

export default defineComponent({
    components: {
        avatar
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        defaultSelectedList: {
            type: Array,
            default: () => []
        },
        disabledIds: {
            type: Array,
            default: () => []
        }
    },

    setup (props, ctx) {
        const state = reactive({
            searchValue: '',
            userList: [],
            currentSelectedIdList: []
        })
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        const showUserList = computed(() => {
            return state.userList.filter(_item => _item.name.indexOf(state.searchValue) >= 0)
        })
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                state.currentSelectedIdList = props.defaultSelectedList
            }
        })

        onMounted(() => {
            _init()
        })
        const _init = async () => {
            try {
                let _userResult = await getUserList()
                if (_userResult.code === 200) {
                    state.userList = _userResult.data
                }
            } catch (err) {
                console.error(err)
            }
        }
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const ok = async () => {
            let _selectedUserList = state.userList.filter(_user => state.currentSelectedIdList.includes(_user.userId))
            ctx.emit('selected', _selectedUserList)
            close()
        }
        const onChangeChecked = (id) => {
            let _index = state.currentSelectedIdList.findIndex(item => item === id)
            if (_index < 0) {
                state.currentSelectedIdList.push(id)
            } else {
                state.currentSelectedIdList.splice(_index, 1)
            }
        }
        return {
            vis,
            state,
            showUserList,
            close,
            closeModal,
            ok,
            onChangeChecked

        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-public-select-user-modal-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portal-public-select-user-modal-search {
    margin-bottom: 16px;
}

.portal-public-select-user-modal-search .cgs-design-input.ant-input-affix-wrapper>input.ant-input,
.portal-public-select-user-modal-search .cgs-design-input.cgs-design-input-background-transparent.ant-input-affix-wrapper {
    border-radius: 20px;
}

.portal-public-select-user-modal-user-item {
    padding: 8px 0;
}

.portal-public-select-user-modal-user-name {
    margin-left: 8px;
    .text-1();
    color: @font-color-1;
}

.portal-public-select-user-modal-user-avatar {
    margin-left: 8px;
}

.portal-public-select-user-modal-user-list-wrapper {
    max-height: 400px;
    overflow-y: auto;
}
</style>