<template>
    <div class="portal-register" :style="{ backgroundImage: `url(${bgImg})` }">
        <div v-if="active === 'register'" class="portal-register-container">
            <div class="portal-register-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-register-container-title">
                <span class="portal-register-container-info1">{{ title }}</span>
            </div>
            <div class="portal-register-container-form">
                <cgs-form ref="sceneForm" :model="formState" :hideRequiredMark="true" labelAlign="left"
                    class="portal-register-container-form-main">
                    <cgs-form-item name="userName" :rules="[{ required: true, message: '请输入姓名!' }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-gerenzhanghu portal-register-container-form-icon"></span>
                            <span>姓名</span>
                        </template>
                        <cgs-input class="portal-register-container-form-input" v-model:value="formState.userName"
                            placeholder="请输入姓名" />
                    </cgs-form-item>
                    <cgs-form-item name="telephone"
                        :rules="[{ required: true, message: '请输入手机号!' },
                        { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号!' }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-shouji portal-register-container-form-icon"></span>
                            <span>手机</span>
                        </template>
                        <cgs-input class="portal-register-container-form-input" v-model:value="formState.telephone"
                            placeholder="请输入手机号" />
                    </cgs-form-item>
                    <cgs-form-item name="safe" :rules="[{ required: true, message: '请输入验证码!' }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-anquanshezhi portal-register-container-form-icon"></span>
                            <span>验证码</span>
                        </template>
                        <div class="portal-register-container-safe">
                            <cgs-input class="portal-register-container-form-input" v-model:value="formState.safe"
                                placeholder="请输入验证码" />
                            <cgs-button class="portal-register-container-safe-button" type="primary"
                                @click="getValidationCode">{{
                                validationCodeText }}</cgs-button>
                        </div>
                    </cgs-form-item>
                    <cgs-form-item name="password"
                        :rules="[{ required: true, message: '请输入密码!' },
                        { pattern: `^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,}$`, message: '请输入字母、数字、特殊字符任意两种， 最少8位!' }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-mima portal-register-container-form-icon"></span>
                            <span>密码</span>
                        </template>
                        <cgs-input class="portal-register-container-form-input" type="password"
                            v-model:value="formState.password" placeholder="请输入密码" />
                    </cgs-form-item>
                </cgs-form>
            </div>
            <div style="z-index:1" class="portal-register-container-button">
                <cgs-button type="primary" :loading="buttonLoading" @click="handleRegister">{{ buttonLoadText
                    }}</cgs-button>
            </div>
            <div class="portal-register-container-bottom">
                <span @click="$router.push({ path: '/login' })">返回登录</span>
            </div>
            <!-- <a class="registration-info" href="http://beian.miit.gov.cn" target="_blank">{{registration}}</a> -->
        </div>
        <div v-if="active === 'tenant'" class="portal-register-container">
            <div class="portal-register-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-register-container-title">
                <span class="portal-register-container-info1">{{ title }}</span>
            </div>
            <div class="portal-register-tenant-header">
                <span>创建或加入组织</span>
            </div>
            <div class="portal-register-portal-register">
                <cgs-button class="portal-register-tenant-join" type="primary"
                    @click="active = 'join'">加入已有组织</cgs-button>
                <div><span>如果被告知要使用，或有同事已经在使用，请选择此项</span></div>
            </div>
            <div class="portal-register-portal-register">
                <cgs-button class="portal-register-tenant-create" ghost @click="active = 'create'">创建新的组织</cgs-button>
                <div><span>如果想为企业或组织创建账号，请选择此项</span></div>
            </div>
            <div class="portal-register-portal-register">
                <span @click="active = 'register'">返回注册</span>
            </div>
            <!-- <a class="registration-info" href="http://beian.miit.gov.cn" target="_blank">{{registration}}</a> -->
        </div>
        <div v-if="active === 'join'" class="portal-register-container">
            <div class="portal-register-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-register-container-title">
                <span class="portal-register-container-info1">{{ title }}</span>
            </div>
            <div class="portal-register-tenant-header">
                <span>请输入组织ID</span>
            </div>
            <div class="portal-register-portal-register">
                <cgs-input class="portal-register-container-form-input" v-model:value="tenantId"
                    placeholder="请输入组织ID"></cgs-input>
                <div><span>组织ID可通过管理员获取</span></div>
            </div>
            <div class="portal-register-portal-register">
                <cgs-button type="primary" @click="handleJoinTenant">加入</cgs-button>
            </div>
            <div class="portal-register-portal-register">
                <span @click="active = 'tenant'">返回上一层</span>
            </div>
            <!-- <a class="registration-info" href="http://beian.miit.gov.cn" target="_blank">{{registration}}</a> -->
        </div>
        <div v-if="active === 'create'" class="portal-register-container">
            <div class="portal-register-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-register-container-title">
                <span class="portal-register-container-info1">{{ title }}</span>
            </div>
            <div class="portal-register-tenant-header">
                <span>创建组织</span>
            </div>
            <div class="portal-register-portal-register">
                <cgs-input class="portal-register-container-form-input" v-model:value="tenantName"
                    placeholder="请输入组织名称"></cgs-input>
                <div><span>您当前账号默认成为组织的管理员</span></div>
            </div>
            <div class="portal-register-portal-register">
                <cgs-button type="primary" @click="handleCreateTenant">创建</cgs-button>
            </div>
            <div class="portal-register-portal-register">
                <span @click="active = 'tenant'">返回上一层</span>
            </div>
        </div>

        <a class="portal-register-registration-info" href="http://beian.miit.gov.cn" target="_blank">{{ registration
            }}</a>
        <!-- <div class="cgs-bottom flex-row">
            <div class="cgs-bottom-triangle">
                <div class="cgs-bottom-down"></div>
                <div class="cgs-bottom-up"></div>
            </div>
        </div> -->
    </div>

</template>
<script>
import {
    defineComponent,
    ref,
    reactive,
    computed,
    onMounted,
    getCurrentInstance
} from 'vue';
import {
    useRouter
} from 'vue-router'
import {
    watchSystemInfo
} from '@/utils/author.js'
import {
    checkValidationCode,
    CheckByUsername,
    getPhoneValidationCode,
    createTenant,
    joinTenant
} from "@/api/user";
import { checkPhone } from "../../utils/util";
export default defineComponent({
    components: {
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance();
        let router = useRouter()
        const formState = reactive({
            userName: '',
            password: '',
            safe: '',
            telephone: ''
        })
        const active = ref('register')
        const tenantId = ref('')
        const tenantName = ref('')
        const buttonLoading = ref(false)
        const validationCodeLoading = ref(false)
        const validationCodeText = ref('获取验证码')
        const buttonLoadText = ref('注册')
        const state = reactive({
            sysInfo: {}
        })
        const sceneForm = ref(null)

        const handleRegister = async () => {
            try {
                await sceneForm.value.validateFields()
                buttonLoading.value = true;
                buttonLoadText.value = "注册中...";

                let res = await checkValidationCode({
                    phoneNumber: formState.telephone,
                    validationCode: formState.safe
                })
                if (res.success) {
                    active.value = "tenant"
                } else {
                    proxy.$message.error(res.msg)
                }
                buttonLoading.value = false
                buttonLoadText.value = "注册"
            } catch (err) {
                proxy.$message.error(err.msg)
                buttonLoading.value = false
                buttonLoadText.value = "注册"
            }
        }

        const getValidationCode = () => {
            if (checkPhone(formState.telephone)) {
                if (validationCodeLoading.value) {
                    return;
                }
                let phone = formState.telephone
                CheckByUsername({
                    username: phone
                }).then(res => {
                    if (res.data.isExist) {
                        proxy.$message.error("该手机号已被注册！")
                    } else {
                        //获取验证码
                        getPhoneValidationCode({
                            phoneNumber: phone
                        })

                        validationCodeLoading.value = true;
                        let time = 60;
                        reGetValidationCode(time, null)
                        let timeOut = setInterval(() => {
                            --time
                            reGetValidationCode(time, timeOut)
                        }, 1000);
                    }
                })
            } else {
                proxy.$message.error("请输入正确的手机号码！")
            }

        }

        const reGetValidationCode = (time, timeOut) => {
            validationCodeText.value = time + "s";
            if (time === 0) {
                clearInterval(timeOut)
                validationCodeText.value = "重新获取"
                validationCodeLoading.value = false
            }
        }

        const handleJoinTenant = () => {
            joinTenant({
                "nickName": formState.userName,
                "phoneNumber": formState.telephone,
                "password": formState.password,
                "type": 1,
                "tenantId": tenantId.value,
                "validationCode": formState.safe
            }).then(res => {
                if (res.success) {
                    proxy.$message.success("注册成功，请登录！")
                    router.push({ path: "/login" });
                } else {
                    proxy.$message.error(res.msg);
                }
            })
        }

        const handleCreateTenant = () => {
            createTenant({
                "nickName": formState.userName,
                "phoneNumber": formState.telephone,
                "password": formState.password,
                "type": 0,
                "tenantName": tenantName.value,
                "validationCode": formState.safe,
            }).then(res => {
                if (res.success) {
                    proxy.$message.success("注册成功，请登录！")
                    router.push({ path: "/login" });
                } else {
                    proxy.$message.error(res.msg);
                }
            })
        }

        const title = computed(() => {
            let _title = ''
            if (state.sysInfo && state.sysInfo.name) {
                _title = state.sysInfo.name.toUpperCase()
            }
            return _title
        })
        const registration = computed(() => {
            let _registration = ''
            if (state.sysInfo && state.sysInfo.domainRegistrationInfo) {
                _registration = state.sysInfo.domainRegistrationInfo
            }
            return _registration
        })
        const bgImg = computed(() => {
            let _img = ''
            if (state.sysInfo && state.sysInfo.bgImg) {
                _img = state.sysInfo.bgImg
            }
            return _img
        })
        const logo = computed(() => {
            let _logo = ''
            if (state.sysInfo && state.sysInfo.logo) {
                _logo = state.sysInfo.logo
            }
            return _logo
        })

        onMounted(() => {
            watchSystemInfo((newVal) => {
                state.sysInfo = newVal
            })
        })
        return {
            sceneForm,
            formState,
            state,
            title,
            registration,
            bgImg,
            logo,
            active,
            tenantId,
            tenantName,
            buttonLoading,
            validationCodeLoading,
            validationCodeText,
            buttonLoadText,

            handleRegister,
            getValidationCode,
            reGetValidationCode,
            handleJoinTenant,
            handleCreateTenant
        }
    }
})
</script>

<style src="../login/index.css"></style>
<style src="./index.css"></style>

<style lang="less">
@import "~@style/less/theme.less";

.portal-register {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-register-container {
    z-index: 100;
    /* align-items: center; */
    padding: 40px 30px;
    border-radius: 5px;
    background-color: white;
    text-align: center;
    width: 445px;
    // height: 530px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.portal-register-container-logo {
    width: 100%;
    height: 40px;
}

.portal-register-container-logo img {
    width: 40px;
    height: 40px;
}

.portal-register-container-title {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.portal-register-container-form {
    text-align: left;
}

.portal-register-container-info1 {
    .big-title
}

.portal-register-container-safe {
    position: relative;
}

.portal-register-container-safe-button.ant-btn {
    position: absolute;
    right: 0px;
    height: 42px;
    width: 128px;
}

.portal-register-container-button {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 42px;
}

.portal-register-container-button button {
    width: 100%;
    height: 42px;
}

.portal-register-container-bottom {
    color: #999999;
    text-align: left;
}

.portal-register-container-bottom span {
    cursor: pointer;
}

.portal-register-container-bottom span:hover {
    color: @primary-color;
}

.portal-register-registration-info {
    color: #333;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #FFF;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}

.portal-register-registration-info:hover {
    color: #222;
    text-decoration: underline
}


.portal-register-tenant-header {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333;
    line-height: 24px;
    /* margin-bottom: 40px; */
}

.portal-register-tenant-header>span {
    border-bottom: 5px solid @primary-color;
}


.portal-register-portal-register {
    /*flex-grow: 1;*/
    align-items: center;
    margin-top: 25px;
}

.portal-register-portal-register button {
    width: 100%;
    height: 42px;
}

.portal-register-portal-register>span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #CBCBCB;
    line-height: 22px;
    cursor: pointer;
}

.portal-register-portal-register>div>span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #CBCBCB;
    line-height: 22px;
    cursor: pointer;
}

.portal-register-portal-register>input {
    // width: 350px;
    // height: 42px;
    // color: @font-color-2!important;
    // background: @primary-color !important;
    // font-size: 16px;
    // border-radius: 23px;
    // text-align: center;
    // .border(2px,2px,2px,2px)
}

// .portal-register-tenant-join.ant-btn-primary {
//     background: @primary-color;
//     color: @font-color-2;
//     .border(2px,2px,2px,2px)
// }
// .portal-register-tenant-create.ant-btn-primary {
//     background:  @card-color;
//     /*border: 2px solid #4C84FF !important;*/
//     color: @font-color-2;
// }
.portal-register-container-form-icon {
    margin-right: 5px;
}
</style>

<style scoped>
.portal-register-container-form-input.cgs-design-input.ant-input {
    line-height: 32px;
    border-radius: 5px;
}
</style>

<style>
.portal-register-container-form-main.cgs-design-form .ant-form-item-label>label {
    line-height: 32px;
    height: 41px;
    color: #333333;
    width: 78px;
}

.portal-register-container-form-main .ant-form-item label:after {
    content: none;
}
</style>