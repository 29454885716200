<template>
    <div>
        <cgs-modal v-model:visible="vis" :title="$t('auth.copyAppId.title')" @close="closeModal" :footer="null">
            <template #content>
                <div>
                    {{ $t('auth.copyAppId.name') }}
                </div>
                <div>
                    <span id="copyAppId">{{ appId }}</span>
                    <span class="portal-menu-authority-copy" @click="handleCopy('copyAppId')">{{ $t('auth.copyAppId.copy')
                    }}</span>
                </div>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    computed,
    getCurrentInstance,
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    copyDomText
} from '@/api/tenant'

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        appId: {
            type: String,
            default: ''
        },
    },

    setup(props, ctx) {
        const { t } = useI18n()
        let {
            proxy
        } = getCurrentInstance()
        let vis = computed({
            get: () => {
                return props.visible
            },
            set: (val) => {
                ctx.emit('update:visible', val)
            }
        })
        const close = () => {
            ctx.emit('close')
            vis.value = false
        }
        const closeModal = () => {
            close()
        }
        const handleCopy = (domid) => {
            let res = copyDomText(domid)
            if (res) {
                proxy.$message.success(t('auth.authInfo.copyRemindSuccess'));
            } else {
                proxy.$message.error(t('auth.authInfo.copyRemindFail'));
            }
        }
        return {
            vis,
            close,
            closeModal,
            handleCopy

        }
    },
})
</script>


<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-menu-authority-copy {
    color: @primary-color;
    margin-left: 20px;
    cursor: pointer
}
</style>