<template>
    <div class="portal-system-apk-addApk">
        <cgs-modal :visible="dialogVisible" :title="submitState.state ==='add'? '添加APK' : '修改APK'" @close="$emit('cancelAddApkModal')" :footer="null">
            <template #content>
                <cgs-form
                :labelCol="{span:4, offset: 0}"
                    :model="formState"
                    @finish="() => {$emit('formRePassSubmit',formState)}"
                >
                    <cgs-form-item
                    v-if="submitState.state === 'add'"
                    label="编码"
                    name="code"
                    :rules="[{ required: true, message: '请输入编码' }]"
                    >
                        <cgs-input v-model:value="formState.code" />
                    </cgs-form-item>
                    <cgs-form-item
                    label="版本"
                    name="version"
                    :rules="[{ required: true, message: '请输入版本' }]"
                    >
                        <cgs-input v-model:value="formState.version" />
                    </cgs-form-item>
                    <cgs-form-item
                    label="链接"
                    name="link"
                    :rules="[{ required: true, message: '请输入链接' }]"
                    >
                        <cgs-input v-model:value="formState.link" />
                    </cgs-form-item>
                    <cgs-form-item
                    label="描述"
                    name="desc"
                    :rules="[{ required: true, message: '请输入描述' }]"
                    >
                        <cgs-input v-model:value="formState.desc" />
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddApkModal')" class="portal-system-apk-addApk-button">取 消</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-system-apk-addApk-button">确 定</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    ref,
    watch
} from 'vue'

export default defineComponent({
    props: {
        dialogVisible:{
            type: Boolean,
            default: false,
        },
        submitState: {
            type: Object,
            default: () => {},
        }
    },
    setup(props) {
        const formState = ref({
            code:'',
            version:'',
            link:'',
            desc:'',
        })
        watch(() => props.dialogVisible, () => {
            if (props.submitState.state === 'updata' && props.dialogVisible === true) {
                let data = props.submitState.scene
                formState.value = {
                    code:data.code,
                    version:data.version,
                    link:data.link,
                    desc:data.desc,
                    id:data.id,
                }
            }else if(props.submitState.state === 'add' && props.dialogVisible === true){
                formState.value = {
                    code:'',
                    version:'',
                    link:'',
                    desc:'',
                }
            }
        })
        return {
            formState,
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-apk-addApk-button{
    margin: 0px 15px;
}
</style>