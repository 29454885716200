import {get, post} from "@/axios/http.js"
// import qs from 'qs'

export const getVersion = () => get('/api/version')

export const getSysInfo = () => get('/api/Config/Sys/Get')

export const updateSysInfo = (data) => post('/api/Config/Sys/Update', data)

export const getSysLib = () => get('/api/config/lib')


