<template>
    <div class="portal-system-tenant-editTenant">
        <cgs-modal :visible="dialogUpdateVisible" title="修改租户账户信息" @close="$emit('cancelEditTenant')" :footer="null">
            <template #content>
                <cgs-form
                :labelCol="{span:4, offset: 0}"
                    :model="formState"
                    @finish="() => {$emit('editTenantOK',formState)}"
                >
                    <cgs-form-item
                    label="版 本："
                    name="version"
                    :rules="[{ required: true, message: '请选择版本' }]"
                    >
                        <cgs-select
                        ref="select"
                        v-model:value="formState.version"
                        style="width: 120px"
                        >
                            <cgs-select-option value="企业版">企业版</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item
                    label="开始时间："
                    name="beginDate"
                    :rules="[{ required: true, message: '请选择日期' }]"
                    >
                        <cgs-date-picker v-model:value="formState.beginDate" show-time :format="format" :value-format="format"/>
                    </cgs-form-item>
                    <cgs-form-item
                    label="结束时间："
                    name="endDate"
                    :rules="[{ required: true, message: '请选择日期' }]"
                    >
                        <cgs-date-picker v-model:value="formState.endDate" show-time :format="format" :value-format="format"/>
                    </cgs-form-item>
                    <cgs-form-item
                    label="是否试用："
                    name="isTrial"
                    >
                        <cgs-switch v-model:checked="formState.isTrial"/>
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelEditTenant')" class="portal-system-tenant-editTenant-button">取 消</cgs-button>
                        <cgs-button type="primary" html-type="submit"  class="portal-system-tenant-editTenant-button">确 定</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    watch,
} from 'vue'
export default defineComponent({
    props: {
        dialogUpdateVisible:{
            type: Boolean,
            default: false,
        },
        form_TenantInfo: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        const format = "YYYY-MM-DD HH:mm:ss"
        const formState = reactive({
            version: "企业版",
            beginDate: "",
            endDate: "",
            isTrial: true
        })
        watch(() => props.dialogUpdateVisible, () => {
            let data = props.form_TenantInfo
            formState.version = data.version
            formState.beginDate = data.beginDate
            formState.endDate = data.endDate
            formState.isTrial = data.isTrial
        })

        return {
            format,
            formState,
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-tenant-editTenant-button{
    margin: 0px 15px;
}
.portal-system-tenant-editTenant-checkbox{
    margin-top: 6px;
    margin-bottom: 15px;
}
</style>