<template>
    <div class="portal-system-user-addUser">
        <cgs-modal :visible="dialogVisible"
            :title="submitState.state === 'add' ? $t('systemInfo.userInfo.addUser') : $t('systemInfo.userInfo.modifyUserInformation')"
            @close="$emit('cancelAddUserModal')" :footer="null">
            <template #content>
                <cgs-form :labelCol="{ span: 5, offset: 0 }" :model="formState"
                    @finish="() => { $emit('formRePassSubmit', formState) }">
                    <cgs-form-item v-if="submitState.state === 'add'" :label="$t('systemInfo.userInfo.userName')"
                        name="userName"
                        :rules="[{ required: true, message: $t('systemInfo.userInfo.userNameNullError') }]">
                        <cgs-input v-model:value="formState.userName" :placeholder="$t('remind.inputRemind')" />
                    </cgs-form-item>
                    <cgs-form-item v-if="submitState.state === 'add'" :label="$t('systemInfo.userInfo.password')"
                        name="passWord"
                        :rules="[{ required: true, message: $t('systemInfo.userInfo.passwordNullError') },
        { pattern: `^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,}$`, message: $t('remind.passwordRegulationError') }]">
                        <cgs-input v-model:value="formState.passWord" :placeholder="$t('remind.inputRemind')"
                            :type="isPassWord ? 'password' : ''">
                            <template #suffix>
                                <span v-if="isPassWord" class="cgs-portal-iconfont cgs-portal-icon-mimaxianshi"
                                    style="cursor: pointer;" @click="isPassWord = false"></span>
                                <span v-else class="cgs-portal-iconfont cgs-portal-icon-mimaxianshi1"
                                    style="cursor: pointer;" @click="isPassWord = true"></span>
                            </template>
                        </cgs-input>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.userInfo.userAlia')" name="userAlia"
                        :rules="[{ required: true, message: $t('systemInfo.userInfo.userAliaNullError') }]">
                        <cgs-input v-model:value="formState.userAlia" :placeholder="$t('remind.inputRemind')" />
                    </cgs-form-item>
                    <cgs-form-item v-if="submitState.state === 'add'" :label="$t('systemInfo.userInfo.userRole')"
                        name="userRole"
                        :rules="[{ required: true, message: $t('systemInfo.userInfo.userRoleNullError') }]">
                        <cgs-select ref="select" v-model:value="formState.userRole" style="width: 120px"
                            :placeholder="$t('remind.selectGroupRemind')">
                            <cgs-select-option v-for="item in role_options" :disabled="item.disabled" :key="item.id"
                                :value="item.id">{{
            item.roleName }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.userInfo.userDeptNames')" name="userDepts"
                        :rules="[{ required: true, message: $t('systemInfo.userInfo.userDeptsNullError') }]">
                        <a-cascader v-model:value="formState.userDepts" change-on-select :options="dept_options"
                            :placeholder="$t('systemInfo.userInfo.selectDeptRemind')" />
                    </cgs-form-item>
                    <cgs-form-item :label="$t('systemInfo.userInfo.userJobs')">
                        <cgs-select ref="select" v-model:value="formState.jobIds" mode="tags"
                            :placeholder="$t('remind.selectGroupRemind')">
                            <cgs-select-option v-for="item in job_options" :key="item.id" :value="item.id">{{
            item.name }}</cgs-select-option>
                        </cgs-select>
                    </cgs-form-item>
                    <cgs-form-item :wrapper-col="{ offset: 8, span: 16 }">
                        <cgs-button @click="$emit('cancelAddUserModal')" class="portal-system-user-addUser-button">{{
            $t('common.cancel') }}</cgs-button>
                        <cgs-button type="primary" html-type="submit" class="portal-system-user-addUser-button">{{
                            $t('common.ok') }}</cgs-button>
                    </cgs-form-item>
                </cgs-form>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    ref,
    watch
} from 'vue'

export default defineComponent({
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        role_options: {
            type: Array,
            default: () => []
        },
        dept_options: {
            type: Array,
            default: () => []
        },
        job_options: {
            type: Array,
            default: () => []
        },
        submitState: {
            type: Object,
            default: () => { },
        }
    },
    setup (props) {
        const formState = ref({
            userName: '',
            passWord: '',
            userAlia: '',
            userRole: '',
            userDepts: '',
            jobIds: []
        })
        const isPassWord = ref(true)
        watch(() => props.dialogVisible, () => {
            if (props.submitState.state === 'updata' && props.dialogVisible === true) {
                let data = props.submitState.scene
                formState.value = {
                    userName: data.userName,
                    passWord: data.passWord,
                    userAlia: data.userAlia,
                    userRole: data.userRole,
                    userId: data.userId,
                    userDepts: data.userDepts,
                    jobIds: data.jobIds ? data.jobIds : []
                }
            } else if (props.submitState.state === 'add' && props.dialogVisible === true) {
                formState.value = {
                    userName: '',
                    passWord: '',
                    userAlia: '',
                    userRole: '',
                    userDepts: '',
                    jobIds: []
                }
            }
        })
        return {
            formState,
            isPassWord
        }
    },
})
</script>

<style lang='less' scoped>
.portal-system-user-addUser-button {
    margin: 0px 15px;
}

.portal-system-user-addUser-pass {
    color: dodgerblue;
}
</style>