<template>
    <div class="portal-findPassword" :style="{backgroundImage: `url(${bgImg})`}">
        <div class="portal-findPassword-container">
            <div class="portal-findPassword-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-findPassword-container-title">
                <span class="portal-findPassword-container-info1">{{title}}</span>
            </div>
            <div class="portal-findPassword-container-form">
                <cgs-form
                    ref="sceneForm"
                    :model="formState"
                    :hideRequiredMark="true"
                    labelAlign="left"
                    class="portal-findPassword-container-form-main"
                >
                    <cgs-form-item
                    name="telephone"
                    :rules="[{ required: true, message: '请输入手机号!'},
                            {pattern:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号!'}]"
                    >
                    <template #label>
                        <span class="cgs-portal-iconfont cgs-portal-icon-shouji portal-findPassword-container-form-icon"></span>
                        <span>手机</span>
                    </template>
                    <cgs-input class="portal-findPassword-container-form-input" v-model:value="formState.telephone" placeholder="请输入手机号"/>
                    </cgs-form-item>
                    <cgs-form-item
                    name="safe"
                    :rules="[{ required: true, message: '请输入验证码!' }]"
                    >
                    <template #label>
                        <span class="cgs-portal-iconfont cgs-portal-icon-anquanshezhi portal-findPassword-container-form-icon"></span>
                        <span>验证码</span>
                    </template>
                    <div class="portal-findPassword-container-safe">
                        <cgs-input class="portal-findPassword-container-form-input" v-model:value="formState.safe" placeholder="请输入验证码"/>
                        <cgs-button class="portal-findPassword-container-safe-button" type="primary" @click="getValidationCode">{{validationCodeText}}</cgs-button>
                    </div>
                    </cgs-form-item>
                    <cgs-form-item
                        name="password"
                        :rules="[{ required: true, message: '请输入密码!' },
                                 {pattern:`^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,}$`, message: '请输入字母、数字、特殊字符任意两种， 最少8位!'}]"
                        >
                        <template #label>
                            <span class="cgs-portal-iconfont cgs-portal-icon-mima portal-findPassword-container-form-icon"></span>
                            <span>密码</span>
                        </template>
                        <cgs-input class="portal-findPassword-container-form-input" type="password" v-model:value="formState.password" placeholder="请输入密码"/>
                    </cgs-form-item>
                </cgs-form>
            </div>
            <div class="portal-findPassword-container-button">
                <cgs-button type="primary" @click="handleFindPassword">确定</cgs-button>
            </div>
            <div class="portal-findPassword-container-bottom">
                <span @click="$router.push({path: '/login'})">返回登录</span>
            </div>
        </div>
            <a class="portal-findPassword-registration-info" href="http://beian.miit.gov.cn" target="_blank">{{registration}}</a>
    </div>
</template>

<script>
    import {
        defineComponent,
        ref,
        reactive,
        computed,
        onMounted,
        getCurrentInstance
    } from 'vue';
    import {
        useRouter
    } from 'vue-router'
    import {
        watchSystemInfo
    } from '@/utils/author.js'
    import {
        checkValidationCode,
        findPassword,
        CheckByUsername,
        getPhoneValidationCode
    } from "@/api/user";
    import {checkPhone} from "../../utils/util.js";
    export default defineComponent({
        components: {
        },
        setup() {
            let {
                proxy
            } = getCurrentInstance();
            const formState = reactive({
                telephone:'',
                password:'',
                safe:'',
            })
            let router = useRouter()
            const buttonCheck = ref('')
            const validationCodeLoading = ref(false)
            const validationCodeText = ref("获取验证码")
            const state = reactive({
                sysInfo: {}
            })
            const sceneForm = ref(null)

            const handleFindPassword = async() => {
                try{
                        await sceneForm.value.validateFields()
                        let _params = {
                            phoneNumber: formState.telephone,
                            validationCode: formState.safe
                        }
                        let res = await checkValidationCode(_params)
                        if(res.success) {
                            let data = {
                                "newPassword": formState.password,
                                "confirmPassword": formState.password,
                                "phoneNumber": formState.telephone,
                                "validationCode": formState.safe
                            }
                            let result = await findPassword(data)
                            if(result.success) {
                                proxy.$message.success("密码修改成功")
                                router.push({path: "/login"});
                            }
                        } else {
                            proxy.$message.error(res.data.msg)
                            // this.findPasswordForm.validationCode = ""
                        }
                }catch(err){
                    console.error(err);
                }
            }

            const getValidationCode = async() => {
                try{
                    if(checkPhone(formState.telephone)){
                        if (validationCodeLoading.value) {
                            return;
                        }
                        let phone = formState.telephone
                        let _params = {
                            username: formState.telephone
                        }
                        let res = await CheckByUsername(_params)
                        if (res.code === 200 && !res.data.isExist) {
                            proxy.$message.error("该用户不存在！")
                            return
                        }
                        //获取验证码
                        await getPhoneValidationCode({
                        phoneNumber: phone
                        })

                        validationCodeLoading.value = true;
                        let time = 60;
                        reGetValidationCode(time)
                        let timeOut = setInterval(() => {
                        --time
                        reGetValidationCode(time, timeOut)
                        },1000);
                    }else{
                        proxy.$message.error("请输入正确的手机号！")
                    }
                }catch(err){
                    proxy.$message.error(err.msg)
                    console.error(err)
                }
            }
            const reGetValidationCode = (time, timeOut) => {
                validationCodeText.value = time + "s";
                if (time === 0) {
                    clearInterval(timeOut)
                    buttonCheck.value = '';
                    validationCodeText.value = "重新获取"
                    validationCodeLoading.value = false
                }
            }

            onMounted(() => {
                watchSystemInfo((newVal) => {
                    state.sysInfo = newVal
                })
            })
            const title = computed(() => {
                let _title = ''
                if (state.sysInfo && state.sysInfo.name) {
                    _title = state.sysInfo.name.toUpperCase()
                }
                return _title
            })
            const registration = computed(() => {
                let _registration = ''
                if (state.sysInfo && state.sysInfo.domainRegistrationInfo) {
                    _registration = state.sysInfo.domainRegistrationInfo
                }
                return _registration
            })
            const bgImg = computed(() => {
                let _img = ''
                if (state.sysInfo && state.sysInfo.bgImg) {
                    _img = state.sysInfo.bgImg
                }
                return _img
            })
            const logo = computed(() => {
                let _logo = ''
                if (state.sysInfo && state.sysInfo.logo) {
                    _logo = state.sysInfo.logo
                }
                return _logo
            })
            return{
                sceneForm,
                formState,
                bgImg,
                logo,
                title,
                state,
                registration,
                buttonCheck,
                validationCodeLoading,
                validationCodeText,

                handleFindPassword,
                getValidationCode,
                reGetValidationCode,
                checkPhone
            }
        }
    })
</script>



<style lang="less">
@import "~@style/less/theme.less";
.portal-findPassword {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.portal-findPassword-container{
    z-index: 100;
    /* align-items: center; */
    padding: 40px 30px;
    border-radius: 5px;
    background-color: white;
    text-align: center;
    width: 445px;
    // height: 470px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}
.portal-findPassword-container-logo{
    width: 100%;
    height: 40px;
}
.portal-findPassword-container-logo img{
    width: 40px;
    height: 40px;
}
.portal-findPassword-container-title {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
.portal-findPassword-container-form{
    text-align: left;
}
.portal-findPassword-container-info1{
    .big-title
}
.portal-findPassword-container-safe{
    position: relative;
}
.portal-findPassword-container-safe-button.ant-btn{
    position: absolute;
    right: 0px;
    height: 42px;
    width: 128px;
}
.portal-findPassword-container-button{
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 42px;
}
.portal-findPassword-container-button button{
    width: 100%;
    height: 42px;
}
.portal-findPassword-container-bottom{
    color: #999999;
    text-align: left;
}
.portal-findPassword-container-bottom span{
    cursor: pointer;
}
.portal-findPassword-container-bottom span:hover{
    color: @primary-color;
}
.portal-findPassword-registration-info{
    color: #333;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #FFF;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}
.portal-findPassword-registration-info:hover{
    color: #222;
    text-decoration: underline
}
.portal-findPassword-container-form-icon{
    margin-right: 5px;
}
</style>

<style scoped>
    .portal-findPassword-container-form-input.cgs-design-input.ant-input{
        line-height: 32px;
        border-radius: 5px;
    }
</style>

<style>
    .portal-findPassword-container-form-main.cgs-design-form .ant-form-item-label>label{
        line-height: 32px;
        height:41px;
        color: #333333;
        width: 78px;
    }
    .portal-findPassword-container-form-main .ant-form-item label:after {
        content: none;
    }
</style>