<template>
  <cgs-modal class="portal-menu-table-import-modal" v-model:visible="vis" title="导入应用到工作空间" @close="closeModal">
    <template #content>
      <div class="portal-menu-table-import-modal-content">
        <div class="portal-menu-table-import-modal-content-item">
          <span class="portal-menu-table-import-modal-content-item-label">导入方式：</span>
          <cgs-select style="width: 100%;" placeholder="请选择导入方式" v-model:value="importType"
            :options="importTypeOptions" />
        </div>
        <div class="portal-menu-table-import-modal-content-item" v-if="importType === 0">
          <span class="portal-menu-table-import-modal-content-item-label">空间名称：</span>
          <cgs-input style="width: 100%;" placeholder="请输入空间名称" v-model:value="state.workspaceName" />
        </div>
        <div class="portal-menu-table-import-modal-content-item" v-if="importType === 1">
          <span class="portal-menu-table-import-modal-content-item-label">选择空间：</span>
          <cgs-select style="width: 100%;" placeholder="请选择导入方式" v-model:value="state.workspaceId" allowClear>
            <cgs-select-option :value="item.id" v-for="item in spaceList" :key="item.id">{{ item.value
              }}</cgs-select-option>
          </cgs-select>
        </div>
        <div class="portal-menu-table-import-modal-content-item">
          <span class="portal-menu-table-import-modal-content-item-label">上传压缩包：</span>
          <div style="width: 100%;"><cgs-upload :max-count="1" v-model:file-list="fileList"
              :before-upload="beforeUpload" @update:fileList="handleRemove">
              <cgs-button>导入</cgs-button></cgs-upload>
          </div>

        </div>
        <div class="portal-menu-table-import-modal-content-check">
          <cgs-checkbox v-model:checked="state.installData"> 包含数据</cgs-checkbox>
        </div>

      </div>
    </template>
    <template #footer>
      <div class="portal-menu-table-import-modal-footer">
        <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
        <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
      </div>
    </template>
  </cgs-modal>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, reactive, getCurrentInstance } from 'vue'
import {
  getUserInfo
} from '@/api/user'
import { setUserAuthority } from "@/utils/author.js";
// import { uploadFile } from '@api/scene'
import { importSpace } from "@api/workSpace"
const props = defineProps({
  vis: {
    type: Boolean,
    default: false
  },
  spaceList: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['close', 'ok'])
const { proxy } = getCurrentInstance()

const vis = computed(() => props.vis)
const spaceList = computed(() => props.spaceList)

const importType = ref(0)
const importTypeOptions = [{
  label: '创建新的工作空间',
  value: 0
}, {
  label: '导入到已有工作空间',
  value: 1
}]

const state = reactive({
  file: null,
  workspaceName: "",
  workspaceId: "",
  installData: true
})

const fileList = ref([])

const beforeUpload = (file) => {
  state.file = file
  fileList.value = [...fileList.value, file];
  return false
}
const handleRemove = file => {
  if (file.length === 0) {
    fileList.value = []
    state.file = null
  }
}
const close = () => {
  state.file = null
  state.workspaceName = ""
  state.workspaceId = ""
  state.installData = true
  emit('close')
  // props.value = false
}
const closeModal = () => {
  close()
}
const updateUserAuthority = () => {
  return new Promise((resolve, reject) => {
    getUserInfo().then(infoRes => {
      if (infoRes.data && infoRes.success) {
        setUserAuthority(infoRes.data);
        window.authManager.init();
      }
      resolve()
    }).catch(e => {
      console.log(e);
      reject()
    })
  })
}

const ok = async () => {
  try {
    proxy.$loading.open()
    let formdata = new FormData();
    formdata.append("file", state.file);
    formdata.append("workspaceName", state.workspaceName);
    formdata.append("workspaceId", state.workspaceId);
    formdata.append("installData", state.installData);
    let res = await importSpace(state)
    if (res.code === 200) {
      proxy.$message.success('导入成功')
      await updateUserAuthority()
      emit('ok')
      close()
    }
    proxy.$loading.close()
  } catch {
    proxy.$loading.close()
  }


}

</script>

<style scoped lang="less">
.portal-menu-table-import-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.portal-menu-table-import-modal-content-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.portal-menu-table-import-modal-content-item-label {
  width: 120px;
}

.portal-menu-table-import-modal-content-check {
  margin-top: 10px;
  margin-left: 95px;
}

.portal-menu-table-import-modal-footer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.portal-menu-table-import-modal-theme {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
  line-height: 32px;
  color: #FFFFFF;
  cursor: pointer;
}
</style>