<template>
 <cgs-modal class="portal-menu-table-add-modal" v-model:visible="vis" :title="$t('createWorkSpace')"
  @close="closeModal">
  <template #content>
   <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
    labelAlign="left">
    <cgs-form-item :label="$t('modifyModal.spaceTitle')" name="Title"
     :rules="[{ required: true, message: $t('modifyModal.spaceTitleNullError') }]">
     <cgs-input v-model:value="formState.Title" />
    </cgs-form-item>

   </cgs-form>
  </template>
  <template #footer>
   <div class="portal-menu-table-add-modal-footer">
    <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
    <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
   </div>
  </template>
 </cgs-modal>
</template>

<script setup>
import { defineProps, defineEmits, computed, reactive, onMounted, ref, getCurrentInstance } from 'vue'

import {
 getCategory,
 getThemeList
} from '@api/scene'
import { addWorkSpace } from "@/api/workSpace"
const props = defineProps({
 vis: {
  type: Boolean,
  default: false
 },
 title: {
  type: String,
  default: ''
 },
 name: {
  type: String,
  default: ''
 }
})
const emit = defineEmits(['close', 'ok'])
const { proxy } = getCurrentInstance()

const vis = computed(() => props.vis)
const state = reactive({
 orgOptions: [],
 themeOptions: [],
 themeModelVisible: false,
 themeIconLeft: 0,
 themeIconTop: 0,
})

const sceneForm = ref(null)
const formState = reactive({

 Title: ""
})

onMounted(() => {
 _init()
})
const _init = async () => {
 try {
  let _orgResult = await getCategory('Org')
  let _themeResult = await getThemeList()
  if (_orgResult.code === 200) {
   state.orgOptions = _orgResult.data
  }
  if (_themeResult.code === 200) {
   state.themeOptions = _themeResult.data
  }
 } catch (err) {
  console.error(err)
 }
}



const close = () => {
 formState.Title = ""
 emit('close')
 // props.value = false
}
const closeModal = () => {
 close()
}

const ok = async () => {
 try {
  await sceneForm.value.validateFields()
  proxy.$loading.open()
  let data = {
   name: formState.Title,
   icon: "gongzuokongjian"
  }
  let res = await addWorkSpace(data)
  if (res.code === 200) {
   proxy.$message.success('添加成功')
   closeModal()
   emit('ok')
  } else {
   proxy.$message.error('添加失败')
  }
  proxy.$loading.close()
 } catch (err) {
  console.log(err);
  proxy.$loading.close()
 }

}

</script>

<style scoped lang="less">
.portal-menu-table-add-modal-footer {
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
}

.portal-menu-table-add-modal-theme {
 width: 32px;
 height: 32px;
 border-radius: 16px;
 text-align: center;
 line-height: 32px;
 color: #FFFFFF;
 cursor: pointer;
}
</style>