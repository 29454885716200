<template>
  <main-layout class="portal-menu">
    <template #header>
      <main-header>
        <template #prefix-icon>
          <span class="portal-menu-change-icon cgs-portal-iconfont cgs-portal-icon-pailiefangshi font-color-3"
            @click="switchLeftCollapse"></span>
        </template>
        <template #middle>
          <middle-header-search></middle-header-search>
        </template>
      </main-header>
    </template>
    <template #left>
      <left-nav :moreMenuList="showMoreMenuList" :middleMenuList="middleMenuList" :title="state.leftNavConfig.title"
        :showAddButton="state.leftNavConfig.showAddButton" :showMode="state.showMode"
        :showMiddleMenuMore="state.leftNavConfig.showMiddleMenuMore" v-model:active-key="state.activeRouterName"
        @clickFooterMenu="onClickFooterMenu" @addSceneType="onAddSceneType" @collectWork="init_workSpace"
        @sortspace="initright">
      </left-nav>
    </template>
    <template #right>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
      </router-view>
    </template>
  </main-layout>

</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  computed,
  getCurrentInstance,
  provide,
  ref
} from 'vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  useI18n
} from 'vue-i18n'
import mainLayout from '@components/main-layout.vue'
import mainHeader from '@components/main-header.vue'
import leftNav from './left-nav.vue'
import middleHeaderSearch from "./middle-header-search.vue"

import { getWorkSpaceList, getCollectList } from "@api/workSpace"
//

import useHeader from "../../store/modules/header"
export default defineComponent({
  components: {
    mainLayout,
    mainHeader,
    middleHeaderSearch,
    leftNav,
  },
  setup () {
    let {
      proxy
    } = getCurrentInstance()
    let router = useRouter()
    let route = useRoute()
    const {
      t
    } = useI18n()
    const headerStore = useHeader()
    const state = reactive({
      sceneTypeManager: {
        visible: false
      },
      editSceneTypeName: {
        visible: false,
        typeName: '',
        typeId: '',
      },
      sceneTypeList: [],
      activeRouterName: '',
      leftNavConfig: {
        title: '场景',
        showMiddleMenuMore: true,
        showAddButton: true
      },
      organizationList: [],
      workSpaceList: [],
    })
    let moreMenuList = [{
      title: '重命名',
      key: 'rename',
      icon: 'cgs-portal-iconfont cgs-portal-icon-zhongmingming'
    },
    {
      title: '隐藏',
      key: 'hidden',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yincang'
    },
      // {
      //     title: '删除',
      //     key: 'delete',
      //     icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
      // }
    ]
    onMounted(() => {
      proxy.$ws.on('current_license', (data) => {
        if (data.Timeout) {
          proxy.$message.warning("License已过期，请重新申请！")
        }
      })

      init_workSpace()
    })
    const showMoreMenuList = computed(() => {
      return moreMenuList.map(_item => {
        _item.title = t('common.' + _item.key)
        return _item
      })
    })
    const middleMenuList = computed(() => {
      return state.workSpaceList
    })


    const list = ref([])
    const init_workSpace = async (type) => {
      state.workSpaceList = [{
        key: '1',
        label: 'homeMenu.homePage',
        icon: 'cgs-portal-iconfont cgs-portal-icon-zuzhi',
        value: 'homeMenu.homePage',
        type: 'workbench',
        children: []
      }, {
        key: '2',
        label: 'homeMenu.myWorkSpace',
        icon: 'cgs-portal-iconfont cgs-portal-icon-zuzhi',
        value: 'homeMenu.myWorkSpace',
        type: 'workSpace',
        children: []
      }]
      let spaceList = await getWorkSpaceList({ isMyApp: true })
      spaceList.data = spaceList.data ? spaceList.data : []
      let collect = await getCollectList()
      list.value = collect.data
      collect.data = collect.data ? collect.data : []
      collect.data.splice(0, 5).forEach((item, index) => {
        if (item.appType) {
          state.workSpaceList[0].children.push({
            key: item.id + "_" + index,
            label: item.title,
            id: item.id,
            icon: item.icon,
            value: item.title,
            type: item.appType,
            theme: item.theme,
            isCollect: item.isCollect,
            ...item
          })
        } else {
          state.workSpaceList[0].children.push({
            key: item.id + "_" + index,
            id: item.id,
            label: item.name,
            icon: item.icon,
            value: item.name,
            theme: "",
            type: "workSpace",
            isCollect: item.isCollect
          })
        }
        if (type === "delete") {
          let current = state.workSpaceList[1]
          if (current && current.children.length !== 0) {
            router.push({
              path: `/home/workSpace/${current.children[0].key}`
            })
            // selectWork.value = current.children[0].key
          } else {
            router.push({
              path: `/home/workbench`
            })
          }
        }


      })
      spaceList.data.forEach((item, index) => {
        state.workSpaceList[1].children.push({
          key: item.id + "_" + index,
          id: item.id,
          label: item.name,
          icon: item.icon,
          value: item.name,
          theme: "",
          type: 'workSpace',
          isCollect: item.isCollect
        })
      })
    }
    const initright = () => {
      list.value = ["1"]
    }

    provide("workSpacList", init_workSpace)
    provide("collectList", list)


    const onClickFooterMenu = (nav) => {
      if ('store' === nav.key) {
        window.open(window.origin + '/cgsStore/', '_blank')
        // router.push({name: 'store'})
      } else {
        router.push({
          name: nav.key
        })
      }
    }

    const onAddSceneType = () => {
      state.sceneTypeManager.visible = true
    }
    const onCloseSceneTypeManagerModal = () => { }



    const switchLeftCollapse = () => {
      headerStore.changeCollapse()
    }


    return {
      route,
      state,
      showMoreMenuList,
      middleMenuList,
      onClickFooterMenu,
      onAddSceneType,
      onCloseSceneTypeManagerModal,
      switchLeftCollapse,
      init_workSpace,
      initright,
    }
  }
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-menu-container {
  width: 100%;
  height: 100%;
}

.portal-menu-change-icon {
  cursor: pointer;
  font-size: 14px;
}
</style>