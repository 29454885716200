<template>
    <div class="portal-menu-dataServer-add-modal">
        <published-style-list v-if="'styleList' === props.type" :type="props.displayMapType"
            ref="publishedServerListRef"></published-style-list>
        <published-model-server-list v-if="'modelServer' === props.type" :type="props.displayMapType"
            ref="publishedServerListRef"></published-model-server-list>
        <published-drawing-server-list v-if="'drawingServer' === props.type" :type="props.displayMapType"
            ref="publishedServerListRef" :baseApiUrl="baseApiUrl" :accessToken="accessToken"></published-drawing-server-list>
        <published-d3-tile-server-list v-if="'d3TileServer' === props.type" :type="props.displayMapType"
            ref="publishedServerListRef"></published-d3-tile-server-list>
        <published-map-server-list v-if="'mapServer' === props.type" :type="props.displayMapType"
            ref="publishedServerListRef"></published-map-server-list>
        <published-d2-tile-server-list v-if="'d2TileServer' === props.type" :type="props.displayMapType"
            ref="publishedServerListRef"></published-d2-tile-server-list>
    </div>
</template>

<script setup>
    import {
        ref,
        defineProps,
        defineExpose
    } from 'vue'
    import {
        getSysLib
    } from "@/utils/author.js";


    const props = defineProps({
        type: {
            type: String,
            default: ''
        },
        displayMapType: {
            type: String,
            default: ''
        }
    })

    const publishedServerListRef = ref(null)
    const baseApiUrl = ref('')
    const accessToken = ref('')

    let _sysLib = getSysLib()
    if (_sysLib && _sysLib.vjmap && _sysLib.vjmap.endpoint && _sysLib.vjmap.token) {
        baseApiUrl.value = _sysLib.vjmap.endpoint
        accessToken.value = _sysLib.vjmap.token
    }
    const createdServer = () => {
        console.log(props.type, props.displayMapType);
        publishedServerListRef.value.updateServerList()
    }
    defineExpose({
        createdServer
    })
</script>

<style lang='less' scoped>
    .portal-menu-table-add-modal-footer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>

<style>
    .portal-menu-table-add-modal-switch .ant-switch-inner {
        width: 38px;
    }
</style>