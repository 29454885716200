<template>
    <div>
        <cgs-modal :title="$t('modifyAppTypeName')" width="500px" :visible="visible" :destroyOnClose="true"
            @close="$emit('cancelModal')">
            <template #content>
                <cgs-form ref="sceneForm" :model="formSceneTypeName">
                    <cgs-form-item :label="$t('common.name')+':'"
                        :rules="[{required: true,message: $t('remind.inputRemind'),trigger: 'blur'}]" name="typeName">
                        <cgs-input v-model:value="formSceneTypeName.typeName"></cgs-input>
                    </cgs-form-item>
                </cgs-form>
            </template>
            <template #footer>
                <div class="portal-menu-edit-scene-type-name-modal-footer">
                    <cgs-button @click="$emit('cancelModal')">{{ $t('common.cancel') }}</cgs-button>
                    <cgs-button type="primary" @click="updateSceneTypeName">{{ $t('common.ok') }}</cgs-button>
                </div>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
    import {
        defineComponent,
        reactive,
        onMounted,
        computed,
        getCurrentInstance,
        ref
    } from 'vue'
    import {
        useI18n
    } from 'vue-i18n'
    import {
        updateSceneType
    } from '../../api/scene'

    export default defineComponent({
        props: {
            config: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, ctx) {
            let {
                proxy
            } = getCurrentInstance()
            const {
                t
            } = useI18n()
            const formSceneTypeName = reactive({
                typeName: '',
            })
            const sceneForm = ref(null)
            let visible = computed({
                get: () => {
                    return props.config.visible
                },
                set: () => {
                    ctx.emit('cancelModal')
                }
            })

            const updateSceneTypeName = async () => {
                try {
                    await sceneForm.value.validateFields()
                    proxy.$loading.open()
                    let params = {
                        typeName: formSceneTypeName.typeName,
                        typeId: props.config.typeId,
                    }
                    let _result = await updateSceneType(params)
                    if (_result.code === 200) {
                        proxy.$message.success(t('common.modifySuccess'))
                        ctx.emit('ok', params)
                    } else {
                        proxy.$message.error(t('common.modifyFail'))
                    }
                    ctx.emit('cancelModal')
                    proxy.$loading.close()
                } catch (err) {
                    console.error(err)
                    proxy.$loading.close()
                    // proxy.$message.error(t('common.modifyFail'))
                }
            }
            onMounted(() => {
                formSceneTypeName.typeName = props.config.typeName
            })
            return {
                visible,
                formSceneTypeName,
                sceneForm,
                updateSceneTypeName
            }
        },
    })
</script>

<style scoped>
    .portal-menu-edit-scene-type-name-modal-footer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>